import React, { useState } from "react";
import { Slider, Switch } from "antd";

const CustomSlider = ({value,setFieldValue,name}) => {
  const onChange = (value) => {
    setFieldValue(name,value)
  };
  
  return (
    <>
      <Slider
        range
        step={1}
        value={value}
        onChange={onChange}
        tooltip={{
            open: true,
            placement: "bottom"
          }}
      />
    </>
  );
};
export default CustomSlider;
