import React, { useEffect, useState } from "react";
import Select from "react-select";
import "./paginationStyle.scss";

const Pagenation = ({
  data,
  next,
  loading,
  previous,
  totalLength,
  currDataCount,
  pageCount,
  pageSize = {
    label: "10",
    value: 10,
  },
  setPageSize,
  showPage = false,
  countVal = 1
}) => {
  const [initial, setInitial] = useState(0);
  const [count, setCount] = useState(countVal);
  const [prev, setPre] = useState(0);

  useEffect(() => {
    if (next !== null) {
      setPre(data?.length);
    }
    if (previous === null) {
      setInitial(0);
      setCount(1);
    }
  }, [data, next]);

  const handleClickNext = () => {
    setCount((count) => count + 1);
    setInitial(initial + prev);
    pageCount(count + 1);
  };

  const handleClickPrev = () => {
    setCount((count) => count - 1);
    setInitial(initial - prev);
    pageCount(count - 1);
  };

  const handlePageClick = (pageNumber) => {
    setCount(pageNumber);
    setInitial((pageNumber - 1) * pageSize.value);
    pageCount(pageNumber);
  };

  const page_count_list = [
    {
      label: "10",
      value: 10,
    },
    {
      label: "20",
      value: 20,
    },
    {
      label: "30",
      value: 30,
    },
    {
      label: "50",
      value: 50,
    },
  ];

  const totalPages = Math.ceil(totalLength / pageSize.value);

  const getPageButtons = () => {
    const buttons = [];
    const maxVisibleButtons = 8;
  
    if (totalPages <= maxVisibleButtons) {
      for (let i = 1; i <= totalPages; i++) {
        buttons.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`${
              count === i ? "bg-[#07415C] text-white px-3 py-1 rounded-lg" : "text-[#1B283F] bg-[#edebeb] px-3 py-1 rounded-lg"
            } font-semibold text-xs md:text-sm`}
          >
            {i}
          </button>
        );
      }
    } else {
      const halfVisibleButtons = Math.floor(maxVisibleButtons / 2);
      let startPage = Math.max(1, count - halfVisibleButtons);
      let endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1);
  
      if (endPage - startPage < maxVisibleButtons - 1) {
        startPage = endPage - maxVisibleButtons + 1;
      }
  
      buttons.push(
        <button
          key={startPage}
          onClick={() => handlePageClick(startPage)}
          className={`${
            count === startPage ? "bg-[#07415C] text-white px-3 py-1 rounded-lg" : "text-[#1B283F] bg-[#edebeb] px-3 py-1 rounded-lg"
          } font-semibold text-xs md:text-sm`}
        >
          {startPage}
        </button>
      );
  
      if (startPage > 1) {
        buttons.push(<span key="startEllipsis">...</span>);
      }
  
      for (let i = startPage + 1; i < endPage; i++) {
        buttons.push(
          <button
            key={i}
            onClick={() => handlePageClick(i)}
            className={`${
              count === i ? "bg-[#07415C] text-white px-3 py-1 rounded-lg" : "text-[#1B283F] bg-[#edebeb] px-3 py-1 rounded-lg"
            } font-semibold text-xs md:text-sm`}
          >
            {i}
          </button>
        );
      }
  
      if (endPage < totalPages) {
        buttons.push(<span key="endEllipsis">...</span>);
      }
  
      buttons.push(
        <button
          key={endPage}
          onClick={() => handlePageClick(endPage)}
          className={`${
            count === endPage ? "bg-[#07415C] text-white px-3 py-1 rounded-lg" : "text-[#1B283F] bg-[#edebeb] px-3 py-1 rounded-lg"
          } font-semibold text-xs md:text-sm`}
        >
          {endPage}
        </button>
      );
    }
  
    return buttons;
  };
  
  const handleSelect = (e)=> {
    let page = pageSize?.value
    if (setPageSize) {
      setPageSize(e);
    }
    
    let newPage = e.value
    let val = 1
    if(page < newPage){
      val = (page * count)/ newPage
    }else {
      val = ((page * count)/ newPage) - 1
    }
    let newCount = Math.ceil(val)
    pageCount(newCount)
    setInitial((e.value * newCount)-e.value);
    setCount(newCount);
  }
  
  
  return (
    <div className="flex w-full items-center justify-between gap-x-6 ">
      <div className="flex items-center gap-2">
      <p className="text-xs md:text-sm text-[#29273A] text-[14px]">
        Showing {initial + 1} - {currDataCount} of {totalLength}
      </p>
      {showPage ? (
          <div className="flex items-center gap-2">
            <Select
              options={page_count_list}
              value={pageSize}
              onChange={(e) => handleSelect(e)}
              // onChange={(e) => {
              //   if (setPageSize) {
              //     setPageSize(e);
              //   }
              //   setInitial((e.value * count)-e.value);
              // }}
              className="custom-select-pagination text-xs md:text-sm w-[5rem] rounded-lg border-none text-[#1B283F]"
            />
            <span className="text-xs md:text-sm text-[#29273A] text-[14px]">items per page</span>
          </div>
        ) : null}
      </div>

      <div className="flex items-center gap-2">
        {previous != null && (
          <button
            className={`${
              loading ? "text-midGray" : "text-[#1B283F] bg-[#edebeb] px-3 py-1 rounded-lg text-[14px]"
            } font-semibold text-xs md:text-sm`}
            onClick={handleClickPrev}
            disabled={loading ? true : false}
          >
            Previous
          </button>
        )}

        {getPageButtons()}

        {next !== null && (
          <button
            className={`${
              loading ? "text-midGray" : "text-[#1B283F] bg-[#edebeb] px-3 py-1 rounded-lg text-[14px]"
            } font-semibold text-xs md:text-sm`}
            onClick={handleClickNext}
            disabled={loading ? true : false}
          >
            Next
          </button>
        )}
        
      </div>
    </div>
  );
};

export default Pagenation;
