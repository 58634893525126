import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AddressCard = ({
  type,
  flatname,
  street,
  pincode,
  landmark,
  personal,
}) => {
  return (
    <div className="p-6 bg-lightYellow rounded-xl shadow-lg flex-1">
      <div className="flex flex-col gap-3">
        <div className="flex gap-2 items-center">
          <h2 className="font-semibold text-sm">Name</h2> :{" "}
          <p className="font-semibold text-base text-darkBlue">
            {personal.name}
          </p>
        </div>
        <div className="text-sm">
          <p>
            <span>{flatname}</span> <span>{street}</span>,{" "}
            <span>{landmark}</span>, <span>{pincode}</span>
          </p>
        </div>
        <div className="flex gap-2 items-center">
          <h2 className="text-sm">Mobile number</h2> :{" "}
          <p className="font-semibold text-sm">{personal.mobile}</p>
        </div>
        <div className="flex gap-2 items-center">
          <h2 className="text-sm">Address type</h2> :{" "}
          <p className="font-semibold text-sm">{type}</p>
        </div>
      </div>
    </div>
  );
};

const Address = ({ data, personal, loading }) => {
  return (
    <div
      className={`w-full grid min-h-[14rem]  grid-cols-1 ${
        data?.length === 0
          ? "hxl:grid-cols-1 place-content-center "
          : "hxl:grid-cols-2"
      }  gap-4 gap-12 mt-12 `}
    >
      {loading ? (
        <Skeleton height={200} />
      ) : data?.length === 0 ? (
        <div className="m-auto">
          <div className="flex justify-center">
            <svg
              version="1.0"
              xmlns="http://www.w3.org/2000/svg"
              width="40px"
              height="40px"
              viewBox="0 0 100.000000 100.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,100.000000) scale(0.100000,-0.100000)"
                fill="#07415C"
                stroke="none"
              >
                <path
                  d="M314 922 c-93 -33 -166 -100 -207 -189 -34 -74 -31 -199 6 -275 35
-72 93 -130 165 -165 49 -24 69 -28 142 -28 73 0 93 4 144 29 l58 29 129 -129
129 -129 27 28 28 27 -128 128 -129 129 30 47 c106 167 39 393 -145 482 -72
36 -176 42 -249 16z m240 -56 c105 -51 161 -144 161 -266 0 -85 -21 -142 -74
-203 -82 -92 -237 -121 -353 -64 -160 79 -215 278 -118 430 80 126 246 170
384 103z"
                />
                <path
                  d="M270 705 c-26 -32 13 -81 48 -59 34 22 22 74 -18 74 -10 0 -23 -7
-30 -15z"
                />
                <path
                  d="M510 705 c-26 -32 13 -81 48 -59 34 22 22 74 -18 74 -10 0 -23 -7
-30 -15z"
                />
                <path
                  d="M355 526 c-39 -17 -61 -44 -48 -57 7 -7 21 -4 44 10 19 12 50 21 69
21 19 0 50 -9 69 -21 39 -23 65 -12 40 17 -33 41 -117 55 -174 30z"
                />
              </g>
            </svg>
          </div>
          <p className="text-sm font-semibold text-darkBlue">
            Address not found
          </p>
        </div>
      ) : (
        data?.map((item, index) => {
          return (
            <AddressCard
              key={index + 1}
              type={item.address_type}
              flatname={item.build_flatname}
              landmark={item.landmark}
              pincode={item.pincode}
              street={item.street}
              personal={personal}
            />
          );
        })
      )}
    </div>
  );
};

export default Address;
