import { axiosInstance } from "../../services/apiInitialSetup";

const addRole = async (data) => {
    try {
      const response = await axiosInstance.post(`/userrole/groups/`,data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const getRole = async ({page = 1,pageSize = 10}) => {
    try {
      const response = await axiosInstance.get(`/userrole/groups/?page=${page}&size=${pageSize}`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const getRoleUsers = async (data) => {
    try {
      const response = await axiosInstance.get(`/userrole/groups/${data}/users/`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const getSingleRoles = async (data) => {
    try {
      const response = await axiosInstance.get(`/userrole/groups/${data}/`);
      return response;
    } catch (error) {
      throw error;
    }
  };
  const addUser = async (data) => {
    try {
      const response = await axiosInstance.post(`/userrole/users/`,data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const editUserRole = async (data) => {
    try {
      const response = await axiosInstance.patch(`/userrole/groups/${data.name}/`,data.data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const userDelete = async (data) => {
    try {
        const response = await axiosInstance.delete(`userrole/groups/${data.groupID}/user/${data.id}/`, data);
        return response;
    } catch (error) {
        throw error;
    }
  };

  export{
    addRole,
    getRole,
    getRoleUsers,
    getSingleRoles,
    addUser,
    editUserRole,
    userDelete
  }