import React, { useState } from "react";
import ModalLayout from "../../../components/customModal/CustomModal";
import upload from "../../../assets/images/commonImages/uploadTwo.svg";
import download from "../../../assets/images/commonImages/downlodTwo.svg";
import "./produxtManegementComponentsStyle.scss";
import { toast } from "react-hot-toast";
import { useAddProductExcel } from "../../../servicesQuery/productQuery/productQuery";
import Button from "../../../components/submitButton/Button";
import { productExcelDownload } from "../../../servicesQuery/productQuery/productFunctions";

const UploadExcelModal = ({ open, setOpen }) => {
  const [file, setFile] = useState("");
  const formData = new FormData();

  const {
    mutateAsync: uploadExcel,
    isLoading: excelLoading,
    isSuccess,
  } = useAddProductExcel();
  const handleUpload = () => {
    formData.append("excel", file);

    toast.promise(
      uploadExcel(formData).then((response) => {
        setOpen(false);
        setFile("");
      }),
      {
        loading: <b>Uploading</b>,
        success: <b>Uploaded</b>,
        error: <b>Could not upload.</b>,
      }
    );
  };
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="bg-white rounded-xl w-[514px]  ">
        <div>
          <div className="w-full flex items-center justify-between p-6 border border-b-[1px] border-lightBlue">
            <h1 className="text-lg text-darkBlue font-semibold">
              Product Upload
            </h1>
            <button onClick={() => setOpen(false)}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3563 7.9998L15.4674 2.88869C16.1119 2.24425 16.1119 1.17758 15.4674 0.533138C14.823 -0.111306 13.7563 -0.111306 13.1119 0.533138L8.00078 5.64425L2.88967 0.533138C2.24522 -0.111306 1.17856 -0.111306 0.534115 0.533138C-0.11033 1.17758 -0.11033 2.24425 0.534115 2.88869L5.64522 7.9998L0.534115 13.1109C-0.11033 13.7554 -0.11033 14.822 0.534115 15.4665C0.867448 15.7998 1.28967 15.9554 1.71189 15.9554C2.13411 15.9554 2.55634 15.7998 2.88967 15.4665L8.00078 10.3554L13.1119 15.4665C13.4452 15.7998 13.8674 15.9554 14.2897 15.9554C14.7119 15.9554 15.1341 15.7998 15.4674 15.4665C16.1119 14.822 16.1119 13.7554 15.4674 13.1109L10.3563 7.9998Z"
                  fill="#07415C"
                />
              </svg>
            </button>
          </div>
          <div className="p-6 border border-b-[1px] border-lightBlue">
            <div className="text-base mb-4">
              <p>
                Please download the excel spreadsheet, edit and upload with the
                details as per the template information
              </p>
            </div>
            <div className="flex items-center gap-6 ">
              <div>
                <img className="w-[4rem]" src={upload} alt="upload" />
              </div>
              <button
                onClick={() => {
                  productExcelDownload().then((res) => {
                    const link = document.createElement("a");
                    const url = URL.createObjectURL(res?.data);
                    link.href = url;
                    link.download = "ProductExcel.xlsx";
                    link.click();
                  });
                }}
              >
                <img src={download} alt="download" />
              </button>
            </div>
          </div>
          <div className="p-6 border border-b-[1px] flex flex-col justify-betwnn border-lightBlue">
            <div className="text-base mb-8">
              <p>
                Upload excel spreadsheet, with the details as per the template
                information
              </p>
            </div>
            <div className="text-base mb-8">
              <p>Note : File should be xlsx format</p>
            </div>
            <div className="flex items-center gap-4  border border-[1px] border-[#AB9E9E] rounded-lg overflow-hidden">
              <div className="px-3 py-2 border-r-[1px] border-[#AB9E9E] cursor-pointer hover:bg-tertiary transition-all">
                <input
                  onChange={(e) => {
                    setFile(e?.target?.files[0]);
                  }}
                  hidden
                  className="excel-upload-input"
                  id="excel"
                  type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel,text/comma-separated-values, text/csv, application/csv"
                />
                <label
                  htmlFor="excel"
                  className="  text-sm cursor-pointer text-[#AB9E9E]"
                >
                  Choose file
                </label>
              </div>
              <div className="text-sm text-[#AB9E9E]">
                <p>{file !== "" ? file?.name : "No file choosen"}</p>
              </div>
            </div>
          </div>
          <div className="p-6 w-full flex justify-center">
            <Button
              style={
                "transition-all text-xs w-[10rem]   py-2 px-6  px-10 py-1 text-sm text-white add-live-btn bg-btn-gradient rounded-md"
              }
              content="Ok"
              onClick={() => {
                handleUpload();
              }}
              disabled={file === "" || excelLoading ? true : false}
            />
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default UploadExcelModal;
