import { useMutation, useQuery, useQueryClient } from "react-query";
import { artmotionDesignLists, artmotionMetalLists, artmotionProductAdd, artmotionproductEdit, artmotionProductLists, artMotionSingleProduct, orderArtmotionList } from "./artmotionFunctions";

export const useGetArtmotionProductList = (data) => {
    return useQuery(["getArtmotionProductList", data], () => artmotionProductLists(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };

export const useGetArtmotionMetalList = (data) => {
    return useQuery(["getArtmotionMetalList", data], () => artmotionMetalLists(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };

export const useGetArtmotionDesignList = (data) => {
  return useQuery(["getArtmotionDesignList", data], () => artmotionDesignLists(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export const useGetArtmotionSingleProduct = (id) => {
  return useQuery(["getArtmotionSingleProduct", id], () => artMotionSingleProduct(id), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

export const useArtmotionAddProduct = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => artmotionProductAdd(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getArtmotionProductList");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

export const useArtmotionEditProduct = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => artmotionproductEdit(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getArtmotionProductList");
      queryClient.invalidateQueries("getArtmotionSingleProduct");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

export const useGetArtmotionOrderList = (data) => {
  let enabled = true
  if(data?.enabled == false){
    enabled = false
  }
  return useQuery(["orderArtmotionList", data], () => orderArtmotionList(data), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: enabled
  });
};