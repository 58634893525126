import { Form, Formik } from "formik";
import React from "react";
import SubmitButton from "../../../../../../components/submitButton/SubmitButton";
import BasicDetials from "./BasicDetials";
import ColorTheme from "./ColorTheme";
import TextEditComponent from "./TextEditComponent";
import {
  useAddAutoCoupon,
  useEditAutoCoupon,
  useGetAutoCoupons,
} from "../../../../../../servicesQuery/couponQuery/CouponQuery";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { isCoupons } from "../utils";
import MissYouCouponStructure from "./MissYouCouponStructure";

const autoCouponValidation = Yup.object().shape({
  cuponCode: Yup.string()
    .matches(/^[A-Z0-9]+$/, "Only uppercase letters and numbers are allowed")
    .min(5, "Minium 5 values required")
    .required("Coupon code  is required"),

  discValue: Yup.mixed().required("Discount value  is required"),
  minDiscValue: Yup.mixed().required("Minimum Order Amount  is required"),
  maxDiscAmount: Yup.string().when(["discType"], {
    is: (value) => value === "percentage",
    then: (schema) => schema.required("Maximum discount value is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  titleContent: Yup.string().required("Title content is required"),
  subContent: Yup.string().required("Sub content is required"),
  footerContent: Yup.string().required("Footer content is required"),
  detialsContent: Yup.string().required("Detials content is required"),
});
const MissYouCouponAddEdit = () => {
  const { data } = useGetAutoCoupons();

  const initialValue = {
    cuponCode: isCoupons(data?.data?.results, "miss you")?.code || "",
    discType:
      isCoupons(data?.data?.results, "miss you")?.discount_type || "fixed",
      discValue:
      isCoupons(data?.data?.results, "miss you")?.discount_type === "percentage"
        ? isCoupons(data?.data?.results, "miss you")?.discount_value * 100
        : isCoupons(data?.data?.results, "miss you")?.discount_value || "",
    minDiscValue:
      isCoupons(data?.data?.results, "miss you")?.minimum_order_amount || "",
    maxDiscAmount:
      isCoupons(data?.data?.results, "miss you")?.maximum_discount_value || "",
    colorThemeOne:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.color_theme_one || "#f9f1e6",
    colorThemeTwo:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.color_theme_two || "#07415C",
    titleContent:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.title_content || "",
    titleFontColor:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.title_color || "#07415C",
    titleFontBold:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.title_bold || false,
    titleFontItalics:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.title_italics || false,
    titleFontUnderline:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.title_underline || false,
    subContent:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.sub_content || "",
    subFontColor:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.sub_color || "#07415C",
    subFontBold:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.sub_bold || false,
    subFontItalics:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.sub_italics || false,
    subFontUnderline:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.sub_underline || false,
    codeFontColor:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.code_color || "#07415C",
    codeFontBold:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.code_bold || false,
    codeFontItalics:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.code_italics || false,
    codeFontUnderline:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.code_underline || false,
    discountFontColor:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.discount_color || "#ffffff",
    discountFontBold:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.discount_bold || false,
    discountFontItalics:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.discount_italics || false,
    discountFontUnderline:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.discount_underline || false,
    footerContent:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.footer_content || "",
    footerFontColor:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.footer_color || "#000000",
    footerFontBold:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.footer_bold || false,
    footerFontItalics:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.footer_italics || false,
    footerFontUnderline:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.footer_underline || false,
    detialsContent:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.detials_content || "",
    detialsFontColor:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.detials_color || "#000000",
    detialsFontBold:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.detials_bold || false,
    detialsFontItalics:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.detials_italics || false,
    detialsFontUnderline:
      isCoupons(data?.data?.results, "miss you")?.coupon_template_data
        ?.detials_underline || false,
  };

  const { mutateAsync: addAutoCoupon, isLoading } = useAddAutoCoupon();
  const { mutateAsync: editAutoCoupon, isLoading: editLoading } =
    useEditAutoCoupon();

  const onSubmit = (values) => {
    const autodata = {
      coupon_for: "miss you",
      code: values.cuponCode,
      discount_type: values.discType,
      discount_value:
        values.discType === "percentage"
          ? values.discValue / 100
          : values.discValue,
      minimum_order_amount: values.minDiscValue,
      ...(values.discType === "percentage" && {
        maximum_discount_value: parseInt(values?.maxDiscAmount),
      }),
      use_default_template: true,
      coupon_template_data: {
        color_theme_one: values.colorThemeOne,
        color_theme_two: values.colorThemeTwo,
        color_theme_three: values.colorThemeThree,

        title_content: values.titleContent,
        title_color: values.titleFontColor,
        title_bold: values.titleFontBold,
        title_italics: values.titleFontItalics,
        title_underline: values.titleFontUnderline,
        sub_content: values.subContent,
        sub_color: values.subFontColor,
        sub_bold: values.subFontBold,
        sub_italics: values.subFontItalics,
        sub_underline: values.subFontUnderline,
        detials_content: values.detialsContent,
        detials_color: values.detialsFontColor,
        detials_bold: values.detialsFontBold,
        detials_italics: values.detialsFontItalics,
        detials_underline: values.detialsFontUnderline,

        code_color: values.codeFontColor,
        code_bold: values.codeFontBold,
        code_underline: values.codeFontUnderline,
        code_italics: values.codeFontItalics,
        discount_color: values.discountFontColor,
        discount_bold: values.discountFontBold,
        discount_italics: values.discountFontItalics,
        discount_underline: values.discountFontUnderline,
        footer_content: values.footerContent,
        footer_color: values.footerFontColor,
        footer_bold: values.footerFontBold,
        footer_italics: values.footerFontItalics,
        footer_underline: values.footerFontUnderline,
      },
    };

    if (isCoupons(data?.data?.results, "miss you") === undefined) {
      addAutoCoupon(autodata)
        .then((res) => {
          toast.success("Coupon added");
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    } else {
      const formData = {
        data: autodata,
        id: isCoupons(data?.data?.results, "miss you")?.id,
      };
      editAutoCoupon(formData)
        .then((res) => {
          toast.success("Coupon added");
        })
        .catch((err) => {
          if (err?.response?.data?.code) {
            toast.error(err?.response?.data?.code);
          } else {
            toast.error("Something went wrong");
          }
        });
    }
  };

  return (
    <div className="h-full">
      <Formik
        initialValues={initialValue}
        enableReinitialize
        validationSchema={autoCouponValidation}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, handleChange, errors }) => (
          <Form>
            <div className="flex flex-col-reverse lg:flex-row gap-12">
              <div className="w-full lg:w-[50%]">
                <BasicDetials
                  values={values}
                  setFieldValue={setFieldValue}
                  change={handleChange}
                />
                <ColorTheme values={values} setFieldValue={setFieldValue} />

                <TextEditComponent
                  name={{
                    data: "titleContent",
                    color: "titleFontColor",
                    bold: "titleFontBold",
                    italic: "titleFontItalics",
                    underline: "titleFontUnderline",
                  }}
                  setFieldValue={setFieldValue}
                  title="Head"
                  values={values}
                  isError={errors.titleContent}
                />

                <TextEditComponent
                  name={{
                    data: "subContent",
                    color: "subFontColor",
                    bold: "subFontBold",
                    italic: "subFontItalics",
                    underline: "subFontUnderline",
                  }}
                  setFieldValue={setFieldValue}
                  title="Sub Head"
                  values={values}
                  isError={errors.subContent}
                />
                <TextEditComponent
                  name={{
                    data: "detialsContent",
                    color: "detialsFontColor",
                    bold: "detialsFontBold",
                    italic: "detialsFontItalics",
                    underline: "detialsFontUnderline",
                  }}
                  setFieldValue={setFieldValue}
                  title="Details Content"
                  values={values}
                  isError={errors.detialsContent}
                />
                <TextEditComponent
                  name={{
                    // data: "codeContent",
                    color: "codeFontColor",
                    bold: "codeFontBold",
                    italic: "codeFontItalics",
                    underline: "codeFontUnderline",
                  }}
                  setFieldValue={setFieldValue}
                  title="Code"
                  values={values}
                  isData={false}
                />
                <TextEditComponent
                  name={{
                    // data: "discountContent",
                    color: "discountFontColor",
                    bold: "discountFontBold",
                    italic: "discountFontItalics",
                    underline: "discountFontUnderline",
                  }}
                  setFieldValue={setFieldValue}
                  isData={false}
                  title="Discount"
                  values={values}
                />
                <TextEditComponent
                  name={{
                    data: "footerContent",
                    color: "footerFontColor",
                    bold: "footerFontBold",
                    italic: "footerFontItalics",
                    underline: "footerFontUnderline",
                  }}
                  setFieldValue={setFieldValue}
                  title="Footer Content"
                  values={values}
                  isError={errors.footerContent}
                />
              </div>
              <div className="w-full lg:w-[50%]">
                <div className=" flex flex-col gap-6 mb-5 items-end">
                  <div className="w-[10rem] hidden lg:block">
                    <SubmitButton
                      content={"Save template"}
                      type="submit"
                      isLoading={isLoading || editLoading}
                    />
                  </div>
                </div>
                <MissYouCouponStructure values={values} />
              </div>
            </div>

            <div className="w-[10rem]  mx-auto block lg:hidden">
              <SubmitButton
                content={"Save template"}
                type="submit"
                isLoading={isLoading || editLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default MissYouCouponAddEdit;
