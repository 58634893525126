import React, { useContext } from "react";
import ProgressBar from "./ProgressBar";
import { useGetUploadTaskList } from "../../../servicesQuery/productQuery/productQuery";
import { Context } from "../../../utilities/context/Context";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function UploadProgressPage() {
  const { data,isLoading, isFetching, isError, refetch } =  useGetUploadTaskList();
  const loopCount = 12
  const { progress, setProgres } = useContext(Context);

  const getProgress = (task_id) => {
    let task = progress?.filter((i) => i.task_id === task_id)[0];
    if (task?.progress) {
      if (task?.progress === 100) {
        refetch();
      }
      return task?.progress;
    } else {
      return 0;
    }
  };
  return (
    <>
      {isLoading || isFetching ? (
        <div className="flex items-center flex-wrap gap-10">
          {Array.from({ length: loopCount }, (_, index) => (
        <div
          key={index}
          className="rounded-lg sm:w-[46%] lg:w-[22%] w-[100%]"
        >
          <SkeletonTheme baseColor="#e2e0e0" highlightColor="#f3f2f2">
            <Skeleton count={1} height={100} />
          </SkeletonTheme>
        </div>
      ))}
        </div>
      ) : (
        <div className="flex items-center flex-wrap gap-10">
          {data?.data?.map((i, index) => (
            <div
              className="p-5 rounded-lg bg-white sm:w-[46%] lg:w-[22%] w-[100%]"
              key={index}
            >
              <p className="font-bold text-darkBlue">Task {index + 1}</p>
              <ProgressBar
                percentage={i.is_running ? getProgress(i.task_id) : 100}
              />
              <div className="flex items center justify-between">
                <span className="text-sm font-bold">Total</span>
                <span className="text-sm font-bold text-[#3B82F6]">
                  {i.is_running
                    ? "-"
                    : Number(i?.success_count) + Number(i?.failed_count)}
                </span>
              </div>
              <div className="flex items center justify-between">
                <span className="text-sm font-medium">Completed</span>
                <span className="text-sm font-medium text-[#22C55E]">
                  {i.is_running ? "-" : i?.success_count}
                </span>
              </div>
              <div className="flex items center justify-between">
                <span className="text-sm font-medium">Failed</span>
                <span className="text-sm font-medium text-[#EF4444]">
                  {i.is_running ? "-" : i?.failed_count}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default UploadProgressPage;
