import React, { useEffect, useState } from "react";
import SingleDatePicker from "../../../components/datePicker/SingleDatePicker";
import CustomTable from "../../../components/customTable/CustomTable";
import Pagenation from "../../../components/pagination/Pagenation";
import download from "../../../assets/images/commonImages/download.svg";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import {
  useGetUserOrder,
  useTrackingDetialsUpdate,
} from "../../../servicesQuery/orderQuery/OrderQuerys";
import OrderTrackingDetialsModal from "../../../components/orderTrackingDetialsModal/OrderTrackingDetialsModal";
import OrderItemsDetialsModal from "../../../components/orderItemDetialsModal/OrderItemsDetialsModal";
import { toast } from "react-hot-toast";
import Dropdown from "../../../components/dropDown/Dropdown";
import eye from "../../../assets/images/commonImages/eyeBlue.png";
import OrderTrackingDetials from "../../../components/orderTrackingDetials/OrderTrackingDetials";
import { limitFraction } from "../../../utilities/limitFractionNumber/limitFraction";
import { getDynamicHead } from "../../couponManagement/couponManagementSubPages/autoCoupons/autoCouponsComponents/utils";
import DynamicColumnFilter from "../../../components/customTable/DynamicColumnFilter";
import TableFilterDropDown from "../../../components/customTable/TableFilterDropDown";

const head1 = [
  {
    key: 1,
    label: "Order ID",
    index: "orderid",
    width: 12,
    is_checked: true,
  },
  {
    key: 2,
    label: "User Name",
    index: "name",
    width: 8,
    is_checked: true,
  },
  // {
  //   key: 3,
  //   label: "Address of User",
  //   index: "address",
  //   width: 13,
  //   is_checked: true,
  // },
  {
    key: 4,
    label: "Order Status",
    index: "orderstatus",
    width: 6,
    is_checked: true,
  },

  {
    key: 5,
    label: "Details",
    index: "productdetials",
    width: 4,
    is_checked: true,
  },
  {
    key: 6,
    label: "Timestamp",
    index: "datetime",
    width: 8,
    is_checked: true,
  },
  {
    key: 7,
    label: "Price",
    index: "price",
    width: 7,
    is_checked: true,
  },
  {
    key: 8,
    label: "Payment Status",
    index: "paymentstatus",
    width: 8,
    is_checked: true,
  },
  {
    key: 9,
    label: "Address",
    index: "dellveryaddress",
    width: 13,
    is_checked: true,
  },

  {
    key: 5,
    label: "Order Status Detials",
    index: "trackingDetials",
    width: 7,
    is_checked: true,
  },

  {
    key: 11,
    label: "Invoice",
    index: "invoice",
    width: 8,
    is_checked: true,
  },
  {
    key: 12,
    label: "Action",
    index: "action",
    width: 9,
    is_checked: true,
  },
];

const OrderDetials = ({ id }) => {
  const dynamicHead = getDynamicHead("user_order_details");
  const [head, setHead] = useState(head1);
  const [dynamicData, setDynamicData] = useState(
    JSON.parse(localStorage.getItem("dynamicData"))
  );
  const [pageCount, setPageCount] = useState(1);
  const [date, setDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [isEnabled, setEnabled] = useState(true);
  const [filterCount, setFilterCount] = useState(0);
  const [orderItem, setOrderItems] = useState();
  const [open, setOpen] = useState(false);
  const [trackingOpen, setTrackingOpen] = useState(false);
  const [orderOpen, setOrderOpen] = useState(false);
  const [trackingData, setTrackingData] = useState("");
  const [orderTrackingData, setOrderTrackingData] = useState("");

  const formData = new FormData();
  const qry = {
    id: id,
    page: pageCount,
    start_date: date,
    end_date: to_date,
    enabled: isEnabled
  };
  const { data, isLoading, isFetching, isError } = useGetUserOrder(qry);
  const { mutateAsync: updateDetials } = useTrackingDetialsUpdate();

  useEffect(() => {
    if (dynamicHead) {
      setHead(dynamicHead);
    }
  }, []);

  useEffect(() => {
    setPageCount(1);
  }, [date,to_date]);

  const handleFilter = (type)=> {
    if(type === "clear"){
      setDate("")
      setToDate("")
      setEnabled(false)
    }else if(type === "save"){
      setEnabled(true)
      let count = 0
      if(date){
        count += 1
      }
      if(to_date){
        count += 1
      }
      setFilterCount(count)
    }
  }

  const ordersBody = () => {
    return data?.data?.results?.map((item) => {
      return {
        orderid: item.order_id || "No data",
        name: item?.user?.full_name || "No data",
        // address:
        //   `${item.address.build_flatname}, ${item.address.street} ${item.address.landmark},${item.address.state},${item.address.pincode}` ||
        //   "No data",
        orderstatus: {
          isRender: true,
          render: () => {
            if (item.status !== 6 && item.status !== 5) {
              return (
                <div>
                  <Dropdown
                    style="border-[1px] border-lightBlue rounded-md"
                    heigth="max-h-[8rem]"
                    styles="text-darkBlue"
                    onchange={(e) => {
                      setTrackingData({
                        status: e.id,
                        id: item.id,
                      });
                      if (e.id !== 4 && e.id !== 2) {
                        formData.append("status", e.id);
                        toast.promise(
                          updateDetials({
                            id: item.id,
                            data: formData,
                          }).then(() => {
                            setTrackingData("");
                          }),
                          {
                            loading: <b>Updating status</b>,
                            success: <b>Updated</b>,
                            error: <b>Could not update.</b>,
                          }
                        );
                      } else if (e.id === 4) {
                        setTrackingOpen(true);
                      }
                    }}
                    values={() => {
                      if (item.status === 2) {
                        return "Placed";
                      } else if (item.status === 3) {
                        return "Processing";
                      } else if (item.status === 4) {
                        return "Shipped";
                      } else if (item.status === 5) {
                        return "Delivered";
                      }
                    }}
                    options={[
                      {
                        content: "Placed",
                        id: 2,
                        disabled: item.history["Placed"] ? true : false,
                      },
                      {
                        content: "Processing",
                        disabled:
                          item.history.Shipped || item.history.Processing
                            ? true
                            : false,
                        id: 3,
                      },
                      {
                        content: "Shipped",
                        disabled: item.history.Shipped ? true : false,
                        id: 4,
                      },
                      {
                        content: "Delivered",
                        id: 5,
                      },
                    ]}
                  />
                </div>
              );
            } else {
              return (
                <div className="w-full px-4">
                  <h1 className=" font-bold text-darkYellow text-xs">
                    {item.status === 5 && "Delivered"}
                    {item.status === 6 && "Cancelled"}
                  </h1>
                </div>
              );
            }
          },
        },
        productdetials: {
          isRender: true,
          render: () => {
            return (
              <div>
                <div className="p-2 rounded-lg border-[1px] border-lightBlue hover:bg-lightBlue transition-all">
                  <button
                    onClick={() => {
                      setOpen(true);
                      setOrderItems({
                        data: item?.order_items,
                        id: item.order_id,
                      });
                    }}
                    className="text-darkYellow "
                  >
                    View
                  </button>
                </div>
              </div>
            );
          },
        },
        datetime:
          dayjs(item.created_at).format("DD/MM/YYYY") +
            " " +
            dayjs(item.created_at).format("hh:mm A") || "No data",
        price: "Rs " + limitFraction(item.total_amount) || "No data",
        paymentstatus: {
          isRender: true,
          render: () => {
            return (
              <div>
                {item.is_paid ? (
                  <p className="text-xs font-semibold text-status">Paid</p>
                ) : (
                  <p className="text-xs font-semibold text-darkBlue">Pending</p>
                )}
              </div>
            );
          },
        },
        dellveryaddress:
          `${item.address.build_flatname}, ${item.address.street} ${item.address.landmark},${item.address.state},${item.address.pincode}` ||
          "No data",

        trackingDetials: {
          isRender: true,
          render: () => {
            return (
              <div
                onClick={() => {
                  setOrderOpen(true);
                  setOrderTrackingData(item?.history);
                }}
                className="w-[20px] cursor-pointer"
              >
                <img className="w-full" src={eye} alt="eye" />
              </div>
            );
          },
        },
        invoice: {
          isRender: true,
          render: () => {
            return (
              <div>
                <Link
                  target="_blank"
                  to={item?.invoice_pdf}
                  className="text-sm text-darkYellow underline"
                >
                  <img src={download} alt="download" />
                </Link>
              </div>
            );
          },
        },

        action: {
          isRender: true,
          render: () => (
            <div className="flex   gap-4 ">
              <div className="p-2 rounded-lg hover:bg-lightBlue transition-all flex items-center">
                <Link to={`/order-management/order-detials/${item.id}`}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 9 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.999973 16.92L7.51997 10.4C8.28997 9.62996 8.28997 8.36996 7.51997 7.59996L0.999973 1.07996"
                      stroke="#07415C"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          ),
        },
      };
    });
  };
  return (
    <div className=" mt-6 h-full">
      <OrderTrackingDetialsModal
        data={trackingData}
        open={trackingOpen}
        setOpen={setTrackingOpen}
      />
      <OrderItemsDetialsModal data={orderItem} open={open} setOpen={setOpen} />
      <OrderTrackingDetials
        opan={orderOpen}
        data={orderTrackingData}
        setOpen={setOrderOpen}
      />

      <div className="w-full h-[full] bg-white rounded-xl shadow-lg p-6 ">
        <div className="h-[78%]">
          <div className="relative w-full flex justify-between mb-8">
            <h1 className="text-base font-bold">Order Detials</h1>
            <div className="flex items-center gap-3">
              {/* <div className="w-[10rem]">
                <SingleDatePicker
                  onDateChange={(e) => {
                    if (e) {
                      setDate(e?.toLocaleDateString());
                    } else {
                      setDate("");
                    }
                  }}
                />
              </div> */}
              <TableFilterDropDown name="user_management" setDate={setDate} setToDate={setToDate} setEnabled={setEnabled} filterCount={filterCount} handleFilter={handleFilter} date={date} to_date={to_date} />
              <div className="w-[10rem]">
                <DynamicColumnFilter
                  head={head}
                  name="user_order_details"
                  setHead={setHead}
                  dynamicData={dynamicData}
                  setDynamicData={setDynamicData}
                />
              </div>
            </div>
          </div>
          <div className="w-full h-[90%]">
            <CustomTable
              head={head}
              height="h-[20rem]"
              dataLength={data?.data?.results?.length}
              loading={isLoading || isFetching}
              body={ordersBody()}
              error={isError}
              is_dynamic={true}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center my-6">
        <Pagenation
          data={data?.data?.results}
          loading={isLoading || isFetching}
          next={data?.data?.next}
          previous={data?.data?.previous}
          currDataCount={data?.data?.data_count}
          totalLength={data?.data?.count}
          pageCount={setPageCount}
        />
      </div>
    </div>
  );
};

export default OrderDetials;
