import React, { useEffect } from "react";
import { Chart, registerables } from 'chart.js'
import { Doughnut } from 'react-chartjs-2';
import { useState } from "react";
Chart.register(...registerables)

const OrderPieChart = ({active_order, completed_order,cancelled_order}) => {
    const isDataZero = active_order === 0 && completed_order === 0 && cancelled_order === 0;
    const data = {
        labels: ['Orders', 'Orders','Orders'],
        datasets: [
          {
            data: [active_order, completed_order,cancelled_order], // Replace with your actual data
            backgroundColor: ['#F19A0E', '#07415C', '#8e0b12'], // Specify colors
          },
        ],
      };
    
      // Calculate the total value
      const totalValue = data.datasets[0].data.reduce((acc, value) => acc + value, 0);
    
      const options = {
        plugins: {
          legend: {
            display: false, // Hide the legend
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                const labelIndex = context.dataIndex;
                if (labelIndex === 0) {
                  return `Active: ${context.parsed}`;
                } else if (labelIndex === 1) {
                  return `Completed: ${context.parsed}`;
                } else if (labelIndex === 2) {
                  return `Cancelled: ${context.parsed}`;
                }
              },
              afterLabel: function (context) {
                return `Total: ${totalValue}`;
              },
            },
          },
        },
      };

      const optionsDefault = {
        plugins: {
          legend: {
            display: false, // Hide the legend
          },
          tooltip: {
            display: false,
            callbacks: {
              label: function (context) {
                return "0 Orders"
              },
            //   afterLabel: function (context) {
            //     return `Total: ${totalValue}`;
            //   },
            },
          },
        },
      };


      const plugins = [{
        beforeDraw: function(chart) {
             var width = chart.width,
             height = chart.height,
             ctx = chart.ctx;
             ctx.restore();
             ctx.font = 'bold 2.25rem sans-serif';
            ctx.fillStyle = '#07415C';
            ctx.textBaseline = 'middle';
             var text = totalValue,
             textX = Math.round((width - ctx.measureText(text).width) / 2),
             textY = height / 2;
             ctx.fillText(text, textX, textY);
             ctx.save();
        } 
      }]

      const pluginsDefault = [{
        beforeDraw: function(chart) {
             var width = chart.width,
             height = chart.height,
             ctx = chart.ctx;
             ctx.restore();
             ctx.font = 'bold 2.25rem sans-serif';
            ctx.fillStyle = '#07415C';
            ctx.textBaseline = 'middle';
             var text = 0,
             textX = Math.round((width - ctx.measureText(text).width) / 2),
             textY = height / 2;
             ctx.fillText(text, textX, textY);
             ctx.save();
        } 
      }]

      const uniqueKeyValue = () => {
        return `${Date.now()}-${Math.random()}`;
      };

    
      return (
        <div className="chart-container" style={{ width: '300px', height: '300px' }}>
            {isDataZero?
            <Doughnut
            data={{
              labels: [],
              datasets: [
                {
                  data: [1], // Add a dummy value to display the default color
                  backgroundColor: ['#CCCCCC'], // Default color
                },
              ],
            }}
            options={optionsDefault}
            plugins={pluginsDefault}
          />
             :
            <Doughnut key={uniqueKeyValue()} data={data} options={options} plugins={plugins}  />
            }
        </div>
      );
    
};

export default OrderPieChart;
