import React, { useState } from "react";

import { Formik, Form } from "formik";

import * as Yup from "yup";

import { toast } from "react-hot-toast";
import { useTrackingDetialsUpdate } from "../../servicesQuery/orderQuery/OrderQuerys";
import ModalLayout from "../customModal/CustomModal";
import SubmitButton from "../submitButton/SubmitButton";
import InputField from "../customField/CustomField";
import ErrorMessageComponent from "../errorMessage/ErrorMessageComponent";
import { ResetForm } from "../../utilities/resetForm/FormikContext";
import SingleDatePicker from "../datePicker/SingleDatePicker";
import "./orderTrackingDetialsStyles.scss";
import BorderdInputField from "../customField/BorderdInputField";
import dayjs from "dayjs";

const OrderTrackingDetialsModal = ({ open, setOpen, data }) => {
  const formData = new FormData();
  const [date, setDate] = useState("");
  const trackingValidation = () => {
    return Yup.object().shape({
      trackingId: Yup.string().required("Tracking id is required"),
      trackingLink: Yup.string().required("Tracking Link is required"),
      // trackingLink: Yup.string()
      //   .required("Tracking Link is required")
      //   .matches(
      //     /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      //     "Please enter correct url"
      //   ),
      expectedDate: Yup.string().required("Date is required"),
    });
  };
  const initialValue = {
    trackingId: "",
    trackingLink: "www.indiapost.gov.in/_layouts/15/dop.portal.tracking/trackconsignment.aspx",
    expectedDate: "",
  };

  const { mutateAsync: updateDetials, isLoading } = useTrackingDetialsUpdate();

  const handleUpdate = (values) => {
    if (!/((https?):\/\/)?(www\.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/.test(values.trackingLink)) {
      toast.error("Please enter a correct URL for Tracking Link");
      return;
    }

    let trackingLink = values.trackingLink
    if(!values.trackingLink.includes("https://")){
      trackingLink = "https://" + trackingLink
    }
    formData.append("status", data?.status);
    formData.append("tracking_id", values.trackingId);
    formData.append("tracking_link", trackingLink);
    formData.append("expected_delivery", values.expectedDate);
    toast.promise(
      updateDetials({
        id: data?.id,
        data: formData,
      }).then(() => {
        setOpen(false);
      }),
      {
        loading: <b>Updating status</b>,
        success: <b>Updated</b>,
        error: <b>Could not update.</b>,
      }
    );
  };
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="p-9 bg-white rounded-lg w-[600px]">
        <div className="w-full flex flex-col items-center">
          <h1 className="text-darkBlue font-semibold mb-6">Traking Details</h1>
          <div className="w-full">
            <Formik
              initialValues={initialValue}
              validationSchema={trackingValidation}
              onSubmit={handleUpdate}
            >
              {({ handleChange, values, setFieldValue }) => (
                <Form>
                  <ResetForm isOpen={open} />
                  <div>
                    <label
                      className="block mb-3 text-sm text-darkBlue"
                      htmlFor="type"
                    >
                      Tracking ID
                    </label>
                    <BorderdInputField
                      type="text"
                      placeholder="Tracking ID"
                      name="trackingId"
                      onChange={handleChange}
                      value={values.trackingId}
                    />
                    <ErrorMessageComponent name="trackingId" />
                  </div>
                  <div>
                    <label
                      className="block mb-3 text-sm text-darkBlue"
                      htmlFor="type"
                    >
                      Tracking Link
                    </label>
                    <BorderdInputField
                      placeholder="Tracking Link"
                      name="trackingLink"
                      onChange={handleChange}
                      value={values.trackingLink}
                    />
                    <ErrorMessageComponent type="text" name="trackingLink" />
                  </div>
                  <div>
                    <label
                      className="block mb-3 text-sm text-darkBlue"
                      htmlFor="type"
                    >
                      Expected Date
                    </label>
                    <SingleDatePicker
                      onDateChange={(e) => {
                        if (e) {
                          setFieldValue("expectedDate", e.toISOString());
                        }
                      }}
                      style="order-detials-date"
                      dateIcon={false}
                      name="expectedDate"
                      setDate={setDate}
                      date={date}
                      open={open}
                      disableDate={dayjs().toDate()}
                    />
                    <ErrorMessageComponent type="text" name="expectedDate" />
                  </div>
                  <div className="flex w-full justify-center">
                    <SubmitButton
                      isLoading={isLoading}
                      width="w-[10rem]"
                      content="Add"
                      type="Submit"
                    />
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default OrderTrackingDetialsModal;
