import React, { useEffect } from "react";
import "./customModalStyles.scss";

const ModalLayout = ({ children, setModalOpen, modalOpen }) => {
  useEffect(() => {
    if (modalOpen) {
      document.querySelector("body").style.overflow = "hidden";
    }else{
      document.querySelector("body").style.overflowY = "scroll";
    }
  }, [modalOpen]);
  return (
    <div
      className={` ${
        modalOpen ? "modal-active" : "modal"
      }  w-full h-screen z-[99999] fixed top-0 left-0 flex items-center justify-center`}
    >
      <div onClick={() => setModalOpen(false)} className="modal-bg"></div>

      {children}
    </div>
  );
};

export default ModalLayout;
