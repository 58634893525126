import React from 'react'
import toast from 'react-hot-toast';
import { useInviteVendor } from '../../servicesQuery/vendorQuery/vendorQuery';
import ModalLayout from '../customModal/CustomModal';
import Button from '../submitButton/Button';

function InviteModal({ open, setOpen,vendor_id }) {
    const { mutateAsync: inviteVendor, isLoading: addLoaing } = useInviteVendor();

    const handleInvite = ()=> {
        toast.promise(inviteVendor(vendor_id)
                .then((res) => {
                    setOpen(false);
                })
                , {
                loading: "Inviting Vendor",
                success: "Vendor Invited",
                error: "Something went wrong",
          });
        
    }
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="bg-white  rounded-lg  w-[90%] hxl:max-w-[20rem] m-4 p-5 ">
        <div className='flex flex-col gap-3 items-center'>
            <span>Do you wish to send the invitation?</span>
            <div className='flex justify-between gap-3'>
                <Button
                    onClick={()=> setOpen(false)}
                    style="text-xs w-[6rem] border border-2 border-error text-darkBlue   rounded-md py-2 px-6"
                    content="Cancel"
                />
                <Button
                onClick={()=> handleInvite()}
                style="text-xs w-[6rem]  border border-2 border-darkYellow  text-darkBlue  rounded-md py-2 px-6"
                content="Invite"
                />
            </div>
        </div>
      </div>
    </ModalLayout>
  )
}

export default InviteModal
