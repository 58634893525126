import { axiosInstance } from "../../services/apiInitialSetup";

const productLists = async ({ page = 1,pageSize = 10, search="", category, producyType,categoryName,subcategoryName,discount,price_from,price_to,productType,sortValue }) => {
  
  try {
    const response = await axiosInstance.get(
      `product/product?page=${page}&size=${pageSize}&${
        search !== "" ? "search=" + search + "&" : ""
      }${
        category && category[0] !== "bc654"
          ? "category_id__in=" + category + "&"
          : ""
      }${
        producyType?.length && producyType[0] !== "ab345" & producyType[0] !== "all"
          ? "product_type_id__in=" + producyType + "&"
          : ""
      }${
        categoryName && (categoryName !== "ab345" && categoryName !== "All")
          ? "category__parent__name=" + categoryName + "&"
          : ""
      }${
        subcategoryName && subcategoryName !== "ab345"
          ? "category__name=" + subcategoryName + "&"
          : ""
      }${
        discount && discount !== "ab345"
          ? "product_discount__discount_percentage__gte=" + discount + "&"
          : ""
      }${
        price_from
          ? "price__gte=" + price_from + "&"
          : ""
      }${
        price_to
          ? "price__lte=" + price_to + "&"
          : ""
      }${
        productType && (productType !== "ab345" && productType !== "All")
          ? "product_type__name=" + productType + "&"
          : ""
      }${
        sortValue && sortValue !== "ab345"
          ? "ordering=" + sortValue + "&"
          : ""
      }`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const singleProduct = async (id) => {
  if(id){
    try {
      const response = await axiosInstance.get(`product/product/${id}/`);
      return response;
    } catch (error) {
      throw error;
    }
  }
};

const productImageUpload = async (data) => {
  try {
    const response = await axiosInstance.post(`product/images/`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
const productVideoUpload = async (data) => {
  try {
    const response = await axiosInstance.post(`product/videos/`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
const productAdd = async (data) => {
  try {
    const response = await axiosInstance.post(`product/product/`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
const productEdit = async (data) => {
  try {
    const response = await axiosInstance.patch(
      `product/product/${data.id}/`,
      data.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};
const productExcelUpload = async (data) => {
  try {
    const response = await axiosInstance.post("product/product/upload/", data);
    return response;
  } catch (error) {
    throw error;
  }
};
const productExcelDownload = async (data) => {
  try {
    const response = await axiosInstance.get("product/excelimport/", {
      responseType: "blob",
    });
    return response;
  } catch (error) {
    throw error;
  }
};
const CatogeryList = async () => {
  try {
    const response = await axiosInstance.get("category/category/lowest/");
    return response;
  } catch (error) {
    throw error;
  }
};
const productTypeList = async () => {
  try {
    const response = await axiosInstance.get("product/product-type/");
    return response;
  } catch (error) {
    throw error;
  }
};
const deleteImage = async (id) => {
  try {
    const response = await axiosInstance.delete(`product/images/${id}/`);
    return response;
  } catch (error) {
    throw error;
  }
};
const deleteVideo = async (id) => {
  try {
    const response = await axiosInstance.delete(`product/videos/${id}/`);
    return response;
  } catch (error) {
    throw error;
  }
};

const getVendor = async () => {
  try {
    const response = await axiosInstance.get(`/vendor/vendor/`);
    return response;
  } catch (error) {
    throw error;
  }
};

const uploadTaskLists = async () => {
  try {
    const response = await axiosInstance.get(`/product/running-upload-tasks/`);
    return response;
  } catch (error) {
    throw error;
  }
};

const removeProductTag = async (data) => {
  try {
    const response = await axiosInstance.patch(
      `product/product/${data.id}/remove_tag/${data.tagid}/`,
      data.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  productLists,
  singleProduct,
  productImageUpload,
  productVideoUpload,
  productAdd,
  productExcelUpload,
  productEdit,
  CatogeryList,
  productTypeList,
  deleteImage,
  deleteVideo,
  productExcelDownload,
  getVendor,
  uploadTaskLists,
  removeProductTag
};
