import React, { memo, useState } from "react";
import "./addEditProductComponent.scss";
import FileUploadButton from "../../../components/submitButton/FileUploadButton";
import { resizeFile } from "../../../utilities/imageResizer/imageResizer";
import {
  useProductImageUpload,
  useProductVideoUpload,
} from "../../../servicesQuery/productQuery/productQuery";
import { toast } from "react-hot-toast";
import ImagePreview from "./ImagePreview";
import ImageCard from "./ImageCard";
import VideoCard from "./VideoCard";
import VideoPreview from "./VideoPreview";
import noImages from "../../../assets/images/commonImages/noImages.png";
import noVideos from "../../../assets/images/commonImages/noVideo.png";
import { useParams } from "react-router-dom";

const PhotosAndVideos = ({ data, id }) => {
  const formData = new FormData();

  const [imgOpen, setImgOpen] = useState(false);
  const [videoOpen, setVideoOpen] = useState(false);
  const [imgurl, setImgUrl] = useState("");
  const [vidoeUrl, setVideoUrl] = useState("");
  const [images, setImages] = useState([]);
  const [video, setVideo] = useState([]);
  const productId = useParams().id;
  const { mutateAsync: uploadImg } = useProductImageUpload();
  const { mutateAsync: uploadVideo } = useProductVideoUpload();

  return (
    <div>
      <div>
        <div className="w-full min-h-[18rem] rounded-lg bg-white p-8  mb-8">
          <div className="flex w-full justify-between items-center mb-4">
            <h1 className="text-lg text-darkBlue font-semibold ">Images</h1>

            <FileUploadButton
              key={1}
              isMultiple={false}
              accept="image/png, image/gif, image/jpeg,image/JPEG, image/webp"
              onChange={async (e) => {
                
                let url = URL.createObjectURL(e.target.files[0]);
                setImages((pre) => [...pre, { image: url }]);

                formData.append("image", e.target.files[0]);
                formData.append("product", id);
                toast.promise(uploadImg(formData), {
                  loading: <b>Uploading image</b>,
                  success: <b> Image uploaded</b>,
                  error: <b>Could not upload.</b>,
                });
              }}
              onclick={() => {
                if (data?.images.length >= 5) {
                  toast.error("Image limit reached");
                }
              }}
              name="image"
              id={"image"}
              content="Upload"
              disable={
                data?.images.length >= 5 || images.length >= 5 ? true : false
              }
            />
          </div>
          <div className="overflow-x-scrol  media-container">
            <div className="flex gap-4 w-full mb-3">
              {data?.images?.length === 0 ? (
                <div className="h-full flex items-center gap-4 py-12">
                  {" "}
                  <div>
                    <img className="w-[2rem]" src={noImages} alt="not found" />
                  </div>{" "}
                  <p className="text-sm font-semibold text-darkBlue">
                    No images found
                  </p>{" "}
                </div>
              ) : (
                (data?.images ||
                images)?.map((item, index) => (
                  <ImageCard
                    setImage={setImgUrl}
                    setImgOpen={setImgOpen}
                    item={item}
                    key={index}
                    id={productId}
                  />
                ))
              )}
            </div>
          </div>
        </div>
        <div className="w-full min-h-[18rem] rounded-lg bg-white p-8 ">
          <div className="flex w-full justify-between items-center mb-4">
            <h1 className="text-lg text-darkBlue font-semibold">Videos</h1>

            <FileUploadButton
              key={2}
              isMultiple={false}
              accept="video/mp4,video/x-m4v,video/*"
              onChange={(e) => {
                if (e.target.files[0]?.size > 2000000) {
                  toast.error("File size should be less than 2MB");
                } else {
                  formData.append("video", e.target.files[0]);
                  formData.append("product", id);

                  let url = URL.createObjectURL(e.target.files[0]);

                  setVideo((pre) => [...pre, { video: url }]);
                  toast.promise(uploadVideo(formData), {
                    loading: <b>Uploading video</b>,
                    success: <b> Video uploaded</b>,
                    error: <b>Could not upload.</b>,
                  });
                }
              }}
              id={"video"}
              name="videos"
              content="Upload"
              disable={
                data?.videos.length >= 1 || video.length >= 1 ? true : false
              }
              onclick={() => {
                if (data?.videos.length >= 1) {
                  toast.error("Video limit reached");
                }
              }}
            />
          </div>
          <div className="overflow-x-scroll media-container">
            <div className="flex gap-4 w-full mb-3">
              {data?.videos?.length === 0 ? (
                <div className="h-full flex items-center gap-4 py-12">
                  {" "}
                  <div>
                    <img className="w-[2rem]" src={noVideos} alt="not found" />
                  </div>{" "}
                  <p className="text-sm font-semibold text-darkBlue">
                    No videos found
                  </p>{" "}
                </div>
              ) : (
                (data?.videos ||
                video)?.map((item, index) => (
                  <VideoCard
                    setVieoOpen={setVideoOpen}
                    setVideo={setVideoUrl}
                    item={item}
                    key={index}
                    productId={productId}
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </div>
      <VideoPreview setOpen={setVideoOpen} open={videoOpen} video={vidoeUrl} />
      <ImagePreview open={imgOpen} setOpen={setImgOpen} img={imgurl} />
    </div>
  );
};

export default memo(PhotosAndVideos);
