import { axiosInstance } from "../../services/apiInitialSetup";

const vendorLists = async ({ page,pageSize = 10, search ,category,invited, is_active}) => {
  try {
    const response = await axiosInstance.get(
      `/vendor/vendor/?page=${page}&size=${pageSize}&${search !== "" ? "search=" + search  : ""}&${invited ? "invited=" + invited  : invited === false ? "invited=" + invited : ""}&${is_active ? "is_active=true" : "is_active=false"}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const stateLists = async () => {
    try {
      const response = await axiosInstance.get(
        `/address/state/`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };
  
  const cityLists = async (state) => {
    if(state){
        try {
            const response = await axiosInstance.get(
              `/address/city/${state}/`
            );
            return response;
          } catch (error) {
            throw error;
          }
    }
    
  };

  const vendorSingle = async (id) => {
    if(id){
        try {
            const response = await axiosInstance.get(
              `/vendor/vendor/${id}/`
            );
            return response;
          } catch (error) {
            throw error;
          }
    }
    
  };

const vendorAdd = async (data) => {
    try {
        const response = await axiosInstance.post(`vendor/vendor/`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

const editVendor = async (data) => {
  try {
    const response = await axiosInstance.patch(`vendor/vendor/${data.id}/`,data);
    return response;
  } catch (error) {
    throw error;
  }
};

const vendorInvite = async (id) => {
  try {
      const response = await axiosInstance.post(`vendor/vendor/${id}/invite/`,);
      return response;
  } catch (error) {
      throw error;
  }
};

export {
    vendorLists,
    stateLists,
    vendorAdd,
    cityLists,
    editVendor,
    vendorSingle,
    vendorInvite
  };