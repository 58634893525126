import { createContext, useEffect, useState } from "react";

export const Context = createContext({});
const MasterContext = ({ children }) => {
  const [value, setValue] = useState("");
  const [progress, setProgres] = useState([]);
  const [is_unread, setUnRead] = useState(false);
  const [pageVal, setPageVal] = useState({});

  return (
    <Context.Provider value={{ value, setValue,progress,setProgres,is_unread,setUnRead,pageVal,setPageVal }}>{children}</Context.Provider>
  );
};
export default MasterContext;
