import React, {  useCallback, useMemo, useState } from "react";
import AddEditForm from "./AddEditForm";


const AddEditProductPageArt = () => {


  return (
    <div className="flex-1 w-full h-full">
      <div>
       <AddEditForm />
      </div>
    </div>
  );
};

export default React.memo(AddEditProductPageArt);
