import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import OrderPage from "./OrderPage";
import ProductsPage from "./ProductsPage";



const ArtMotionMainPage = () => {
  const { state } = useLocation();
    const [index, setIndex] = useState("");

  useEffect(()=> {
    if(state?.fromPage?.length && state?.fromPage[1] === "art-in-motion" && state?.fromPage[2] === "order-detials"){
      setIndex("Orders")
    }else {
      setIndex("products")
    }
  },[])

  const handleTab = useCallback((tab) => {
    setIndex(tab);
  }, []);

  return (
    <div>
        <div className="w-full mb-10 flex justify-between gap-6">
          <div className="flex items-center w-full gap-y-3 gap-x-2 justify-between md:justify-start md:gap-12 ">
            <button
              onClick={(e) => {
                handleTab("products");
              }}
              className={`text-xs md:text-lg ${
                index === "products" ? "tab-active" : ""
              }  transition-all  hover:text-darkYellow text-[gray] font-bold`}
              type="button"
            >
              Products
            </button>
            <button
              onClick={(e) => {
                handleTab("Orders");
              }}
              className={`text-xs md:text-lg ${
                index === "Orders" ? "tab-active" : ""
              }  transition-all hover:text-darkYellow   text-[gray] font-bold`}
              type="button"
            >
              Orders
            </button>
          </div>
        </div>
      {
        index === "products"? 
        <ProductsPage />
        : index === "Orders"?
        <OrderPage />
        :
        null
      }
    </div>
  );
};

export default ArtMotionMainPage;
