import { Formik, Form } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import SubmitButton from "../../../../components/submitButton/SubmitButton";
import {
  useAddtags,
  useEditags,
  useGetLatestTags,
  useGetSingleTags,
} from "../../../../servicesQuery/tagQuery/tagQuery";
import { FormikContext } from "../../../../utilities/resetForm/FormikContext";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import TagStructureNew from "./tagTempleteComponents/TagStructureNew";
import BorderdInputField from "../../../../components/customField/BorderdInputField";
import ErrorMessageComponent from "../../../../components/errorMessage/ErrorMessageComponent";
import bangle from "../../../../assets/images/commonImages/bangle.png";
import earing from "../../../../assets/images/commonImages/earing.png";
import necklace from "../../../../assets/images/commonImages/necklace.png";
import ring from "../../../../assets/images/commonImages/ring.png";
import gold_ring from "../../../../assets/images/commonImages/gold_ring.png";
import demoRing from "../../../../assets/images/commonImages/demoRing.png";

const tagValidation = Yup.object().shape({
  name: Yup.mixed().required("Name is required"),
//   title: Yup.mixed().required("Title is required").length(6, 'Input must be exactly 6 characters long'),
  title: Yup.string()
    .required('This field is required')
    .max(8, 'Input must not exceed 8 characters'),
    title1: Yup.string()
    .required('This field is required')
    .max(10, 'Input must not exceed 8 characters'),
  description: Yup.mixed().required("Description is required"),
  card_image: Yup.mixed().required("Image is required"),
});
const TagTempleteNew = () => {
  const [image, setImage] = useState("");
  const { mutateAsync: addTag, isLoading } = useAddtags();
  const { mutateAsync: editTag, isLoading: editLoading } = useEditags();
  const [formik, setFormik] = useState();
  const id = useParams().id;
  const { data: tagData, isLoading: tagLoading } = useGetLatestTags();
  const formData = new FormData();

  function getImageBlobFromURL(imageURL) {
    console.log(imageURL,"imageURL");
    if(imageURL){
      return fetch(imageURL)
      .then((response) => response.blob())
      .then((blob) => {
        return blob;
      });
    }
  }


  function createFileFromBlob(blob, filename) {
    return new File([blob], filename, { type: blob.type });
  }

  const handleSetImg = (imageURL,setFieldValue) => {
    if(imageURL){
      getImageBlobFromURL(imageURL)
      .then((imageBlob) => {
        const fileName = 'image.jpg'; // Replace with your desired filename
        const imageFile = createFileFromBlob(imageBlob, fileName);
        if(setFieldValue){
          setFieldValue("card_image", imageFile);
        }else {
          return imageFile
        }
        // Now you have the image as a File object.
      })
      .catch((error) => {
        console.error('Error fetching or converting the image:', error);
      });
    }
  }

  const initialValues = {
    id: tagData?.data?.data?.id || "",
    name: tagData?.data?.data?.name || "",
    title: tagData?.data?.data?.content?.title || "Get",
    title1: tagData?.data?.data?.content?.title1 || "25 % Off",
    description: tagData?.data?.data?.content?.description || "On newest collection",
    card_image: tagData?.data?.data?.card_image || handleSetImg(ring)
  };

  const handleAddTag = (values) => {
    formData.append("name", values.name);
    if(typeof values.card_image !== "string"){
      formData.append("card_image", values.card_image);
    }
    formData.append("is_active", true);
    formData.append(
      "content",
      JSON.stringify({
        title: values.title,
        title1: values.title1,
        description: values.description,
      })
    );
    
    if (!values?.id) {
      addTag(formData)
        .then((res) => {
          toast.success("Tag added");
        })
        .catch((err) => {
          toast.error("Some thing went wrong");
        });
    } else {
      editTag({ data: formData, id: values?.id })
        .then((res) => {
          toast.success("Tag edited");
        })
        .catch((err) => {
          toast.error("Some thing went wrong");
        });
    }
  };

  

  const image_list = [
    {img: bangle,name: "Bangles"},
    {img: earing, name: "Earings"},
    {img: necklace, name: "Necklace"},
    {img: ring, name: "Ring"},
    {img: gold_ring, name: "Gold Ring"},
    {img: demoRing, name: "Ring"}
  ]

  return (
    <div
      className={`flex-1 w-full h-full ${
        (id && tagLoading) || isLoading || editLoading
          ? "opacity-50 pointer-events-none"
          : "opacity-100 pointer-events-auto"
      }`}
    >
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={tagValidation}
          onSubmit={handleAddTag}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="flex flex-col-reverse lg:flex-row gap-12">
                <div className="w-full lg:w-[60%] bg-white rounded-lg p-6">
                <div className="mb-3">
                    <label
                      className="block mb-3 text-sm text-darkBlue"
                      htmlFor="type"
                    >
                     Name
                    </label>

                    <div className=" border-[#AB9E9E] rounded-lg">
                      <BorderdInputField
                        as="input"
                        name="name"
                        placeholder={`Add card name`}
                        value={values?.name}
                      />
                      <ErrorMessageComponent name="name" />
                    </div>
                  </div>
                  <div className="mb-3">
                    <label
                      className="block mb-3 text-sm text-darkBlue"
                      htmlFor="type"
                    >
                      Title 1
                    </label>

                    <div className=" border-[#AB9E9E] rounded-lg">
                      <BorderdInputField
                        as="input"
                        name="title"
                        placeholder={`Add title 1`}
                        value={values?.title}
                        maxLength={8}
                      />
                      <ErrorMessageComponent name="title" />
                    </div>
                  </div>

                  <div className="mb-3">
                    <label
                      className="block mb-3 text-sm text-darkBlue"
                      htmlFor="type"
                    >
                      Title 2
                    </label>

                    <div className=" border-[#AB9E9E] rounded-lg">
                      <BorderdInputField
                        as="input"
                        name="title1"
                        placeholder={`Add title 2`}
                        value={values?.title1}
                        maxLength={10}
                      />
                      <ErrorMessageComponent name="title1" />
                    </div>
                  </div>


                  <div className="mb-3">
                    <label
                      className="block mb-3 text-sm text-darkBlue"
                      htmlFor="type"
                    >
                      Description
                    </label>

                    <div className=" border-[#AB9E9E] rounded-lg">
                      <BorderdInputField
                        as="input"
                        name="description"
                        placeholder={`Add description`}
                        maxLength={23}
                      />
                      <ErrorMessageComponent name="description" />
                    </div>
                  </div>
                  <div className="flex gap-4 mb-3 flex-wrap">
                    {image_list?.map((i)=> 
                    <div onClick={()=> handleSetImg(i.img,setFieldValue)} className="p-2 shadow-xl rounded-lg cursor-pointer text-center hover:shadow-2xl">
                      <div className="w-[80px] h-[80px]">
                        <img src={i.img} className="w-full h-full" />
                      </div>
                      <span className="text-[12px]">{i.name}</span>
                  </div>
                    )}
                    
                  </div>
                  <div className="">
                    <label
                      className="block mb-3 text-sm text-darkBlue"
                      htmlFor="type"
                    >
                      Image
                    </label>

                    <div className="flex items-center gap-4   border-[1px] border-[#AB9E9E] rounded-lg overflow-hidden">
                      <div className="px-3 py-2 border-r-[1px] border-[#AB9E9E] cursor-pointer hover:bg-tertiary transition-all">
                        <input
                          hidden
                          className="excel-upload-input"
                          id="excel"
                          type="file"
                          onChange={(e) => {
                            if (e.target.files[0]?.size > 2000000) {
                              toast.error("Image size must be less than 2mb");
                            } else {
                              setFieldValue("card_image", e.target.files[0]);
                            }
                          }}
                        />
                        <label
                          htmlFor="excel"
                          className="  text-sm cursor-pointer text-[#AB9E9E]"
                        >
                          Choose file
                        </label>
                      </div>
                      <div className="text-sm text-[#AB9E9E]">
                        <p>{values?.card_image?.name || "No file choosen"}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full lg:flex-1 p-8 bg-white rounded-lg">
                  <div className="sticky top-10 flex flex-col gap-6 items-end">
                    <span>{values?.name}</span>
                    <TagStructureNew values={values} image={image} />
                    <div className="w-[10rem] hidden lg:block">
                      <SubmitButton
                        content={"Save template"}
                        type="submit"
                        isLoading={isLoading || editLoading}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <FormikContext setFormik={setFormik} />
              {/* <div className="w-[10rem]  mx-auto block lg:hidden">
                <SubmitButton
                  content={"Save template"}
                  type="submit"
                  isLoading={isLoading || editLoading}
                />
              </div> */}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default TagTempleteNew;
