import React, { useState,useEffect } from "react";
import ModalLayout from "../../../components/customModal/CustomModal";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FormikContext,
  ResetForm,
} from "../../../utilities/resetForm/FormikContext";
import BorderdInputField from "../../../components/customField/BorderdInputField";
import ErrorMessageComponent from "../../../components/errorMessage/ErrorMessageComponent";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import SubmitButton from "../../../components/submitButton/SubmitButton";
import { useCreateSubCat, useGetSubCategoryList, useGetSubcatSingle, useUpdateSubCat } from "../../../servicesQuery/categoryQuery/categoryQuery";
import toast from "react-hot-toast";
import ToggleButton from "../../../components/toggleButton/ToggleButton";

 const AddCategoryModal=({ open, setOpen,isEdit, item }) =>{
  const [formikValue, setFormikValue] = useState("");
  const [fileName, setFileName] = useState("")
  const { mutateAsync: subCatCreate, isLoading } = useCreateSubCat();
  const { mutateAsync: subCatUpdate, isLoading: editLoaing } = useUpdateSubCat();
//   const { data: ItemData, isLoading: itemLoading } = useGetSubcatSingle(id);
//     const item = ItemData?.data
  const validation = () => {
    return Yup.object().shape({
      name: Yup.string().required("Name is required"),
      image: Yup.mixed().required("Image is required"),
    });
  };
  const getImgName = (imageUrl)=> {
    const parts = imageUrl?.split('/');
    const lastPart = parts[parts?.length - 1]?.split('.')[0];
    return lastPart
  }

//   useEffect(()=> {
//     if(typeof formikValue.image === "string"){
//         let name = getImgName(formikValue.image)
        
//     }else {
//         console.log(formikValue?.image?.name);
//         setFileName(formikValue?.image?.name)
//     }
//   },[formikValue.image])
  const initialValue = {
    id: item?.id || "",
    name:item?.name || "",
    image: item?.image || "",
    status: item?.is_active === false ? false : true
  };
  const handleSubmmit = (values, { resetForm }) => {
    const formData = new FormData()
    if(isEdit){
        formData.append("id", values.id);
        if(initialValue.name !== values.name){
            formData.append("name", values.name);
        }
        if(initialValue.image !== values.image){
            formData.append("image", values.image);
        }
        if(initialValue.status !== values.status){
            formData.append("is_active", values.status);
        }
        subCatUpdate(formData)
            .then((res) => {
            toast.success("Category Updated");
            setOpen(false)
            resetForm();
            })
            .catch((err) => {
            toast.error("Something went wrong");
            });
    }else {
        formData.append("name", values.name);
        formData.append("image", values.image);
        formData.append("is_active", values.status);
        subCatCreate(formData)
            .then((res) => {
            toast.success("Category added");
            setOpen(false)
            resetForm();
            })
            .catch((err) => {
            toast.error("Something went wrong");
            });
    }
    
  };

  const statusList = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
  ];
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="bg-white  rounded-xl  w-[90%] hxl:max-w-[50rem] m-4 ">
        <div className="w-full flex justify-between p-6 border-b-[.5px] border-darkBlue border-opacity-20 ">
          <h2 className="text-lg text-darkBlue font-bold">{isEdit? "Edit Sub Category": "Add New Sub Category"}</h2>

          <div
            className="cursor-pointer "
            onClick={() => {
              setOpen(false);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3554 8.00005L15.4665 2.88894C16.1109 2.24449 16.1109 1.17783 15.4665 0.533382C14.822 -0.111062 13.7554 -0.111062 13.1109 0.533382L7.9998 5.64449L2.88869 0.533382C2.24425 -0.111062 1.17758 -0.111062 0.533138 0.533382C-0.111306 1.17783 -0.111306 2.24449 0.533138 2.88894L5.64425 8.00005L0.533138 13.1112C-0.111306 13.7556 -0.111306 14.8223 0.533138 15.4667C0.866471 15.8 1.28869 15.9556 1.71092 15.9556C2.13314 15.9556 2.55536 15.8 2.88869 15.4667L7.9998 10.3556L13.1109 15.4667C13.4442 15.8 13.8665 15.9556 14.2887 15.9556C14.7109 15.9556 15.1331 15.8 15.4665 15.4667C16.1109 14.8223 16.1109 13.7556 15.4665 13.1112L10.3554 8.00005Z"
                fill="#07415C"
              />
            </svg>
          </div>
        </div>
        <div className="  p-6 ">
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmmit}
            validationSchema={validation}
            enableReinitialize
          >
            {({ setFieldValue, values,handleChange }) => (
              <Form>
                <ResetForm isOpen={open} />
                <div className="flex flex-col gap-x-6">
                  <div className="flex items-center gap-6 w-full">
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        Category Name
                      </label>
                      <BorderdInputField
                        placeholder={"Enter name of the category"}
                        width={"w-full"}
                        name="name"
                        value={values.name}
                      />
                      <ErrorMessageComponent name={"name"} />
                    </div>
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        Image
                      </label>

                      <div className="flex items-center gap-4   border-[1px] border-[#AB9E9E] rounded-lg overflow-hidden">
                        <div className="px-3 py-2 border-r-[1px] border-[#AB9E9E] cursor-pointer hover:bg-tertiary transition-all">
                          <input
                            hidden
                            className="excel-upload-input"
                            id="image"
                            name="image"
                            type="file"
                            onChange={(e) => {
                                setFieldValue("image", e.target.files[0]);
                            }}
                          />
                          <label
                            htmlFor="image"
                            className="  text-sm cursor-pointer text-[#AB9E9E]"
                          >
                            Choose file
                          </label>
                        </div>
                        <div className="text-sm text-[#AB9E9E]">
                           {values?.image?
                           <p>{values?.image?.name ? values?.image?.name : getImgName(values?.image)}</p>
                           : 
                           <p>no file choosen</p>
                           }
                            
                          
                        </div>
                      </div>
                      <ErrorMessageComponent name={"image"} />
                    </div>
                  </div>
                  <div className="w-full flex justify-start mb-4 gap-4">
                    <p className="text-base text-darkBlue">Status</p>
                    <ToggleButton
                        isChecked={values.status}
                        change={(e) => setFieldValue("status", e)}
                        id={'status'}
                        /> 
                    
                    {values.status === true ? <span className="text-status">active</span> :
                     <span className="text-error">inactive</span> }
                  </div>
                  <div className="flex items-center gap-6 w-full">
                    <div className="w-1/2">
                      <SubmitButton
                        width="w-full"
                        type="submit"
                        content={isEdit?"Edit Category" : "Add Category"}
                        isLoading={isLoading || editLoaing}
                      />
                    </div>
                  </div>
                </div>
               
                <FormikContext setFormik={(e) => setFormikValue(e.values)} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalLayout>
  );
}

export default React.memo(AddCategoryModal)
