import React from 'react'
import { Outlet } from 'react-router-dom'

const VendorManagement = () => {
  return (
    <div className="flex-1 w-full h-full">
    <Outlet/>
   </div>
  )
}

export default VendorManagement