import React from "react";
import {
  formatDate,
  formatDateWithDay,
} from "../../utilities/dateFunctions/dateFunctions";
import ModalLayout from "../customModal/CustomModal";
import "./orderTrackerStyle.scss";
const OrderTrackingDetials = ({ opan, setOpen, data }) => {
  console.log(data, "datadata");
  return (
    <ModalLayout modalOpen={opan} setModalOpen={setOpen}>
      <div className="w-[500px] h-auto rounded-lg bg-white ">
        <div className="flex w-full gap-6 justify-between items-center px-10 pt-10 pb-6 border-b-[1px] border-lightBlue">
          <h1 className="text-darkBlue text-md">Tracking Detials</h1>
          <button onClick={() => setOpen(false)}>
            <svg
              width="12"
              height="12"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.2378 8.00001L15.0933 3.14445C15.7056 2.53223 15.7056 1.5189 15.0933 0.906676C14.4811 0.294454 13.4678 0.294454 12.8556 0.906676L8.00001 5.76223L3.14445 0.906676C2.53223 0.294454 1.5189 0.294454 0.906676 0.906676C0.294454 1.5189 0.294454 2.53223 0.906676 3.14445L5.76223 8.00001L0.906676 12.8556C0.294454 13.4678 0.294454 14.4811 0.906676 15.0933C1.22334 15.41 1.62445 15.5578 2.02556 15.5578C2.42668 15.5578 2.82779 15.41 3.14445 15.0933L8.00001 10.2378L12.8556 15.0933C13.1722 15.41 13.5733 15.5578 13.9745 15.5578C14.3756 15.5578 14.7767 15.41 15.0933 15.0933C15.7056 14.4811 15.7056 13.4678 15.0933 12.8556L10.2378 8.00001Z"
                fill="#07415C"
              />
            </svg>
          </button>
        </div>

        {data["refund_initiated"] ? (
          <div className="p-10 flex gap-[2rem]">
            <div className="tracker-container">
              <div
                className={`tracker ${
                  data?.Placed || data?.Processing || data["refund_initiated"]
                    ? "placed"
                    : ""
                }  `}
              >
                <div className="track-container">
                  <div className="round">
                    <div></div>
                  </div>
                  <div className="track">
                    <div></div>
                  </div>
                </div>
              </div>
              {
                data?.Processing? 
                <div
                className={`tracker  ${
                  data?.Processing
                    ? "dispatched"
                    : ""
                }  `}
              >
                <div className="track-container">
                  <div className="round">
                    <div></div>
                  </div>
                  <div className="track">
                    <div></div>
                  </div>
                </div>
              </div>
                : null
              }
              
              <div
                className={`tracker  ${
                  data?.Processing || data["refund_initiated"] || data["refund_processed"]
                    ? "dispatched"
                    : ""
                }  `}
              >
                <div className="track-container">
                  <div className="round">
                    <div></div>
                  </div>
                  <div className="track">
                    <div></div>
                  </div>
                </div>
              </div>

              <div
                className={`tracker  ${
                  data["refund_processed"] || data["Cancelled"] ? "shipped" : ""
                }  `}
              >
                <div className="track-container">
                  <div className="round shipped-round">
                    <div></div>
                  </div>
                  <div className="track">
                    <div></div>
                  </div>
                  <div className={`round  ${data["Cancelled"] ? "deliverd" : ""}`}>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detials-container">
              <div className="detials">
                <h1>Order Placed</h1>
                <p>{data?.Placed ? formatDateWithDay(data?.Placed) : ""}</p>
              </div>
              {
                data?.Processing ?
                <div className="detials">
                  <h1>Order Processed</h1>
                  <p>
                    {data?.Processing ? formatDateWithDay(data?.Processing) : ""}
                  </p>
                </div>
                : null
              }
              <div className="detials">
                <h1>Refund Initiated</h1>
                <p>
                  {data["refund_initiated"] ? formatDateWithDay(data["refund_initiated"]) : ""}
                </p>
              </div>
              <div className="detials">
                <h1>Refund Processed</h1>
                <p>{data["refund_processed"] ? formatDateWithDay(data["refund_processed"]) : ""}</p>
              </div>
              <div className="detials">
                <h1>Cancelled</h1>
                <p>
                  {data?.Cancelled ? formatDateWithDay(data?.Cancelled) : ""}
                </p>
              </div>
            </div>
          </div>
        ) : (
          <div className="p-10 flex gap-[2rem]">
            <div className="tracker-container">
              <div
                className={`tracker ${
                  data?.Placed || data?.Processing || data.Shipped
                    ? "placed"
                    : ""
                }  `}
              >
                <div className="track-container">
                  <div className="round">
                    <div></div>
                  </div>
                  <div className="track">
                    <div></div>
                  </div>
                </div>
              </div>
              <div
                className={`tracker  ${
                  data?.Processing || data.Shipped || data.Delivered
                    ? "dispatched"
                    : ""
                }  `}
              >
                <div className="track-container">
                  <div className="round">
                    <div></div>
                  </div>
                  <div className="track">
                    <div></div>
                  </div>
                </div>
              </div>

              <div
                className={`tracker  ${
                  data?.Shipped || data.Delivered ? "shipped" : ""
                }  `}
              >
                <div className="track-container">
                  <div className="round shipped-round">
                    <div></div>
                  </div>
                  <div className="track">
                    <div></div>
                  </div>
                  <div className={`round  ${data.Delivered ? "deliverd" : ""}`}>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="detials-container">
              <div className="detials">
                <h1>Order Placed</h1>
                <p>{data?.Placed ? formatDateWithDay(data?.Placed) : ""}</p>
              </div>
              <div className="detials">
                <h1>Order Processed</h1>
                {/* <p>{!data?.Processing ? formatDateWithDay(data?.Shipped): formatDateWithDay(data?.Processing)}</p> */}
                <p>
                  {data?.Processing ? formatDateWithDay(data?.Processing) : ""}
                </p>
              </div>
              <div className="detials">
                <h1>Order Shipped</h1>
                <p>{data?.Shipped ? formatDateWithDay(data?.Shipped) : ""}</p>
              </div>
              <div className="detials">
                <h1>Order Delivered</h1>
                <p>
                  {data?.Delivered ? formatDateWithDay(data?.Delivered) : ""}
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </ModalLayout>
  );
};

export default OrderTrackingDetials;
