import React from "react";
import ColorPicker from "../../../../../../components/colorPicker/ColorPicker";

const ColorTheme = ({ setFieldValue,values }) => {
  return (
    <div className="bg-white rounded-lg p-6 mb-6">
      <h1 className="text-lg text-darkBlue font-semibold mb-4">Color Theme</h1>
      <div className="mb-6">
        <div className="flex items-center gap-2">
          <ColorPicker
            defaultColor={values?.colorThemeOne}
            onChange={(e) => {
              setFieldValue("colorThemeOne", e?.hex);
            }}
            SelectButton={({ color }) => {
              return (
                <div
                  className={`w-[2rem] h-[2rem] rounded-full cursor-pointer active:scale-110 transition-all`}
                  style={{
                    backgroundColor: values?.colorThemeOne || color?.hex,
                  }}
                ></div>
              );
            }}
          />
          <ColorPicker
            defaultColor={values?.colorThemeTwo}
            onChange={(e) => {
              setFieldValue("colorThemeTwo", e?.hex);
            }}
            SelectButton={({ color }) => {
              return (
                <div
                  className={`w-[2rem] h-[2rem] rounded-full cursor-pointer active:scale-110 transition-all`}
                  style={{
                    backgroundColor: values?.colorThemeTwo || color?.hex,
                  }}
                ></div>
              );
            }}
          />
        
      
        </div>
      </div>
    </div>
  );
};

export default ColorTheme;
