import React from "react";

const MasterFooter = () => {
  // self-center hxl:self-end
  return (
  
     
        <h1 className=" text-xs  md:text-sm text-darkBlue  text-center right-0 self-center  mt-12 lg:mt-7 hxl:pb-0">Copyright © 2023 Magicwomen. All rights reserved
       </h1>
      

  );
};

export default MasterFooter;
