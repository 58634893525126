import { Progress } from "antd";
import React, { useCallback, useContext, useState } from "react";
import rating from "../../assets/images/commonImages/rating.svg";
import { Tabs } from "antd";
import { useEffect } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import CustomTable from "../../components/customTable/CustomTable";
import CustomSelect from "../../components/customSelect/CustomSelect";
import { formatDate } from "../../utilities/dateFunctions/dateFunctions";
import { Image } from "antd";
import ReviewImagePreview from "./ReviewImagePreview";
import Pagenation from "../../components/pagination/Pagenation";
import ReviewDeleteModal from "./ReviewDeleteModal";
import { useGetReviewList, usePublishReview } from "../../servicesQuery/reviewQuery/reviewQuery";
import { date } from "yup";
import toast from 'react-hot-toast';
import { Context } from "../../utilities/context/Context";

function ProductReview({id}) {
    const { value, setValue } = useContext(Context);
    const [pageCount, setPageCount] = useState(1);
    const [pageSize, setPageSize] = useState({
        label: "10",
        value: 10,
    });
  const [statusFilter, setStausFilter] = useState({
    label: "ALL",
    value: "ab345",
  });
  const [instance, setInstance] = useState({});
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [ratings, setRatings] = useState([]);
  const [avgRate, setAvgRate] = useState(0);
  const [ratersCount, setRatersCount] = useState(0);

  const { data, isLoading, isFetching,isError } = useGetReviewList({
    page: pageCount,
    status:statusFilter?.value,
    pageSize: pageSize?.value,
    search: value,
    productid:id
  });

  useEffect(()=> {
    let ratings = [];
    let multiRate = 0
    let totCount = 0
    if(data?.data?.results?.rating_counts && Object.keys(data?.data?.results?.rating_counts)?.length > 0){
        let obj = data?.data?.results?.rating_counts
        const keys = Object.keys(obj);
        const values = Object.values(obj);
        let buyerCount = 0
        for (let i = 5; i > 0; i--){
            if(keys.includes(String(i))){
              let index = keys.indexOf(String(i));
                ratings.push(values[index])
                multiRate += (i * values[index])
                totCount += values[index]
                let AvgRate = multiRate / totCount
                setRatings(ratings)
                setAvgRate(AvgRate)
                buyerCount += values[index]
                setRatersCount(buyerCount)
            }else {
                ratings.push(0)
            }
        }
    }else {
        setRatings([0,0,0,0,0])
        setAvgRate(0)
        setRatersCount(0)
    }
    
  },[data])

  useEffect(() => {
    document.querySelector("body").style.overflowY = "scroll";
  }, [deleteOpen]);


  const { mutateAsync: publishReview, isLoading: publishLoading } = usePublishReview();

  const handlePublish = (item) => {
    let data = {
        id: item?.id,
        is_publised: true,
    }
    toast.promise(publishReview(data)
    .then((res) => {
        // setOpen(false);
    })
    , {
    loading: "publishing Review",
    success: "Review Published",
    error: "Something went wrong",
});
  }
  

  const customFormat = (ratersCount) => {
    return `${ratersCount}`;
  };

  const head = [
    {
      key: 2,
      label: "Reviewer",
      index: "reviewer",
      width: 11,
    },
    {
      key: 3,
      label: "Review",
      index: "review",
      width: 11,
    },
    {
      key: 4,
      label: "Date",
      index: "date",
      width: 8,
    },
    {
      key: 5,
      label: "Status",
      index: "status",
      width: 8 ,
    },
    {
      key: 6,
      label: "Action",
      index: "action",
    //   width: 6,
    },
  ];

  
  const statusList = [
    {
      label: "ALL",
      value: "ab345",
    },
    {
      label: "Published",
      value: "published",
    },
    {
      label: "Pending",
      value: "pending",
    },
  ];

  const showRating = (rate) => {
    const ratings = [];
    for (let i = 0; i < rate; i++) {
      ratings.push(
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1743_8656)">
            <path
              d="M2.70464 11.6003C2.54464 11.6536 2.40464 11.6336 2.28464 11.5403C2.16464 11.447 2.11798 11.307 2.14464 11.1203L2.74464 7.6003L0.104645 5.0803C-0.00202178 4.94697 -0.0353551 4.8003 0.00464489 4.6403C0.0446449 4.4803 0.157978 4.38697 0.344645 4.3603L3.98464 3.8403L5.62464 0.600305C5.70464 0.440304 5.82464 0.360304 5.98464 0.360304C6.14464 0.360304 6.26464 0.440304 6.34464 0.600305L7.98464 3.8403L11.6646 4.3603C11.8246 4.38697 11.9246 4.4803 11.9646 4.6403C12.0046 4.8003 11.9713 4.94697 11.8646 5.0803L9.22464 7.6003L9.82464 11.1203C9.85131 11.307 9.80464 11.447 9.68464 11.5403C9.56464 11.6336 9.42464 11.6536 9.26464 11.6003L5.98464 9.8803L2.70464 11.6003Z"
              fill="#FFC226"
            />
          </g>
          <defs>
            <clipPath id="clip0_1743_8656">
              <rect
                width="12"
                height="12"
                fill="white"
                transform="matrix(1 0 0 -1 0 12)"
              />
            </clipPath>
          </defs>
        </svg>
      );
    }
    return ratings;
  };

  const reviewBody = () => {
    return data?.data?.results?.data?.map((item, index) => {
      return {
        reviewer: {
          isRender: true,
          render: () => {
            return (
              <div className="flex items-center gap-2">
                <div className="w-16 h-16 overflow-hidden rounded-lg">
                  {item?.user?.profile_pic?
                  <img src={item?.user?.profile_pic} className="w-full h-full" />
                  :
                  <img src={require('../../assets/images/commonImages/avatar.png')} className="w-full h-full" />
                  }
                </div>
                <div className="flex flex-col">
                  <span className="text-[#121212] text-[12px] font-semibold w-[60%]">
                    {item?.user?.full_name}
                  </span>
                  <span className="text-[#121212] text-[12px] font-normal w-[60%]">
                    {item?.user?.email}
                  </span>
                </div>
              </div>
            );
          },
        },
        review: {
          isRender: true,
          render: () => {
            return (
              <div className="flex flex-col justify-center gap-2">
                <div className="flex items-center gap-1">
                  {showRating(item?.rating)}
                </div>
                <span className="text-[#121212] text-[12px] font-light">
                  {item?.review}
                </span>
                <div className="flex items-center gap-2">
                    {item?.review_images?.map((i)=> 
                        <div className="reviewImg">
                            <ReviewImagePreview img={i?.image} id={i?.id} />
                        </div>
                    )}
                </div>
              </div>
            );
          },
        },
        date: {
          isRender: true,
          render: () => {
            return (
              <div>
                <span className="text-[#121212] text-[12px] font-normal">
                  {formatDate(item?.created_at)}
                </span>
              </div>
            );
          },
        },
        status: {
            isRender: true,
            render: () => {
              return (
                <div>
                  <span className={`text-[${item?.is_publised == false ? "#F19A0E": "#79B364"}] font-bold text-[12px]`}>{item?.is_publised? "Published": "Pending"}</span>
                </div>
              );
            },
          },
        action: {
          isRender: true,
          render: () => (
            <div className="flex  items-center w-full gap-1 h-full">
                {item?.is_publised == false ?
                <div className="flex items-center gap-1">
                    <span className="text-[#07415C] font-semibold text-[12px]">Publish</span>
                    <div className="p-2 rounded-lg hover:bg-lightBlue transition-all">
                            <button onClick={()=> handlePublish(item)}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path opacity="0.4" d="M10.0013 18.3333C14.6037 18.3333 18.3346 14.6023 18.3346 9.99996C18.3346 5.39759 14.6037 1.66663 10.0013 1.66663C5.39893 1.66663 1.66797 5.39759 1.66797 9.99996C1.66797 14.6023 5.39893 18.3333 10.0013 18.3333Z" fill="#07415C"/>
                                <path d="M12.9435 9.1417L10.4435 6.6417C10.2018 6.40003 9.80182 6.40003 9.56016 6.6417L7.06016 9.1417C6.81849 9.38337 6.81849 9.78337 7.06016 10.025C7.30182 10.2667 7.70182 10.2667 7.94349 10.025L9.37682 8.5917V12.9167C9.37682 13.2584 9.66016 13.5417 10.0018 13.5417C10.3435 13.5417 10.6268 13.2584 10.6268 12.9167V8.5917L12.0602 10.025C12.1852 10.15 12.3435 10.2084 12.5018 10.2084C12.6602 10.2084 12.8185 10.15 12.9435 10.025C13.1852 9.78337 13.1852 9.38337 12.9435 9.1417Z" fill="#07415C"/>
                                </svg>
                            </button>
                    </div>
                </div>
                :
                null}

              <div className="p-2 rounded-lg hover:bg-lightBlue transition-all">
                <button
                  onClick={() => {
                      setInstance(item)
                      setDeleteOpen(true)
                  }}
                >
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.5574 4.35829C16.2157 4.22496 14.8741 4.12496 13.5241 4.04996V4.04163L13.3407 2.95829C13.2157 2.19163 13.0324 1.04163 11.0824 1.04163H8.89907C6.95741 1.04163 6.77407 2.14163 6.64074 2.94996L6.46574 4.01663C5.69074 4.06663 4.91574 4.11663 4.14074 4.19163L2.44074 4.35829C2.09074 4.39163 1.84074 4.69996 1.87407 5.04163C1.90741 5.38329 2.20741 5.63329 2.55741 5.59996L4.25741 5.43329C8.62407 4.99996 13.0241 5.16663 17.4407 5.60829C17.4657 5.60829 17.4824 5.60829 17.5074 5.60829C17.8241 5.60829 18.0991 5.36663 18.1324 5.04163C18.1574 4.69996 17.9074 4.39163 17.5574 4.35829Z"
                      fill="#07415C"
                    />
                    <path
                      opacity="0.3991"
                      d="M16.0264 6.78337C15.8264 6.57504 15.5514 6.45837 15.2681 6.45837H4.73475C4.45142 6.45837 4.16809 6.57504 3.97642 6.78337C3.78475 6.99171 3.67642 7.27504 3.69309 7.56671L4.20975 16.1167C4.30142 17.3834 4.41809 18.9667 7.32642 18.9667H12.6764C15.5848 18.9667 15.7014 17.3917 15.7931 16.1167L16.3098 7.57504C16.3264 7.27504 16.2181 6.99171 16.0264 6.78337Z"
                      fill="#07415C"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.98438 14.1666C7.98438 13.8214 8.2642 13.5416 8.60938 13.5416H11.3844C11.7296 13.5416 12.0094 13.8214 12.0094 14.1666C12.0094 14.5118 11.7296 14.7916 11.3844 14.7916H8.60938C8.2642 14.7916 7.98438 14.5118 7.98438 14.1666Z"
                      fill="#07415C"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M7.29297 10.8334C7.29297 10.4882 7.57279 10.2084 7.91797 10.2084H12.0846C12.4298 10.2084 12.7096 10.4882 12.7096 10.8334C12.7096 11.1786 12.4298 11.4584 12.0846 11.4584H7.91797C7.57279 11.4584 7.29297 11.1786 7.29297 10.8334Z"
                      fill="#07415C"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ),
        },
      };
    });
  };

  console.log(data?.data?.current_page_data_count,"data?.data?.current_page_data_count");
  console.log(data?.data?.data_count,"data?.data?.data_count");
  return (
    <div>
      {isLoading ? (
        <div>
          <SkeletonTheme baseColor="#e2e0e0" highlightColor="#f3f2f2">
            <p>
              <Skeleton count={1} height={170} />
            </p>
          </SkeletonTheme>
        </div>
      ) : (
        <div className="bg-[#FFFFFF] p-5 rounded-3xl flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="w-[120px] h-[120px]">
              <img src={rating} className="h-full w-full" />
            </div>
            <div>
              <div className="flex gap-2 items-center h-[44px]">
                <span className="text-[#07415C] text-[50px] font-medium">
                  {Number(avgRate).toFixed(2)}
                </span>
                <div className="mt-3">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1739_7996)">
                      <path
                        d="M3.15347 13.5337C2.9668 13.5959 2.80347 13.5726 2.66347 13.4637C2.52347 13.3548 2.46902 13.1915 2.50013 12.9737L3.20013 8.86702L0.120133 5.92702C-0.00431187 5.77146 -0.0432008 5.60035 0.00346591 5.41369C0.0501326 5.22702 0.182355 5.11813 0.400133 5.08702L4.6468 4.48035L6.56013 0.700355C6.65347 0.513688 6.79347 0.420355 6.98013 0.420355C7.1668 0.420355 7.3068 0.513688 7.40013 0.700355L9.31347 4.48035L13.6068 5.08702C13.7935 5.11813 13.9101 5.22702 13.9568 5.41369C14.0035 5.60035 13.9646 5.77146 13.8401 5.92702L10.7601 8.86702L11.4601 12.9737C11.4912 13.1915 11.4368 13.3548 11.2968 13.4637C11.1568 13.5726 10.9935 13.5959 10.8068 13.5337L6.98013 11.527L3.15347 13.5337Z"
                        fill="#FFC226"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1739_7996">
                        <rect
                          width="14"
                          height="14"
                          fill="white"
                          transform="matrix(1 0 0 -1 0 14)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
              </div>
              <div>
                <span className="text-[#676767] text-[12px] font-light">
                  {ratersCount} verified buyers
                </span>
              </div>
            </div>
          </div>
          <div className="w-[72%]">
            {ratings.map((i, index) => (
              <div className="flex items-center gap-2 justify-between mb-2">
                <div className="flex items-center gap-1 w-[3%] justify-between">
                  <span>{5 - index}</span>
                  <svg
                    width="7"
                    height="8"
                    viewBox="0 0 7 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_1739_8003)">
                      <path
                        d="M1.57673 7.76489C1.4834 7.796 1.40173 7.78434 1.33173 7.72989C1.26173 7.67545 1.23451 7.59378 1.25007 7.48489L1.60007 5.43156L0.0600663 3.96156C-0.00215593 3.88378 -0.0216004 3.79822 0.00173295 3.70489C0.0250663 3.61156 0.0911774 3.55711 0.200066 3.54156L2.3234 3.23822L3.28007 1.34822C3.32673 1.25489 3.39673 1.20822 3.49007 1.20822C3.5834 1.20822 3.6534 1.25489 3.70007 1.34822L4.65673 3.23822L6.8034 3.54156C6.89673 3.55711 6.95507 3.61156 6.9784 3.70489C7.00173 3.79822 6.98229 3.88378 6.92007 3.96156L5.38007 5.43156L5.73007 7.48489C5.74562 7.59378 5.7184 7.67545 5.6484 7.72989C5.5784 7.78434 5.49673 7.796 5.4034 7.76489L3.49007 6.76156L1.57673 7.76489Z"
                        fill="#121212"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1739_8003">
                        <rect
                          width="7"
                          height="7"
                          fill="white"
                          transform="matrix(1 0 0 -1 0 7.99805)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </div>
                <Progress
                  className="mb-1"
                  size="small "
                  percent={(i /ratersCount) * 100 }
                  format={() => customFormat(i)}
                  strokeColor={
                    index === 0
                      ? "#07415C"
                      : index === 1
                      ? "#307290"
                      : index === 2
                      ? "#639eb9"
                      : index === 3
                      ? "#F1C379"
                      : "#F99165"
                  }
                />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="bg-[#FFFFFF] p-6 rounded-3xl mt-5">
        <div className="flex items-center justify-between mb-5">
          <h3 className="text-[#29273A] font-bold text-[18px]">
            Ratings & Reviews
          </h3>
          <div className="w-[20%]">
            <CustomSelect
              placeholder={"Status"}
              options={statusList}
              width={"min-w-[8rem]"}
              value={statusFilter}
              onChange={(e) => {
                setStausFilter(e);
              }}
            />
          </div>
        </div>
        <div className="w-full h-[95%] rounded">
          <CustomTable
            head={head}
            body={reviewBody()}
            loading={isLoading || isFetching}
            dataLength={data?.data?.results?.data?.length}
            height="max-h-[55vh]"
            error={isError}
          />
        </div>
        <div className="w-full flex justify-center my-6">
        <Pagenation
          pageCount={setPageCount}
          loading={isLoading || isFetching}
          data={data?.data?.results?.data ? data?.data?.results?.data : []}
          next={data?.data?.next ? data?.data?.next : null}
          currDataCount={data?.data?.current_page_data_count ? data?.data?.current_page_data_count : 0}
          totalLength={data?.data?.data_count ? data?.data?.data_count : 0}
          previous={data?.data?.previous? data?.data?.previous : null}
          pageSize={pageSize}
          setPageSize={setPageSize}
          showPage={true}
        />
      </div>
      </div>
      {deleteOpen && <ReviewDeleteModal open={deleteOpen} setOpen={setDeleteOpen} id={instance?.id} />}
    </div>
  );
}

export default ProductReview;
