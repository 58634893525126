import { axiosInstance } from "../../services/apiInitialSetup";

const addTag = async (data) => {
  try {
    const response = axiosInstance.post("tags/tag/", data);
    return response
  } catch (error) {
    throw error;
  }
};
const getTag = async (data) => {
    try {
      const response = axiosInstance.get("tags/tag");
      return response
    } catch (error) {
      throw error;
    }
  };
  const deleteTag = async (id) => {
    try {
      const response = axiosInstance.delete(`tags/tag/${id}/`);
      return response
    } catch (error) {
      throw error;
    }
  };
  const getSingleTag = async (id) => {
    try {
      const response = axiosInstance.get(`tags/tag/${id}/`);
      return response
    } catch (error) {
      throw error;
    }
  };

  const getLatestTag = async (id) => {
    try {
      const response = axiosInstance.get(`tags/tag/latest_tag/`);
      return response
    } catch (error) {
      throw error;
    }
  };

  const editTag = async (data) => {
    try {
      const response = axiosInstance.patch(`tags/tag/${data.id}/`,data.data);
      return response
    } catch (error) {
      throw error;
    }
  };


 
export { addTag ,getTag,deleteTag,getSingleTag,editTag,getLatestTag};
