import React, { useEffect, useState } from "react";
import Sidebar from "./components/Sidebar";
import { Outlet } from "react-router-dom";
import MasterHeader from "./components/MasterHeader";
import MobileSideBar from "./components/MobileSideBar";
import menu from "../../assets/images/commonImages/menu.svg";
import logoDark from "../../assets/images/logoDark.png";
import MasterFooter from "./components/MasterFooter";
const MasterLayout = () => {

  const [mobileActive, setMobileActive] = useState(false);
  const [collapse, setCollapse] = useState(false);
  
 

  const [isFixed, setIsFixed] = useState(
    window.matchMedia("(max-width: 890px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 890px)")
      .addEventListener("change", (e) => setIsFixed(e.matches));
  }, [isFixed]);

  return (
    <div className={` ${isFixed ? "px-4 py-6" : "p-10"} bg-primary h-full  w-full`}>
      {isFixed && (
        <div className="mb-5  flex items-center justify-between w-full">
          <img
            src={menu}
            alt="menu"
            className="cursor-pointer"
            onClick={() => setMobileActive(true)}
          />
          <img src={logoDark} className="w-36" alt="logo" />
        </div>
      )}
      <div className="flex w-full h-full">
        {!isFixed && <Sidebar collapse={collapse} setCollapse={setCollapse} />}
        <MobileSideBar
          setMobileActive={setMobileActive}
          mobileActive={mobileActive}
        />
        <div
          className={`flex-1  flex flex-col w-[70%] ${
            !collapse ? " ml-0 hxl:ml-[390px]" : " ml-0 hxl:ml-[100px]"
          } transition-all items-start justify-between`}
        >
          <MasterHeader />

          <Outlet />

          <MasterFooter />
        </div>
      </div>
    </div>
  );
};

export default MasterLayout;
