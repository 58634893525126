import React from "react";
import logo from "../../../../../assets/images/logoHead.svg";
import bgImg from "../../../../../assets/images/commonImages/tagBg.png";
import demoRing from "../../../../../assets/images/commonImages/demoRing.png";


const TagStructureNew = ({ values, image }) => {
  const imgUrl = () => {
    if ( typeof values.bgImage !== 'string') {
      return URL.createObjectURL(values?.bgImage);
    }
  };


  return (
    <div className=" h-[166px] w-[346px]  rounded-xl  flex relative"
        style={{
            backgroundImage: "url(" + bgImg + ")",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center",
        }}
    >
        <div className="min-w-[8rem] flex flex-col mt-[13px] ml-[19px]">
            <span className="text-[28px] leading-[30px] text-[#320409] font-RoxboroughFont">{values.title}</span>
            <span className="text-[28px] leading-[30px] text-[#320409] font-RoxboroughFont">{values.title1}</span>
            <span className="font-normal text-[14px] leading-[30px] text-[#000000] font-timeRomanFont">{values.description}</span>
        </div>
        <div className="w-[218px] h-[164px] max-w-[218px] absolute right-[-18px]">
            <img src={values.card_image && typeof values.card_image !== "string"? URL.createObjectURL(values.card_image): values.card_image} alt="logo" className="w-full h-full" />
        </div>
        
    </div>
  );
};

export default TagStructureNew;
