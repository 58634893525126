import React, { useState } from "react";
import DetialsSection from "./userOverviewComponent/DetialsSection.jsx";
import Address from "./userOverviewComponent/Address.jsx";
import KycDetials from "./userOverviewComponent/KycDetials.jsx";
import OrderDetials from "./userOverviewComponent/OrderDetials.jsx";
import { useParams } from "react-router";
import { useGetSingleUser } from "../../servicesQuery/userQuery/userQuery.jsx";

const UserOverview = () => {
  const [tabIndex, setTabIndex] = useState("address");
  const params = useParams().id;
  const { data, isLoading } = useGetSingleUser(params);



  return (
    <div className=" flex-1 w-full h-full">
      <div>
        <DetialsSection
          data={data?.data?.data}
          loading={isLoading}
          setTabIndex={setTabIndex}
          tabIndex={tabIndex}
        />
      </div>
      <div className="h-[90^]">
        {tabIndex === "address" && (
          <Address
            data={data?.data.data.user_address}
            personal={{
              name: data?.data.data.full_name,
              mobile: data?.data.data.phone_number,
            }}
            loading={isLoading}
          />
        )}
        {tabIndex === "kyc" && <KycDetials data={data?.data.data.userkyc} />}
        {tabIndex === "order" && <OrderDetials id={data?.data?.data?.id} />}
      </div>
    </div>
  );
};

export default UserOverview;
