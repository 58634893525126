import { Formik, Form } from "formik";
import React, { useState } from "react";
import DiscountSection from "../../subPagesComponents/DiscountSection";
import GeneralSection from "./GeneralSection";
import SubmitButton from "../../../../../components/submitButton/SubmitButton";
import * as Yup from "yup";

import { FormikContext } from "../../../../../utilities/resetForm/FormikContext";
import {
  useAddCoupons,
  useEditCoupons,
  useGetSingleCoupons,
} from "../../../../../servicesQuery/couponQuery/CouponQuery";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import ToggleButton from "../../../../../components/toggleButton/ToggleButton";

const couponValidationStepTwo = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  code: Yup.string()
    .matches(/^[A-Z0-9]+$/, "Only uppercase letters and numbers are allowed")
    .min(5, "Minium 5 values required")
    .required("Code is required"),
  expire: Yup.date().when(["noExpire"], {
    is: (value) => !value,
    then: (schema) =>
      schema
        .min(dayjs(), "Date should be in future")
        .required("Expiry is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  discription: Yup.string().required("Description is required"),
  discountType: Yup.string().required("Discount type is required"),
  discValue: Yup.string().when(["discountType"], {
    is: (value) => value === "percentage" || value === "fixed",
    then: (schema) =>
      schema
        .required("Discount value is required")
        .max(100, "Discount value should be percentage between 0-100"),
    otherwise: (schema) => schema.notRequired(),
  }),
  minOrderAmount: Yup.string().when(["discountType"], {
    is: (value) =>
      value === "percentage" || value === "free shipping" || value === "fixed",
    then: (schema) => schema.required("Minimum order amount is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  maxOrderAmount: Yup.string().when("discountType", {
    is: (value) => value === "percentage",
    then: (schema) => schema.required("Maximum order amount is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const WelcomeCouponAddEdit = () => {
  const id = useParams().id;
  const { data, isLoading } = useGetSingleCoupons(id);
  const initialValues = {
    name: data?.data?.name || "",
    code: data?.data?.code || "",
    expire: data?.data?.expires_on || "",
    discription: data?.data?.description || "",
    discountType: data?.data?.discount_type || "fixed",
    discValue: data?.data?.current_discount_value || "",
    minOrderAmount: data?.data?.min_order_amount || "",
    maxOrderAmount: data?.data?.maximum_discount_value || "",
    noExpire: data?.data?.expires_on === null ? true : false,
    is_active: data?.data?.is_active === false ? false : true,
  };
  const navigate = useNavigate();
  const [formik, setFormik] = useState();

  const { mutateAsync: addCoupons, isLoading: addLoding } = useAddCoupons();

  const { mutateAsync: editCoupons, isLoading: editLoding } = useEditCoupons();

  const handleAddCoupon = (values, { resetForm }) => {
    if (!id) {
      let data = {
        code: values?.code,
        discount_type: values.discountType,
        expires_on: values?.expire
          ? dayjs(values?.expire).format("YYYY-MM-DDThh:mm:ss")
          : "",
        discount_value:
          values.discountType === "percentage"
            ? values?.discValue / 100
            : values?.discValue || 0,
        name: values?.name,
        description: values?.discription,
        min_order_amount: values?.minOrderAmount || 0,
        coupon_type: "welcome",
        is_active: values.is_active,
        ...(values.discountType === "percentage" && {
          maximum_discount_value: parseInt(values?.maxOrderAmount),
        }),
      };
      
      addCoupons(data)
        .then((res) => {
          toast.success("Coupon Added");
          navigate("/coupon-management/welcome-coupons");
          resetForm();
        })
        .catch((err) => {
          if (err.response?.data?.code) {
            toast.error(err.response?.data?.code[0]);
          } else {
            toast.error("Something went wrong");
          }
        });
    } else {
      const valueData = {
        code: values?.code,
        discount_type: values.discountType,
        expires_on: values?.expire
          ? dayjs(values?.expire).format("YYYY-MM-DDThh:mm:ss")
          : "",
        discount_value:
          values.discountType === "percentage"
            ? values?.discValue / 100
            : values?.discValue || 0,
        name: values?.name,
        description: values?.discription,
        min_order_amount: values?.minOrderAmount || 0,
        coupon_type: "welcome",
        is_active: values.is_active,
        ...(values.discountType === "percentage" && {
          maximum_discount_value: parseInt(values?.maxOrderAmount),
        }),
      };

      const data = {
        id: id,
        data: valueData,
      };
      editCoupons(data)
        .then((res) => {
          toast.success("Coupon Edited");
          navigate("/coupon-management/welcome-coupons");
          resetForm();
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={couponValidationStepTwo}
      onSubmit={handleAddCoupon}
    >
      {({ setFieldValue, handleChange, values, initialValues }) => (
        <Form
          className={`${
            id && isLoading
              ? "opacity-50 pointer-events-none"
              : "opacity-100 pointer-events-auto"
          } transition`}
        >
          <div className="flex flex-col md:flex-row  gap-6">
            <div className="md:w-[16rem] flex flex-col gap-8">
              <DiscountSection
                setFieldValue={setFieldValue}
                change={handleChange}
                values={values}
              />
            </div>
            <div className="flex-1">
              <div className="w-full flex justify-end mb-4 gap-4">
                <p
                  className={` ${
                    values.is_active ? "text-darkBlue" : "text-error"
                  } text-base `}
                >
                  {values.is_active ? "Active" : "Inactive"}
                </p>

                <ToggleButton
                  isChecked={values.is_active}
                  change={(e) => setFieldValue("is_active", e)}
                  id={"welcom"}
                />
              </div>
              <GeneralSection
                values={values}
                setFieldValue={setFieldValue}
                initialValues={initialValues}
                handleChange={handleChange}
              />
            </div>
          </div>
          <div className="w-full flex justify-end">
            {" "}
            <div className="w-[20rem] mt-6">
              <SubmitButton
                isLoading={addLoding || editLoding}
                type={"submit"}
                content={id ? "Edit Coupons" : "Add Coupons"}
              />
            </div>
          </div>
          <FormikContext setFormik={setFormik} />
        </Form>
      )}
    </Formik>
  );
};

export default WelcomeCouponAddEdit;
