import React from "react";
import cart from "../../../../../../assets/images/commonImages/empty-cart1.png";
import facebook from "../../../../../../assets/images/commonImages/empty-cart2.png";
import insta from "../../../../../../assets/images/commonImages/empty-cart3.png";

import linkedin from "../../../../../../assets/images/commonImages/empty-cart4.png";

import twitter from "../../../../../../assets/images/commonImages/empty-cart5.png";

import logo from "../../../../../../assets/images/logoDark.png";
import { Link } from "react-router-dom";

const EmptyCartCouponStructure = ({ values }) => {
  return (
    <div className="w-full h-full  ">
      <div className=" overflow-hidden bg-white">
        <div className="p-6 w-full flex flex-col items-center gap-4">
          <img src={logo} alt="logo" />
          <div className="w-[12rem] h-[2px] bg-[#303030]"></div>
        </div>
        <div className="p-6 flex flex-col items-center  mb-6">
          <div className="mb-4">
            <img className="max-w-[35rem]" src={cart} alt="cart" />
          </div>
          <h1
            className="text-[3rem]  mb-4"
            style={{
              color: values.titleFontColor,
              fontStyle: values.titleFontItalics ? "italic" : "normal",
              fontWeight: values.titleFontBold ? "bold" : "normal",
              textDecoration: values.titleFontUnderline ? "underline" : "",
            }}
          >
            {values.titleContent !== "" ? values.titleContent : "Your title"}
          </h1>
          <h2
            className="text-[1.5rem] w-[80%] text-center mb-4"
            style={{
              color: values.subFontColor,
              fontStyle: values.subFontItalics ? "italic" : "normal",
              fontWeight: values.subFontBold ? "bold" : "normal",
              textDecoration: values.subFontUnderline ? "underline" : "",
            }}
          >
            {values.subContent !== "" ? values.subContent : "Your subtitle"},
            {"   "}
            <span className="font-bold">
              you get {values.discValue}{" "}
              {values?.discType === "fixed"
                ? " Rs "
                : values?.discType === "percentage"
                ? " % "
                : ""}
              off your order!
            </span>
          </h2>
          <p className="text-sm">Use this code at checkout:</p>
          <p
            className=" px-4 py-2"
            style={{
              color: values.codeFontColor,
              fontStyle: values.codeFontItalics ? "italic" : "normal",
              fontWeight: values.codeFontBold ? "bold" : "normal",
              textDecoration: values.codeFontUnderline ? "underline" : "",
              backgroundColor: values.colorThemeOne,
            }}
          >
            {" "}
            {values.cuponCode !== "" ? values.cuponCode : "Coupon code"}
          </p>
        </div>
        <div className="flex justify-center mb-8">
          <button
            type="button"
            style={{ backgroundColor: values.colorThemeTwo }}
            className="px-6 py-3  text-white font-bold"
          >
            Checkout Now »
          </button>
        </div>

        <div
          style={{ backgroundColor: values.colorThemeTwo }}
          className="p-6 flex flex-col items-center"
        >
          <div className="flex gap-3 mb-4">
            <Link>
              <img className="w-[35px]" src={facebook} alt="social" />
            </Link>
            <Link>
              <img className="w-[35px]" src={insta} alt="social" />
            </Link>
            <Link>
              <img className="w-[35px]" src={twitter} alt="social" />
            </Link>
            <Link>
              <img className="w-[35px]" src={linkedin} alt="social" />
            </Link>
          </div>
          <div className="text-center mb-4">
            <h3 className="font-bold">Company name</h3>
            <p>Address (eg: 123 Main St, City, ST 12345)</p>
          </div>
          <div className="flex gap-1 text-sm">
            <p>Don't want to receive cart reminder emails?</p>
            <Link className="text-[blue] underline">Unsubcribe</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyCartCouponStructure;
