import React, { useContext, useEffect, useState } from "react";
import CustomTable from "../../../components/customTable/CustomTable";
import Pagenation from "../../../components/pagination/Pagenation";
import { Link } from "react-router-dom";
import {
  useGetVendortList,
} from "../../../servicesQuery/vendorQuery/vendorQuery";
import { Context } from "../../../utilities/context/Context";
import Button from "../../../components/submitButton/Button";
import AddVendorModal from "../../../components/addVendorModal/AddVendorModal";
import InviteModal from "../../../components/addVendorModal/InviteModal";
import { getDynamicHead } from "../../couponManagement/couponManagementSubPages/autoCoupons/autoCouponsComponents/utils";
import DynamicColumnFilter from "../../../components/customTable/DynamicColumnFilter";

const head1 = [
  {
    key: 0,
    label: "SlNo",
    index: "id",
    width: 6,
    is_checked: true,
  },
  {
    key: 1,
    label: "Vendor ID",
    index: "vendor_id",
    width: 7,
    is_checked: true,
  },
  {
    key: 2,
    label: "Vendor Name",
    index: "name",
    width: 12,
    is_checked: true,
  },
  {
    key: 3,
    label: "LegalName of Entity",
    index: "legal_name_entity",
    width: 12,
    is_checked: true,
  },
  {
    key: 4,
    label: "Vendor Type",
    index: "vendor_type",
    width: 10,
    is_checked: true,
  },

  {
    key: 5,
    label: "Contact Number",
    index: "contact_number",
    width: 10,
    is_checked: true,
  },
  {
    key: 6,
    label: "Email Address",
    index: "email",
    width: 10,
    is_checked: true,
  },
  {
    key: 7,
    label: "Address",
    index: "address",
    width: 10,
    is_checked: true,
  },
  {
    key: 8,
    label: "City",
    index: "city",
    width: 8,
    is_checked: true,
  },
  {
    key: 8,
    label: "State",
    index: "states",
    width: 8,
    is_checked: true,
  },
  {
    key: 8,
    label: "GST",
    index: "gst",
    width: 8,
    is_checked: true,
  },
  {
    key: 8,
    label: "PAN",
    index: "pan",
    width: 8,
    is_checked: true,
  },
  {
    key: 8,
    label: "Earning Percentage",
    index: "earning_percent",
    width: 8,
    is_checked: true,
  },
  {
    key: 18,
    label: "Action",
    index: "action",
    width: 7,
    is_checked: true,
  },
];

const InviteVendorMainPage = () => {
  const dynamicHead = getDynamicHead("invite_vendor_management")
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState({
    label: "10",
    value: 10,
  });
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [inviteOpen,setInviteOpen] = useState(false)
  const [vendor,setVendor] =useState('')
  const {value,setValue}=useContext(Context)
  const [head, setHead] = useState(head1)
  const [dynamicData, setDynamicData] = useState(JSON.parse(localStorage.getItem('dynamicData')))
  const { data, isLoading, isFetching,isError } = useGetVendortList({
    page: pageCount,
    search:value,
    invited: false,
    pageSize: pageSize?.value
  });

  useEffect(()=> {
    if(dynamicHead){
      setHead(dynamicHead)
    }
  },[])

  useEffect(() => {
    document.querySelector("body").style.overflowY = "scroll";
  }, [open,editOpen]);
  
  
  const vendortBody = () => {
    return data?.data?.results?.map((item,index) => {
      return {
        id: {
          isRender:true,
          render:()=>{
            return (
              <div>
                <span>{(pageCount - 1) * pageSize?.value + index+1}</span>
              </div>
            )
          }
        },
        vendor_id: item.vendor_code || "No data",
        name: item.first_name + " " + item.last_name || "No data",
        legal_name_entity: item.leagal_name_of_entity || "No data",
        vendor_type: item.vendor_type || "No data",
        contact_number: item.phone || "No data",
        email: item.email || "No data",
        address: item.address || "No data",
        city: item.city || "No data",
        states: item.state_name || "No data",
        gst: item.gst || "No data",
        pan: item.pan || "No data",
        earning_percent: item.my_earning_percent || "No data",
        action: {
          isRender: true,
          render: () => (
            <div className="flex  items-center w-full gap-4 h-full">
              <div className=" p-2 rounded-lg hover:bg-lightBlue transition-all">
              <button onClick={()=>{
                setVendor(item)
                setEditOpen(true)
                }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M17.5 18.3334H2.5C2.15833 18.3334 1.875 18.05 1.875 17.7084C1.875 17.3667 2.15833 17.0834 2.5 17.0834H17.5C17.8417 17.0834 18.125 17.3667 18.125 17.7084C18.125 18.05 17.8417 18.3334 17.5 18.3334Z"
                    fill="#F19A0E"
                  />
                  <path
                    opacity="0.4"
                    d="M15.8505 2.90005C14.2338 1.28338 12.6505 1.24172 10.9921 2.90005L9.9838 3.90838C9.90047 3.99172 9.86714 4.12505 9.90047 4.24172C10.5338 6.45005 12.3005 8.21672 14.5088 8.85005C14.5421 8.85838 14.5755 8.86672 14.6088 8.86672C14.7005 8.86672 14.7838 8.83338 14.8505 8.76672L15.8505 7.75838C16.6755 6.94172 17.0755 6.15005 17.0755 5.35005C17.0838 4.52505 16.6838 3.72505 15.8505 2.90005Z"
                    fill="#F19A0E"
                  />
                  <path
                    d="M13.0079 9.60829C12.7663 9.49163 12.5329 9.37496 12.3079 9.24163C12.1246 9.13329 11.9496 9.01663 11.7746 8.89163C11.6329 8.79996 11.4663 8.66663 11.3079 8.53329C11.2913 8.52496 11.2329 8.47496 11.1663 8.40829C10.8913 8.17496 10.5829 7.87496 10.3079 7.54163C10.2829 7.52496 10.2413 7.46663 10.1829 7.39163C10.0996 7.29163 9.95795 7.12496 9.83295 6.93329C9.73295 6.80829 9.61628 6.62496 9.50795 6.44163C9.37461 6.21663 9.25795 5.99163 9.14128 5.75829C9.02461 5.50829 8.93295 5.26663 8.84961 5.04163L3.61628 10.275C3.50795 10.3833 3.40795 10.5916 3.38295 10.7333L2.93295 13.925C2.84961 14.4916 3.00795 15.025 3.35795 15.3833C3.65795 15.675 4.07461 15.8333 4.52461 15.8333C4.62461 15.8333 4.72461 15.825 4.82461 15.8083L8.02461 15.3583C8.17461 15.3333 8.38295 15.2333 8.48295 15.125L13.7163 9.89163C13.4829 9.80829 13.2579 9.71663 13.0079 9.60829Z"
                    fill="#F19A0E"
                  />
                </svg>
              </button>
              </div>
              <div className=" p-2 rounded-lg hover:bg-lightBlue transition-all">
              <button onClick={()=>{
                setVendor(item)
                setInviteOpen(true)
                }}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path opacity="0.4" d="M17.5748 17.9167C17.5748 18.1501 17.3915 18.3334 17.1581 18.3334H2.84147C2.60814 18.3334 2.4248 18.1501 2.4248 17.9167C2.4248 14.4667 5.8248 11.6667 9.9998 11.6667C10.8581 11.6667 11.6915 11.7834 12.4581 12.0084C11.9665 12.5917 11.6665 13.3501 11.6665 14.1667C11.6665 14.7917 11.8415 15.3834 12.1498 15.8834C12.3165 16.1667 12.5331 16.4251 12.7831 16.6417C13.3665 17.1751 14.1415 17.5001 14.9998 17.5001C15.9331 17.5001 16.7748 17.1167 17.3748 16.5001C17.5081 16.9501 17.5748 17.4251 17.5748 17.9167Z" fill="#F19A0E"/>
                    <path d="M17.4753 11.9416C16.8753 11.2583 15.9837 10.8333 15.0003 10.8333C14.067 10.8333 13.217 11.2166 12.6087 11.8416C12.0253 12.4416 11.667 13.2666 11.667 14.1666C11.667 14.7916 11.842 15.3833 12.1503 15.8833C12.317 16.1666 12.5337 16.4249 12.7837 16.6416C13.367 17.1749 14.142 17.4999 15.0003 17.4999C16.217 17.4999 17.2753 16.8499 17.8503 15.8833C18.0253 15.5999 18.1587 15.2749 18.2337 14.9416C18.3003 14.6916 18.3337 14.4333 18.3337 14.1666C18.3337 13.3166 18.0087 12.5333 17.4753 11.9416ZM16.2503 14.7749H15.6253V15.4249C15.6253 15.7666 15.342 16.0499 15.0003 16.0499C14.6587 16.0499 14.3753 15.7666 14.3753 15.4249V14.7749H13.7503C13.4087 14.7749 13.1253 14.4916 13.1253 14.1499C13.1253 13.8082 13.4087 13.5249 13.7503 13.5249H14.3753V12.9333C14.3753 12.5916 14.6587 12.3083 15.0003 12.3083C15.342 12.3083 15.6253 12.5916 15.6253 12.9333V13.5249H16.2503C16.592 13.5249 16.8753 13.8082 16.8753 14.1499C16.8753 14.4916 16.592 14.7749 16.2503 14.7749Z" fill="#F19A0E"/>
                    <path d="M9.99967 10.0001C12.3009 10.0001 14.1663 8.1346 14.1663 5.83341C14.1663 3.53223 12.3009 1.66675 9.99967 1.66675C7.69849 1.66675 5.83301 3.53223 5.83301 5.83341C5.83301 8.1346 7.69849 10.0001 9.99967 10.0001Z" fill="#F19A0E"/>
                </svg>

              </button>
              </div>
              {/* <div className="p-2 rounded-lg hover:bg-lightBlue transition-all">
              <Link to={`product-detials/${item.id}`}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 9 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.999973 16.92L7.51997 10.4C8.28997 9.62996 8.28997 8.36996 7.51997 7.59996L0.999973 1.07996"
                    stroke="#07415C"
                    strokeMiterlimit="10"
                    strokelinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
              </div> */}
            </div>
          ),
        },
      };
    });
  };

  return (
    <div >
      <div className="w-full  bg-white rounded-xl shadow-lg p-6 ">
        <div className="h-full">
        <div className="mb-8">
          <div className="flex gap-6 justify-end">
            {/* <div className="flex gap-6 w-[10rem]">
             
            </div> */}

            <Button
              onClick={() => setOpen(true)}
              style="text-xs w-[10rem] hover:shadow-md active:shadow-sm transition-all active:scale-95  border border-2 border-darkYellow  text-darkBlue   rounded-md py-2 px-6"
              content="Add New Vendor"
            />
            <div className="w-full md:w-[10rem]">
                <DynamicColumnFilter head={head} name="invite_vendor_management" setHead={setHead} dynamicData={dynamicData} setDynamicData={setDynamicData} />
              </div>
          </div>
        </div>
          <div className="w-full h-[95%]">
            <CustomTable
              head={head}
              body={vendortBody()}
              loading={isLoading || isFetching}
              dataLength={data?.data.length}
              height="max-h-[55vh]"
              error={isError}
              is_dynamic={true}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center my-6">
        <Pagenation
          pageCount={setPageCount}
          loading={isLoading || isFetching}
          data={data?.data?.results}
          next={data?.data?.next}
          currDataCount={data?.data?.data_count}
          totalLength={data?.data?.count}
          previous={data?.data?.previous}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </div>
      {open && <AddVendorModal open={open} setOpen={setOpen} />}
      {editOpen && <AddVendorModal open={editOpen} setOpen={setEditOpen} isEdit={true} vendor_id = {vendor.id} />}
      {inviteOpen && <InviteModal open={inviteOpen} setOpen={setInviteOpen} vendor_id = {vendor.id} />}
    </div>
  );
};

export default InviteVendorMainPage;
