import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function ChatSkeleton() {
  return (
    <div className="w-full">
      <div className="">
        <SkeletonTheme baseColor="#e2e0e0" highlightColor="#f3f2f2">
          <Skeleton count={1} height={60} width={"40%"} />
        </SkeletonTheme>
      </div>
        <div className="text-end">
          <SkeletonTheme baseColor="#e2e0e0" highlightColor="#f3f2f2">
            <Skeleton count={1} height={60} width={"40%"} />
          </SkeletonTheme>
        </div>
    </div>
  );
}

export default ChatSkeleton;
