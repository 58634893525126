import { axiosInstance } from "../../services/apiInitialSetup";


const notificationList = async ({ page, pageSize = 10,tab}) => {
    let url = "/notifications/adminnotification/?"
    if(page){
      url = url + `page=${page}&`
    }
    if(pageSize){
      url = url + `size=${pageSize}&`
    }
    if(tab === "unread"){
        url = url + `is_read=${false}&`
    }
    if(tab === "read"){
        url = url + `is_read=${true}&`
    }
   
    try {
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const readNotification = async (data) => {
    try {
        const response = await axiosInstance.patch(`/notifications/adminnotification/${data?.id}/mark_as_read/`, data);
        return response;
    } catch (error) {
        throw error;
    }
  };

  const readAllNotification = async (data) => {
    try {
        const response = await axiosInstance.post(`/notifications/adminnotification/mark_all_as_read/`, data);
        return response;
    } catch (error) {
        throw error;
    }
  };

  const policyList = async ({tab}) => {
    let url = `/adminpanel/policy?name=${tab}`
    try {
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const policyAdd = async (data) => {
    try {
      const response = await axiosInstance.post(`/adminpanel/policy/`, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

export {
    notificationList,
    readNotification,
    readAllNotification,
    policyList,
    policyAdd
}