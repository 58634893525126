import React from "react";
import BorderdInputField from "../../../../../../components/customField/BorderdInputField";
import ErrorMessageComponent from "../../../../../../components/errorMessage/ErrorMessageComponent";
import { Field } from "formik";
import { onlyNumbers } from "../../../../../../utilities/onlyNumbers/onlyNumbers";
const Flat = ({ values }) => {
  return (
    <div className="flex gap-6">
      <div className="w-full">
        <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
          Discount Value
        </label>
        <BorderdInputField
          placeholder={"Discount Value"}
          width={"w-full"}
          name="discValue"
          value={values.discValue}
          onInput={(e) => onlyNumbers(e)}
        />
        <ErrorMessageComponent name={"discValue"} />
      </div>
      <div className="w-full">
        <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
          Minimum Order Amount
        </label>
        <BorderdInputField
          placeholder={"Minimum Order Amount"}
          width={"w-full"}
          name="minDiscValue"
          value={values.minDiscValue}
          onInput={(e) => onlyNumbers(e)}
        />
        <ErrorMessageComponent name={"minDiscValue"} />
      </div>
    </div>
  );
};
const Percentage = ({ values }) => {
  return (
    <div>
      <div className="flex items-center gap-6">

     
      <div className="w-full">
        <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
          Discount Value %
        </label>
        <BorderdInputField
          placeholder={"Discount Value"}
 
          width={"w-full"}
          maxLength={3}
          name="discValue"
          onInput={(e) => onlyNumbers(e)}
          value={values.discValue}
        />
        <ErrorMessageComponent name={"discValue"} />
      </div>
      <div className="w-full">
        <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
          Minimum Order Amount
        </label>
        <BorderdInputField
          placeholder={"Minimum Order Amount"}
          width={"w-full"}
          name="minDiscValue"
          onInput={(e) => onlyNumbers(e)}
          value={values.minDiscValue}
        />
        <ErrorMessageComponent name={"minDiscValue"} />
      </div>
      </div>
      <div className="w-full">
        <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
          Maximum Discount Amount
        </label>
        <BorderdInputField
          placeholder={"Maximum Discount Amount"}
          width={"w-full"}
          name="maxDiscAmount"
          onInput={(e) => onlyNumbers(e)}
          value={values.maxDiscAmount}
        />
        <ErrorMessageComponent name={"maxDiscAmount"} />
      </div>
    </div>
  );
};
const BasicDetials = ({ values, setFieldValue, change }) => {
  return (
    <div className="bg-white rounded-lg p-6 mb-6">
      <h1 className="text-lg text-darkBlue font-semibold mb-6">
        Basic Details
      </h1>
      <div>
        <label className="block mb-3 text-sm text-darkBlue" htmlFor="type">
          Coupon Code
        </label>
        <BorderdInputField
          placeholder={"Enter Coupon Code "}
          width={"w-full"}
          name="cuponCode"
          values={values.cuponCode}
          maxLength={30}
        />
        <ErrorMessageComponent name={"cuponCode"} />
      </div>
      <h1 className="text-base text-darkBlue font-semibold mb-4">
        Discount Type{" "}
      </h1>
      <div className="flex gap-4 flex-wrap mb-6">
        <div className="flex items-center gap-3">
          <p className="text-sm text-darkBlue">Flat</p>

          <Field
            type="radio"
            className="accent-secondary w-4 h-4"
            name="discType"
            value="fixed"
            onChange={(e) => {
              setFieldValue("discValue", "");
              setFieldValue("minOrderAmount", "");
              setFieldValue("maxOrderAmount", "");
              change(e);
            }}
          />
        </div>

        <div className="flex items-center gap-3">
          <p className="text-sm text-darkBlue">Percentage</p>
          <Field
            type="radio"
            className="w-4 h-4 accent-secondary "
            name="discType"
            value="percentage"
          />
        </div>
      </div>

      {values.discType === "fixed" && <Flat values={values} />}
      {values.discType === "percentage" && <Percentage values={values} />}
    </div>
  );
};

export default BasicDetials;
