import React, { useContext, useEffect, useState } from "react";
import CustomTable from "../../../../components/customTable/CustomTable";
import { Link } from "react-router-dom";
import CustomSelect from "../../../../components/customSelect/CustomSelect";
import { useGetCoupons } from "../../../../servicesQuery/couponQuery/CouponQuery";
import dayjs from "dayjs";
import edit from "../../../../assets/images/commonImages/edit.svg";
import Pagenation from "../../../../components/pagination/Pagenation";
import DynamicColumnFilter from "../../../../components/customTable/DynamicColumnFilter";
import { getDynamicHead, getPageVal } from "../autoCoupons/autoCouponsComponents/utils";
import { Context } from "../../../../utilities/context/Context";

const head1 = [
  {
    key: 0,
    label: "SlNo",
    index: "id",
    width: 6,
    is_checked: true,
  },
  {
    key: 1,
    label: "Name",
    index: "name",
    width: 6,
    is_checked: true,
  },
  {
    key: 2,
    label: "Code",
    index: "code",
    width: 7,
    is_checked: true,
  },
  // {
  //   key: 3,
  //   label: "Discription",
  //   index: "discription",
  //   width: 7,
  // },

  // {
  //   key: 4,
  //   label: "Discount Type",
  //   index: "discounType",
  //   width: 10,
  // },
  // {
  //   key: 5,
  //   label: "Discount Value",
  //   index: "discountValue",
  //   width: 10,
  // },
  // {
  //   key: 6,
  //   label: "Minimum Order Amount",
  //   index: "miniumOrderAmount",
  //   width: 6,
  // },

  {
    key: 7,
    label: "Expiry Date",
    index: "expiry",
    width: 4,
    is_checked: true,
  },
  {
    key: 8,
    label: "Status",
    index: "status",
    width: 4,
    is_checked: true,
  },
  // {
  //   key: 9,
  //   label: "Usage Limit",
  //   index: "usage",
  //   width: 4,
  // },
  {
    key: 10,
    label: "Action",
    index: "action",
    width: 4,
    is_checked: true,
  },
];


const PremiumCoupons = () => {
  const { pageVal,setPageVal } = useContext(Context);
  const dynamicHead = getDynamicHead("premium_coupon_management")
  const [head, setHead] = useState(head1)
  const [dynamicData, setDynamicData] = useState(JSON.parse(localStorage.getItem('dynamicData')))
  const [pageCount, setPageCount] = useState(getPageVal("premium_coupon_management","pageCount",pageVal));
  const [pageSize, setPageSize] = useState(getPageVal("premium_coupon_management","pageSize",pageVal));
  useEffect(()=> {
    setPageVal((prevVal)=> {
      return {
        ...prevVal,
        name: "premium_coupon_management",
        pageSize,
        pageCount
      }
    })
  },[pageSize,pageCount])
  const { data, isLoading, isFetching,isError } = useGetCoupons({
    page: pageCount,
    type: "premium",
    pageSize: pageSize?.value
  });

  useEffect(()=> {
    if(dynamicHead){
      setHead(dynamicHead)
    }
  },[])

  const userBody = data?.data?.results?.map((item,index) => {
    return {
      id: {
        isRender:true,
        render:()=>{
          return (
            <div>
              <span>{(pageCount - 1) * pageSize?.value + index+1}</span>
            </div>
          )
        }
      },
      name: item?.name,
      code: {
        isRender: true,
        render: () => {
          return (
            <p className=" py-1 px-4 text-center rounded-lg bg-[#FFF8EF]">
              {item?.code}
            </p>
          );
        },
      },

      // discription: item?.description,

      // discounType: item?.discount_type,
      // discountValue:
      //   item.discounType === "percentage"
      //     ? item?.discount_value * 100
      //     : item?.discount_value,
      // miniumOrderAmount: item?.min_order_amount,
      expiry:
        item?.expires_on !== null
          ? dayjs(item?.expires_on).format("DD/MM/YYYY")
          : "No expiry",
      status: {
        isRender: true,
        render: () => {
          return (
            <p
              className={`font-semibold ${
                item?.is_active ? "text-status" : "text-error"
              }`}
            >
              {item?.is_active ? "Active" : "Inactive"}
            </p>
          );
        },
      },
      // usage: item?.usage_limit_per_user,

      action: {
        isRender: true,
        render: () => (
          <div className="flex h-full items-end w-full">
            <div className=" p-2 rounded-lg hover:bg-lightBlue transition-all">
              <Link to={`edit-coupon/${item.id}`}>
                <img src={edit} alt="edit" />
              </Link>
            </div>
          </div>
        ),
      },
    };
  });

  return (
    <div>
      <div className="rounded-xl p-8 bg-white">
        <div className="flex items-center justify-end gap-2 mb-10">
          <div className="flex items-center gap-6">
            <Link
              to={"/coupon-management/premium-coupons/add-coupons"}
              className="text-xs w-[10rem]   border-2 border-darkYellow hover:shadow-md active:shadow-sm transition-all active:scale-95  text-darkBlue   rounded-md py-2 px-6"
            >
              Add New Coupon
            </Link>
            <div className="w-full md:w-[10rem]">
                <DynamicColumnFilter head={head} name="premium_coupon_management" setHead={setHead} dynamicData={dynamicData} setDynamicData={setDynamicData} />
            </div>
          </div>
        </div>

        <div>
          <CustomTable
            body={userBody}
            dataLength={data?.data?.results?.length}
            loading={isLoading || isFetching}
            head={head}
            is_dynamic={true}
            error={isError}
          />
        </div>
      </div>
      <div className="w-full flex justify-center my-6">
        <Pagenation
          pageCount={setPageCount}
          loading={isLoading || isFetching}
          data={data?.data?.results}
          next={data?.data?.next}
          currDataCount={data?.data?.data_count}
          totalLength={data?.data?.count}
          previous={data?.data?.previous}
          pageSize={pageSize}
          setPageSize={setPageSize}
          countVal={pageCount}
        />
      </div>
    </div>
  );
};

export default PremiumCoupons;
