import { axiosInstance } from "../../services/apiInitialSetup";

const categoryLists = async ({ page,pageSize = 10, search ,category,invited}) => {
  try {
    const response = await axiosInstance.get(
      `/category/category/?page=${page}&size=${pageSize}&${
        search !== "" ? "search=" + search + "&" : ""
      }`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const categorySingle = async ({id}) => {
  if(id && id != "ab345"){
    try {
      const response = await axiosInstance.get(
        `/category/category/${id}/`
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
};

const subCategoryLists = async (data) => {
  let page = null
  let search = ""
  let pageSize = null
  if(data?.page){
    page = data?.page
  }
  if(data?.search){
    search = data?.search
  }
  if(data?.pageSize){
    pageSize = data?.pageSize
  }
    try {
      const response = await axiosInstance.get(
        `/category/name/?page=${page}&size=${pageSize}&${
          search !== "" ? "search=" + search + "&" : ""
        }`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

const subCatAdd = async (data) => {
try {
    const response = await axiosInstance.post(`category/category/`, data);
    return response;
} catch (error) {
    throw error;
}
};

const subCatCreate = async (data) => {
    try {
        const response = await axiosInstance.post(`category/name/`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

const subCatUpdate = async (data) => {
    try {
        const response = await axiosInstance.patch(`category/name/${data.get("id")}/`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

const subCategorySingle = async (id) => {
    try {
      const response = await axiosInstance.get(
        `/category/name/${id}/`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const productTypeLists = async ({ page, search, pageSize}) => {
    let url = "/product/product-type/?"
    if(page){
      url = url + `page=${page}&`
    }
    if(search){
      url = url + `search=${search}&`
    }
    if(pageSize){
      url = url + `size=${pageSize}&`
    }
    try {
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const productTypeCreate = async (data) => {
    try {
        const response = await axiosInstance.post(`product/product-type/`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

const productTypeUpdate = async (data) => {
    try {
        const response = await axiosInstance.patch(`product/product-type/${data.id}/`, data);
        return response;
    } catch (error) {
        throw error;
    }
};

const productTypeDelete = async (data) => {
  try {
      const response = await axiosInstance.delete(`product/product-type/${data.id}/`, data);
      return response;
  } catch (error) {
      throw error;
  }
};

const productSpecificationLists = async (data) => {
  try {
      const response = await axiosInstance.get(`specifications/productspecifications/?product__id__in=${[data?.product]}`, data);
      return response;
  } catch (error) {
      throw error;
  }
  };

const specificationLists = async (data) => {
  try {
      const response = await axiosInstance.get(`specifications/specifications/`, data);
      return response;
  } catch (error) {
      throw error;
  }
  };

  const specificationProductAdd = async (data) => {
    try {
      const response = await axiosInstance.post(`specifications/productspecifications/`, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const specificationProductEdit = async (data) => {
    try {
      const response = await axiosInstance.patch(`specifications/productspecifications/${data.id}/`, data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const specificationProductDelete = async (data) => {
    try {
        const response = await axiosInstance.delete(`specifications/productspecifications/${data.id}/`, data);
        return response;
    } catch (error) {
        throw error;
    }
  };

  const unitLists = async (data) => {
    try {
        const response = await axiosInstance.get(`specifications/units/`, data);
        return response;
    } catch (error) {
        throw error;
    }
    };

    const specificationAdd = async (data) => {
      try {
        const response = await axiosInstance.post(`specifications/specifications/`, data);
        return response;
      } catch (error) {
        throw error;
      }
    };

    const specificationEdit = async (data) => {
      try {
        const response = await axiosInstance.patch(`specifications/specifications/${data?.id}/`, data);
        return response;
      } catch (error) {
        throw error;
      }
    };

    const specificationDelete = async (data) => {
      try {
          const response = await axiosInstance.delete(`specifications/specifications/${data.id}/`, data);
          return response;
      } catch (error) {
          throw error;
      }
    };
  

export {specificationDelete,specificationEdit,specificationAdd,categoryLists,subCategoryLists,subCatAdd,subCatCreate,subCatUpdate,subCategorySingle,productTypeLists,productTypeCreate,productTypeUpdate,productTypeDelete,categorySingle,specificationLists,productSpecificationLists,specificationProductAdd,specificationProductEdit,specificationProductDelete,unitLists}