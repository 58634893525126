import React, { useState } from "react";
import thumbnail from "../../../assets/images/commonImages/thumbnail.svg";
import ErrorMessageComponent from "../../../components/errorMessage/ErrorMessageComponent";
import { toast } from "react-hot-toast";
import eye from "../../../assets/images/commonImages/eye.png";
import ImagePreview from "./ImagePreview";

const Thumbnail = ({ setImage, change, image,id }) => {
  const [preview, setPreview] = useState("");
  const [imgOpen, setImgOpen] = useState(false);

  return (
    <div className="p-6 rounded-lg bg-white  ">
      <h1 className="text-lg font-semibold text-darkBlue mb-4">Thumbnail</h1>
      <div className="flex flex-col items-center">
        <input
          type="file"
          name="thumbnail"
          hidden
          id="thumbnail"
          accept="image/png, image/jpeg, image/webp"
          onChange={(e) => {
            if (e.target.files[0]?.size > 2000000) {
              toast.error("File size should be less than 2MB");
            }
            setImage(e.target.files[0]);
            let url;
            if (e.target.files.length > 0) {
              url = URL.createObjectURL(e?.target?.files[0]);
            }
            setPreview(url);
            change(e);
          }}
        />
        <label htmlFor="thumbnail" className="cursor-pointer relative">
          {id&&<div className="absolute z-[1] top-4 right-4 w-9 h-9">
            <button type="button" className="w-[30px] h-[30px]" onClick={()=>setImgOpen(true)}>
              <img className="w-full" src={eye} alt="" />
            </button>
          </div>}
          <img
            className="w-full rounded-lg"
            src={preview || image || thumbnail}
            alt="thumbnail"
          />
         {id&& <p className="absolute top-[50%] h-10 flex items-center justify-center bg-opacity-75 bg-blend-multiply 
           font-bold text-md text-white left-[50%] translate-x-[-50%] w-full bg-darkBlue hover:bg-opacity-100 transition-all">
            
            Change thumbnail
          </p>}
        </label>

        <p className="text-xs font-light mt-4">
          {" "}
          Set the product thumbnail image.{" "}
        </p>
      </div>
      <ErrorMessageComponent name={"thumbnail"} />
      <ImagePreview open={imgOpen} setOpen={setImgOpen} img={image} />
    </div>
  );
};

export default Thumbnail;
