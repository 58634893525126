import React, { useContext, useEffect, useState } from "react";
import SingleDatePicker from "../../../components/datePicker/SingleDatePicker";
import CustomTable from "../../../components/customTable/CustomTable";
import Pagenation from "../../../components/pagination/Pagenation";
import { Link } from "react-router-dom";
import { useGetUserList } from "../../../servicesQuery/userQuery/userQuery";
import { Context } from "../../../utilities/context/Context";
import DynamicColumnFilter from "../../../components/customTable/DynamicColumnFilter";
import { getDynamicHead, getPageVal } from "../../couponManagement/couponManagementSubPages/autoCoupons/autoCouponsComponents/utils";
import TableFilterDropDown from "../../../components/customTable/TableFilterDropDown";
import dayjs from "dayjs";

const head1 = [
  {
    key: 1,
    label: "SlNo",
    index: "id",
    width: 2,
    is_checked: true
  },
  {
    key: 2,
    label: "User Name",
    index: "username",
    width: 8,
    is_checked: true
  },
  {
    key: 3,
    label: "Mobile Number",
    index: "mobile",
    width: 8,
    is_checked: true
  },
  {
    key: 4,
    label: "Email Id",
    index: "email",
    width: 10,
    is_checked: true
  },

  {
    key: 6,
    label: "Kyc Status",
    index: "kyc",
    width: 6,
    is_checked: true
  },

  {
    key: 8,
    label: "Status",
    index: "status",
    width: 7,
    is_checked: true
  },
  {
    key: 10,
    label: "Timestamp",
    index: "datetime",
    width: 8,
    is_checked: true,
  },
  {
    key: 9,
    label: "Actions",
    index: "action",
    width: 4,
    is_checked: true
  },
];

const UserManagementMainPage = () => {
  const { pageVal,setPageVal } = useContext(Context);
  const dynamicHead = getDynamicHead("user_management")
  const [date, setDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [pageCount, setPageCount] = useState(getPageVal("user_management","pageCount",pageVal));
  const [pageSize, setPageSize] = useState(getPageVal("user_management","pageSize",pageVal));
  useEffect(()=> {
    setPageVal((prevVal)=> {
      return {
        ...prevVal,
        name: "user_management",
        pageSize,
        pageCount
      }
    })
  },[pageSize,pageCount])
  const {value,setValue}=useContext(Context)
  const [head, setHead] = useState(head1)
  const [dynamicData, setDynamicData] = useState(JSON.parse(localStorage.getItem('dynamicData')))
  const [isEnabled, setEnabled] = useState(true);
  const [filterCount, setFilterCount] = useState(0);
  const { data, isLoading, isFetching,isError } = useGetUserList({
    start_date: date,
    end_date: to_date,
    page: pageCount,
    search:value,
    pageSize: pageSize?.value,
    enabled: isEnabled
  });

  useEffect(()=> {
    if(dynamicHead){
      setHead(dynamicHead)
    }
  },[])

  useEffect(() => {
    if(date ||to_date ){
      setPageCount(1);
    }
  }, [date,to_date]);

  const handleFilter = (type)=> {
    if(type === "clear"){
      setDate("")
      setToDate("")
      setEnabled(false)
    }else if(type === "save"){
      setEnabled(true)
      let count = 0
      if(date){
        count += 1
      }
      if(to_date){
        count += 1
      }
      setFilterCount(count)
    }
  }


  const userBody = () => {
    return data?.data?.results?.map((item,index) => {
      return {
        id: {
          isRender:true,
          render:()=>{
            return (
              <div>
                <span>{(pageCount - 1) * pageSize?.value + index+1}</span>
              </div>
            )
          }
        },
        username: item.full_name,
        mobile: item.phone_number,
        email: item.email,
        // address: "6391 Elgin St. Celina, Delaware 10299sdfsdfsdfsdf ",
        kyc: {
          isRender:true,
          render:()=>{
            return (
              <div>
                {
                  item?.userkyc?.kyc_completed ? <p className="font-semibold text-status">Verified</p>:<p className="font-semibold text-darkBlue">Pending</p>
                }
              </div>
            )
          }
        },
       
        status: {
          isRender: true,
          render: () =>
            item.is_active ? (
              <p className="text-status font-semibold">Active</p>
            ) : (
              <p className="text-[grays] font-semibold">Inactive</p>
            ),
        },
        datetime:
          dayjs(item.created_at).format("DD/MM/YYYY") +
            " " +
            dayjs(item.created_at).format("hh:mm A") || "No data",
        action: {
          isRender: true,
          render: () => (
            <div className="flex justify-center h-full w-full">
              <div className=" p-2 rounded-lg hover:bg-lightBlue transition-all">
                <Link to={`user-overview/${item.id}`}>
                  <svg
                    width="9"
                    height="18"
                    viewBox="0 0 9 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.999973 16.92L7.51997 10.4C8.28997 9.62996 8.28997 8.36996 7.51997 7.59996L0.999973 1.07996"
                      stroke="#07415C"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          ),
        },
      };
    });
  };
 
  return (
    <div >
      <div className="w-full overflow-hidden bg-white rounded-xl shadow-lg p-6 ">
        <div className="">
          <div className="relative w-full flex justify-between flex-col md:flex-row gap-4 mb-8">
            <h1 className="text-base font-bold">Customer Management</h1>
            <div className=" w-full md:w-[20rem] flex items-center gap-4">
              {/* <SingleDatePicker onDateChange={(date) =>{ 
                if (date) {
                  setDate(date?.toLocaleDateString());
                } else {
                  setDate("");
                }
                }} /> */}
                <TableFilterDropDown name="user_management" setDate={setDate} setToDate={setToDate} setEnabled={setEnabled} filterCount={filterCount} handleFilter={handleFilter} date={date} to_date={to_date} />
                <DynamicColumnFilter head={head} name="user_management" setHead={setHead} dynamicData={dynamicData} setDynamicData={setDynamicData} />
            </div>
          </div>
          <div className="w-full h-[95%]">
            <CustomTable
              body={userBody()}
              dataLength={data?.data?.results?.length}
              loading={isLoading || isFetching}
              head={head}
             
              error={isError}
              is_dynamic={true}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center my-6">
        <Pagenation
          pageCount={setPageCount}
          loading={isLoading || isFetching}
          data={data?.data?.results}
          next={data?.data?.next}
          currDataCount={data?.data?.data_count}
          totalLength={data?.data?.count}
          previous={data?.data?.previous}
          pageSize={pageSize}
          setPageSize={setPageSize}
          showPage={true}
          countVal={pageCount}
        />
      </div>
    </div>
  );
};

export default UserManagementMainPage;
