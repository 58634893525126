import React from "react";
import product from "../../../assets/images/commonImages/product.png";
import edit from "../../../assets/images/commonImages/edit.svg";
import Skeleton from "react-loading-skeleton";
import { useEditProduct } from "../../../servicesQuery/productQuery/productQuery";
import { toast } from "react-hot-toast";
import { limitFraction } from "../../../utilities/limitFractionNumber/limitFraction";

const DetailSection = ({
  tabIndex,
  setEditOpen,
  setTabIndex,
  data,
  loading,
}) => {
  const { mutateAsync: editImage } = useEditProduct();
  const formData = new FormData();

  return (
    <div
        className={`flex gap-12 items-start justify-between hxl:justify-start mb-10`}
      >
        <button
          className={`text-base relative ${
            tabIndex === "main_categories" ? "tab-active" : ""
          }   ${tabIndex === "main_categories" ? "text-darkYellow" : "text-darkBlue"} ${
            tabIndex === "main_categories" ? "font-bold" : "font-normal"
          } transition-all hover:text-darkYellow hover:opacity-70`}
          onClick={() => setTabIndex("main_categories")}
        >
          Main Categories
        </button>
        <button
          className={`text-base relative ${
            tabIndex === "sub_categories" ? "tab-active" : ""
          }   ${tabIndex === "sub_categories" ? "text-darkYellow" : "text-darkBlue"} ${
            tabIndex === "sub_categories" ? "font-bold" : "font-normal"
          } transition-all hover:text-darkYellow hover:opacity-70`}
          onClick={() => setTabIndex("sub_categories")}
        >
          Sub Categories
        </button>
        <button
          className={`text-base relative ${
            tabIndex === "product_types" ? "tab-active" : ""
          }   ${tabIndex === "product_types" ? "text-darkYellow" : "text-darkBlue"} ${
            tabIndex === "product_types" ? "font-bold" : "font-normal"
          } transition-all hover:text-darkYellow hover:opacity-70`}
          onClick={() => setTabIndex("product_types")}
        >
          Product Types
        </button>
        <button
          className={`text-base relative ${
            tabIndex === "specification" ? "tab-active" : ""
          }   ${tabIndex === "specification" ? "text-darkYellow" : "text-darkBlue"} ${
            tabIndex === "specification" ? "font-bold" : "font-normal"
          } transition-all hover:text-darkYellow hover:opacity-70`}
          onClick={() => setTabIndex("specification")}
        >
          Specification
        </button>
      </div>
  );
};

export default DetailSection;
