import React, { useEffect } from "react";
import logoAuth from "../../../assets/images/logoHalf.svg";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import SubmitButton from "../../../components/submitButton/SubmitButton";
import ErrorMessageComponent from "../../../components/errorMessage/ErrorMessageComponent";
import { useResetPasswordConfirmQuery, useVendorResetPassword } from "../../../servicesQuery/authQuery/AuthQuerys";

const VendorResetPassword = () => {
  const forgotValidation = () => {
    return Yup.object().shape({
      confirmPassword: Yup.string()
        .required("Confirm password is required")
        .oneOf([Yup.ref("password"), null], "Password does not match"),

      password: Yup.string().required("Password is required"),
    });
  };

  const params = useParams();
  const formData = new FormData();
  const initialValue = {
    password: "",
    confirmPassword: "",
  };
  const navigate = useNavigate();
  const {
    mutateAsync: resetPassowrd,
    isLoading,
   
  } = useVendorResetPassword();

  const onSubmit = (values) => {
    formData.append("token", params.token);
    formData.append("password", values.password);

    resetPassowrd(formData)
      .then((res) => {
        toast.success(res.data.message);
        navigate('/signin')
      })
      .catch((err) => {
        toast.error(err.message || err.errors.password);
      });
  };
  return (
    <div className="w-full h-full flex flex-col gap-4 items-center justify-center">
      <div className="mb-8 w-[8rem]">
        <img className="w-full" src={logoAuth} alt="logo" />
      </div>

      <div className="flex flex-col items-center gap-0">
        <h1 className="text-xl font-bold text-darkYellow ">Reset password</h1>
        <div className="h-12 w-full p-2 text-error text-sm font-normal text-center transition-all  "></div>
        <div>
          <Formik
            initialValues={initialValue}
            onSubmit={onSubmit}
            validationSchema={forgotValidation}
          >
            <Form className="flex flex-col items-center  w-[25rem] p-2">
              <Field
                type="password"
                className="w-full border-solid	 border-2 border-[#F19A0E4D] rounded-md px-4 py-2"
                placeholder="Password"
                name="password"
              />
              <ErrorMessageComponent name="password" />
              <Field
                type="password"
                className="w-full border-solid	 border-2 border-[#F19A0E4D] rounded-md px-4 py-2"
                placeholder="Confirm password"
                name="confirmPassword"
              />
              <ErrorMessageComponent name="confirmPassword" />
              <SubmitButton
                type="submit"
                content="Reset password"
                isLoading={isLoading}
              />
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default VendorResetPassword;
