import React from 'react'

function PhoneUI({content}) {
    // const htmlContent = '<h1><span style="color: rgb(230, 0, 0);">Terms and Conditions</span></h1><p><br></p><h2><em>dskfnsdnfld</em></h2><p><em>dsflksdnlksd</em></p><p><em>sdgd</em></p>';
  return (
    <div className="phone-emulator relative">
        <div className="screen" dangerouslySetInnerHTML={{ __html: content }} />
        <div className='absolute h-[10px] bg-[#333] top-3 left-[50%] w-[50%] translate-x-[-50%] rounded-b-lg'></div>
    </div>
  )
}

export default PhoneUI
