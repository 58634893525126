import { axiosInstance } from "../../services/apiInitialSetup";

const dashboardData = async ({dateFilter}) => {
    try {
      const response = await axiosInstance.get(
        `/adminpanel/dashboard/?${
          dateFilter?.startDate && dateFilter?.endDate
            ? "date_range=" + dateFilter?.startDate + "," + dateFilter?.endDate
            : ""
        }`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

  const dashboardOrderData = async ({dateFilter}) => {
    try {
      const response = await axiosInstance.get(
        `/adminpanel/order-graph/?${
          dateFilter?.startDate && dateFilter?.endDate
            ? "created_at__date__range=" + dateFilter?.startDate + "," + dateFilter?.endDate
            : ""
        }`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

export {
    dashboardData,
    dashboardOrderData
}