import React from "react";
import { Outlet } from "react-router-dom";


const ReviewPage = () => {

  
  return (
    <div className="flex-1 w-full">
      <Outlet/>
    </div>
  );
};

export default ReviewPage;
