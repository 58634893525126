import React from "react";
import { Outlet } from "react-router-dom";


const UserRolePage = () => {

  
  return (
    <div className="flex-1 w-full">
      <Outlet/>
    </div>
  );
};

export default UserRolePage;
