import { axiosInstance } from "../../services/apiInitialSetup";

export const artmotionProductLists = async ({ page,pageSize = 10, metal }) => {
  try {
    const response = await axiosInstance.get(
      `/art-in-motion/product/?page=${page}&size=${pageSize}&${
        metal && metal !== "All" ? "metal_type__name__iexact="+ metal : ""
      }`
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const artmotionMetalLists = async () => {
    try {
      const response = await axiosInstance.get(
        `/art-in-motion/metal_type/`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };


  export const artmotionDesignLists = async () => {
    try {
      const response = await axiosInstance.get(
        `/art-in-motion/design_option/`
      );
      return response;
    } catch (error) {
      throw error;
    }
  };

export const artMotionSingleProduct = async (id) => {
  if(id){
    try {
      const response = await axiosInstance.get(`/art-in-motion/product/${id}/`);
      return response;
    } catch (error) {
      throw error;
    }
  }
};

export const artmotionProductAdd = async (data) => {
  try {
    const response = await axiosInstance.post(`/art-in-motion/product/`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const artmotionproductEdit = async (data) => {
  try {
    const response = await axiosInstance.patch(
      `/art-in-motion/product/${data.id}/`,
      data.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const orderArtmotionList = async ({ date, page,pageSize = 10,search,order_status,payment_status,start_date,end_date }) => {
  try {
    const response = axiosInstance.get(
      `art-in-motion/order?${start_date ? "start_date=" + start_date + "&" : ""}${end_date ? "end_date=" + end_date + "&" : ""}${search !== ''? 'search='+search+'&':''}page=${page}&size=${pageSize}&${order_status && order_status !== "bc654"? "status__in=" + order_status + "&": ""}${payment_status && payment_status !== "bc654"? "order_payments__status__in=" + payment_status + "&": ""}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};