import {
    useMutation,
    useQuery,
    useQueryClient,
  } from "react-query";
import { acceptTicket, ChatList, SingleTicket, TicketList } from "./ticketFunctions";
  
  const useGetTicketList = (data) => {
    return useQuery(["getTicketList", data], () => TicketList(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };

  const useGetChatList = (data) => {
    return useQuery(["getChatList", data], () => ChatList(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };

  const useAcceptTicket = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => acceptTicket(data), {
      onSuccess: (data) => {
          queryClient.invalidateQueries('getChatList')
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useGetSingleTicket = (data) => {
    return useQuery(["getSingleTicket", data], () => SingleTicket(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };

export {
    useGetTicketList,
    useGetChatList,
    useAcceptTicket,
    useGetSingleTicket
}