import React from 'react'
import toast from 'react-hot-toast';
import ModalLayout from '../../../components/customModal/CustomModal';
import Button from '../../../components/submitButton/Button';
import { useDeleteSpecification } from '../../../servicesQuery/categoryQuery/categoryQuery';

function DeleteSpecificationModal({ open, setOpen,data }) {
    const { mutateAsync: deleteSpecification, isLoading: addLoaing } = useDeleteSpecification();

    const handleDelete = ()=> {
        toast.promise(deleteSpecification(data)
                .then((res) => {
                    setOpen(false);
                })
                , {
                loading: "deleting Specification",
                success: "Specification Deleted",
                error: (err) => err?.response?.data?.detail === "Cannot delete due to related protected objects."? "The chosen specification is currently in active use": err?.response?.data?.detail
          });
    }
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="bg-white  rounded-lg  w-[90%] hxl:max-w-[20rem] m-4 p-5 ">
        <div className='flex flex-col gap-3 items-center'>
            <span>Are you sure,  you want to delete?</span>
            <div className='flex justify-between gap-3'>
                <Button
                onClick={()=> setOpen(false)}
                style="text-xs w-[6rem]  border border-2 border-darkYellow  text-darkBlue  rounded-md py-2 px-6"
                content="Cancel"
                />
                <Button
                    onClick={()=> handleDelete()}
                    style="text-xs w-[6rem] border border-2 border-error text-darkBlue   rounded-md py-2 px-6"
                    content="Delete"
                />
            </div>
        </div>
      </div>
    </ModalLayout>
  )
}

export default React.memo(DeleteSpecificationModal)
