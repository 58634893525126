import React, { useEffect, useState } from "react";
import ModalLayout from "../customModal/CustomModal";
import { Field, Form, Formik } from "formik";
import BorderdInputField from "../customField/BorderdInputField";
import CustomSelect from "../customSelect/CustomSelect";
import SubmitButton from "../submitButton/SubmitButton";
import {
  useAddRoles,
  useEditRole,
} from "../../servicesQuery/roleQuery/roleQuery";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import ErrorMessageComponent from "../errorMessage/ErrorMessageComponent";
import { ResetForm } from "../../utilities/resetForm/FormikContext";
const Checkbox = ({
  name,
  id,
  value,
  handleChange,
  isChecked,
  onChange = () => {},
  label,
}) => {
  const [checked, setChecked] = useState(isChecked);
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div className="flex gap-6 justify-between">
      <div className="flex gap-6 items-center">
        <Field
          type="checkbox"
          name={name}
          value={value}
          id={id}
          className="w-4 h-4 accent-secondary"
          onChange={(e) => {
            onChange(e);
            handleChange(e);
          }}
          onClick={() => setChecked(!checked)}
        />
        <label htmlFor={id} className="text-sm">
          {label}
        </label>
      </div>
    </div>
  );
};

const AddUserRoleModal = ({ open, setOpen, isEdit, role }) => {
  const validation = () => {
    return Yup.object().shape({
      roleName: Yup.string().required("Role name is required"),
      permission: Yup.array()
        .min(1, "Atleast one permission is required")
        .required("Atleast one permission is required"),
    });
  };
  const { mutateAsync: addRoles, isLoading: addLoaing } = useAddRoles();
  const { mutateAsync: editRoles, isLoading: editLoaing } = useEditRole();
  const initialValue = {
    roleName: role?.name || "",
    permission:
      role?.permissions?.map((e) => {
        return e.codename;
      }) || [],
  };

  const handleSubmmit = (values, { resetForm }) => {
    if (isEdit) {
      const data = {
        name: role?.name,
        data: {
          name: values.roleName,
          permissions: values?.permission?.map((e) => {
            return {
              codename: e,
            };
          }),
        },
      };

      editRoles(data)
        .then((res) => {
          toast.success("User role edited");
          resetForm();
          setOpen(false);
        })
        .catch((error) => {
          if (error?.response?.data.name[0]) {
            toast.error("Somethig went wrong");
          }
        });
    } else {
      const data = {
        name: values.roleName,
        permissions: values?.permission?.map((e) => {
          return {
            codename: e,
          };
        }),
      };

      addRoles(data)
        .then((res) => {
          toast.success("User role added");
          resetForm();
          setOpen(false);
        })
        .catch((error) => {
          if (error?.response?.data.name[0]) {
            toast.error("Role name already exists");
          }
        });
    }
  };

  const handleCheckBox = (checked,values,name)=> {
    if (!checked && values.permission.includes(name)) {
      const index = values.permission.indexOf(name);
      if (index !== -1) {
        values.permission.splice(index, 1);
      }
    }
  }

  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="bg-primary  rounded-xl  w-[90%] hxl:max-w-[50rem] m-4 ">
        <div className="w-full flex justify-between p-6 border-b-[.5px] border-darkBlue border-opacity-20 ">
          <h2 className="text-lg text-darkBlue font-bold">
            {isEdit ? "Edit User Role" : "Add New User Role"}
          </h2>

          <div
            className="cursor-pointer "
            onClick={() => {
              setOpen(false);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3554 8.00005L15.4665 2.88894C16.1109 2.24449 16.1109 1.17783 15.4665 0.533382C14.822 -0.111062 13.7554 -0.111062 13.1109 0.533382L7.9998 5.64449L2.88869 0.533382C2.24425 -0.111062 1.17758 -0.111062 0.533138 0.533382C-0.111306 1.17783 -0.111306 2.24449 0.533138 2.88894L5.64425 8.00005L0.533138 13.1112C-0.111306 13.7556 -0.111306 14.8223 0.533138 15.4667C0.866471 15.8 1.28869 15.9556 1.71092 15.9556C2.13314 15.9556 2.55536 15.8 2.88869 15.4667L7.9998 10.3556L13.1109 15.4667C13.4442 15.8 13.8665 15.9556 14.2887 15.9556C14.7109 15.9556 15.1331 15.8 15.4665 15.4667C16.1109 14.8223 16.1109 13.7556 15.4665 13.1112L10.3554 8.00005Z"
                fill="#07415C"
              />
            </svg>
          </div>
        </div>
        <div className="overflow-y-scroll  p-6 ">
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmmit}
            validationSchema={validation}
            enableReinitialize
          >
            {({ setFieldValue, values, handleChange, setValues }) => (
              <Form>
                
                <ResetForm isOpen={open} />

                <div className="flex gap-6 mb-6 flex-col sm:flex-row">
                  <div className="w-full ">
                    <label
                      className=" text-secondary text-base inline-block mb-2"
                      htmlFor="role"
                    >
                      User Role
                    </label>
                    <BorderdInputField
                      type={"text"}
                      id="role"
                      name={"roleName"}
                      placeholder="Name of the user role"
                      onChange={handleChange}
                      value={values?.roleName}
                    />
                    <ErrorMessageComponent name={"roleName"} />
                  </div>
                </div>

                <div className="w-full mb-6 ">
                  <div className="mb-6 text-lg text-darkBlue font-bold">
                    <h2>Permissions</h2>
                  </div>
                  <div className="w-full flex flex-col gap-4 max-h-[25rem] overflow-y-scroll scroll-m-1">
                    <div className="flex gap-6 justify-between">
                      <h2 className="text-base text-darkBlue">Dashboard</h2>
                      <div className="flex gap-12">
                        <Checkbox
                          values={values}
                          onChange={(e) => {
                            handleCheckBox(e.target.checked,values,"can_edit_dashboard")
                          }}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"dashboard-view"}
                          name={"permission"}
                          value={"can_view_dashboard"}
                          label={"View"}
                        />
                        <Checkbox
                          values={values}
                          onChange={(e) => {
                            if (e.target.checked && !values.permission.includes("can_view_dashboard")) {
                              values.permission.push("can_view_dashboard");
                            }
                          }}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"dashboard-edit"}
                          name={"permission"}
                          value={"can_edit_dashboard"}
                          label={"Edit"}
                        />
                      </div>
                    </div>
                    <div className="flex gap-6 justify-between">
                      <h2 className="text-base text-darkBlue">
                        Customer Management
                      </h2>
                      <div className="flex gap-12">
                        <Checkbox
                          values={values}
                          onChange={(e) => {
                            handleCheckBox(e.target.checked,values,"can_edit_user")
                          }}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"user-view"}
                          name={"permission"}
                          value={"can_view_user"}
                          label={"View"}
                        />
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"user-edit"}
                          name={"permission"}
                          value={"can_edit_user"}
                          onChange={(e) => {
                            if (e.target.checked && !values.permission.includes("can_view_user")) {
                              values.permission.push("can_view_user");
                            }
                          }}
                          label={"Edit"}
                        />
                      </div>
                    </div>
                    <div className="flex gap-6 justify-between">
                      <h2 className="text-base text-darkBlue">
                        Coupon Management
                      </h2>
                      <div className="flex gap-12">
                        <Checkbox
                          values={values}
                          onChange={(e) => {
                            handleCheckBox(e.target.checked,values,"can_edit_coupon")
                          }}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"coupon-view"}
                          name={"permission"}
                          value={"can_view_coupon"}
                          label={"View"}
                        />
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"coupon-edit"}
                          name={"permission"}
                          value={"can_edit_coupon"}
                          onChange={(e) => {
                            if (e.target.checked && !values.permission.includes("can_view_coupon")) {
                              values.permission.push("can_view_coupon");
                            }
                          }}
                          label={"Edit"}
                        />
                      </div>
                    </div>
                    <div className="flex gap-6 justify-between">
                      <h2 className="text-base text-darkBlue">
                        Product Management
                      </h2>
                      <div className="flex gap-12">
                        <Checkbox
                          values={values}
                          onChange={(e) => {
                            handleCheckBox(e.target.checked,values,"can_edit_product")
                          }}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"product-view"}
                          name={"permission"}
                          value={"can_view_product"}
                          label={"View"}
                        />
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"product-edit"}
                          name={"permission"}
                          value={"can_edit_product"}
                          onChange={(e) => {
                            if (e.target.checked && !values.permission.includes("can_view_product")) {
                              values.permission.push("can_view_product");
                            }
                          }}
                          label={"Edit"}
                        />
                      </div>
                    </div>
                    <div className="flex gap-6 justify-between">
                      <h2 className="text-base text-darkBlue">
                        Order Management
                      </h2>
                      <div className="flex gap-12">
                        <Checkbox
                          values={values}
                          onChange={(e) => {
                            handleCheckBox(e.target.checked,values,"can_edit_order")
                          }}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"order-view"}
                          name={"permission"}
                          value={"can_view_order"}
                          label={"View"}
                        />
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"order-edit"}
                          name={"permission"}
                          value={"can_edit_order"}
                          onChange={(e) => {
                            if (e.target.checked && !values.permission.includes("can_view_order")) {
                              values.permission.push("can_view_order");
                            }
                          }}
                          label={"Edit"}
                        />
                      </div>
                    </div>
                    <div className="flex gap-6 justify-between">
                      <h2 className="text-base text-darkBlue">
                        Tag Management
                      </h2>
                      <div className="flex gap-12">
                        <Checkbox
                          values={values}
                          onChange={(e) => {
                            handleCheckBox(e.target.checked,values,"can_edit_appmanagement")
                          }}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"tags-view"}
                          name={"permission"}
                          value={"can_view_appmanagement"}
                          label={"View"}
                        />
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"tags-edit"}
                          name={"permission"}
                          value={"can_edit_appmanagement"}
                          onChange={(e) => {
                            if (e.target.checked && !values.permission.includes("can_view_appmanagement")) {
                              values.permission.push("can_view_appmanagement");
                            }
                          }}
                          label={"Edit"}
                        />
                      </div>
                    </div>
                    {/* <div className="flex gap-6 justify-between">
                      <h2 className="text-base text-darkBlue">
                        Reports Management
                      </h2>
                      <div className="flex gap-12">
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"reports-view"}
                          name={"permission"}
                          value={"can_view_reports"}
                          label={"View"}
                        />
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"reports-edit"}
                          name={"permission"}
                          value={"can_edit_reports"}
                          label={"Edit"}
                        />
                      </div>
                    </div> */}

                    {/* <div className="flex gap-6 justify-between">
                      <h2 className="text-base text-darkBlue">
                        Email Management
                      </h2>
                      <div className="flex gap-12">
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"email-view"}
                          name={"permission"}
                          value={"can_view_emailmanagement"}
                          label={"View"}
                        />
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"email-edit"}
                          name={"permission"}
                          value={"can_edit_emailmanagement"}
                          label={"Edit"}
                        />
                      </div>
                    </div> */}

                    <div className="flex gap-6 justify-between">
                      <h2 className="text-base text-darkBlue">
                        Vendor Management
                      </h2>
                      <div className="flex gap-12">
                        <Checkbox
                          values={values}
                          onChange={(e) => {
                            handleCheckBox(e.target.checked,values,"can_edit_vendor")
                          }}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"vendor-view"}
                          name={"permission"}
                          value={"can_view_vendor"}
                          label={"View"}
                        />
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"vendor-edit"}
                          name={"permission"}
                          value={"can_edit_vendor"}
                          onChange={(e) => {
                            if (e.target.checked && !values.permission.includes("can_view_vendor")) {
                              values.permission.push("can_view_vendor");
                            }
                          }}
                          label={"Edit"}
                        />
                      </div>
                    </div>

                    <div className="flex gap-6 justify-between">
                      <h2 className="text-base text-darkBlue">
                        Catogery Management
                      </h2>
                      <div className="flex gap-12">
                        <Checkbox
                          values={values}
                          onChange={(e) => {
                            handleCheckBox(e.target.checked,values,"can_edit_category")
                          }}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"category-view"}
                          name={"permission"}
                          value={"can_view_category"}
                          label={"View"}
                        />
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"category-edit"}
                          name={"permission"}
                          value={"can_edit_category"}
                          onChange={(e) => {
                            if (e.target.checked && !values.permission.includes("can_view_category")) {
                              values.permission.push("can_view_category");
                            }
                          }}
                          label={"Edit"}
                        />
                      </div>
                    </div>

                    <div className="flex gap-6 justify-between">
                      <h2 className="text-base text-darkBlue">
                        User role Management
                      </h2>
                      <div className="flex gap-12">
                        <Checkbox
                          values={values}
                          onChange={(e) => {
                            handleCheckBox(e.target.checked,values,"can_edit_userrolemanagement")
                          }}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"role-view"}
                          name={"permission"}
                          value={"can_view_userrolemanagement"}
                          label={"View"}
                        />
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"role-edit"}
                          name={"permission"}
                          value={"can_edit_userrolemanagement"}
                          onChange={(e) => {
                            if (e.target.checked && !values.permission.includes("can_view_userrolemanagement")) {
                              values.permission.push(
                                "can_view_userrolemanagement"
                              );
                            }
                          }}
                          label={"Edit"}
                        />
                      </div>
                    </div>
                    {/* <div className="flex gap-6 justify-between">
                      <h2 className="text-base text-darkBlue">
                        Order Management
                      </h2>
                      <div className="flex gap-12">
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"order-view"}
                          name={"permission"}
                          value={"can_view_order"}
                          label={"View"}
                        />
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"order-edit"}
                          name={"permission"}
                          value={"can_edit_order"}
                          label={"Edit"}
                        />
                      </div>
                    </div> */}
                    <div className="flex gap-6 justify-between">
                      <h2 className="text-base text-darkBlue">
                        Ticket Management
                      </h2>
                      <div className="flex gap-12">
                        <Checkbox
                          values={values}
                          onChange={(e) => {
                            handleCheckBox(e.target.checked,values,"can_edit_tickets")
                          }}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"tickets-view"}
                          name={"permission"}
                          value={"can_view_tickets"}
                          label={"View"}
                        />
                        <Checkbox
                          values={values}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                          id={"tickets-edit"}
                          name={"permission"}
                          value={"can_edit_tickets"}
                          onChange={(e) => {
                            if (e.target.checked && !values.permission.includes("can_view_tickets")) {
                              values.permission.push("can_view_tickets");
                            }
                          }}
                          label={"Edit"}
                        />
                      </div>
                    </div>
                  </div>
                  <ErrorMessageComponent name="permission" />
                </div>

                <SubmitButton
                  width="w-full"
                  type="submit"
                  content={isEdit ? "Edit User Role" : "Add User Role"}
                  isLoading={addLoaing || editLoaing}
                />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalLayout>
  );
};

export default AddUserRoleModal;
