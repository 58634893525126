import React from "react";
import { Link } from "react-router-dom";
import birthday from "../../../../assets/images/commonImages/birthday.png";
import empty from "../../../../assets/images/commonImages/empty.png";
import missYou from "../../../../assets/images/commonImages/missYou.png";


const AutoCouponCards = ({ title, image, link }) => {
  return (
    <Link
      to={link}
      className="h-[490px] md:w-[285px] hover:shadow-md active:shadow-sm transition-all active:scale-[.97]  bg-white rounded-lg overflow-hidden p-6"
    >
      <div className="h-full ">
        <h2 className="text-lg font-semibold text-darkYellow mb-4">{title}</h2>
        <div className="h-[300px] overflow-hidden">
          <img src={image} className="w-full " alt="coupon image" />
        </div>
        <div className="mt-[3rem]">
          <p className="text-center text-base text-darkBlue">Edit Templete</p>
        </div>
      </div>
    </Link>
  );
};


const AutoCoupons = () => {


  return (
    <div className="flex gap-8 flex-wrap">
      <AutoCouponCards title="Birthday" image={birthday} link={'/coupon-management/auto-coupons/add-coupons/birthday-coupons'}/>
      <AutoCouponCards title="We miss You" image={missYou} link={'/coupon-management/auto-coupons/add-coupons/miss-you-coupons'}/>
      <AutoCouponCards title="You Forgot Something" image={empty} link={'/coupon-management/auto-coupons/add-coupons/empty-cart-coupons'} />
    </div>
  );
};

export default AutoCoupons;
