import React, { useEffect } from "react";
import { Chart, registerables } from 'chart.js'
import { Bar } from "react-chartjs-2";
import { useState } from "react";
Chart.register(...registerables)

function OrderChart({dataOrder}) {
  const [labels,setLabels] = useState([])
  const [activeData,setActiveData] = useState([])
  const [completeData,setCompleteData] = useState([])

  useEffect(()=> {
    if(dataOrder?.data){
      let labels = dataOrder?.data.map(item => item.x_axis);
      let activeData = dataOrder?.data.map(item => item.active);
      let completeData = dataOrder?.data.map(item => item.completed);
      setLabels(labels)
      setActiveData(activeData)
      setCompleteData(completeData)
    }
  },[dataOrder])

  var barChartData = {
    labels: labels,
    datasets: [
      {
        barPercentage: 0.6,
        categoryPercentage: 0.4,
        label: "Active Orders",
        backgroundColor: "#F19A0E",
        borderColor: "#F19A0E",
        borderWidth: 0.4,
        data: activeData,
        borderRadius: 10,
      },
      {
        barPercentage: 0.6,
        categoryPercentage: 0.4,
        label: "Completed Orders",
        backgroundColor: "#07415C",
        borderColor: "#07415C",
        borderWidth: 0.4,
        data: completeData,
        borderRadius: 10,
      },
    ],
  };

  var chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    layout: {
      autoPadding: true,
      padding: {
        top: 50,
      },
    },
    legend: {
      // position: "top",
    },
    title: {
      display: false,
    //   text: "Growth Chart",
    },
    scales: {
      y: {
        beginAtZero: true,
        title: {
          display: false,
        //   text: "Growth →",
          font: {
            size: 13,
          },
        },
        grid: {
            drawOnChartArea: false
        }
      },
      x: {
        title: {
          display: false,
        //   text: "Time →",
          font: {
            size: 13,
          },
        },
        grid: {
            drawOnChartArea: false
        }
      },
    },
    plugins: {
      legend: {
        position: "top",
        align: "end",
        display: false
      },
      tooltip: {
        enabled: true,
        position: "average",
        yAlign: "bottom",
        bodyAlign: "center",
        intersect: true,
        backgroundColor: "#FFFFFF",
        bodyColor: "#242424",
        titleColor: "#242424",
        borderColor: "rgba(222, 200, 2)",
        borderWidth: "2",
        bodySpacing: "4",
        padding: "14",
        cornerRadius: "5",
        displayColors: false,
      },
    },
  };

  const chartWidth = labels.length * 100;
  
  return (
    <div style={{ overflowX: "scroll", whiteSpace: "nowrap" }} className="catogery-scroll">
      <div style={{ width: `${chartWidth}px`, overflowY: "hidden", height:"300px" }}>
        <Bar data={barChartData} options={chartOptions} height={300} />
      </div>
    </div>
  );
}

export default OrderChart;