import { axiosInstance } from "../../services/apiInitialSetup";

const orderList = async ({ date, page,pageSize = 10,search,order_status,payment_status,start_date,end_date }) => {
  try {
    const response = axiosInstance.get(
      `order/order?${start_date ? "start_date=" + start_date + "&" : ""}${end_date ? "end_date=" + end_date + "&" : ""}${search !== ''? 'search='+search+'&':''}page=${page}&size=${pageSize}&${order_status && order_status !== "bc654"? "status__in=" + order_status + "&": ""}${payment_status && payment_status !== "bc654"? "order_payments__status__in=" + payment_status + "&": ""}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
const singleOrder = async (id) => {
  try {
    const response = axiosInstance.get(`order/order/${id}/`);
    return response;
  } catch (error) {
    throw error;
  }
};
const userOrders = async (data) => {
  try {
    const response = axiosInstance.get(
      `order/order/${data.id}/user_order?${
        data.date ? "created_at__date=" + data.date + "&" : ""
      }${
        data?.start_date && data?.end_date ? "created_at__date__range=" + data?.start_date + "," + data?.end_date + "&" : ""
      }page=${data.page}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
const trackingDetials = async (data) => {
  try {
    const response = axiosInstance.post(
      `order/order/${data.id}/update_status/`,
      data.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const orderEdit = async (data) => {
  try {
    const response = await axiosInstance.patch(
      `order/order/${data.id}/`,
      data.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const cancelOrder = async (data) => {
  try {
    const response = await axiosInstance.post(
      `order/order/${data.id}/request_refund/`,
      {amount: data?.amount}
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const cancelOrderSuccess = async (data) => {
  try {
    const response = await axiosInstance.post(
      `payments/refund-response/${data}/`,
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export { orderEdit,orderList, singleOrder, trackingDetials, userOrders };
