import React from "react";
import cart from "../../../../../../assets/images/commonImages/empty-cart1.png";
import facebook from "../../../../../../assets/images/commonImages/empty-cart2.png";
import insta from "../../../../../../assets/images/commonImages/empty-cart3.png";

import linkedin from "../../../../../../assets/images/commonImages/empty-cart4.png";

import twitter from "../../../../../../assets/images/commonImages/empty-cart5.png";

import logo from "../../../../../../assets/images/logoBlue.png";
import { Link } from "react-router-dom";

const MissYouCouponStructure = ({ values }) => {
  return (
    <div className="w-full h-full  ">
      <div className=" overflow-hidden bg-white">
        <div className="w-full flex justify-center px-6 py-12">
          <img className="max-w-[13rem]" src={logo} alt="logo" />
        </div>

        <div
          className="p-6  mb-6 text-center flex flex-col items-center"
          style={{
            backgroundColor: values.colorThemeOne,
          }}
        >
          <div>
            <h1
              className="text-[2rem]  mb-4"
              style={{
                color: values.titleFontColor,
                fontStyle: values.titleFontItalics ? "italic" : "normal",
                fontWeight: values.titleFontBold ? "bold" : "normal",
                textDecoration: values.titleFontUnderline ? "underline" : "",
              }}
            >
              {values.titleContent !== "" ? values.titleContent : "Your title"}
            </h1>
            <h2
              className="text-[1.4rem]  mb-4"
              style={{
                color: values.subFontColor,
                fontStyle: values.subFontItalics ? "italic" : "normal",
                fontWeight: values.subFontBold ? "bold" : "normal",
                textDecoration: values.subFontUnderline ? "underline" : "",
              }}
            >
              ({values.subContent !== "" ? values.subContent : "Your subtile"})
            </h2>
            <p
              className="mb-6 "
              style={{
                color: values.detialsFontColor,
                fontStyle: values.detialsFontItalics ? "italic" : "normal",
                fontWeight: values.detialsFontBold ? "bold" : "normal",
                textDecoration: values.detialsFontUnderline ? "underline" : "",
              }}
            >
              {values.detialsContent !== "" ? values.detialsContent : "Detials"}
            </p>
          </div>
          <div
            className="w-[300px] h-[120px] p-4  rounded-md mb-6"
            style={{
              backgroundColor: values.colorThemeTwo,
            }}
          >
            <div className="border-2 h-full flex  justify-center items-center bg-miss-youcard bg-cover bg-no-repeat ">
              <p
                className="text-3xl font-bold "
                style={{
                  color: values.discountFontColor,
                  fontStyle: values.discountFontItalics ? "italic" : "normal",
                  fontWeight: values.discountFontBold ? "bold" : "normal",
                  textDecoration: values.discountFontUnderline
                    ? "underline"
                    : "",
                }}
              >
                {values.discValue !== "" ? values.discValue : "Discount"}{" "}
                {values?.discType === "fixed"
                  ? " Rs "
                  : values?.discType === "percentage"
                  ? " % "
                  : ""}
                off
              </p>
            </div>
          </div>
          <div>
            <p className="text-xl mb-6">
              Use code{" "}
              <span
                className="font-bold text-darkBlue"
                style={{
                  color: values.codeFontColor,
                  fontStyle: values.codeFontItalics ? "italic" : "normal",
                  fontWeight: values.codeFontBold ? "bold" : "normal",
                  textDecoration: values.codeFontUnderline ? "underline" : "",
                }}
              >
                {" "}
                {values.cuponCode !== "" ? values.cuponCode : "Coupon code"}
              </span>{" "}
              at checkout
            </p>
            <button
              type="button"
              className="px-4 py-2 bg-primary border border-x-darkBlue rounded-md"
            >
              Shop Now
            </button>
          </div>
        </div>
        <div className="text-center ">
          <div className="flex items-center gap-3 justify-center p-12">
            <Link>
              <img className="w-[35px]" src={facebook} alt="social" />
            </Link>
            <Link>
              <img className="w-[35px]" src={insta} alt="social" />
            </Link>

            <Link>
              <img className="w-[35px]" src={twitter} alt="social" />
            </Link>

            <Link>
              <img className="w-[35px]" src={linkedin} alt="social" />
            </Link>
          </div>

          <div className="p-8">
            <p
              style={{
                color: values.footerFontColor,
                fontStyle: values.footerFontItalics ? "italic" : "normal",
                fontWeight: values.footerFontBold ? "bold" : "normal",
                textDecoration: values.footerFontUnderline ? "underline" : "",
              }}
            >
              {values.footerContent !== ""
                ? values.footerContent
                : "Footer content"}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MissYouCouponStructure;
