import React,{useEffect,useState} from "react";
import ErrorMessageComponent from "../../../../../components/errorMessage/ErrorMessageComponent";
import CustomSelect from "../../../../../components/customSelect/CustomSelect";
import { restrictAll } from "../../../../../utilities/restrictDropdown/resctrictDropdoen";
import CustomSelectAsync from "../../../../../components/customSelect/CustomSelectAsync";
import { productLists } from "../../../../../servicesQuery/productQuery/productFunctions";


const ProductDetials = ({ values, setFieldValue, data }) => {
  const [couponProductList, setCouponProductList] = useState();
  const [searchVal, setSearchVal] = useState("");
  
  useEffect(() => {
    const fetchApi = async () => {
      try {
        const data = await productLists({
          category: values?.catogary?.map((e) => e.value),
          producyType: values?.productType?.map((e) => e.value),
          search: searchVal
        });
        if (data?.data?.results?.length) {
          let coupon_products = [{ label: "All", value: "ab345" }].concat(
            data?.data?.results?.map((e) => {
              return {
                label: e.name,
                value: e.id,
                category: e.category,
                product_type: e.product_type
              };
            })
          )
          setCouponProductList(coupon_products)
        }else {
          setCouponProductList([])
        }
      } catch (err) {
        console.log(err);
      }
    };
    if(values.catogary || values.productType || searchVal){
      fetchApi();
    }
  }, [values.productType,values.catogary,searchVal]);

  const handleChange = (values,setFieldValue,e) => {
    const removedOptions = values?.catogary.filter(
      (prevOption) => !e.includes(prevOption)
    );
    if(removedOptions?.length && values?.product?.length){
      const removedCatIds = removedOptions.map(item => item.value);
      const filteredProducts = values.product.filter(item => !removedCatIds.includes(item.category));
      setFieldValue("product", filteredProducts)
      setCouponProductList([])
    }
    setFieldValue("catogary", e)
  }

  return (
    <div className="flex flex-col gap-6">
      <div className="bg-white rounded-lg p-6">
        <h1 className="text-lg font-semibold text-darkBlue mb-4">
          {" "}
          Product Details
        </h1>
        <div className="flex flex-col gap-x-6">
          <div className="flex items-center gap-6 w-full">
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Coupon Product Type
              </label>
              <CustomSelect
                placeholder={"Select Coupon Product Type"}
                options={data.productType.productType}
                isDisabled={data.productType.typeLoading}
                name="productType"
                defaultValue={{ label: "All", value: 2 }}
                isMulti
                value={values.productType}
                onChange={(e) => {
                  setFieldValue("productType", e);
                }}
                isOptionDisabled={(options, selected) =>
                  restrictAll(options, selected)
                }
              />
              <ErrorMessageComponent name={"productType"} />
            </div>
          </div>
          <div className="flex items-center gap-6 w-full">
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Coupon Category
              </label>
              <CustomSelect
                placeholder={"Select categories"}
                width={"w-full"}
                options={data.catType.catType}
                isDisabled={data.catType.catLoading}
                isMulti
                defaultValue={{ label: "All", value: "all" }}
                
                name="catogary"
                value={values.catogary}
                onChange={(e) => handleChange (values,setFieldValue,e)}
                // onChange={(e) => {
                //   setFieldValue("catogary", e);
                // }}
                isOptionDisabled={(options, selected) =>
                  restrictAll(options, selected)
                }
              />

              <ErrorMessageComponent name={"catogary"} />
            </div>
          </div>
          <div className="flex items-center gap-6 w-full">
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Coupon Products
              </label>
              <CustomSelect
                placeholder={"Search Products"}
                value={ values.product}
                options={couponProductList}
                name="product"
                isMulti
                onChange={(e) => {
                  setFieldValue("product", e);
                }}
                onInputChange={(inputValue) => {
                  setSearchVal(inputValue)
                }}
              />
              {/* <CustomSelectAsync
                placeholder={"Search Products"}
                width={"w-full"}
                defaultValue={{ label: "All", value: "all" }}
                loadingMessage={() => <p>Product Loading</p>}
                noOptionsMessage={(e, l) => <p>Products Not found</p>}
                loadOptions={couponProductList}
                isMulti
                defaultOptions
                isSearchable
                name="product"
                value={values.product}
                isDisabled={
                  values.catogary?.length === 0 || data.prodData.productLoading
                    ? true
                    : false
                }
                onChange={(e) => {
                  setFieldValue("product", e);
                }}
                isOptionDisabled={(options, selected) =>
                  restrictAll(options, selected)
                }
              /> */}

              <ErrorMessageComponent name={"product"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetials;
 