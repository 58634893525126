import React, { useState } from 'react';
import { Button, Divider, Popover, Space } from 'antd';
import { FilterFilled } from "@ant-design/icons";
import SingleDatePicker from '../datePicker/SingleDatePicker';
import CustomSelect from '../customSelect/CustomSelect';
import dayjs from 'dayjs';


function TableFilterDropDown(props) {
    const [open, setOpen] = useState(false);

    const handlePopover = (val) => { 
        setOpen(val)
        if(val === true){
            props?.setEnabled(false)
        }
    }

    const isDateDisabled = (date) => {
        let today = new Date()
        let startDate = new Date(props?.date)
        let todayDefference = today - date;
        let daysDifferencetdy = todayDefference / (1000 * 60 * 60 * 24);
        if (daysDifferencetdy <= 0) {
          return false;
        }else if (startDate) {
          let timeDifference = date - startDate;
          let daysDifference = timeDifference / (1000 * 60 * 60 * 24);
          let diff = Math.abs(Math.trunc(daysDifference));
          if(diff === 0){
            daysDifference = 0
          }
          if (daysDifference < 0) {
            return false;
          }
        }
        return true;
      };

    const content = (
        <div className='w-[350px] p-0'>
            <div>
                <div className='p-4'>
                    <span className='text-[#07415C] text-[20px] font-medium'>Filters</span>
                </div>
                <Divider style={{ margin: '0px'}} />
            </div>
            <div className='p-4'>
                {props.name === "user_management"?
                    <div>
                        <div className='mb-2'>
                            <div className='mb-2'>
                                <span className='font-semibold'>Select Start Date</span>
                            </div>
                            <SingleDatePicker placeholder="Select Start Date" value={props?.date} onDateChange={(date) =>{ 
                            if (date) {
                                props?.setDate(dayjs(date).format("YYYY-MM-DD"));
                                props?.setToDate("");
                            } else {
                                props?.setDate("");
                            }
                            }} />
                        </div>
                        <div className='mb-2'>
                            <div className='mb-2'>
                                <span className='font-semibold'>Select End Date</span>
                            </div>
                            <SingleDatePicker placeholder="Select End Date" value={props?.to_date} onDateChange={(date) =>{ 
                            if (date) {
                            // props?.setToDate(date?.toLocaleDateString());
                            props?.setToDate(dayjs(date).format("YYYY-MM-DD"));
                            } else {
                            props?.setToDate("");
                            }
                            }} isDateDisabled={isDateDisabled} />
                        </div>
                    </div>
                    :props.name === "order_management"?
                    <div className=''>
                        <div className="flex flex-col  md:min-w-[8rem] w-full  justify-center mb-4">
                            <CustomSelect
                            placeholder={"Order Status"}
                            options={props?.order_status_list}
                            value={props?.filterData?.order_status}
                            onChange={(e) => {
                                props?.setFilterData({ ...props?.filterData, order_status: e });
                            }}
                            />
                        </div>
                        <div className="flex flex-col justify-center md:min-w-[8rem] w-full mb-4">
                            <CustomSelect
                            placeholder={"Payment Status"}
                            options={props?.payment_status_list}
                            value={props?.filterData?.payment_status}
                            onChange={(e) => {
                                props?.setFilterData({ ...props?.filterData, payment_status: e });
                            }}
                            />
                        </div>
                        <div className="md:min-w-[8rem] w-full mb-4">
                            <SingleDatePicker
                            placeholder="Select Start Date"
                            value={props?.date}
                            onDateChange={(e) => {
                                if (e) {
                                props?.setDate(dayjs(e).format("YYYY-MM-DD"));
                                props?.setToDate("");
                                } else {
                                props?.setDate("");
                                }
                            }}
                            />
                        </div>
                        <div className="md:min-w-[8rem] w-full">
                            <SingleDatePicker
                            placeholder="Select End Date"
                            value={props?.to_date}
                            onDateChange={(e) => {
                                if (e) {
                                props?.setToDate(dayjs(e).format("YYYY-MM-DD"));
                                } else {
                                props?.setToDate("");
                                }
                            }}
                            isDateDisabled={isDateDisabled}
                            />
                        </div>
                    </div>
                :
                null} 
                
                <div className='mt-4 mb-2'>
                    <div className='flex items-center justify-around'>
                        <Button onClick={()=> props?.handleFilter("clear")}>Clear all filters</Button>
                        <Button onClick={()=> {props?.handleFilter("save");setOpen(false)}} className='text-xs w-full md:w-[8rem]  hover:shadow-lg active:shadow-sm  transition-all active:scale-95 py-2 px-6  px-10 py-1 text-sm text-white add-live-btn bg-btn-gradient rounded-md'>Apply</Button>
                    </div>
                </div>
            </div>
        </div>
      );
    
  return (
    <div>
        <Popover placement="bottomRight" content={content} title={false} trigger="click" className='filter' onOpenChange={(e)=> handlePopover(e)} open={open}>
            <div className="hover:text-[#65656a] text-[#07415C] border rounded border-[#EEF0F7] py-1 px-3 flex items-center gap-3 cursor-pointer">
                <FilterFilled />
                <span className="text-[14px]">Filters</span>
                <span className="text-[14px] w-[22px] h-[22px] bg-[#EEF0F7] flex items-center justify-center rounded-[22px]">{props?.filterCount}</span>
            </div>
        </Popover>
    </div>
  )
}

export default React.memo(TableFilterDropDown)
