import React,{useState} from 'react'
import DetailSection from './DetailSection';
import MainCategory from './MainCategory';
import ProductType from './ProductType';
import SubCategory from './SubCategory';
import Specification from './Specification';

function CategoryManagementMainPage() {
    const [tabIndex, setTabIndex] = useState("main_categories");
    const data = []
  return (
    <div className=" flex-1 w-full h-full mb-8">
      <DetailSection data={data?.data} loading={false} setTabIndex={setTabIndex}  tabIndex={tabIndex}/>
      <div className="h-[90]">
         {tabIndex==='main_categories'  && <MainCategory />}
         {tabIndex==='sub_categories'  && <SubCategory />}
         {tabIndex==='product_types'  && <ProductType />}
         {tabIndex==='specification'  && <Specification />}
      </div>
    </div>
  )
}

export default CategoryManagementMainPage
