import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import NormalCoupons from "./normalCoupons/NormalCoupons";

const CuponManagementMainPage = () => {
    const pathname = useLocation().pathname.split("/");
    const LinkHighlighter = (path) => {
      return pathname.includes(path);
    };
  return (
    <div className="h-full">
      {!pathname.includes(
        "add-coupons" || !pathname.includes("edit-coupons")
      ) && (
        <div className="flex items-center gap-4 md:gap-12 w-full mb-10 flex-wrap justify-center md:justify-start">
          <div>
            <Link
              to="/coupon-management/normal-coupons"
              className={` ${
                LinkHighlighter("normal-coupons") || pathname?.length === 2
                  ? "coupon-head "
                  : ""
              }  text-xs md:text-base text-darkBlue hover:text-darkYellow hover:font-bold transition-all`}
            >
              Normal Coupons
            </Link>
          </div>
          <div>
            <Link
              to={"/coupon-management/premium-coupons"}
              className={` ${
                LinkHighlighter("premium-coupons") ? "coupon-head " : ""
              } text-xs md:text-base text-darkBlue hover:text-darkYellow hover:font-bold transition-all`}
            >
              Premium Coupons
            </Link>
          </div>
          <div>
            <Link
              to="/coupon-management/welcome-coupons"
              className={` ${
                LinkHighlighter("welcome-coupons") ? "coupon-head " : ""
              } text-xs md:text-base text-darkBlue hover:text-darkYellow hover:font-bold transition-all`}
            >
              Welcome Coupons
            </Link>
          </div>
          <div>
            <Link
              to="/coupon-management/auto-coupons"
              className={` ${
                LinkHighlighter("auto-coupons") ? "coupon-head " : ""
              } text-xs md:text-base text-darkBlue hover:text-darkYellow hover:font-bold transition-all`}
            >
              Auto Coupons
            </Link>
          </div>
        
        </div>
      )}

      <div>{ !pathname[2] ?<NormalCoupons/> : <Outlet />}</div>
    </div>
  );
};

export default CuponManagementMainPage;
