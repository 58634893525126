import { axiosInstance } from "../../services/apiInitialSetup";


const TicketList = async (data) => {
  console.log(data.status);
    try {
      const response = await axiosInstance.get(`support/tickets/?page=${data?.page}&${
        data?.search !== "" ? "search=" + data?.search + "&" : ""
      }${
        data?.date? "created_at=" + data?.date + "&": ""
      }${
        data?.status && data?.status?.value !== "ALL"
          ? "ticket_status=" + data?.status?.value + "&"
          : ""
      }`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const ChatList = async (data) => {
    try {
      const response = await axiosInstance.get(`support/message/${data.id}`);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const acceptTicket = async (data) => {
    try {
      const response = await axiosInstance.patch(`support/tickets/${data.id}/`,data);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const SingleTicket = async (data) => {
    try {
      const response = await axiosInstance.get(`support/tickets/${data?.id}/`);
      return response;
    } catch (error) {
      throw error;
    }
  };

export {
    TicketList,
    ChatList,
    acceptTicket,
    SingleTicket
}