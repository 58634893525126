import React, { useCallback, useEffect, useState } from "react";

import CustomSelect from "../../../components/customSelect/CustomSelect";
import { Field } from "formik";
import ErrorMessageComponent from "../../../components/errorMessage/ErrorMessageComponent";
import {
  useGetCatogeryList,
  useGetProductTypeList,
  useRemoveProductTag,
} from "../../../servicesQuery/productQuery/productQuery";
import { categorySingle } from "../../../servicesQuery/categoryQuery/categoryFunctions";
import { useGetLatestTags, useGetTags } from "../../../servicesQuery/tagQuery/tagQuery";
import { toast } from "react-hot-toast";

const Tags = ({ change, setFieldValue, values }) => {
  const [tags, setTags] = useState([]);
  useEffect(() => {
    setFieldValue("tags", tags);
  }, [tags]);
  const handleRemoveTags = (index) => {
    setTags(
      tags.filter((_, i) => {
        return index !== i;
      })
    );
  };
  const handleTag = useCallback((e) => {
    if (e.code === "Enter") {
      setTags([...tags, e.target.value]);
      e.target.value = "";
    }
    change(e);
  });
  return (
    <div className="border-[1px] rounded-md border-[#bab1b1] p-3 max-h-[10rem] overflow-scroll">
      <div className="flex flex-wrap gap-2">
        {tags.map((item, index) => {
          return (
            <p className="bg-lightYellow text-sm text-darkBlue rounded-lg px-2 py-1 flex items-center gap-2">
              {item}
              <span
                onClick={() => handleRemoveTags(index)}
                className="cursor-pointer"
              >
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 8 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.06035 4.00008L7.36035 1.70008C7.65035 1.41008 7.65035 0.930077 7.36035 0.640077C7.07035 0.350077 6.59035 0.350077 6.30035 0.640077L4.00035 2.94008L1.70035 0.640077C1.41035 0.350077 0.930352 0.350077 0.640352 0.640077C0.350352 0.930077 0.350352 1.41008 0.640352 1.70008L2.94035 4.00008L0.640352 6.30008C0.350352 6.59008 0.350352 7.07008 0.640352 7.36008C0.790352 7.51008 0.980351 7.58008 1.17035 7.58008C1.36035 7.58008 1.55035 7.51008 1.70035 7.36008L4.00035 5.06008L6.30035 7.36008C6.45035 7.51008 6.64035 7.58008 6.83035 7.58008C7.02035 7.58008 7.21035 7.51008 7.36035 7.36008C7.65035 7.07008 7.65035 6.59008 7.36035 6.30008L5.06035 4.00008Z"
                    fill="#07415C"
                  />
                </svg>
              </span>
            </p>
          );
        })}
      </div>
      <div>
        <Field
          type="text"
          name="tags"
          placeholder="Type..."
          className="bg-[transparant] border-none outline-none w-[3rem] text-sm m-2"
          onChange={(e) => {
            change(e);
          }}
          onKeyPress={handleTag}
          value={values.tags}
        />
      </div>
    </div>
  );
};

const ProductDetials = ({ change, setFieldValue, values,data,productData }) => {
  const [subCatList, setsubCatList] = useState();
  const { mutateAsync: removeTag, isLoading: removeLoading } = useRemoveProductTag();
  // const { data: tagData, isFetched } = useGetTags({
  //   page: 10,
  //   search: "",
  //   invited: false,
  // });
  const { data: tagData, isLoading: tagLoading } = useGetLatestTags();
  let tagList = [
    {
      label: tagData?.data?.data?.name,
      value: tagData?.data?.data?.id,
    }
  ]
  // let tagList = tagData?.data?.map((item) => {
  //   return {
  //     label: item.name,
  //     value: item.id,
  //   };
  // });


  useEffect(() => {
    const fetchApi = async () => {
      try {
        let subcatData = await categorySingle({ id: values.catogery?.value });
        if (subcatData?.data?.child_categories?.length) {
          let subcat = subcatData?.data?.child_categories?.map((item) => {
            return {
              label: item.name,
              value: item.id,
            };
          });

          if(!values.subCatogery && values.subCatogery !== "" && productData?.data?.category_name){
            let sub_cat = subcat?.find((e) => e?.label === productData?.data?.category_name)
            setFieldValue("subCatogery", sub_cat)
          }
          setsubCatList(subcat)
        }
      } catch (err) {
        console.log(err);
      }
    };
    if(values.catogery?.value){
      fetchApi();
    }
  }, [values.catogery]);

  const handleChangeTag = (values,setFieldValue,e)=> {
    const productId = productData?.data?.id
    const removedOptions = values.all_tags.filter(
      (prevOption) => !e.includes(prevOption)
    );
    if(productId && removedOptions?.length){
      removeTag({ tagid: removedOptions[0].value, id: productId })
      .then((res) => {
        toast.success("Tag Removed");
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
    }
    setFieldValue("all_tags", e)
  }

  return (
    <div className="p-6 rounded-lg bg-white ">
      <h1 className="text-lg font-semibold text-darkBlue mb-4">
        Product Details
      </h1>
      <div className="flex flex-col gap-x-4">
        <div>
          <label className="block mb-3 text-sm text-darkBlue" htmlFor="type">
            Product Type
          </label>
          <CustomSelect
            placeholder={"Select Product Type"}
            options={data.prodTyoeData}
            onChange={(e) => setFieldValue("productType", e)}
            value={values.productType}
          />
          <ErrorMessageComponent name={"productType"} />
        </div>
        <div>
          <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
            Jewellery Category
          </label>
          <CustomSelect
            placeholder={"Select  Category"}
            value={values.catogery}
            options={data.catData}
            name="catogery"
            onChange={(e) => {setFieldValue("catogery", e)
            setFieldValue("subCatogery", "")}}
          />
          <ErrorMessageComponent name={"catogery"} />
        </div>
        <div>
          <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
            Jewellery Sub Category
          </label>
          <CustomSelect
            placeholder={"Select  Jewellery Sub Category"}
            value={ values.subCatogery}
            options={subCatList}
            name="subCatogery"
            onChange={(e) => setFieldValue("subCatogery", e)}
          />
          <ErrorMessageComponent name={"subCatogery"} />
        </div>
        <div>
          <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
            Tags
          </label>
          <CustomSelect
            placeholder={"Select  Tags"}
            value={ values.all_tags}
            options={tagList}
            name="all_tags"
            onChange={(e) => handleChangeTag (values,setFieldValue,e)}
            isMulti
          /> 
          <ErrorMessageComponent name={"subCatogery"} />
        </div>
        {/* <div>
          <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
            Tags
          </label>
          <Tags change={change} setFieldValue={setFieldValue} values={values} />
          <ErrorMessageComponent name={"tags"} />
          <p className="text-sm mt-3">Add tags to this product</p>
        </div> */}
      </div>
    </div>
  );
};

export default React.memo(ProductDetials);
