import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { initialNavigation } from '../utilities/userPermissons/userPermissions'

const PublicRoute = () => {
    const token = JSON.parse(localStorage.getItem('Tokens'))
  return (
    <> {!token?.access ? <Outlet/>: <Navigate to={`${initialNavigation()}`} />} </>
  )
}

export default PublicRoute