import { axiosInstance } from "../../services/apiInitialSetup";

const userList = async ({created_at,page,pageSize = 10,search,start_date,end_date}) => {

  try {
    // const response = await axiosInstance.get(`adminpanel/users-list?created_at__date=${created_at}&page=${page}&size=${pageSize}&search=${search}`);
    const response = await axiosInstance.get(
      `adminpanel/users-list?page=${page}&size=${pageSize}&${
        search !== "" ? "search=" + search + "&" : ""
      }${
        start_date
          ? "start_date=" + start_date + "&"
          : ""
      }${
        end_date
          ? "end_date=" + end_date + "&"
          : ""
      }`);
    return response;
  } catch (error) { 
    throw error; 
  }
};

const singleUser = async (id) => {
  try {
    const response = await axiosInstance.get(`adminpanel/users-details/${id}/`);
    return response;
  } catch (error) {
    throw error;
  }
};

const editUser = async (data) => {
  try {
    const response = await axiosInstance.patch(`/userrole/users/${data.id}/`,data.data);
    return response;
  } catch (error) {
    throw error;
  }
};


export { userList, singleUser,editUser };
