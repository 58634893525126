import firebase from 'firebase/compat/app';
import 'firebase/compat/messaging';;


const firebaseConfig = {
  apiKey: "AIzaSyCrVICml7x771dCZGDuXd_NV-bgigaj1ZY",
  authDomain: "magic-woman-b1fb0.firebaseapp.com",
  projectId: "magic-woman-b1fb0",
  storageBucket: "magic-woman-b1fb0.appspot.com",
  messagingSenderId: "547844608918",
  appId: "1:547844608918:web:e75fa216bdd8b3535203b7",
  measurementId: "G-XHFHN9Q66D"
};

firebase.initializeApp(firebaseConfig);

export default firebase;