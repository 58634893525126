import React from "react";
import ModalLayout from "../../components/customModal/CustomModal";
import placeholder from "../../assets/images/commonImages/imagePlaceholder.png";
const OrderItemTab = ({ itemData, number }) => {
  return (
    <div>
      <h3 className="text-sm text-darkBlue mb-5">Item {number}</h3>
      <div className="p-5 bg-lightYellow rounded-lg flex gap-5 w-full">
        <div className="w-[120px] h-[110px] rounded-md overflow-hidden">
          <img
            className="w-full"
            src={
              itemData?.product?.image_file
                ? itemData?.product?.image_file
                : placeholder
            }
            alt="image"
          />
        </div>
        <div className="flex flex-col w-full justify-between">
          <div className="flex w-full  gap-6 justify-between">
            <div>
              <h1 className="text-darkBlue font-semibold text-base mb-2">
                {itemData?.product?.name}
              </h1>
             
              <h3 className="text-sm text-darkYellow">{itemData?.item_total_weight} grams | 22 karat</h3>
            </div>
            <div>
              <p className="italic text-[#8E8E8E] text-xs">
                {itemData?.product?.category_name}
              </p>
            </div>
          </div>
          <div>
          <h3 className="text-sm text-darkBlue">₹{itemData?.item_total_gst} - gst | {itemData?.item_total_discount} - Discount</h3>
          </div>
          <div>
          {/* <h3 className="text-sm text-darkBlue">₹{itemData?.item_total_gst} - gst</h3> */}
          </div>
          <div className="flex gap-5 justify-between w-full">
            <p className="text-sm"> ₹ {Number(itemData?.item_total_price).toFixed(2)}</p>
            <p className="text-xs">Quantity : {itemData?.quantity}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
const OrderItemsDetialsModal = ({ open, setOpen, data }) => {
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="w-[700px] h-[500px] rounded-lg rounded-lg bg-white ">
        <div className="flex gap-6 justify-between w-full border-b-[1px] border-lightBlue p-6">
          <div>
            <h1 className="text-md font-semibold text-darkBlue mb-2">
              Product Details
            </h1>
            <p className="text-xs text-darkYellow">
              <span>Order ID : </span>{" "}
              <span className="font-bold"> {data?.id}</span>
            </p>
          </div>
          <div>
            <button onClick={() => setOpen(false)}>
              <svg
                width="12"
                height="12"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.3554 7.98218L15.4665 3.31325C16.1109 2.72456 16.1109 1.75018 15.4665 1.16149C14.822 0.572798 13.7554 0.572798 13.1109 1.16149L7.9998 5.83042L2.88869 1.16149C2.24425 0.572798 1.17758 0.572798 0.533138 1.16149C-0.111306 1.75018 -0.111306 2.72456 0.533138 3.31325L5.64425 7.98218L0.533138 12.6511C-0.111306 13.2398 -0.111306 14.2142 0.533138 14.8029C0.866471 15.1074 1.28869 15.2495 1.71092 15.2495C2.13314 15.2495 2.55536 15.1074 2.88869 14.8029L7.9998 10.134L13.1109 14.8029C13.4442 15.1074 13.8665 15.2495 14.2887 15.2495C14.7109 15.2495 15.1331 15.1074 15.4665 14.8029C16.1109 14.2142 16.1109 13.2398 15.4665 12.6511L10.3554 7.98218Z"
                  fill="#07415C"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="p-6 w-full flex flex-col gap-5 h-[80%] overflow-y-scroll">
          {data?.data?.map((item, index) => {
            return (
              <OrderItemTab itemData={item} number={index + 1} key={index} />
            );
          })}
        </div>
      </div>
    </ModalLayout>
  );
};

export default OrderItemsDetialsModal;
