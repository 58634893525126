import { useMutation, useQuery, useQueryClient } from "react-query";
import { categoryLists, categorySingle, productSpecificationLists, productTypeCreate, productTypeDelete, productTypeLists, productTypeUpdate, specificationProductAdd, specificationProductDelete, specificationProductEdit, specificationLists, subCatAdd, subCatCreate, subCategoryLists, subCategorySingle, subCatUpdate, unitLists, specificationAdd, specificationEdit, specificationDelete } from "./categoryFunctions";

const useGetCategoryList = (data) => {
  return useQuery(["getCategoryList", data], () => categoryLists(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const useGetCatSingle = (data) => {
  return useQuery(["getCategorySingle",data], () => categorySingle(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const useGetSubCategoryList = (data) => {
    return useQuery(["getSubCategoryList",], () => subCategoryLists(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };

  const useAddSubCat = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => subCatAdd(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getCategoryList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useCreateSubCat = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => subCatCreate(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getSubCategoryList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useUpdateSubCat = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => subCatUpdate(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getSubCategoryList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useGetSubcatSingle = (id) => {
    return useQuery(["getSubCategorySingle",id], () => subCategorySingle(id), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };

  const useGetProductTypeList = (data) => {
    return useQuery(["getProductTypeList",data], () => productTypeLists(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };

  const useCreateProductType = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => productTypeCreate(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getProductTypeList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useUpdateProductType = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => productTypeUpdate(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getProductTypeList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useDeleteProductType = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => productTypeDelete(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getProductTypeList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useGetProductSpecificationList = (data) => {
    return useQuery(["getProductSpecificationList",data], () => productSpecificationLists(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };

  const useGetSpecificationList = (data) => {
    return useQuery(["getSpecificationList",data], () => specificationLists(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };

  const useAddSpecificationProduct = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => specificationProductAdd(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getProductSpecificationList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useEditSpecificationProduct = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => specificationProductEdit(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getProductSpecificationList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useDeleteSpecificationProduct = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => specificationProductDelete(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getProductSpecificationList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useGetUnitList = (data) => {
    return useQuery(["getUnitList",data], () => unitLists(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };


  const useAddSpecification = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => specificationAdd(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getSpecificationList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useEditSpecification = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => specificationEdit(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getSpecificationList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useDeleteSpecification = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => specificationDelete(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getSpecificationList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

export {useDeleteSpecification,useEditSpecification,useAddSpecification,useGetCategoryList,useGetSubCategoryList,useAddSubCat,useCreateSubCat,useUpdateSubCat,useGetSubcatSingle,useGetProductTypeList,useCreateProductType,useUpdateProductType,useDeleteProductType,useGetCatSingle,useGetSpecificationList,useGetProductSpecificationList,useAddSpecificationProduct,useEditSpecificationProduct,useDeleteSpecificationProduct,useGetUnitList}