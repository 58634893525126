import React, { useState,useEffect } from "react";
import ModalLayout from "../../../components/customModal/CustomModal";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  FormikContext,
  ResetForm,
} from "../../../utilities/resetForm/FormikContext";
import BorderdInputField from "../../../components/customField/BorderdInputField";
import ErrorMessageComponent from "../../../components/errorMessage/ErrorMessageComponent";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import SubmitButton from "../../../components/submitButton/SubmitButton";
import { useCreateProductType, useGetSubcatSingle, useUpdateProductType } from "../../../servicesQuery/categoryQuery/categoryQuery";
import toast from "react-hot-toast";

 const AddProductTypeModal=({ open, setOpen,isEdit, item }) =>{
  const [formikValue, setFormikValue] = useState("");
  const { mutateAsync: productTypeCreate, isLoading } = useCreateProductType();
  const { mutateAsync: productTypeUpdate, isLoading: editLoaing } = useUpdateProductType();

  const validation = () => {
    return Yup.object().shape({
      name: Yup.string().required("Name is required"),
    });
  };

  const initialValue = {
    id: item?.id || "",
    name:item?.name || "",
    
  };
  const handleSubmmit = (values, { resetForm }) => {
    let data = {}
    if(isEdit){
        data.id = values.id
        if(initialValue.name !== values.name){
            data.name = values.name
        }
       
        productTypeUpdate(data)
            .then((res) => {
            toast.success("Product Type Updated");
            setOpen(false)
            resetForm();
            })
            .catch((err) => {
            toast.error("Something went wrong");
            });
    }else {
        data = {
            "name": values.name,
        }
        productTypeCreate(data)
            .then((res) => {
            toast.success("Product Type added");
            setOpen(false)
            resetForm();
            })
            .catch((err) => {
            toast.error("Something went wrong");
            });
    }
    
  };

  const statusList = [
    {
      label: "Active",
      value: "active",
    },
    {
      label: "Inactive",
      value: "inactive",
    },
  ];
 
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="bg-white  rounded-xl  w-[90%] hxl:max-w-[30rem] m-4 ">
        <div className="w-full flex justify-between p-6 border-b-[.5px] border-darkBlue border-opacity-20 ">
          <h2 className="text-lg text-darkBlue font-bold">{isEdit? "Edit Product Type": "Add New Product Type"}</h2>

          <div
            className="cursor-pointer "
            onClick={() => {
              setOpen(false);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3554 8.00005L15.4665 2.88894C16.1109 2.24449 16.1109 1.17783 15.4665 0.533382C14.822 -0.111062 13.7554 -0.111062 13.1109 0.533382L7.9998 5.64449L2.88869 0.533382C2.24425 -0.111062 1.17758 -0.111062 0.533138 0.533382C-0.111306 1.17783 -0.111306 2.24449 0.533138 2.88894L5.64425 8.00005L0.533138 13.1112C-0.111306 13.7556 -0.111306 14.8223 0.533138 15.4667C0.866471 15.8 1.28869 15.9556 1.71092 15.9556C2.13314 15.9556 2.55536 15.8 2.88869 15.4667L7.9998 10.3556L13.1109 15.4667C13.4442 15.8 13.8665 15.9556 14.2887 15.9556C14.7109 15.9556 15.1331 15.8 15.4665 15.4667C16.1109 14.8223 16.1109 13.7556 15.4665 13.1112L10.3554 8.00005Z"
                fill="#07415C"
              />
            </svg>
          </div>
        </div>
        <div className="  p-6 ">
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmmit}
            validationSchema={validation}
            enableReinitialize
          >
            {({ setFieldValue, values,handleChange }) => (
              <Form>
                <ResetForm isOpen={open} />
                <div className="flex flex-col gap-x-6">
                  <div className="">
                    <div className="">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        Product Type
                      </label>
                      <BorderdInputField
                        placeholder={"Enter Product Type"}
                        width={"w-full"}
                        name="name"
                        value={values.name}
                      />
                      <ErrorMessageComponent name={"name"} />
                    </div>
                    {/* <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        Status
                      </label>
                      <CustomSelect
                        placeholder={"Select Status"}
                        name="status"
                        value={values.status}
                        options={statusList}
                        onChange={(e) => setFieldValue("status", e)}
                      />
                      <ErrorMessageComponent name={"status"} />
                    </div> */}
                  </div>
                  <div className="flex items-center gap-6 w-full">
                    <div className="w-full">
                      <SubmitButton
                        width="w-full"
                        type="submit"
                        content={isEdit?"Edit Product Type" : "Add Product Type"}
                        isLoading={isLoading || editLoaing}
                      />
                    </div>
                  </div>
                </div>
               
                <FormikContext setFormik={(e) => setFormikValue(e.values)} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalLayout>
  );
}

export default React.memo(AddProductTypeModal)
