import { Field } from "formik";
import React from "react";

const BorderdInputField = (props) => {
  return (
    <div className="rounded-md border-[1px] bg-opacity-0 border-[#bab1b1] overflow-hidden  focus-within:border-[#575252]  transition-all    ">
      <Field
        {...props}
        className="px-4 py-[8px] w-full text-sm placeholder:text-darkBlue placeholder:opacity-30 placeholder:text-sm "
      />
    </div>
  );
};

export default React.memo(BorderdInputField);
