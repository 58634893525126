import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import callenderYellow from "../../assets/images/commonImages/calendarYellow.svg";
const SingleDatePicker = ({
  onDateChange,
  icon,
  dateIcon = true,
  style,
  value =null,
  name = "date",
  open = true,
  disableDate,
  placeholder,
  isDateDisabled
}) => {
  const [selected, setSelected] = useState("");
  useEffect(() => {
    if (!open) {
      setSelected("");
    }
  }, [open]);

  useEffect(() => {
    if (!value) {
      setSelected("");
    }
  }, [value]);

  return (
    <div className="w-full">
      <div
        className={`flex w-full justify-between ${
          style ? style : " bg-lightBlue bg-opacity-50 focus-within:bg-primary transition-all  focus-within:border-[#d1d1d1] border border-white px-3 py-[6.5px] rounded-[5px] "
        } items-center relative `}
      >
        <div className="w-full">
          <DatePicker
            selected={selected}
            className="date-picker text-darkBlue text-xs w-full text-left font-semibold cursor-pointer placeholder:text-darkBlue placeholder:opacity-30 placeholder:text-left"
            popperPlacement="bottom-start"
            popperClassName="date-popper"
            dateFormat="yyyy-MM-dd"
            name={name}
            value={!value ? null : value}
            onChange={(date) => {
              onDateChange(date);
              setSelected(date);
            }}
            minDate={disableDate}
            placeholderText={placeholder ? placeholder : "Select Date"}
            closeOnScroll
            filterDate={(date) => isDateDisabled ? isDateDisabled(date,"start") : true}
          />
        </div>
        {dateIcon && (
          <div>
            <div
              className={`absolute cross right-[0px] cursor-pointer top-[2px] px-3 py-1 ${selected !==''?'opacity-100':" opacity-0"} transition-all`}
            >
              <div
                className="w-[20px] h-[20px] bg-[#07415C1A]  flex items-center justify-center"
                onClick={() => {
                  setSelected('');
                  onDateChange("");
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 30 30"
                  width="10px"
                  height="10px"
                >
                  <path
                    fill="#07415C"
                    d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"
                  />
                </svg>
              </div>
            </div>
            <div className=" opacity-100 hover:opacity-0">
              {!icon ? (
                <img src={callenderYellow} alt="callander white" />
              ) : (
                icon()
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleDatePicker;
