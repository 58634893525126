import React from "react";

const Button = ({ content, style, type,onClick,disabled }) => {
  return (
    <button
      className={` rounded-full ${style} ${disabled?"opacity-50":'opacity-100'}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
};

export default Button;
