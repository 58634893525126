import React from "react";
import ErrorMessageComponent from "../../../../../components/errorMessage/ErrorMessageComponent";
import BorderdInputField from "../../../../../components/customField/BorderdInputField";
import SingleDatePicker from "../../../../../components/datePicker/SingleDatePicker";
import { Field } from "formik";
import { onlyNumbers } from "../../../../../utilities/onlyNumbers/onlyNumbers";
import dayjs from "dayjs";

const GeneralSection = ({setFieldValue,values,handleChange,initialValues}) => {
  
  return (
    <div className="flex flex-col gap-6 mb-6">
      <div className="bg-white rounded-lg p-6">
        <h1 className="text-lg font-semibold text-darkBlue mb-4">General</h1>
        <div className="flex flex-col gap-x-6">
          <div className="flex flex-col hxl:flex-row items-center gap-6 w-full">
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Name
              </label>
              <BorderdInputField
                placeholder={"Enter name of the coupon"}
                width={"w-full"}
                name="name"
                value={values.name}
                
              />
              <ErrorMessageComponent name={"name"} />
            </div>
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Code
              </label>
              <BorderdInputField
                placeholder={"Enter code"}
                width={"w-full"}
                name="code"
                onChange={(e) => {
                  const alphanumeric = /^[A-Za-z0-9\s]*$/;
                  if(e.target.value[e.target.value.length-1]!=" " && alphanumeric.test(e.target.value)){
                    setFieldValue("code", e.target.value)
                }        
                }}
                value={values.code}
              />
              <ErrorMessageComponent name={"code"} />
            </div>
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Total Order History Amount
              </label>
              <BorderdInputField
                placeholder={"Enter Total Order History Amount"}
                width={"w-full"}
                name="totalOrderHistory"
                value={values.totalOrderHistory}
                onInput={(e)=>onlyNumbers(e)}
              />
              <ErrorMessageComponent name={"totalOrderHistory"} />
            </div>
          </div>
          <div className="flex flex-col md:flex-row items-center gap-6 w-full">
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Expiry Date
              </label>
              <div className="flex items-center gap-4 w-full justify-between">
                <div className={`w-[80%] ${values.noExpire
                      ? "opacity-50 pointer-events-none"
                      : "opacity-100 pointer-events-auto"
                  } `}>
              <SingleDatePicker
                dateIcon={true}
                name="expire"
                style="rounded-md border-[1px] focus-within:border-[#575252] transition-all bg-opacity-0 border-[#bab1b1] px-4 py-[8px]  "
                icon={() => (
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.9592 2.96662V1.66663C13.9592 1.32496 13.6758 1.04163 13.3342 1.04163C12.9925 1.04163 12.7092 1.32496 12.7092 1.66663V2.91663H7.29251V1.66663C7.29251 1.32496 7.00917 1.04163 6.66751 1.04163C6.32584 1.04163 6.04251 1.32496 6.04251 1.66663V2.96662C3.79251 3.17496 2.70084 4.51662 2.53418 6.50829C2.51751 6.74996 2.71751 6.94996 2.95084 6.94996H17.0508C17.2925 6.94996 17.4925 6.74162 17.4675 6.50829C17.3008 4.51662 16.2092 3.17496 13.9592 2.96662Z"
                      fill="#07415C"
                    />
                    <path
                      opacity="0.4"
                      d="M16.6667 8.19995C17.125 8.19995 17.5 8.57495 17.5 9.03328V14.1666C17.5 16.6666 16.25 18.3333 13.3333 18.3333H6.66667C3.75 18.3333 2.5 16.6666 2.5 14.1666V9.03328C2.5 8.57495 2.875 8.19995 3.33333 8.19995H16.6667Z"
                      fill="#07415C"
                    />
                    <path
                      d="M7.08333 12.5C6.86667 12.5 6.65 12.4083 6.49167 12.2583C6.34167 12.1 6.25 11.8833 6.25 11.6666C6.25 11.45 6.34167 11.2333 6.49167 11.075C6.725 10.8416 7.09167 10.7666 7.4 10.9C7.50833 10.9416 7.6 11 7.675 11.075C7.825 11.2333 7.91667 11.45 7.91667 11.6666C7.91667 11.8833 7.825 12.1 7.675 12.2583C7.51667 12.4083 7.3 12.5 7.08333 12.5Z"
                      fill="#07415C"
                    />
                    <path
                      d="M9.99935 12.5C9.78268 12.5 9.56602 12.4083 9.40768 12.2583C9.25768 12.1 9.16602 11.8833 9.16602 11.6666C9.16602 11.45 9.25768 11.2333 9.40768 11.075C9.48268 11 9.57435 10.9416 9.68268 10.9C9.99101 10.7666 10.3577 10.8416 10.591 11.075C10.741 11.2333 10.8327 11.45 10.8327 11.6666C10.8327 11.8833 10.741 12.1 10.591 12.2583C10.5493 12.2916 10.5077 12.325 10.466 12.3583C10.416 12.3916 10.366 12.4167 10.316 12.4333C10.266 12.4583 10.216 12.475 10.166 12.4833C10.1077 12.4917 10.0577 12.5 9.99935 12.5Z"
                      fill="#07415C"
                    />
                    <path
                      d="M12.9173 12.5001C12.7007 12.5001 12.484 12.4084 12.3257 12.2584C12.1757 12.1 12.084 11.8834 12.084 11.6667C12.084 11.4501 12.1757 11.2334 12.3257 11.0751C12.409 11.0001 12.4923 10.9417 12.6007 10.9C12.7507 10.8334 12.9173 10.8167 13.084 10.85C13.134 10.8584 13.184 10.875 13.234 10.9C13.284 10.9167 13.334 10.9417 13.384 10.9751C13.4257 11.0084 13.4673 11.0417 13.509 11.0751C13.659 11.2334 13.7507 11.4501 13.7507 11.6667C13.7507 11.8834 13.659 12.1 13.509 12.2584C13.4673 12.2917 13.4257 12.325 13.384 12.3584C13.334 12.3917 13.284 12.4167 13.234 12.4334C13.184 12.4584 13.134 12.4751 13.084 12.4834C13.0257 12.4917 12.9673 12.5001 12.9173 12.5001Z"
                      fill="#07415C"
                    />
                    <path
                      d="M7.08333 15.4167C6.975 15.4167 6.86667 15.3917 6.76667 15.35C6.65833 15.3084 6.575 15.25 6.49167 15.175C6.34167 15.0167 6.25 14.8 6.25 14.5833C6.25 14.3667 6.34167 14.15 6.49167 13.9917C6.575 13.9167 6.65833 13.8583 6.76667 13.8167C6.91667 13.75 7.08333 13.7333 7.25 13.7667C7.3 13.775 7.35 13.7917 7.4 13.8167C7.45 13.8333 7.5 13.8584 7.55 13.8917C7.59167 13.925 7.63333 13.9584 7.675 13.9917C7.825 14.15 7.91667 14.3667 7.91667 14.5833C7.91667 14.8 7.825 15.0167 7.675 15.175C7.63333 15.2083 7.59167 15.25 7.55 15.275C7.5 15.3083 7.45 15.3334 7.4 15.35C7.35 15.375 7.3 15.3917 7.25 15.4C7.19167 15.4084 7.14167 15.4167 7.08333 15.4167Z"
                      fill="#07415C"
                    />
                    <path
                      d="M9.99935 15.4166C9.78268 15.4166 9.56602 15.3249 9.40768 15.1749C9.25768 15.0166 9.16602 14.8 9.16602 14.5833C9.16602 14.3666 9.25768 14.15 9.40768 13.9916C9.71602 13.6833 10.2827 13.6833 10.591 13.9916C10.741 14.15 10.8327 14.3666 10.8327 14.5833C10.8327 14.8 10.741 15.0166 10.591 15.1749C10.4327 15.3249 10.216 15.4166 9.99935 15.4166Z"
                      fill="#07415C"
                    />
                    <path
                      d="M12.9173 15.4166C12.7007 15.4166 12.484 15.3249 12.3257 15.1749C12.1757 15.0166 12.084 14.8 12.084 14.5833C12.084 14.3666 12.1757 14.15 12.3257 13.9916C12.634 13.6833 13.2007 13.6833 13.509 13.9916C13.659 14.15 13.7507 14.3666 13.7507 14.5833C13.7507 14.8 13.659 15.0166 13.509 15.1749C13.3507 15.3249 13.134 15.4166 12.9173 15.4166Z"
                      fill="#07415C"
                    />
                  </svg>
                )}
                onDateChange={(e) => {
                  setFieldValue("expire", e);
                }}
                value={
                  values.expire !== "" &&
                  dayjs(values.expire).format("DD/MM/YYYY")
                }
              />
              </div>
              <div className="flex items-center gap-3">
                  <Field
                    id="all-expire"
                    type="checkbox"
                    name="noExpire"
                    className="accent-secondary w-[16px] h-[16px]"
                    onChange={(e)=>{
                      if(e.target.checked){
                       setFieldValue('expire','')
                      }else{
                        setFieldValue('expire',initialValues.expire)
                      }
                      handleChange(e)
                   }}
                  />
                  <label htmlFor="all-expire" className="text-sm whitespace-nowrap">No Expiry</label>
                </div>
              </div>
              <ErrorMessageComponent name={"expire"} />
            </div>
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Usage limit per user
              </label>
              <div className="flex items-center gap-4 w-full justify-between">
                <div
                  className={`w-[80%] ${
                    values.allLimit
                      ? "opacity-50 pointer-events-none"
                      : "opacity-100 pointer-events-auto"
                  } `}
                >
                  <BorderdInputField
                    placeholder={"Enter Usage limit per user"}
                    width={"w-full"}
                    name="limit"
                    value={values.limit}
                    onInput={(e) => onlyNumbers(e)}
                  />
                </div>
                <div className="flex items-center gap-3">
                  <Field
                    id="all-limit"
                    type="checkbox"
                    name="allLimit"
                    className="accent-secondary w-[16px] h-[16px]"
                    onChange={(e)=>{
                      if(e.target.checked){
                       setFieldValue('limit',0)
                      }else{
                        setFieldValue('limit',initialValues.limit)
                      }
                      handleChange(e)
                   }}
                  />
                  <label htmlFor="all-limit" className=" text-sm  whitespace-nowrap">No limit</label>
                </div>
              </div>
              <ErrorMessageComponent name={"limit"} />
            </div>
          </div>
          <div className="w-full ">
            <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
              Description
            </label>
            <div className="rounded-md border-[1px] bg-opacity-0 focus-within:border-[#575252] transition-all border-[#bab1b1] overflow-hidden">
              <Field
                as="textarea"
                placeholder={"Enter Description "}
                width={"w-full"}
                name="discription"
                onChange={(e) => {
                  const reg = /( {2,}|\n{2,})/; // Regular expression to match 2 or more spaces or newlines
                  if (!reg.test(e.target.value)) {
                    setFieldValue("discription", e.target.value);
                  }
                }}
                value={values.discription}
                className="px-4 py-[8px] w-full h-[7rem] placeholder:text-darkBlue placeholder:opacity-30 placeholder:text-sm"
              />
            </div>
            <ErrorMessageComponent name={"discription"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GeneralSection;
