import { useMutation, useQuery, useQueryClient } from "react-query";
import { dashboardData, dashboardOrderData } from "./dashboardFunctions";


const useGetDashboardData = (data) => {
    return useQuery(["getDashboardData", data], () => dashboardData(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };

const useGetDashboardOrderData = (data) => {
    return useQuery(["getDashboardOrderData", data], () => dashboardOrderData(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };


export {
    useGetDashboardData,
    useGetDashboardOrderData
}