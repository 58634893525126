import React from 'react'
import ModalLayout from '../../../components/customModal/CustomModal'

const ImagePreview = ({open,setOpen,img}) => {
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen} >
         <div style={{ transform: `scale(1)` }}>

            <img src={img} alt="product img" />
              
         </div>
    </ModalLayout>
  )
}

export default ImagePreview