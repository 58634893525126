import React, { memo, useEffect, useState } from "react";
import BorderdInputField from "../../../components/customField/BorderdInputField";
import { Field } from "formik";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import "../../../pages/AddEditProduct/AddEditProductComponents/addEditProductComponent.scss";
import ErrorMessageComponent from "../../../components/errorMessage/ErrorMessageComponent";
import { onlyNumbers } from "../../../utilities/onlyNumbers/onlyNumbers";
import ToggleButton from "../../../components/toggleButton/ToggleButton";
import CustomSlider from "../../../components/customeSlider/CustomSlider";

const GeneralForm = ({ setFieldValue, values, data }) => {
  const [rangeValue, setRangeVlaue] = useState(0);

  useEffect(() => {
    const thumb = document.querySelector(".value");

    thumb.style.left = `${rangeValue}%`;
  }, [rangeValue]);
  useEffect(() => {
    if (values?.discountPerc !== "") {
      setRangeVlaue(values?.discountPerc);
    }
  }, [values]);

  useEffect(() => {
    setFieldValue("sell_price", values.price)
    if(values.discountActive == 2 && values.discountPerc && values.price){
      let descAmt = Number(values.price) * Number(values.discountPerc) / 100
      if(descAmt){
        let sell_price = Number(values.price) - Number(descAmt)
        setFieldValue("sell_price", sell_price)
      }
    }
  }, [values.discountActive,values.discountType,values.price,values.discountPerc]);


  return (
    <div className="flex flex-col gap-6">
      <div className="bg-white rounded-lg p-6">
        <div className="flex items-center justify-between">
          <h1 className="text-lg font-semibold text-darkBlue mb-4">General</h1>
          <div className="flex items-center gap-3 md:gap-6">
            <p
              className={`${
                values.isActive ? "text-status" : "text-error"
              } transition-all font-semibold text-xs md:text-base`}
            >
              {values.isActive ? "Active" : "Inactive"}
            </p>
            <ToggleButton
              change={(e) => setFieldValue("isActive", e)}
              isChecked={values.isActive ? true : false}
              id={"productActive"}
            />
          </div>
        </div>

        <div className="flex flex-col gap-x-6">
          <div className="flex items-center md:flex-row flex-col gap-6 w-full">
          <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Model Name
              </label>
              <BorderdInputField
                placeholder={"Enter model"}
                width={"w-full"}
                name="model"
                value={values.model}
              />
              <ErrorMessageComponent name={"model"} />
            </div>
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                SKU Number
              </label>
              <BorderdInputField
                placeholder={"Enter SKU Number"}
                width={"w-full"}
                name="skuNumber"
                value={values.skuNumber}
              />
              <ErrorMessageComponent name={"skuNumber"} />
            </div>
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Product Name
              </label>
              <BorderdInputField
                placeholder={"Enter Product Name"}
                width={"w-full"}
                name="name"
                value={values.name}
              />
              <ErrorMessageComponent name={"name"} />
            </div>
          </div>
          <div className="w-full ">
            <label className="mb-3 block text-sm  text-darkBlue" htmlFor="type">
              Description
            </label>
            <div className="rounded-md border-[1px] bg-opacity-0 border-[#bab1b1] focus-within:border-[#575252] transition-all overflow-hidden">
              <Field
                as="textarea"
                placeholder={"Enter Description "}
                width={"w-full"}
                name="discription"
                value={values.discription}
                className="px-4 py-[8px] w-full h-[7rem] placeholder:text-darkBlue  placeholder:opacity-30 placeholder:text-sm"
              />
            </div>
            <ErrorMessageComponent name={"discription"} />
          </div>



          <div className="flex items-center md:flex-row flex-col gap-6 w-full">
          <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Metal Type
              </label>
              <CustomSelect
                placeholder={"Choose Metal Type"}
                name="metal_type"
                value={values.metal_type}
                options={data.metalDataList}
                onChange={(e) => setFieldValue("metal_type", e)}
              />
              <ErrorMessageComponent name={"metal_type"} />
            </div>
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Weight(g)
              </label>
              <BorderdInputField
                placeholder={"Enter Weight"}
                width={"w-full"}
                name="weight"
                value={values.weight}
                onInput={(e) => onlyNumbers(e)}
              />
              <ErrorMessageComponent name={"weight"} />
            </div>
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Design Options
              </label>
              <CustomSelect
                placeholder={"Choose Design Option"}
                name="design_option"
                value={values.design_option}
                options={data.designDataList}
                onChange={(e) => setFieldValue("design_option", e)}
              />
              <ErrorMessageComponent name={"design_option"} />
            </div>
            {/* <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Delivery Expected(in days)
              </label>
              <CustomSlider value={values.delivery_time} setFieldValue={setFieldValue} name="delivery_time" />
              <BorderdInputField
                placeholder={"Enter Delivery Time"}
                width={"w-full"}
                name="delivery_time"
                value={values.delivery_time}
                onInput={(e) => onlyNumbers(e)}
              />
              <ErrorMessageComponent name={"delivery_time"} />
            </div> */}
          </div>
          <div className="flex items-center md:flex-row flex-col gap-6 w-full">
          
            <div className="w-[48%]">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Purity
              </label>
              <BorderdInputField
                placeholder={"Enter Purity"}
                width={"w-full"}
                name="purity"
                value={values.purity}
                onInput={(e) => onlyNumbers(e)}
              />
              <ErrorMessageComponent name={"purity"} />
            </div>
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Delivery Expected(in days)
              </label>
              <CustomSlider value={values.delivery_time} setFieldValue={setFieldValue} name="delivery_time" />
            </div>
            {/* <div className="w-full"></div> */}
          </div>
        </div>
      </div>
      <div className="bg-white rounded-lg p-6">
        <h1 className="text-lg font-semibold text-darkBlue mb-4">Price</h1>
        <div className="flex flex-col gap-6">
          <div className="flex items-center md:flex-row flex-col gap-6  w-full">
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Actual Price
              </label>
              <BorderdInputField
                placeholder={"Enter Price"}
                width={"w-full"}
                name="price"
                value={values.price}
                maxLength={9}
                onInput={(e) => onlyNumbers(e)}
              />
              <ErrorMessageComponent name={"price"} />
            </div>
            {/* <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Price Ex Tax
              </label>
              <BorderdInputField
                placeholder={"Enter Price Ex Tax"}
                width={"w-full"}
                name="priceExtax"
                value={values.priceExtax}
                maxLength={9}
                onInput={(e) => onlyNumbers(e)}
              />
              <ErrorMessageComponent name={"priceExtax"} />
            </div> */}
            <div
              className={`w-full ${
                values.discountActive === 1
                  ? "opacity-50 pointer-events-none"
                  : "opacity-100 pointer-events-auto"
              }  `}
            >
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Discount Type
              </label>
              <CustomSelect
                placeholder={"Choose Discount"}
                width={"w-full"}
                options={data.discountData}
                onChange={(e) => setFieldValue("discountType", e)}
                // defaultValue={[{ label: "Percentage", value: "Percentage" }]}
                value={values.discountType}
              />
              <ErrorMessageComponent name={"discountType"} />
            </div>
            <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Tax Class
              </label>
              <CustomSelect
                placeholder={"Enter Tax Class"}
                width={"w-full"}
                options={data.taxData}
                onChange={(e) => {
                  setFieldValue("tax", e);

                  if (values.tax.value === "no_tax" || e.value === "no_tax") {
                    setFieldValue("CGST", "");
                    setFieldValue("IGST", "");
                    setFieldValue("CGST/SGST", "");
                  }
                }}
                value={values.tax}
              />
              <ErrorMessageComponent name={"tax"} />
            </div>
          </div>
          <div className="flex items-center md:flex-row flex-col gap-x-6 w-full">
            <div className="w-full relative">
              
              <div
                className={`w-full ${
                  values.discountActive === 1
                    ? "opacity-50 pointer-events-none"
                    : "opacity-100 pointer-events-auto"
                }  `}
              >
                <div className="w-[100%]">
                  <p className="relative w-[50px] text-center top-[-20px]   text-darkBlue font-semibold text-base value ">
                    {rangeValue} %
                  </p>
                </div>

                <input
                  min="0"
                  max="99"
                  className="w-full discount-range"
                  type="range"
                  name="discountPerc"
                  // defaultValue={0}
                  onChange={(e) => {
                    setRangeVlaue(e.target.value);
                    setFieldValue("discountPerc", e.target.value);
                  }}
                  value={values.discountPerc}
                />
              </div>
              <ErrorMessageComponent name={"discountPerc"} />
            </div>
          </div>
          <div className="flex items-center md:flex-row flex-col gap-6 w-full">
            {/* <div className="w-full">
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                Tax Class
              </label>
              <CustomSelect
                placeholder={"Enter Tax Class"}
                width={"w-full"}
                options={data.taxData}
                onChange={(e) => {
                  setFieldValue("tax", e);

                  if (values.tax.value === "no_tax" || e.value === "no_tax") {
                    setFieldValue("CGST", "");
                    setFieldValue("IGST", "");
                    setFieldValue("CGST/SGST", "");
                  }
                }}
                value={values.tax}
              />
              <ErrorMessageComponent name={"tax"} />
            </div> */}
            <div
              className={`w-full ${
                values?.tax?.label === "IGST" || values?.tax?.label === "No Tax"
                  ? "opacity-50 pointer-events-none"
                  : "opacity-100 pointer-events-auto"
              }`}
            >
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                CGST Rate %
              </label>
              <BorderdInputField
                disabled={
                  values?.tax?.label === "IGST" ||
                  values?.tax?.label === "No Tax"
                    ? true
                    : false
                }
                placeholder={"Enter CGST Rate"}
                width={"w-full"}
                name="CGST"
                values={values.CGST}
                onInput={(e) => onlyNumbers(e)}
              />
              <ErrorMessageComponent name={"CGST"} />
            </div>
            <div
              className={`w-full ${
                values?.tax?.label === "IGST" || values?.tax?.label === "No Tax"
                  ? "opacity-50 pointer-events-none"
                  : "opacity-100 pointer-events-auto"
              } `}
            >
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                SGST / UTGST Rate %
              </label>
              <BorderdInputField
                disabled={
                  values?.tax?.label === "IGST" ||
                  values?.tax?.label === "No Tax"
                    ? true
                    : false
                }
                placeholder={"Enter CGST / SGST Rate"}
                width={"w-full"}
                name="CGST/SGST"
                values={values["CGST/SGST"]}
                onInput={(e) => onlyNumbers(e)}
              />
              <ErrorMessageComponent name={"CGST/SGST"} />
            </div>
            <div
              className={`w-full  ${
                values?.tax?.label === "CGST and SGST" ||
                values?.tax?.label === "No Tax"
                  ? "opacity-50 pointer-events-none"
                  : "opacity-100 pointer-events-auto"
              }`}
            >
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                IGST Rate %
              </label>
              <BorderdInputField
                disabled={
                  values?.tax?.label === "CGST and SGST" ||
                  values?.tax?.label === "No Tax"
                    ? true
                    : false
                }
                placeholder={"Enter IGST Rate"}
                width={"w-full"}
                name="IGST"
                values={values.IGST}
                onInput={(e) => onlyNumbers(e)}
              />
              <ErrorMessageComponent name={"IGST"} />
            </div>
          </div>
          {/* <div className="flex items-center md:flex-row flex-col gap-6 w-full">
            <div
              className={`w-full  ${
                values?.tax?.label === "CGST and SGST" ||
                values?.tax?.label === "No Tax"
                  ? "opacity-50 pointer-events-none"
                  : "opacity-100 pointer-events-auto"
              }`}
            >
              <label
                className="mb-3 block text-sm text-darkBlue"
                htmlFor="type"
              >
                IGST Rate %
              </label>
              <BorderdInputField
                disabled={
                  values?.tax?.label === "CGST and SGST" ||
                  values?.tax?.label === "No Tax"
                    ? true
                    : false
                }
                placeholder={"Enter IGST Rate"}
                width={"w-full"}
                name="IGST"
                values={values.IGST}
                onInput={(e) => onlyNumbers(e)}
              />
              <ErrorMessageComponent name={"IGST"} />
            </div>
            <div className="w-full"></div>
            <div className="w-full"></div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default memo(GeneralForm);
