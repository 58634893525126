import React,{useState} from 'react'
import toast from 'react-hot-toast';
import CustomSelect from '../../../components/customSelect/CustomSelect'
import Button from '../../../components/submitButton/Button'
import { useAddSubCat } from '../../../servicesQuery/categoryQuery/categoryQuery';

function CategorySelect({options,item}) {
    const [category,setCategory] =useState({})
    const { mutateAsync: addSubCat, isLoading: addLoaing } = useAddSubCat();
    const handleSubCat = () => {
       
        const data = {
            "name": category.value,
            "parent": item.id,
        }
        toast.promise(addSubCat(data)
                .then((res) => {
                    setCategory({});
                })
                , {
                loading: "Adding Subcategory",
                success: "Subcategory Added",
                error: "Something went wrong",
          });
        
    }
  return (
    <div className="w-1/2 flex gap-3">
        <CustomSelect
            placeholder={"Add subcategories"}
            name="subcategory"
            value={category.value? category : null}
            options={options}
            onChange={(e) => setCategory(e)}
            />
        {category.value ?
        <Button
            onClick={() => handleSubCat()}
            style="text-xs  border border-2 border-darkYellow  text-darkBlue   rounded-md py-2 px-6"
            content="Add"
            />
            : null}
        
        </div>
  )
}

export default CategorySelect
