import React from "react";

const ProgressBar = ({ percentage }) => {
  const getColor = (percentage) => {
    if (percentage >= 75) {
      return "bg-[#22C55E]";
    } else if (percentage >= 50) {
      return "bg-[#3B82F6]";
    } else if (percentage >= 25) {
      return "bg-[#EAB308]";
    }
    return "bg-[#EF4444]";
  };

  return (
    <div className="relative pt-1">
      <div className="flex mb-2 items-center justify-between">
        <div>
          <span className="text-xs font-semibold inline-block py-1 uppercase rounded-full text-teal-600 bg-teal-200">
            Progress
          </span>
        </div>
        <div className="text-right">
          <span className="text-xs font-semibold inline-block text-teal-600">
            {percentage}%
          </span>
        </div>
      </div>
      <div className="overflow-hidden h-2 mb-4 text-xs flex rounded bg-primary">
        <div
          style={{ width: `${percentage}%` }}
          className={`shadow-none flex flex-col text-center whitespace-nowrap text-white justify-center ${getColor(
            percentage
          )}`}
        ></div>
      </div>
    </div>
  );
};

export default ProgressBar;
