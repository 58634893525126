import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";


const ProductManagement = () => {
 
  return (
    <div className="flex-1 w-full h-full">
     <Outlet/>
    </div>
  );
};

export default ProductManagement;
