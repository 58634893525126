import React from "react";
import { Outlet } from "react-router-dom";


const CatogeryManagement = () => {
  
  return (
    <div className="flex-1 w-full h-full">
     <Outlet/>
    </div>
  );
};

export default CatogeryManagement;
