import { useMutation, useQuery, useQueryClient } from "react-query";
import { addAutoCoupons, addCoupons, editAutoCoupons, editCoupons, getAutoCoupons, getCoupons, getSingleCoupons } from "./couponFunctions";

const useAddCoupons = (data) => {
  const queryClient = useQueryClient();

  return useMutation((data) => addCoupons(data), {
    onSuccess: (data) => {
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};
const useEditCoupons = (data) => {
  const queryClient = useQueryClient();

  return useMutation((data) => editCoupons(data), {
    onSuccess: (data) => {
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

const useGetCoupons = (data) => {
  return useQuery(["couponLists", data], () => getCoupons(data), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
const useGetSingleCoupons = (data) => {
  return useQuery(["singleCoupon", data], () => getSingleCoupons(data), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const useAddAutoCoupon = (data) => {
  const queryClient = useQueryClient();

  return useMutation((data) => addAutoCoupons(data), {
    onSuccess: (data) => {
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

const useEditAutoCoupon = (data) => {
  const queryClient = useQueryClient();

  return useMutation((data) => editAutoCoupons(data), {
    onSuccess: (data) => {
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};


const useGetAutoCoupons = (data) => {
  return useQuery(["autoCoupon", data], () => getAutoCoupons(data), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};


export { useAddCoupons,useEditAutoCoupon, useGetCoupons, useGetSingleCoupons ,useEditCoupons,useAddAutoCoupon,useGetAutoCoupons};
