import { useMutation, useQuery, useQueryClient } from "react-query";
import { cityLists, editVendor, stateLists, vendorAdd, vendorInvite, vendorLists, vendorSingle } from "./vendorFunctions";

const useGetVendortList = (data) => {
  return useQuery(["getVendorList", data], () => vendorLists(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const useGetStateList = (data) => {
  return useQuery(["getStateList", data], () => stateLists(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const useGetCityList = (data) => {
    return useQuery(["getCityList", data], () => cityLists(data), {
        // staleTime: 30000,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
      });
    
  };

const useGetVendorSingle = (data) => {
  return useQuery(["getVendorSingle", data], () => vendorSingle(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  
};

const useAddVendor = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => vendorAdd(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getVendorList");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

const useEditVendor = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => editVendor(data), {
    onSuccess: (data) => {
        queryClient.invalidateQueries('getVendorList')
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

const useInviteVendor = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => vendorInvite(id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getVendorList");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

export { useGetVendortList, useGetStateList, useAddVendor,useGetCityList,useEditVendor,useGetVendorSingle,useInviteVendor };
