import dash from '../assets/images/menuIcons/dash.svg'
import dashActive from '../assets/images/menuIcons/dashActive.svg'
import user from '../assets/images/menuIcons/user.svg'
import userActive from '../assets/images/menuIcons/userActive.svg'
import prod from '../assets/images/menuIcons/product.svg'
import prodActive from '../assets/images/menuIcons/productActive.svg'
import vendor from '../assets/images/menuIcons/vendor.svg'
import vendorActive from '../assets/images/menuIcons/vendorActive.svg'
import order from '../assets/images/menuIcons/order.svg'
import orderActive from '../assets/images/menuIcons/orderActive.svg'
import coupon from '../assets/images/menuIcons/coupon.svg'
import couponActive from '../assets/images/menuIcons/couponActive.svg'
import ticket from '../assets/images/menuIcons/ticket.svg'
import ticketActive from '../assets/images/menuIcons/ticketActive.svg'
import email from '../assets/images/menuIcons/email.svg'
import emailActive from '../assets/images/menuIcons/emailActive.svg'

import role from '../assets/images/menuIcons/role.svg'
import roleActive from '../assets/images/menuIcons/roleActive.svg'
import Dashboard from "../pages/dashboard/Dashboard";
import UserManagement from "../pages/userManagement/UserManagement";
import UserOverview from "../pages/userOverview/UserOverview";
import OrderDetials from "../pages/orderDetials/OrderDetials";
import OrderManagement from "../pages/orderManagement/OrderManagement";
import ProductManagement from "../pages/productMnagement/ProductManagement";
import Signin from "../pages/signin/Signin";
import ForgetPassword from "../pages/forgetPassword/ForgetPassword";
import UserRolePage from "../pages/userRole/UserRolePage";
import UserDetialsPage from "../pages/userRoleDetials/UserDetialsPage";
import AddEditProductPage from "../pages/AddEditProduct/AddEditProductPage";
import TagManagementPage from "../pages/tagManagement/TagManagementPage";
import CouponManagemant from "../pages/couponManagement/CouponManagemant";
import NormalCoupons from "../pages/couponManagement/couponManagementSubPages/normalCoupons/NormalCoupons";
import OrderManagementMainPage from "../pages/orderManagement/orderManagementComponent/OrderManagementMainPage";
import ProductManagementMainPage from "../pages/productMnagement/productManagementComponent/ProductManagementMainPage";
import UserRoleManagementMainPage from "../pages/userRole/UserRoleManagementComponent/UserRoleManagementMainPage";
import UserManagementMainPage from "../pages/userManagement/userManagementComponent/UserManagementMainPage";
import WelcomeCoupons from "../pages/couponManagement/couponManagementSubPages/welcomeCoupons/WelcomeCoupons";
import CouponAddEdit from "../pages/couponManagement/couponManagementSubPages/normalCoupons/NormalCouponsComponents/CouponAddEdit";
import PremiumCoupons from "../pages/couponManagement/couponManagementSubPages/premiumCoupons/premiumCoupons";
import PremiumCouponAddEdit from "../pages/couponManagement/couponManagementSubPages/premiumCoupons/premiumCouponsComponents/PremiumCouponAddEdit";
import WelcomeCouponAddEdit from "../pages/couponManagement/couponManagementSubPages/welcomeCoupons/welcomeCouponsComponents/WelcomeCouponAddEdit";
import VendorManagement from "../pages/vendorManagement/VendorManagement";
import TagTemplete from "../pages/tagManagement/tagManagementSubPages/tagTemplete/TagTemplete";
import SavedTagPage from "../pages/tagManagement/tagManagementSubPages/savedTag/SavedTagPage";
import TagManagementMainPage from "../pages/tagManagement/tagManagementSubPages/tagManagementMainPage/TagManagementMainPage";
import VendorManagementMainPage from "../pages/vendorManagement/VendorManagementComponent/VendorManagementMainPage";
import InviteVendorMainPage from "../pages/vendorManagement/VendorManagementComponent/InviteVendorMainPage";
import CatogeryManagement from "../pages/catogeryManagement/CatogeryManagement";
import CategoryManagementMainPage from "../pages/catogeryManagement/categoryManagementComponents/CategoryManagementMainPage";
import VendorResetPassword from "../pages/vendorManagement/VendorManagementComponent/VendorResetPassword";
import CouponManagemantMainPage from "../pages/couponManagement/couponManagementSubPages/CuponManagementMainPage";
import AutoCoupons from "../pages/couponManagement/couponManagementSubPages/autoCoupons/AutoCoupons";
import BirthdayCouponAddEdit from "../pages/couponManagement/couponManagementSubPages/autoCoupons/autoCouponsComponents/birthdayCoupons/BirthdayCouponAddEdit";
import EmptyCartCouponAddEdit from "../pages/couponManagement/couponManagementSubPages/autoCoupons/autoCouponsComponents/emtyCartCoupon/EmptyCartCouponAddEdit";
import MissYouCouponAddEdit from "../pages/couponManagement/couponManagementSubPages/autoCoupons/autoCouponsComponents/missYouCoupons/MissYouCouponAddEdit";
import TicketPage from "../pages/Tickets/TicketPage";
import TicketMainPage from "../pages/Tickets/TicketComponent/TicketMainPage";
import TicketChatPage from "../pages/Tickets/TicketComponent/TicketChatPage";
import tickets from "../assets/images/sidebarImages/tickets.svg";
import review from "../assets/images/sidebarImages/review.svg";
import userrole from "../assets/images/sidebarImages/userrole.svg";
import coupen from "../assets/images/sidebarImages/coupen.svg";
import EmailManagement from '../pages/emailManagement/EmailManagement'
import EmailManagementMainPage from '../pages/emailManagement/emailManagementCOmponents/EmailManagementMainPage'
import SalesTemplateAddEdit from '../pages/emailManagement/emailManagementCOmponents/salesTemplate/SalesTemplateAddEdit'
import UploadProgressPage from '../pages/productMnagement/productManagementComponent/UploadProgressPage'
import ReviewPage from '../pages/review/ReviewPage'
import ReviewMainPage from '../pages/review/ReviewMainPage'
import TagTempleteNew from '../pages/tagManagement/tagManagementSubPages/tagTemplete/tagTemplateNew'
import art_motion from "../assets/images/sidebarImages/art_motion.svg";
import ArtMotionPage from '../pages/artInMotion/ArtMotionPage'
import ArtMotionMainPage from '../pages/artInMotion/artMotionComponents/ArtMotionMainPage'
import AddEditProductPageArt from '../pages/artInMotion/artMotionComponents/AddEditProductPage'
import OrderDetails from '../pages/artInMotion/artMotionComponents/OrderDetails'


export const routes = [
  {
    key: 0,
    path: "dashboard",
    label: "Dashboard",
    icon: dash,
    iconActive:dashActive,
    privetRoute: true,
    isSubRoute: false,
    permission: "can_view_dashboard",
    title: "Dashboard",
    component: Dashboard,
    subRoute: [
      {
        key: 1,
        path: `dashboard`,
        title: "Dashboard",
        component: Dashboard,
      },
    ],
  },
  {
    key: 1,
    path: "user-management",
    label: "Customer Management",
    icon: user,
    iconActive: userActive,
    privetRoute: true,
    isSubRoute: true,
    permission: "can_view_user",
    component: UserManagement,
    title: "Customer Management",
    subRoute: [
      {
        key: 1,
        path: `user-management`,
        title: "Customer Management",
        component: UserManagementMainPage,
      },
      {
        key: 2,
        path: `user-management/user-overview/:id`,
        title: "Customer Overview",
        breadCrumbs: [
          {
            label: "Customer Management",
            path: "user-management",
          },
          {
            label: "Customer Overview",
            path: "#",
          },
        ],
        component: UserOverview,
      },
      {
        key: 3,
        path: `user-management/order-detials/:id`,
        title: "Order Details",
        breadCrumbs: [
          {
            label: "Customer Management",
            path: "user-management",
          },
          {
            label: "Customer Overview",
            path: "user-management/user-overview/:id",
          },
          {
            label: "Order Details",
            path: "#",
          },
        ],
        component: OrderDetials,
      },
    ],
  },
  {
    key: 2,
    path: "order-management",
    title: "Order Management",
    label: "Order Management",
    icon: order,
    iconActive: orderActive,
    privetRoute: true,
    component: OrderManagement,
    permission: "can_view_order",
    isSubRoute: true,
    subRoute: [
      {
        key: 1,
        path: `order-management`,
        title: "Order Management",
        component: OrderManagementMainPage,
      },
      {
        key: 2,
        title: "Order Details",
        breadCrumbs: [
          {
            label: "Order Management",
            path: "order-management",
          },
          {
            label: "Order Details",
            path: "#",
          },
        ],
        path: `order-management/order-detials/:id`,
        component: OrderDetials,
      },
    ],
  },
  {
    key: 2,
    label: "Product Management",
    icon: prod,
    iconActive: prodActive,
    privetRoute: true,
    isSubRoute: false,
    isSubMenu: true,
    permission: "can_view_product",
    subMenu: [
      {
        key: 1,
        component: ProductManagement,
        path: "product-management/product",
        title: "Product Management",
        label: "Products",
        isSubRoute: true,
        subRoute: [
          {
            key: 1,
            path: `product-management/product`,
            title: "Product Management - Products",
            component: ProductManagementMainPage,
          },
          {
            key: 2,
            title: "Product Management - Add Product",
            breadCrumbs: [
              {
                label: "Products",
                path: "product-management/product",
              },
              {
                label: "Add Product",
                path: "",
              },
            ],
            path: `product-management/product/add-product`,
            component: AddEditProductPage,
          },
          {
            key: 3,
            title: "Product Management - Edit Product",
            breadCrumbs: [
              {
                label: "Products",
                path: "product-management/product",
              },
              {
                label: "Edit Product",
                path: "#",
              },
            ],
            path: `product-management/product/edit-product/:id`,
            component: AddEditProductPage,
          },
          {
            key: 4,
            title: "Product Upload Progress",
            breadCrumbs: [
              {
                label: "Products",
                path: "product-management/product",
              },
              {
                label: "Product Upload Progresses",
                path: "#",
              },
            ],
            path: `product-management/product/upload-progress`,
            component: UploadProgressPage,
          },
        ],
      },
      {
        key: 2,
        component: TagManagementPage,
        label: "Tags",
        path: "product-management/tag/template",
        title: "Tags",
        subRoute: [
          {
            key: 1,
            path: `product-management/tag/template`,
            title: "Product Management - Add Tag",

            component: TagTempleteNew,
          },
          // {
          //   key: 1,
          //   path: `product-management/tag`,
          //   title: "Product Management - Tags",

          //   component: TagManagementMainPage,
          // },
          // {
          //   key: 2,
          //   title: "Product Management - Add Tag",
          //   breadCrumbs: [
          //     {
          //       label: "Tags",
          //       path: "product-management/tag",
          //     },
          //     {
          //       label: "Add Tag",
          //       path: "#",
          //     },
          //   ],
          //   path: `product-management/tag/template`,
          //   component: TagTemplete,
          // },
          // {
          //   key: 3,
          //   title: "Product Management - Edit Tag",
          //   path: `product-management/tag/edit-tag/:id`,
          //   breadCrumbs: [
          //     {
          //       label: "Tags",
          //       path: "product-management/tag",
          //     },
          //     {
          //       label: "Edit Tag",
          //       path: "#",
          //     },
          //   ],
          //   component: TagTemplete,
          // },
          // {
          //   key: 4,
          //   title: "Product Management - Saved Tag",
          //   breadCrumbs: [
          //     {
          //       label: "Tags",
          //       path: "product-management/tag",
          //     },
          //     {
          //       label: "Saved Tags",
          //       path: "#",
          //     },
          //   ],
          //   path: `product-management/tag/saved-tags`,
          //   component: SavedTagPage,
          // },
        ],
      },
      {
        key: 3,
        component: CatogeryManagement,
        title: "Catogeries Management",
        label: "Categories",
        path: "product-management/catogeries",
        isSubRoute: true,
        subRoute: [
          {
            key: 1,
            title: "Product Management - Catogeries",
            path: `product-management/catogeries`,
            component: CategoryManagementMainPage,
          },
        ],
      },
    ],
  },
  {
    key: 3,
    label: "Vendor Management",
    icon: vendor,
    iconActive: vendorActive,
    privetRoute: true,
    isSubRoute: false,
    isSubMenu: true,
    permission: "can_view_vendor",
    subMenu: [
      {
        key: 1,
        component: VendorManagement,
        path: "vendor-management/active",
        label: "Active",

        isSubRoute: true,
        subRoute: [
          {
            key: 1,
            path: `vendor-management/active`,
            title: "Vendor Management - Active",
            component: VendorManagementMainPage,
          },
        ],
      },
      {
        key: 2,
        component: VendorManagement,
        path: "vendor-management/invite",
        label: "Invite",

        isSubRoute: true,
        subRoute: [
          {
            key: 1,
            title: "Vendor Management - Invite",
            path: `vendor-management/invite`,
            component: InviteVendorMainPage,
          },
        ],
      },
    ],
  },

  {
    key: 7,
    path: "user-role-management",
    label: "User Role Management",
    icon: role,
    iconActive: roleActive,
    privetRoute: true,
    component: UserRolePage,
    permission: "can_view_userrolemanagement",
    isSubRoute: true,
    subRoute: [
      {
        key: 1,
        path: `user-role-management`,
        title: "User Role Management",
        component: UserRoleManagementMainPage,
      },
      {
        key: 2,
        title: "User Role Details",
        path: `user-role-management/detials/:id`,
        breadCrumbs: [
          {
            label: "User Role Management",
            path: "user-role-management",
          },
          {
            label: "User Role Details",
            path: "#",
          },
        ],
        component: UserDetialsPage,
      },
    ],
  },

  {
    key: 9,
    path: "signin",
    label: "Signin",
    icon: "",
    iconActive: "",
    privetRoute: false,
    isSubRoute: false,
    component: Signin,
  },
  {
    key: 10,
    path: "forgot-password/:uid/:token",
    label: "Forgot Password",
    icon: "",
    iconActive: "",
    privetRoute: false,
    isSubRoute: false,
    component: ForgetPassword,
  },
  {
    key: 11,
    path: "coupon-management",
    label: "Coupon Management",
    icon: coupon,
    iconActive:couponActive,
    privetRoute: true,
    component: CouponManagemant,
    title: "Normal Coupons",
    permission: "can_view_coupon",
    isSubRoute: true,
    subRoute: [
      {
        key: 1,
        path: `coupon-management`,
        title: "Coupon Management - Normal Coupons",

        component: CouponManagemantMainPage,
      },
      {
        key: 1,
        path: `coupon-management/normal-coupons`,
        title: "Coupon Management - Normal Coupons",

        component: NormalCoupons,
      },
      {
        key: 1,
        path: `coupon-management/normal-coupons/add-coupons`,
        title: "Coupon Management - Add Normal Coupons",
        breadCrumbs: [
          {
            label: "Normal Coupons",
            path: "coupon-management/normal-coupons",
          },
          {
            label: "Add Normal Coupons",
            path: "#",
          },
        ],
        component: CouponAddEdit,
      },
      {
        key: 1,
        path: `coupon-management/normal-coupons/edit-coupon/:id`,
        title: "Coupon Management - Edit Normal Coupons",
        breadCrumbs: [
          {
            label: "Normal Coupons",
            path: "coupon-management/normal-coupons",
          },
          {
            label: "Edit Normal Coupons",
            path: "#",
          },
        ],
        component: CouponAddEdit,
      },
      {
        key: 2,
        path: `coupon-management/premium-coupons`,
        title: "Coupon Management - Premium Coupons",

        component: PremiumCoupons,
      },
      {
        key: 1,
        path: `coupon-management/premium-coupons/add-coupons`,
        title: "Coupon Management - Add Premium Coupons",
        breadCrumbs: [
          {
            label: "Premium Coupons",
            path: "coupon-management/premium-coupons",
          },
          {
            label: "Add Premium Coupons",
            path: "#",
          },
        ],
        component: PremiumCouponAddEdit,
      },
      {
        key: 1,
        path: `coupon-management/premium-coupons/edit-coupon/:id`,
        title: "Coupon Management - Edit Premium Coupons",
        breadCrumbs: [
          {
            label: "Premium Coupons",
            path: "coupon-management/premium-coupons",
          },
          {
            label: "Edit Premium Coupons",
            path: "#",
          },
        ],
        component: PremiumCouponAddEdit,
      },
      {
        key: 3,
        path: `coupon-management/welcome-coupons`,
        title: "Coupon Management - Welcome Coupons",
        component: WelcomeCoupons,
      },
      {
        key: 1,
        path: `coupon-management/welcome-coupons/add-coupons`,
        title: "Coupon Management - Add Welcome Coupons",
        breadCrumbs: [
          {
            label: "Welcome Coupons",
            path: "coupon-management/welcome-coupons",
          },
          {
            label: "Add Welcome Coupons",
            path: "#",
          },
        ],
        component: WelcomeCouponAddEdit,
      },
      {
        key: 1,
        
        path: `coupon-management/welcome-coupons/edit-coupon/:id`,
        breadCrumbs: [
          {
            label: "Welcome Coupons",
            path: "coupon-management/welcome-coupons",
          },
          {
            label: "Edit Welcome Coupons",
            path: "#",
          },
        ],
        title: "Coupon Management - Edit Welcome Coupons",
        component: WelcomeCouponAddEdit,
      },

      {
        key: 3,
        path: `coupon-management/auto-coupons`,
        title: "Coupon Management - Auto Coupons",
        component: AutoCoupons,
      },
      {
        key: 1,
        path: `coupon-management/auto-coupons/add-coupons/birthday-coupons`,
        title: "Coupon Management - Add Birthday Coupons",
        breadCrumbs: [
          {
            label: "Auto Coupons",
            path: "coupon-management/auto-coupons",
          },
          {
            label: "Add Birthday Coupons",
            path: "#",
          },
        ],
        component: BirthdayCouponAddEdit,
      },
      {
        key: 1,
        path: `coupon-management/auto-coupons/add-coupons/empty-cart-coupons`,
        title: "Coupon Management - You Forgot Something Coupons",
        breadCrumbs: [
          {
            label: "Auto Coupons",
            path: "coupon-management/auto-coupons",
          },
          {
            label: "You Forgot Something Coupons",
            path: "#",
          },
        ],
        component: EmptyCartCouponAddEdit,
      },
      {
        key: 1,
        path: `coupon-management/auto-coupons/add-coupons/miss-you-coupons`,
        title: "Coupon Management - We Miss You",
        breadCrumbs: [
          {
            label: "Auto Coupons",
            path: "coupon-management/auto-coupons",
          },
          {
            label: "We Miss You",
            path: "#",
          },
        ],
        component: MissYouCouponAddEdit,
      },
      {
        key: 1,
        path: `coupon-management/auto-coupons/edit-coupon/:id`,
        breadCrumbs: [
          {
            label: "Auto Coupons",
            path: "coupon-management/auto-coupons",
          },
          {
            label: "Edit Auto Coupons",
            path: "#",
          },
        ],
        title: "Coupon Management - Edit Auto Coupons",
        component: WelcomeCouponAddEdit,
      },
    ],
  },
  {
    key: 12,
    path: "vendor-reset-password/:token",
    label: "Reset Password",
    icon: vendor,
    iconActive: vendorActive,
    privetRoute: false,
    isSubRoute: false,
    component: VendorResetPassword,
  },

  // {
  //   key: 13,
  //   path: "email-management",
  //   title: "Email Management",
  //   label: "Email Management",
  //   icon: email,
  //   iconActive: emailActive,
  //   privetRoute: true,
  //   component: EmailManagement,
  //   permission: "can_view_userrolemanagement",
  //   isSubRoute: true,
  //   subRoute: [
  //     {
  //       key: 1,
  //       path: `email-management`,
  //       title: "Email Management",
  //       component: EmailManagementMainPage,
  //     },
  //     {
  //       key: 2,
  //       title: "Sales Details",
  //       breadCrumbs: [
  //         {
  //           label: "Email Management",
  //           path: "email-management",
  //         },
  //         {
  //           label: "Sales Template",
  //           path: "#",
  //         },
  //       ],
  //       path: `email-management/sales-template`,
  //       component: SalesTemplateAddEdit,
  //     },
  //   ],
  // },
  {
    key: 13,
    path: "tickets",
    label: "Tickets",
    icon: tickets,
    iconActive: tickets,
    privetRoute: true,
    component: TicketPage,
    permission: "can_view_tickets",
    isSubRoute: true,
    subRoute: [
      {
        key: 1,
        path: `tickets`,
        title: "Customer Support",
        component: TicketMainPage,
      },
      {
        key: 2,
        title: "Customer Support ",
        breadCrumbs: [
          {
            label: "Customer Support",
            path: "tickets",
          },
          {
            label: "Ticket View",
            path: "#",
          },
        ],
        path: `tickets/chat/:id`,
        component: TicketChatPage,
      },
    ],
  },
  {
    key: 13,
    path: "reviews",
    label: "Manage Reviews",
    icon: review,
    iconActive: review,
    privetRoute: true,
    component: ReviewPage,
    permission: "view_review_management",
    isSubRoute: true,
    subRoute: [
      {
        key: 1,
        path: `reviews`,
        title: "Manage Reviews",
        component: ReviewMainPage,
      },
      
    ],
  },
  {
    key: 14,
    path: "art-in-motion",
    label: "Art in Motion",
    icon: art_motion,
    iconActive: art_motion,
    privetRoute: true,
    component: ArtMotionPage,
    permission: "can_view_product",
    isSubRoute: true,
    subRoute: [
      {
        key: 1,
        path: `art-in-motion`,
        title: "Art In Motion",
        component: ArtMotionMainPage,
      },
      {
        key: 2,
        title: "Art in Motion - Add Product",
        breadCrumbs: [
          {
            label: "Products",
            path: "art-in-motion",
          },
          {
            label: "Add Product",
            path: "#",
          },
        ],
        path: `art-in-motion/add-product`,
        component: AddEditProductPageArt,
      },
      {
        key: 3,
        title: "Art in Motion - Edit Product",
        breadCrumbs: [
          {
            label: "Products",
            path: "art-in-motion",
          },
          {
            label: "Edit Product",
            path: "#",
          },
        ],
        path: `art-in-motion/edit-product/:id`,
        component: AddEditProductPageArt,
      },
      {
        key: 4,
        path: `art-in-motion/order-detials/:id`,
        title: "Order Details",
        breadCrumbs: [
          {
            label: "Order Management",
            path: "art-in-motion",
          },
          {
            label: "Order Details",
            path: "#",
          },
          
        ],
        component: OrderDetails,
      },
    ],
  },
];
