import React, { useEffect, useState } from "react";
import { Drawer, Tabs, Skeleton } from "antd";
import { getTimeDifference } from "../../../utilities/dateFunctions/dateFunctions";
import { useGetNotificationList, useReadAllNotification, useReadNotification } from "../../../servicesQuery/notificationQuery/notificationQuery";
import Button from "../../../components/submitButton/Button";
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { useNavigate } from "react-router-dom";

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
    }}
    spin
  />
);

const NotificationDrawer = ({ open, setOpen }) => {
  const navigate = useNavigate()
  const [tab, setTab] = useState("all");
  const [page, setPage] = useState(1)
  const [notificationData, setNotificationData] = useState([])
  const { data, isLoading, isFetching, isError } = useGetNotificationList({
    page: page,
    pageSize: 10,
    tab
  });

  const { mutateAsync: readNotification, isLoading: publishLoading } = useReadNotification();
  const { mutateAsync: readAllNotification, isLoading: markAllLoading } = useReadAllNotification();

  useEffect(()=> {
    if(data?.status === 200){
      if(page === 1){
        setNotificationData(data?.data?.results)
      }else {
        const mergedData = notificationData.concat(data?.data?.results);
        setNotificationData(mergedData)
      }
      
    }
  },[data])

  useEffect(() => {
    if (open) {
      document.querySelector("body").style.overflow = "hidden";
    } else {
      document.querySelector("body").style.overflowY = "scroll";
    }
  }, [open]);
  
  const onChange = (key) => {
    setTab(key);
    setPage(1)
    // console.log(key);
  };

  const handleNavigate = (i)=> {
    console.log(i);
    setOpen(false)
    if(i?.message_for === "order success"){
      navigate('/order-management')
    }else if (i?.message_for === "token create"){
      navigate('/tickets')
    }
    if(i.is_read === false){
      readNotification(i)
    }
  }

  const handleMarkAll = () => {
    readAllNotification()
  }

  const items = [
    {
      key: "all",
      label: "All",
    },
    {
      key: "unread",
      label: "Unread",
    },
    {
      key: "read",
      label: "Read",
    },
  ];

  // const data = [
  //   {
  //     profile_pic: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg",
  //     userid: "USER1234",
  //     username: "Jane Cooper",
  //     msg: "Raise an issue",
  //     date: "2023-09-15T14:30:00",
  //     type: "ticket",
  //     is_read: true
  //   },
  //   {
  //     msg: "A Order has been placed ",
  //     orderId: "O12346",
  //     date: "2023-09-15T09:20:00",
  //     type: "order",
  //     is_read: false
  //   },
  //   {
  //     msg: "A Order has been placed ",
  //     orderId: "O12348",
  //     date: "2023-09-15T07:20:00",
  //     type: "order",
  //     is_read: true
  //   },
  //   {
  //     profile_pic: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg",
  //     userid: "USER1234",
  //     username: "Jane Cooper",
  //     msg: "Raise an issue",
  //     date: "2023-09-15T14:30:00",
  //     type: "ticket",
  //     is_read: false
  //   },
  //   {
  //     msg: "A Order has been placed ",
  //     orderId: "O12348",
  //     date: "2023-09-15T07:20:00",
  //     type: "order",
  //     is_read: true
  //   },
  //   {
  //     msg: "A Order has been placed ",
  //     orderId: "O12348",
  //     date: "2023-09-15T07:20:00",
  //     type: "order",
  //     is_read: true
  //   },
  //   {
  //     profile_pic: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg",
  //     userid: "USER1234",
  //     username: "Jane Cooper",
  //     msg: "Raise an issue",
  //     date: "2023-09-15T14:30:00",
  //     type: "ticket",
  //     is_read: false
  //   },
  //   {
  //     profile_pic: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg",
  //     userid: "USER1234",
  //     username: "Jane Cooper",
  //     msg: "Raise an issue",
  //     date: "2023-09-15T14:30:00",
  //     type: "ticket",
  //     is_read: false
  //   },
  //   {
  //     profile_pic: "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg",
  //     userid: "USER1234",
  //     username: "Jane Cooper",
  //     msg: "Raise an issue",
  //     date: "2023-09-15T14:30:00",
  //     type: "ticket",
  //     is_read: false
  //   },
  //   {
  //     msg: "A Order has been placed ",
  //     orderId: "O12348",
  //     date: "2023-09-15T07:20:00",
  //     type: "order",
  //     is_read: true
  //   },
  //   {
  //     msg: "A Order has been placed ",
  //     orderId: "O12348",
  //     date: "2023-09-15T07:20:00",
  //     type: "order",
  //     is_read: true
  //   },
  // ]


  return (
    <>
      <Drawer
        title="Notifications"
        placement="right"
        onClose={() => setOpen(false)}
        open={open}
        headerStyle={{ color: "red" }}
        className='notification-drawer'
        contentWrapperStyle={{ width: '500px' }}
      >
        <div className="flex flex-col h-full">
          <Tabs defaultActiveKey="all" items={items} onChange={onChange} />
          <div className="flex-1 overflow-y-scroll catogery-scroll">
            {isLoading ? (
              <div className="py-2 px-4">
                {Array(8)
                  .fill()
                  .map((_, index) => (
                    <Skeleton
                      className="mb-5"
                      title={false}
                      loading={isLoading}
                      round
                      active
                      avatar
                      paragraph={{
                        rows: 2,
                      }}
                    />
                  ))}
              </div>
            ) : (
              notificationData
                ?.filter(
                  (f) =>
                    (tab === "unread" && f?.is_read === false) ||
                    (tab === "read" && f?.is_read === true) ||
                    tab === "all"
                )
                ?.map(
                  (i) => (
                    <div
                      onClick={()=> handleNavigate(i)}
                      className={`cursor-pointer flex justify-between py-4 px-6 ${
                        i.is_read
                          ? "bg-white hover:bg-[#F5F5F5]"
                          : "bg-[#F5F5F5] border-b border-[#ECECEC] hover:bg-white"
                      }`}
                    >
                      <div className="w-[8%]">
                        {i?.message_for === "order success" ? (
                          <svg
                            className="ml-1"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M3.16992 7.44L11.9999 12.55L20.7699 7.47"
                              stroke="#07415C"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M12 21.61V12.54"
                              stroke="#07415C"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.92989 2.47998L4.58989 5.43998C3.37989 6.10998 2.38989 7.78998 2.38989 9.16998V14.82C2.38989 16.2 3.37989 17.88 4.58989 18.55L9.92989 21.52C11.0699 22.15 12.9399 22.15 14.0799 21.52L19.4199 18.55C20.6299 17.88 21.6199 16.2 21.6199 14.82V9.16998C21.6199 7.78998 20.6299 6.10998 19.4199 5.43998L14.0799 2.46998C12.9299 1.83998 11.0699 1.83998 9.92989 2.47998Z"
                              stroke="#07415C"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        ) : (
                          <div className="w-[30px] h-[30px] rounded-[30px]">
                            <img
                              src={require("../../../assets/images/commonImages/avatar.png")}
                              className="w-full h-full rounded-[50px]"
                            />
                          </div>
                        )}
                      </div>
                      <div className="w-[65%]">
                        <div className="flex items-center gap-3">
                          <span className="text-[#0B4058] text-[14px] font-medium">
                            {i?.message}
                          </span>
                          {/* <span className="text-[#07415C] text-[14px] font-bold">{i?.username}</span> */}
                        </div>

                        <p className="text-[#454545] text-[14px] font-normal">
                          {i?.message_for}
                        </p>
                      </div>
                      <div className="w-[20%]">
                        <span className="text-[#a69e9e] text-[13px] font-normal">
                          {getTimeDifference(i?.created_at)}
                        </span>
                      </div>
                    </div>
                  )
                  //   i.message_for === "token create"?
                  //   <div className={`cursor-pointer flex justify-between py-4 px-6 ${i.is_read ? "bg-white hover:bg-[#F5F5F5]": "bg-[#F5F5F5] border-b border-[#ECECEC] hover:bg-white"}`}>
                  //     <div className="w-[8%]">
                  //         <div className="w-[30px] h-[30px] rounded-[30px]">
                  //             <img src={i?.profile_pic} className="w-full h-full rounded-[50px]" />
                  //         </div>
                  //     </div>
                  //     <div className="w-[65%]">
                  //       <div className="flex items-center gap-3">
                  //         <span className="text-[#0B4058] text-[14px] font-medium">{i?.message}</span>
                  //         {/* <span className="text-[#07415C] text-[14px] font-bold">{i?.username}</span> */}
                  //       </div>

                  //       <p className="text-[#454545] text-[14px] font-normal">{i?.message_for}</p>
                  //     </div>
                  //     <div className="w-[20%]">
                  //       <span className="text-[#CBCACA] text-[13px] font-normal">{getTimeDifference(i?.created_at)}</span>
                  //     </div>
                  //   </div> :
                  //   <div className={`cursor-pointer flex justify-between py-4 px-6 ${i.is_read ? "bg-white hover:bg-[#F5F5F5]": "bg-[#F5F5F5] border-b border-[#ECECEC] hover:bg-white"}`}>
                  //     <div className="w-[8%]">
                  //           <svg className="ml-1" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  //               <path d="M3.16992 7.44L11.9999 12.55L20.7699 7.47" stroke="#07415C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  //               <path d="M12 21.61V12.54" stroke="#07415C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  //               <path d="M9.92989 2.47998L4.58989 5.43998C3.37989 6.10998 2.38989 7.78998 2.38989 9.16998V14.82C2.38989 16.2 3.37989 17.88 4.58989 18.55L9.92989 21.52C11.0699 22.15 12.9399 22.15 14.0799 21.52L19.4199 18.55C20.6299 17.88 21.6199 16.2 21.6199 14.82V9.16998C21.6199 7.78998 20.6299 6.10998 19.4199 5.43998L14.0799 2.46998C12.9299 1.83998 11.0699 1.83998 9.92989 2.47998Z" stroke="#07415C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  //             </svg>
                  //     </div>
                  //   <div className="w-[65%]">
                  //     <div className="flex items-center gap-3">
                  //       <span className="text-[#0B4058] text-[14px] font-medium">{i?.msg}</span>
                  //     </div>

                  //     <p className="text-[#454545] text-[14px] font-normal">Order ID: {i?.orderId}</p>
                  //   </div>
                  //   <div className="w-[20%]">
                  //     <span className="text-[#CBCACA] text-[13px] font-normal">{getTimeDifference(i?.date)}</span>
                  //   </div>
                  // </div>
                )
            )}

            {isFetching && !isLoading ?
            <div className="p-4 flex items-center justify-center">
              <Spin indicator={antIcon} />
            </div>
            : null}
            
            {notificationData?.length < data?.data?.count ? 
              <div className="w-full flex items-center justify-center gap-5 mt-5">
                <Button
                  style="text-xs w-1/2 md:w-[10rem]  hover:shadow-lg active:shadow-sm  transition-all active:scale-95 py-2 px-6  px-10 py-1 text-sm text-white add-live-btn bg-btn-gradient rounded-2xl"
                  content="Load More"
                  type="butten"
                  onClick={() => setPage(page + 1)}
                  disabled={isLoading}
                />
                {data?.data?.unread ?
                  <Button
                    style="text-xs w-1/2 md:w-[10rem]  hover:shadow-lg active:shadow-sm  transition-all active:scale-95 py-2 px-6  px-10 py-1 text-sm text-white add-live-btn bg-btn-gradient rounded-2xl"
                    content="Mark all as Read"
                    type="butten"
                    onClick={() => handleMarkAll()}
                    disabled={markAllLoading}
                  />
                : null}
                
              </div>
            : null}
            
          </div>
        </div>
      </Drawer>
    </>
  );
};
export default NotificationDrawer;
