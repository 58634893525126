import React, { useContext, useEffect, useState } from "react";
import CustomTable from "../../../components/customTable/CustomTable";
import Pagenation from "../../../components/pagination/Pagenation";
import { Link } from "react-router-dom";
import { Context } from "../../../utilities/context/Context";
import Button from "../../../components/submitButton/Button";
import { useGetSubCategoryList } from "../../../servicesQuery/categoryQuery/categoryQuery";
import AddCategoryModal from "./AddCategoryModal";

const SubCategory = () => {
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState({
    label: "10",
    value: 10,
  });
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [inviteOpen,setInviteOpen] = useState(false)
  const [subcat,setSubCat] =useState('')
  const {value,setValue}=useContext(Context)
  const { data, isLoading, isFetching,isError } = useGetSubCategoryList({
    page: pageCount,
    search:value,
    pageSize: pageSize?.value,
  });

  useEffect(() => {
    document.querySelector("body").style.overflowY = "scroll";
  }, [open,editOpen]);

  const head = [
    {
      key: 0,
      label: "SlNo",
      index: "id",
      width: 6,
    },
    {
      key: 1,
      label: "Category Name",
      index: "name",
    //   width: 10,
    },
    {
      key: 2,
      label: "Image",
      index: "image",
    //   width: 10,
    },
    {
      key: 3,
      label: "Status",
      index: "status",
    //   width: 10,
    },
    {
      key: 18,
      label: "Action",
      index: "action",
    //   width: 7,
    },
  ];
  const subCattBody = () => {
    return data?.data?.map((item,index) => {
      return {
        id: {
          isRender:true,
          render:()=>{
            return (
              <div>
                <span>{index+1}</span>
              </div>
            )
          }
        },
        name: item.name || "No data",
        image: {
            isRender: true,
            render: ()=> (
                <div className="w-16 h-16 overflow-hidden rounded-lg">
                    <img src={item.image} className="w-full h-full" />
                </div>
            )
        },
        status: {
            isRender: true,
            render: ()=> (<span className={`${item.is_active? "text-[#79B364] font-bold" : "" }`}>{item.is_active? "Active": "Inactive"}</span>)
        },
        action: {
          isRender: true,
          render: () => (
            <div className="flex  items-center w-full gap-4 h-full">
              <div className=" p-2 rounded-lg hover:bg-lightBlue transition-all">
              <button onClick={()=>{
                setSubCat(item)
                setEditOpen(true)
                }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M17.5 18.3334H2.5C2.15833 18.3334 1.875 18.05 1.875 17.7084C1.875 17.3667 2.15833 17.0834 2.5 17.0834H17.5C17.8417 17.0834 18.125 17.3667 18.125 17.7084C18.125 18.05 17.8417 18.3334 17.5 18.3334Z"
                    fill="#F19A0E"
                  />
                  <path
                    opacity="0.4"
                    d="M15.8505 2.90005C14.2338 1.28338 12.6505 1.24172 10.9921 2.90005L9.9838 3.90838C9.90047 3.99172 9.86714 4.12505 9.90047 4.24172C10.5338 6.45005 12.3005 8.21672 14.5088 8.85005C14.5421 8.85838 14.5755 8.86672 14.6088 8.86672C14.7005 8.86672 14.7838 8.83338 14.8505 8.76672L15.8505 7.75838C16.6755 6.94172 17.0755 6.15005 17.0755 5.35005C17.0838 4.52505 16.6838 3.72505 15.8505 2.90005Z"
                    fill="#F19A0E"
                  />
                  <path
                    d="M13.0079 9.60829C12.7663 9.49163 12.5329 9.37496 12.3079 9.24163C12.1246 9.13329 11.9496 9.01663 11.7746 8.89163C11.6329 8.79996 11.4663 8.66663 11.3079 8.53329C11.2913 8.52496 11.2329 8.47496 11.1663 8.40829C10.8913 8.17496 10.5829 7.87496 10.3079 7.54163C10.2829 7.52496 10.2413 7.46663 10.1829 7.39163C10.0996 7.29163 9.95795 7.12496 9.83295 6.93329C9.73295 6.80829 9.61628 6.62496 9.50795 6.44163C9.37461 6.21663 9.25795 5.99163 9.14128 5.75829C9.02461 5.50829 8.93295 5.26663 8.84961 5.04163L3.61628 10.275C3.50795 10.3833 3.40795 10.5916 3.38295 10.7333L2.93295 13.925C2.84961 14.4916 3.00795 15.025 3.35795 15.3833C3.65795 15.675 4.07461 15.8333 4.52461 15.8333C4.62461 15.8333 4.72461 15.825 4.82461 15.8083L8.02461 15.3583C8.17461 15.3333 8.38295 15.2333 8.48295 15.125L13.7163 9.89163C13.4829 9.80829 13.2579 9.71663 13.0079 9.60829Z"
                    fill="#F19A0E"
                  />
                </svg>
              </button>
              </div>
              
              {/* <div className="p-2 rounded-lg hover:bg-lightBlue transition-all">
              <Link to={`product-detials/${item.id}`}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 9 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.999973 16.92L7.51997 10.4C8.28997 9.62996 8.28997 8.36996 7.51997 7.59996L0.999973 1.07996"
                    stroke="#07415C"
                    strokeMiterlimit="10"
                    strokelinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
              </div> */}
            </div>
          ),
        },
      };
    });
  };

  return (
    <div >
      <div className="w-full  bg-white rounded-xl shadow-lg p-6 ">
        <div className="h-full">
        <div className="mb-8">
          <div className="flex gap-6 justify-between">
            <div className="flex gap-6 w-[10rem]">
             
            </div>

            <Button
              onClick={() => setOpen(true)}
              style="text-xs w-[10rem] hover:shadow-md active:shadow-sm transition-all active:scale-95  border border-2 border-darkYellow  text-darkBlue   rounded-md py-2 px-6"
              content="Add Sub Category"
            />
          </div>
        </div>
          <div className="w-full h-[95%]">
            <CustomTable
              head={head}
              body={subCattBody()}
              loading={isLoading || isFetching}
              dataLength={data?.data.length}
              height="max-h-[55vh]"
              error={isError}
            />
          </div>
        </div>
      </div>
      {/* <div className="w-full flex justify-center my-6">
        <Pagenation
          pageCount={setPageCount}
          loading={isLoading || isFetching}
          data={data?.data?.results}
          next={data?.data?.next}
          currDataCount={data?.data?.data_count}
          totalLength={data?.data?.count}
          previous={data?.data?.previous}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </div> */}
      {open && <AddCategoryModal open={open} setOpen={setOpen} />}
      {(editOpen && subcat.id) && <AddCategoryModal open={editOpen} setOpen={setEditOpen} isEdit={true} item={subcat} />}
    </div>
  );
};

export default SubCategory;
