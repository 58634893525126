import React from 'react'


const KycDetials = ({data}) => {

  return (
    <div className='w-full min-h-[15rem] '>
        <div className='my-6'>
            <h1 className='text-lg font-bold text-darkBlue'>Kyc Document</h1>
        </div>

        <div className='w-full bg-lightYellow p-4 flex items-center justify-between rounded-lg '>
        <h1 className='text-base '>{data?.aadhar_verified && 'Aadhar card'}</h1>{data?.kyc_completed ? <p className='text-base text-status font-bold'>Verified</p>:<p className='text-base text-darkBlue font-bold'>Pending</p>}
        </div>
        
    

    </div>
  )
}

export default KycDetials