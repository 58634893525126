import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import "./customTableStyle.scss";
import "react-loading-skeleton/dist/skeleton.css";
import uuid from "react-uuid";
import noData from "../../assets/images/nodata.json";
import errorData from "../../assets/images/error.json";

import Lottie from "react-lottie-player";


const CustomTable = ({ head, body, loading, height, dataLength, error,is_dynamic}) => {
  const [body1,setBody] = useState(body)
  const [head1,setHead] = useState(head)
  const [index, setIndex] = useState("");

  useEffect(() => {
    setIndex(
      head1?.map((item) => {
        return item.index;
      })
    );
  }, []);

  useEffect(()=> {
    if(is_dynamic&& body?.length){
      const updatedBody = body?.map((data) => {
        const updatedData = { ...data };
      
        head.forEach((header) => {
          const { index, is_checked } = header;
          if (!is_checked) {
            delete updatedData[index];
          }
        });
      
        return updatedData;
      });
      setBody(updatedBody)
    }else {
      setBody(body)
    }
    if(is_dynamic && head?.length){
      let updatedHead = head?.filter((f)=> f.is_checked === true)
      setHead(updatedHead)
    }else {
      setHead(head)
    }
  },[body,head])

  
  return (
    <div className={`overflow-scroll  custom-table flex min-h-[10rem] `}>
      <table className=" table w-full relative overflow-scroll rounded-xl border-collapse">
        <thead
          className={`	
          ${
            loading
              ? "flex justify-between items-center bg-lightYellow rounded-3xl"
              : "table-header-group	"
          }
          flex justify-between bg-lightYellow rounded-3xl
          transition-all `}
        >
          <tr>
            {head1?.map((items) => {
              if (items.customRender) {
                return (
                  <th
                    key={uuid()}
                    className={`text-[12px] font-semibold text-secondary bg-lightYellow head-items 
                   text-start px-[15px] py-[16px]`}
                    style={{ width: items.width }}
                  >
                    <span>{items.label}</span>
                    {<items.render />}
                  </th>
                );
              } else {
                return (
                  <th
                    key={uuid()}
                    className={`text-[12px] font-semibold text-secondary bg-lightYellow head-items 
                    text-start  px-[15px] py-[16px]`}
                    style={{ minWidth: items.width + "rem" }}
                  >
                    {items.label}
                  </th>
                );
              }
            })}
          </tr>
        </thead>

        {dataLength === 0 && !loading && (
          <tbody
            className={` sticky min-h-[30rem] top-[10rem] left-[50%] translate-x-[-50%] w-full   justify-center	flex flex-col items-center`}
          >
            <tr>
              <td className="text-center flex flex-col items-center">
                {/* <svg
                  version="1.0"
                  xmlns="http://www.w3.org/2000/svg"
                  width="50px"
                  height="50px"
                  viewBox="0 0 96.000000 96.000000"
                  preserveAspectRatio="xMidYMid meet"
                >
                  <g
                    transform="translate(0.000000,96.000000) scale(0.100000,-0.100000)"
                    fill="#9B9B9B"
                    stroke="none"
                  >
                    <path
                      d="M432 880 c-12 -12 -22 -25 -22 -30 0 -6 -54 -10 -130 -10 l-130 0 0
-375 0 -375 191 0 191 0 39 -33 c96 -83 221 -76 314 18 70 70 91 146 63 236
-13 46 -68 113 -109 135 l-29 15 0 189 0 190 -123 0 -122 0 -28 30 c-34 36
-73 39 -105 10z m76 -72 c3 -26 7 -28 48 -28 42 0 44 -1 44 -30 l0 -30 -120 0
-120 0 0 30 c0 29 2 30 45 30 40 0 45 2 45 23 0 30 9 39 35 35 13 -2 21 -12
23 -30z m-208 -88 l0 -60 180 0 180 0 0 60 0 60 45 0 45 0 0 -150 0 -150 -31
0 c-62 0 -113 -23 -164 -75 -68 -67 -93 -156 -65 -229 l10 -26 -145 0 -145 0
0 315 0 315 45 0 45 0 0 -60z m500 -322 c132 -67 132 -249 0 -316 -24 -12 -60
-22 -80 -22 -20 0 -56 10 -80 22 -132 67 -132 249 0 316 24 12 60 22 80 22 20
0 56 -10 80 -22z"
                    />
                    <path d="M300 540 l0 -30 180 0 180 0 0 30 0 30 -180 0 -180 0 0 -30z" />
                    <path d="M300 420 l0 -30 90 0 90 0 0 30 0 30 -90 0 -90 0 0 -30z" />
                    <path
                      d="M625 330 c-18 -20 -18 -21 15 -55 l34 -35 -34 -35 -34 -35 22 -22 22
-22 35 34 35 34 35 -34 35 -34 22 22 22 22 -34 35 -34 35 34 35 34 35 -22 22
-22 22 -35 -34 -35 -34 -33 32 c-18 18 -35 32 -38 32 -3 0 -14 -9 -24 -20z"
                    />
                  </g>
                </svg>
                <p className="text-sm font-semibold text-darkBlue">
                  No data found
                </p> */}

                <div>
                  <Lottie
                    animationData={noData}
                    play
                    style={{ width: 200, height: 200 }}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        )}

        {error && !loading && (
          <tbody
            className={` sticky min-h-[30rem] top-[10rem] left-[50%] translate-x-[-50%] w-full   justify-center	flex flex-col items-center`}
          >
            <tr className="w-full">
              <td className="text-center flex flex-col items-center">
                <div>
                  <Lottie
                    animationData={errorData}
                    play
                    style={{ width: 150, height: 150 }}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        )}

        {loading ? (
          <tbody className="	w-full">
            {[1, 2, 3, 4, 5, 6, 7, 8, 9]?.map(() => (
              <tr key={uuid()}>
                <td>
                  <Skeleton
                    height={35}
                    count={1}
                    width={100}
                    className="w-full   my-[9px]"
                    containerClassName=" flex w-full 	"
                  />
                </td>
              </tr>
            ))}
          </tbody>
        ) : (
          <tbody className="table-row-group	 rounded-xl	overflow-scroll">
            {index?.length > 0 &&
              body1?.map((item) => {
                return (
                  <tr
                    key={uuid()}
                    className="border-b-[.5px] border-lightBlue max-w-[15rem] align-middle  hover:bg-[#eeeeee70] transition-all "
                  >
                    {index?.map((data) => {
                      if (item?.[data]?.isRender) {
                        return (
                          <td
                            key={uuid()}
                            className=" px-[15px] py-[16px]	text-[12px] table-item  align-middle	"
                          >
                            {item[`${data}`]?.render()}
                          </td>
                        );
                      } else if(item[data]){
                        return (
                          <td
                            title={item[data]}
                            key={uuid()}
                            className=" px-[15px] py-[16px] max-w-[15rem] truncate align-middle text-[12px] table-item 		"
                          >
                            <div>{item[data]}</div>
                          </td>
                        );
                      }
                    })}
                  </tr>
                );
              })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default CustomTable;
