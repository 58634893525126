import React, { useContext, useEffect, useState } from "react";
import "./masterLayoutConponentStyle.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Context } from "../../../utilities/context/Context";
import { debounce } from "lodash";
import { routes } from "../../../routes/routes";
import uuid from "react-uuid";
import NotificationDrawer from "./NotificationDrawer";
import { Badge } from 'antd'
import SettingsPopup from "./SettingsPopup";

const MasterHeader = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { is_unread,setUnRead } = useContext(Context);
  const [isFixed, setIsFixed] = useState(
    window.matchMedia("(max-width: 890px)").matches
  );
  useEffect(() => {
    window
      .matchMedia("(max-width: 890px)")
      .addEventListener("change", (e) => setIsFixed(e.matches));
  }, [isFixed]);

  useEffect(() => {
    document.querySelector("body").style.overflowY = "scroll";
  }, [open]);

  useEffect(() => {
    const input = document.querySelector(".search-input");
    if (input) {
      input.value = "";
    }
  }, [window.location.pathname]);

  const [searchActive, setSearchActive] = useState(false);
  const pathname = useLocation().pathname.split("/");
  const { setValue } = useContext(Context);

  const searchVisible = () => {
    if (pathname?.length <= 3) {
      if (pathname.includes("user-management")) {
        return true;
      }
      if (pathname.includes("order-management")) {
        return true;
      }
      if (pathname.includes("product-management")) {
        return true;
      }
      if (pathname.includes("vendor-management")) {
        return true;
      }
      if (pathname.includes("tickets")) {
        return true;
      }
      if (pathname.includes("reviews")) {
        return true;
      }
    }
  };
  const heading = () => {
    const filteredRouteData = routes
      .map((route) => {
        if (route.isSubMenu) {
          return route.subMenu.map((e) => e.subRoute);
        } else if (!route.isSubMenu) {
          return route.subRoute;
        } else {
          return route;
        }
      })
      .flat(2)
      .filter((e) => e !== undefined);

    let dataHead;
    let breadCurmbs;
    for (let x of filteredRouteData) {
      if (pathname.join("/").slice(1) === (x.path||x.path+'/')) {
        dataHead = x.title;
        breadCurmbs = x.breadCrumbs;
        break;
      } else if (
        pathname.slice(1, -1).join("/") ===
      (  x.path.split("/").slice(0, -1).join("/") || x.path.split("/").slice(0, -1).join("/") + '/')
      ) {
        dataHead = x.title;
        breadCurmbs = x.breadCrumbs;
      }
    }
    if(pathname[1] === "terms-and-conditions"){
      dataHead = "Config"
    }
    return { dataHead, breadCurmbs };
  };
  return (
    <div className="w-full mb-16 hxl:mb-15">
      <div className="flex items-center justify-between w-full">
        <div className="w-full">
          <div className="flex items-center gap-1">
            <div
              onClick={() => navigate(-1)}
              className="w-[35px] h-[25px] transition-all cursor-pointer px-2 py-1 hover:border border-[#07415c00]   hover:border-[#07415C]   rounded-lg"
            >
              <img
                src={require("../../../assets/images/commonImages/backArrow.png")}
                className="w-full h-full"
              />
            </div>
            <h1
              className={` ${
                isFixed ? " text-xs md:text-base" : "text-xl"
              } text-darkBlue font-bold`}
            >
              {heading().dataHead}
            </h1>
          </div>

          <div className="flex gap-2 items-center">
            {heading().breadCurmbs?.map((item, index) => {
              return (
                <Link
                  to={item.path}
                  state={{ fromPage: pathname }}
                  className="flex items-center gap-4 mt-3 w-auto "
                  key={uuid()}
                >
                  <h1 className="text-xs md:text-sm text-darkBlue hover:opacity-100 opacity-70 transition-all active:opacity-75">
                    {item?.label}
                  </h1>{" "}
                  {index !== heading().breadCurmbs?.length - 1 && (
                    <div>
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <circle
                          cx="4"
                          cy="4"
                          r="4"
                          fill="#07415C"
                          fillOpacity="0.6"
                        />
                      </svg>
                    </div>
                  )}
                </Link>
              );
            })}
          </div>
        </div>
        <div>
          <div className="flex gap-3 items-center">
            {searchVisible() &&
              (!isFixed ? (
                <div className="flex border-2 items-center border-tertiary focus-within:border-[#a59f9f] transition-all rounded-lg px-2 py-1 w-[350px] h-[40px]">
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                      fill="#07415C"
                    />
                    <path
                      d="M21.3001 22.0001C21.1201 22.0001 20.9401 21.9301 20.8101 21.8001L18.9501 19.9401C18.6801 19.6701 18.6801 19.2301 18.9501 18.9501C19.2201 18.6801 19.6601 18.6801 19.9401 18.9501L21.8001 20.8101C22.0701 21.0801 22.0701 21.5201 21.8001 21.8001C21.6601 21.9301 21.4801 22.0001 21.3001 22.0001Z"
                      fill="#07415C"
                    />
                  </svg>
                  <input
                    className="search-input ml-2 w-full "
                    type="text"
                    placeholder="Search"
                    name="search"
                    onChange={debounce((e) => {
                      setValue(e.target.value);
                    }, 500)}
                  />
                </div>
              ) : (
                <div>
                  <button
                    className="h-[40px]  rounded-lg border-2 border-tertiary p-2"
                    onClick={() => setSearchActive(!searchActive)}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                        fill="#07415C"
                      />
                      <path
                        d="M21.3001 22.0001C21.1201 22.0001 20.9401 21.9301 20.8101 21.8001L18.9501 19.9401C18.6801 19.6701 18.6801 19.2301 18.9501 18.9501C19.2201 18.6801 19.6601 18.6801 19.9401 18.9501L21.8001 20.8101C22.0701 21.0801 22.0701 21.5201 21.8001 21.8001C21.6601 21.9301 21.4801 22.0001 21.3001 22.0001Z"
                        fill="#07415C"
                      />
                    </svg>
                  </button>

                  {searchActive && searchVisible() && (
                    <div className="fixed  right-0 left-0 z-[999] w-full mt-4   ">
                      <div className="px-6">
                        <div className=" border-2 w-full  border-tertiary bg-white rounded-lg px-2 py-1 w-[350px] h-[40px]">
                          <input
                            className="search-input ml-2 w-full"
                            type="text"
                            placeholder="Search"
                            name="search"
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}

          <div className="flex items-center gap-3">
            <SettingsPopup />
          </div>

            <div className="flex items-center gap-3">
            <Badge dot={is_unread} size="large" offset={[-15, 13]}>
              <button className="rounded-lg border-2 border-tertiary p-2" onClick={()=> setOpen (!open)}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 40 40"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M32.2333 24.1499L30.5667 21.3833C30.2167 20.7666 29.9 19.5999 29.9 18.9166V14.6999C29.9 9.26658 25.4833 4.83325 20.0333 4.83325C14.5833 4.83325 10.1667 9.26658 10.1667 14.6999V18.9166C10.1667 19.5999 9.84999 20.7666 9.49999 21.3666L7.81665 24.1499C7.14999 25.2666 6.99999 26.4999 7.41665 27.6333C7.81665 28.7499 8.76665 29.6166 9.99999 30.0333C13.2333 31.1333 16.6333 31.6666 20.0333 31.6666C23.4333 31.6666 26.8333 31.1333 30.0667 30.0499C31.2333 29.6666 32.1333 28.7833 32.5667 27.6333C33 26.4833 32.8833 25.2166 32.2333 24.1499Z"
                    fill="#07415C"
                    fillOpacity="0.7"
                  />
                  <path
                    d="M23.75 5.53325C22.6 5.08325 21.35 4.83325 20.0334 4.83325C18.7334 4.83325 17.4834 5.06659 16.3334 5.53325C17.05 4.18325 18.4667 3.33325 20.0334 3.33325C21.6167 3.33325 23.0167 4.18325 23.75 5.53325Z"
                    fill="#07415C"
                  />
                  <path
                    d="M24.7167 33.3499C24.0167 35.2833 22.1667 36.6666 20 36.6666C18.6834 36.6666 17.3834 36.1333 16.4667 35.1833C15.9334 34.6833 15.5334 34.0166 15.3 33.3333C15.5167 33.3666 15.7334 33.3833 15.9667 33.4166C16.35 33.4666 16.75 33.5166 17.15 33.5499C18.1 33.6333 19.0667 33.6833 20.0334 33.6833C20.9834 33.6833 21.9334 33.6333 22.8667 33.5499C23.2167 33.5166 23.5667 33.4999 23.9 33.4499C24.1667 33.4166 24.4334 33.3833 24.7167 33.3499Z"
                    fill="#07415C"
                  />
                </svg>
              </button>
            </Badge>
              

              {/* <Link className=" rounded-lg border-2 border-tertiary p-2">
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.7"
                    d="M22.4266 2.66675H9.5733C6.7333 2.66675 4.42664 4.98675 4.42664 7.81341V26.6001C4.42664 29.0001 6.14664 30.0134 8.2533 28.8534L14.76 25.2401C15.4533 24.8534 16.5733 24.8534 17.2533 25.2401L23.76 28.8534C25.8666 30.0267 27.5866 29.0134 27.5866 26.6001V7.81341C27.5733 4.98675 25.2666 2.66675 22.4266 2.66675Z"
                    fill="#07415C"
                  />
                  <path
                    d="M16 13.7067C14.64 13.7067 13.28 13.4667 11.9867 13.0001C11.4667 12.8134 11.2 12.2401 11.3867 11.7201C11.5867 11.2001 12.16 10.9334 12.68 11.1201C14.8267 11.8934 17.1867 11.8934 19.3333 11.1201C19.8533 10.9334 20.4267 11.2001 20.6133 11.7201C20.8 12.2401 20.5333 12.8134 20.0133 13.0001C18.72 13.4667 17.36 13.7067 16 13.7067Z"
                    fill="#07415C"
                  />
                </svg>
              </Link> */}
            </div>
            {open && 
            <NotificationDrawer setOpen={setOpen} open={open} />
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterHeader;
