import React, { useState } from "react";
import "./datePickerStyle.scss";
import DatePicker from "react-datepicker";
import callenderWhite from "../../assets/images/commonImages/calendarWhite.svg";
import calendarGrey from "../../assets/images/commonImages/calendarGrey.svg";
import { useEffect } from "react";
import dayjs from "dayjs";
import toast from "react-hot-toast";
const DateRangePicker = ({ bg, setDate, name }) => {
  const [startDate, setStartDate] = useState(false);
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    if (setDate && startDate && endDate) {
      setDate((prevState) => {
        return {
          ...prevState,
          startDate: dayjs(startDate).format("YYYY-MM-DD"),
          endDate: dayjs(endDate).format("YYYY-MM-DD"),
        };
      });
    }else if(!startDate&&!endDate){
      setDate((prevState) => {
        return {
          ...prevState,
          startDate: "",
          endDate: "",
        };
      });
    }
  }, [startDate, endDate]);

  const isDateDisabled = (date) => {
    let today = new Date()
    let todayDefference = today - date;
    let daysDifferencetdy = todayDefference / (1000 * 60 * 60 * 24);
    if (daysDifferencetdy <= 0) {
      return false;
    }else if (startDate) {
      let timeDifference = date - startDate;
      let daysDifference = timeDifference / (1000 * 60 * 60 * 24);
      console.log(daysDifference);
      if (daysDifference < 0) {
        return false;
      }
    }
    return true;
  };

  return (
    <div className="w-[15rem]">
      {name === "order_graph" ? (
        <div
          className={`flex bg-[#FFFFFF] p-2 rounded-xl justify-around items-center w-full`}
        >
          <div className="w-[45%] text-center">
            <DatePicker
              selected={startDate}
              className={`date-picker text-[#07415C] date-picker-secondary text-xs font-bold w-full`}
              popperPlacement="bottom"
              popperClassName="date-popper"
              // onChange={(date) => setStartDate(date)}
              onChange={(date) => {
                setStartDate(date);
                setEndDate(null); // Reset the end date when changing the start date
              }}
              placeholderText="Start Date"
              closeOnScroll
              // filterDate={(date) => isDateDisabled(date,"start")}
            />
          </div>
          <div className="w-[10%] text-center">
            <img
              src={bg ? calendarGrey : callenderWhite}
              alt="callander white"
            />
          </div>
          <div className="w-[45%] text-center">
            <DatePicker
              selected={endDate}
              className={`date-picker text-[#07415C] date-picker-secondary text-xs font-bold w-full`}
              popperPlacement="bottom-start"
              popperClassName="date-popper"
              onChange={(date) => setEndDate(date)}
              placeholderText="End Date"
              filterDate={(date) => isDateDisabled(date)}
            />
          </div>
        </div>
      ) : (
        <div
          className={`flex ${
            bg ? "bg-[#FFFFFF]" : "bg-darkYellow"
          } p-2 rounded-xl justify-around items-center w-full`}
        >
          <div className="w-[45%] text-center flex items-center">
            <DatePicker
              selected={startDate}
              className={`date-picker ${
                bg ? "text-[#07415C] date-picker-secondary" : "text-white"
              } text-xs font-bold w-full`}
              popperPlacement="bottom"
              popperClassName="date-popper"
              // onChange={(date) => setStartDate(date)}
              onChange={(date) => {
                setStartDate(date);
                setEndDate(null); // Reset the end date when changing the start date
              }}
              placeholderText="Start Date"
              closeOnScroll
            />
            {/* {startDate ? (
              <div className="cursor-pointer" onClick={() => setStartDate("")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 30 30"
                  width="10px"
                  height="10px"
                >
                  <path
                    fill="#07415C"
                    d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"
                  />
                </svg>
              </div>
            ) : null} */}
          </div>
          <div className="w-[10%] text-center relative">
            <img
              src={bg ? calendarGrey : callenderWhite}
              alt="callander white"
            />
            {/* {startDate || endDate ?
            <div className="cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" onClick={() => {setStartDate("");setEndDate("")}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="10px"
                height="10px"
              >
                <path
                  fill="#07415C"
                  d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"
                />
              </svg>
            </div>
            : null} */}
          </div>
          <div className="w-[45%] text-center flex items-center">
            <DatePicker
              selected={endDate}
              className={`date-picker ${
                bg ? "text-[#07415C] date-picker-secondary" : "text-white"
              } text-xs font-bold w-full`}
              popperPlacement="bottom-start"
              popperClassName="date-popper"
              onChange={(date) => setEndDate(date)} 
              placeholderText="End Date"
              filterDate={(date) => isDateDisabled(date,"start")}
            />
            {/* {endDate ? (
              <div className="cursor-pointer" onClick={()=> setEndDate("")}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 30 30"
                  width="10px"
                  height="10px"
                >
                  <path
                    fill="#07415C"
                    d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"
                  />
                </svg>
              </div>
            ) : null} */}
            {startDate || endDate ?
            <div className="cursor-pointer mr-1" onClick={() => {setStartDate("");setEndDate("")}}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 30 30"
                width="10px"
                height="10px"
              >
                <path
                  fill="#07415C"
                  d="M 7 4 C 6.744125 4 6.4879687 4.0974687 6.2929688 4.2929688 L 4.2929688 6.2929688 C 3.9019687 6.6839688 3.9019687 7.3170313 4.2929688 7.7070312 L 11.585938 15 L 4.2929688 22.292969 C 3.9019687 22.683969 3.9019687 23.317031 4.2929688 23.707031 L 6.2929688 25.707031 C 6.6839688 26.098031 7.3170313 26.098031 7.7070312 25.707031 L 15 18.414062 L 22.292969 25.707031 C 22.682969 26.098031 23.317031 26.098031 23.707031 25.707031 L 25.707031 23.707031 C 26.098031 23.316031 26.098031 22.682969 25.707031 22.292969 L 18.414062 15 L 25.707031 7.7070312 C 26.098031 7.3170312 26.098031 6.6829688 25.707031 6.2929688 L 23.707031 4.2929688 C 23.316031 3.9019687 22.682969 3.9019687 22.292969 4.2929688 L 15 11.585938 L 7.7070312 4.2929688 C 7.5115312 4.0974687 7.255875 4 7 4 z"
                />
              </svg>
            </div>
            : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default DateRangePicker;
