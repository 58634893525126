import React, { useState } from 'react'
import { SettingFilled,LoginOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import coupon from "../../../assets/images/commonImages/coupon.svg";
import { useNavigate } from 'react-router-dom';

function SettingsPopup(props) {
    let user_type = JSON.parse(localStorage.getItem('user_type'));
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handlePopover = (val) => {
        setOpen(val)
    }

    const onLogout = () => {
        localStorage.removeItem("Tokens");
        localStorage.removeItem("permissions");
        localStorage.removeItem("dynamicData");
        navigate("/signin");
      };

    const content = (
        <div className='p-3 w-[200px]'>
            {user_type === "Admin" || user_type === "admin"?
            <div className='flex gap-2 cursor-pointer items-center' onClick={()=> navigate('/terms-and-conditions')}>
                <img className="w-[20px]" src={coupon} />
                <p>Config</p>
            </div>
            : null}
          <div className='flex gap-2 cursor-pointer mt-3 items-center' onClick={onLogout}>
            <LoginOutlined className="w-[20px] text-[#07415C]" />
            <p>Logout</p>
          </div>
        </div>
      );
  return (
    <div>
      <Popover placement="bottomRight" content={content} title={false} trigger="click" className='filter' onOpenChange={(e)=> handlePopover(e)} open={open}>
        <div className="rounded-lg border-2 border-tertiary p-[0.6rem] flex items-center justify-center cursor-pointer">
            <SettingFilled style={{color: '#4E778A', width: 16,height: 16 }} />
        </div>
        </Popover>
    </div>
  )
}

export default SettingsPopup
