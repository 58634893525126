import React, { useContext, useEffect, useState } from "react";
import CustomTable from "../../../components/customTable/CustomTable";
import Pagenation from "../../../components/pagination/Pagenation";
import { Link } from "react-router-dom";
import { Context } from "../../../utilities/context/Context";
import Button from "../../../components/submitButton/Button";
import { useGetProductTypeList, useGetSubCategoryList } from "../../../servicesQuery/categoryQuery/categoryQuery";
import AddCategoryModal from "./AddCategoryModal";
import { productTypeLists } from "../../../servicesQuery/categoryQuery/categoryFunctions";
import AddProductTypeModal from "./AddProductTypeModal";
import DeleteModal from "./DeleteModal";

const ProductType = () => {
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState({
    label: "10",
    value: 10,
  });
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [proType,setProType] =useState('')
  const {value,setValue}=useContext(Context)
  const { data, isLoading, isFetching,isError } = useGetProductTypeList({
    page: pageCount,
    search:value,
    pageSize: pageSize?.value
  });

  useEffect(() => {
    document.querySelector("body").style.overflowY = "scroll";
  }, [open,editOpen,deleteOpen]);

  const head = [
    {
      key: 0,
      label: "SlNo",
      index: "id",
      width: 6,
    },
    {
      key: 1,
      label: "Product Types",
      index: "name",
      width: 18,
    },
    // {
    //   key: 3,
    //   label: "Status",
    //   index: "status",
    // //   width: 10,
    // },
    {
      key: 18,
      label: "Action",
      index: "action",
      width: 18,
    },
  ];
  const subCattBody = () => {
    return data?.data?.results?.map((item,index) => {
      return {
        id: {
          isRender:true,
          render:()=>{
            return (
              <div>
                <span>{(pageCount - 1) * pageSize?.value + index+1}</span>
              </div>
            )
          }
        },
        name: item.name || "No data",
        // status: {
        //     isRender: true,
        //     render: ()=> (<span className={`${item.is_active? "text-[#79B364] font-bold" : "" }`}>{item.is_active? "Active": "Inactive"}</span>)
        // },
        action: {
          isRender: true,
          render: () => (
            <div className="flex  items-center w-full gap-4 h-full">
              <div className=" p-2 rounded-lg hover:bg-lightBlue transition-all">
              <button onClick={()=>{
                setProType(item)
                setEditOpen(true)
                }}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    opacity="0.4"
                    d="M17.5 18.3334H2.5C2.15833 18.3334 1.875 18.05 1.875 17.7084C1.875 17.3667 2.15833 17.0834 2.5 17.0834H17.5C17.8417 17.0834 18.125 17.3667 18.125 17.7084C18.125 18.05 17.8417 18.3334 17.5 18.3334Z"
                    fill="#F19A0E"
                  />
                  <path
                    opacity="0.4"
                    d="M15.8505 2.90005C14.2338 1.28338 12.6505 1.24172 10.9921 2.90005L9.9838 3.90838C9.90047 3.99172 9.86714 4.12505 9.90047 4.24172C10.5338 6.45005 12.3005 8.21672 14.5088 8.85005C14.5421 8.85838 14.5755 8.86672 14.6088 8.86672C14.7005 8.86672 14.7838 8.83338 14.8505 8.76672L15.8505 7.75838C16.6755 6.94172 17.0755 6.15005 17.0755 5.35005C17.0838 4.52505 16.6838 3.72505 15.8505 2.90005Z"
                    fill="#F19A0E"
                  />
                  <path
                    d="M13.0079 9.60829C12.7663 9.49163 12.5329 9.37496 12.3079 9.24163C12.1246 9.13329 11.9496 9.01663 11.7746 8.89163C11.6329 8.79996 11.4663 8.66663 11.3079 8.53329C11.2913 8.52496 11.2329 8.47496 11.1663 8.40829C10.8913 8.17496 10.5829 7.87496 10.3079 7.54163C10.2829 7.52496 10.2413 7.46663 10.1829 7.39163C10.0996 7.29163 9.95795 7.12496 9.83295 6.93329C9.73295 6.80829 9.61628 6.62496 9.50795 6.44163C9.37461 6.21663 9.25795 5.99163 9.14128 5.75829C9.02461 5.50829 8.93295 5.26663 8.84961 5.04163L3.61628 10.275C3.50795 10.3833 3.40795 10.5916 3.38295 10.7333L2.93295 13.925C2.84961 14.4916 3.00795 15.025 3.35795 15.3833C3.65795 15.675 4.07461 15.8333 4.52461 15.8333C4.62461 15.8333 4.72461 15.825 4.82461 15.8083L8.02461 15.3583C8.17461 15.3333 8.38295 15.2333 8.48295 15.125L13.7163 9.89163C13.4829 9.80829 13.2579 9.71663 13.0079 9.60829Z"
                    fill="#F19A0E"
                  />
                </svg>
              </button>
              </div>

              <div className=" p-2 rounded-lg hover:bg-lightBlue transition-all">
              <button onClick={()=>{
                setProType(item)
                setDeleteOpen(true)
                }}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.5574 4.35829C16.2157 4.22496 14.8741 4.12496 13.5241 4.04996V4.04163L13.3407 2.95829C13.2157 2.19163 13.0324 1.04163 11.0824 1.04163H8.89907C6.95741 1.04163 6.77407 2.14163 6.64074 2.94996L6.46574 4.01663C5.69074 4.06663 4.91574 4.11663 4.14074 4.19163L2.44074 4.35829C2.09074 4.39163 1.84074 4.69996 1.87407 5.04163C1.90741 5.38329 2.20741 5.63329 2.55741 5.59996L4.25741 5.43329C8.62407 4.99996 13.0241 5.16663 17.4407 5.60829C17.4657 5.60829 17.4824 5.60829 17.5074 5.60829C17.8241 5.60829 18.0991 5.36663 18.1324 5.04163C18.1574 4.69996 17.9074 4.39163 17.5574 4.35829Z" fill="#07415C"/>
                  <path opacity="0.3991" d="M16.0264 6.78337C15.8264 6.57504 15.5514 6.45837 15.2681 6.45837H4.73475C4.45142 6.45837 4.16809 6.57504 3.97642 6.78337C3.78475 6.99171 3.67642 7.27504 3.69309 7.56671L4.20975 16.1167C4.30142 17.3834 4.41809 18.9667 7.32642 18.9667H12.6764C15.5848 18.9667 15.7014 17.3917 15.7931 16.1167L16.3098 7.57504C16.3264 7.27504 16.2181 6.99171 16.0264 6.78337Z" fill="#07415C"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M7.98438 14.1666C7.98438 13.8214 8.2642 13.5416 8.60938 13.5416H11.3844C11.7296 13.5416 12.0094 13.8214 12.0094 14.1666C12.0094 14.5118 11.7296 14.7916 11.3844 14.7916H8.60938C8.2642 14.7916 7.98438 14.5118 7.98438 14.1666Z" fill="#07415C"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M7.29297 10.8334C7.29297 10.4882 7.57279 10.2084 7.91797 10.2084H12.0846C12.4298 10.2084 12.7096 10.4882 12.7096 10.8334C12.7096 11.1786 12.4298 11.4584 12.0846 11.4584H7.91797C7.57279 11.4584 7.29297 11.1786 7.29297 10.8334Z" fill="#07415C"/>
                  </svg>
              </button>
              </div>
              
              {/* <div className="p-2 rounded-lg hover:bg-lightBlue transition-all">
              <Link to={`product-detials/${item.id}`}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 9 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.999973 16.92L7.51997 10.4C8.28997 9.62996 8.28997 8.36996 7.51997 7.59996L0.999973 1.07996"
                    stroke="#07415C"
                    strokeMiterlimit="10"
                    strokelinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
              </div> */}
            </div>
          ),
        },
      };
    });
  };

  return (
    <div >
      <div className="w-full  bg-white rounded-xl shadow-lg p-6 ">
        <div className="h-full">
        <div className="mb-8">
          <div className="flex gap-6 justify-between">
            <div className="flex gap-6 w-[10rem]">
             
            </div>

            <Button
              onClick={() => setOpen(true)}
              style="text-xs w-[10rem]  border border-2 border-darkYellow hover:shadow-md active:shadow-sm transition-all active:scale-95   text-darkBlue   rounded-md py-2 px-6"
              content="Add Product Type"
            />
          </div>
        </div>
          <div className="w-full h-[95%]">
            <CustomTable
              head={head}
              body={subCattBody()}
              loading={isLoading || isFetching}
              dataLength={data?.data.length}
              height="max-h-[55vh]"
              error={isError}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center my-6">
        <Pagenation
          pageCount={setPageCount}
          loading={isLoading || isFetching}
          data={data?.data?.results}
          next={data?.data?.next}
          currDataCount={data?.data?.data_count}
          totalLength={data?.data?.count}
          previous={data?.data?.previous}
          pageSize={pageSize}
          setPageSize={setPageSize}
          showPage={true}
        />
      </div>
      {open && <AddProductTypeModal open={open} setOpen={setOpen} />}
      {editOpen && <AddProductTypeModal open={editOpen} setOpen={setEditOpen} isEdit={true} item={proType} />}
      {deleteOpen && <DeleteModal open={deleteOpen} setOpen={setDeleteOpen} data = {proType} />}
    </div>
  );
};

export default ProductType;
