import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useCancelOrder, useCancelOrderSuccess, useTrackingDetialsUpdate } from "../../servicesQuery/orderQuery/OrderQuerys";
import ModalLayout from "../customModal/CustomModal";
import SubmitButton from "../submitButton/SubmitButton";
import "./orderTrackingDetialsStyles.scss";
import { Radio } from 'antd';
import { setNestedObjectValues } from "formik";
import BorderdInputField from "../customField/BorderdInputField";
import { onlyNumbers } from "../../utilities/onlyNumbers/onlyNumbers";
import Button from "../submitButton/Button";

const CancelOrderModal = ({ open, setOpen, data }) => {
    const [value, setValue] = useState({
        type: 1,
        refund: data?.total_amount,
        amount: data?.total_amount,
    });

  const onChange = (e) => {
    let name = e.target.name
    let val = e.target.value
    if(name==="type" && val === 1){
        setValue((prevState)=> {
            return {
                ...prevState,
                [name]:val,
                refund: value?.amount
            }
        })
    }else {
        setValue((prevState)=> {
            return {
                ...prevState,
                [name]:val
            }
        })
    }
  };
  const { mutateAsync: cancelOrder, isLoading } = useCancelOrder();
  const { mutateAsync: cancelOrderSuccess, isLoading: successLoading } = useCancelOrderSuccess();

  const handleUpdate = () => {
    if(value?.refund){
        toast.promise(
            cancelOrder({
              id: data?.id,
              amount: value?.refund,
            }).then((res) => {
              if(res?.data?.status === "SUCCESS"){
                toast.promise(
                    cancelOrderSuccess(res?.data?.razor_res?.id).then(()=>{
                        setOpen(false);
                      }),
                      {
                        loading: <b>updating refund</b>,
                        success: <b>Refund Completed</b>,
                        error: <b>Could not update refund.</b>,
                      }
                )
              }
            }),
            {
              loading: <b>Canceling Order</b>,
              success: <b>Cancelled Order</b>,
              error: <b>Could not cancel.</b>,
            }
          );
    }
  };
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="p-9 bg-white rounded-lg w-[600px]">
        <div className="w-full flex flex-col items-center">
          <h1 className="text-[#07415C] font-semibold text-[16px] mb-6">
            CANCELLED ORDER
          </h1>
          <div className="flex justify-center flex-col w-[90%] gap-5">
            <div className="bg-[#F5F5F5] py-8 px-[50px] rounded-[20px]">
              <span className="text-[#07415C] text-[16px] font-medium">
                Price Details
              </span>
              <div className="flex items-center justify-between mt-4">
                <span className="text-[#12121299] font-medium text-[16px]">
                  Amount
                </span>
                <span className="text-[#121212] text-[16px] font-semibold">
                  ₹ {value?.amount}
                </span>
              </div>
              <div className="flex items-center justify-between mt-4">
                <span className="text-[#12121299] font-medium text-[16px]">
                  Coupon applied
                </span>
                <span className="text-[#121212] text-[16px] font-semibold">
                  ₹ {data?.coupon_discount}
                </span>
              </div>
              <div className="flex items-center justify-between mt-4">
                <span className="text-[#12121299] font-medium text-[16px]">
                  Refund Amount
                </span>
                {value?.type === 1? 
                <span className="text-[#121212] text-[16px] font-semibold">
                  ₹ {value?.refund}
                </span>
                : 
                <input
                  type="text"
                  onInput={(e) => onlyNumbers(e)}
                  name="refund"
                  placeholder="Enter value"
                  onChange={(e) => {
                    if(e.target.value < value?.amount){
                        onChange(e);
                    }
                  }}
                  value={value?.refund}
                  className="rounded-md border-[1px] bg-opacity-0 focus-within:border-[#575252]  transition-all    border-[#bab1b1] overflow-hidden px-4 py-[8px] w-[30%] text-sm placeholder:text-darkBlue placeholder:opacity-30 placeholder:text-sm"
                />
                }
                
              </div>
            </div>
            <div className="ml-2 custom-radio">
            <Radio.Group onChange={onChange} value={value?.type} name="type">
                <Radio value={1} className="text-[#07415C] text-[14px]">Refund Total Amount</Radio>
                <Radio value={2} className="text-[#07415C] text-[14px]">Refund Partial Amount</Radio>
            </Radio.Group>
            </div>
            <Button
              style="w-full hover:shadow-lg active:shadow-sm  transition-all active:scale-95 py-2 px-6  px-10 py-1 text-white add-live-btn bg-btn-gradient rounded-2xl"
              content="Refund Amount"
              type="butten"
              onClick={() => handleUpdate()}
              disabled={isLoading}
            />
          </div>
        </div>
      </div>
    </ModalLayout>
  );
};

export default CancelOrderModal;
