import { useMutation, useQuery, useQueryClient } from "react-query";
import { editUser, singleUser, userList } from "./userFrunctions";

const useGetUserList = (filter) => {
  let enabled = true
  if(filter?.enabled == false){
    enabled = false
  }
  return useQuery(["getUserList",filter], () => userList(filter), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: enabled
  });
};
const useGetSingleUser = (id) => {
  return useQuery(["getSingleUser",id], () => singleUser(id), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const useEditUser = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => editUser(data), {
    onSuccess: (data) => {
        queryClient.invalidateQueries('getRoles')
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};
export {
    useGetUserList,
    useGetSingleUser,
    useEditUser
}
