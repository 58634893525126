import React from "react";
import cake from "../../../../../../assets/images/commonImages/cake.png";
import gift from "../../../../../../assets/images/commonImages/gift.png";
const BirthdayCouponStructure = ({ values }) => {
  return (
    <div className="w-full h-full">
      <div className=" overflow-hidden bg-white">
        <div className="relative ">
          <div className="absolute top-[-8rem] left-0 z-[0]">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.0"
              width="602.000000pt"
              height="500.000000pt"
              viewBox="0 0 602.000000 500.000000"
              preserveAspectRatio="xMidYMid meet"
            >
              <g
                transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)"
                fill={values.colorThemeOne}
                stroke="none"
              >
                <path d="M0 2556 l0 -2443 73 -21 c334 -98 642 -115 894 -48 48 13 147 44 218 70 360 131 551 128 1195 -21 420 -96 584 -85 1185 85 315 90 406 110 532 120 153 12 219 1 433 -71 275 -93 457 -129 720 -142 147 -8 383 7 472 30 83 22 187 77 249 133 l49 44 0 2354 0 2354 -3010 0 -3010 0 0 -2444z" />
              </g>
            </svg>
          </div>

          <div className="flex flex-col relative z-[1] mt-[3rem] items-center p-6 ">
            <h1
              className="text-[3.5rem]"
              style={{
                color: values.titleFontColor,
                fontStyle: values.titleFontItalics ? "italic" : "normal",
                fontWeight: values.titleFontBold ? "bold" : "normal",
                textDecoration: values.titleFontUnderline ? "underline" : "",
              }}
            >
              {values.titleContent !== "" ? values.titleContent : "Your title"}
            </h1>
            <p
              className="text-[2rem] "
              style={{
                color: values.subFontColor,
                fontStyle: values.subFontItalics ? "italic" : "normal",
                fontWeight: values.subFontBold ? "bold" : "normal",
                textDecoration: values.subFontUnderline ? "underline" : "",
              }}
            >
              {values.subContent !== "" ? values.subContent : "Your content"}
            </p>
            <img src={cake} alt="cake" className=" max-w-[25rem] mt-12 " />
          </div>
        </div>
        <div
          className=" px-6 py-12 relative z-[1] mt-[3rem]"
          style={{ backgroundColor: values.colorThemeFour }}
        >
          <div className="flex flex-col items-center justify-center">
            <h3
              className="text-xlmb-5"
              style={{
                color: values.wishFontColor,
                fontStyle: values.wishFontItalics ? "italic" : "normal",
                fontWeight: values.wishFontBold ? "bold" : "normal",
                textDecoration: values.wishFontUnderline ? "underline" : "",
              }}
            >
              {values.wishContent !== "" ? values.wishContent : "Your wish"}
            </h3>
            <p
              className="text-base  max-w-[80%] text-center mb-6"
              style={{
                color: values.wishDetialsFontColor,
                fontStyle: values.wishDetialsFontItalics ? "italic" : "normal",
                fontWeight: values.wishDetialsFontBold ? "bold" : "normal",
                textDecoration: values.wishDetialsFontUnderline
                  ? "underline"
                  : "",
              }}
            >
              {values.wishDetialsContent !== ""
                ? values.wishDetialsContent
                : "Your wish content"}
            </p>
            <p
              className=" text-lg mb-6 font-bold"
              style={{
                color: values.codeFontColor,
                fontStyle: values.codeFontItalics ? "italic" : "normal",
                fontWeight: values.codeFontBold ? "bold" : "normal",
                textDecoration: values.codeFontUnderline ? "underline" : "",
              }}
            >
              {" "}
              {values.cuponCode !== "" ? values.cuponCode : "Coupon code"}
            </p>

            <div
              className=" h-[11rem] w-[20rem] flex items-center justify-center gap-5 "
              style={{
                backgroundColor: values.colorThemeTwo,
                boxShadow: "2px 14px 12px -9px rgba(0,0,0,0.56)",
              }}
            >
              <img src={gift} className="w-[8rem]" alt="gift" />
              <p
                className="text-2xl font-semibold w-[7rem] text-center"
                style={{
                  color: values.discountFontColor,
                  fontStyle: values.discountFontItalics ? "italic" : "normal",
                  fontWeight: values.discountFontBold ? "bold" : "normal",
                  textDecoration: values.discountFontUnderline
                    ? "underline"
                    : "",
                }}
              >
                {values.discValue !== "" ? values.discValue : "Discount value"}
                {values?.discType === "fixed"
                  ? " Rs "
                  : values?.discType === "percentage"
                  ? " % "
                  : ""}
                <br /> Discount
              </p>
            </div>
            <button
              type="button"
              style={{
                backgroundColor: values.colorThemeOne,
              }}
              className="px-8 py-2  text-white text-base mt-6"
            >
              Redeem Now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BirthdayCouponStructure;
