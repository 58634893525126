import React, { useContext, useEffect, useState } from "react";
import DateRangePicker from "../../components/datePicker/DateRangePicker";
import "react-datepicker/dist/react-datepicker.css";
import users from "../../assets/images/commonImages/people.svg";
import card from "../../assets/images/commonImages/card.svg";
import order from "../../assets/images/commonImages/order_card.png";
import coupon from "../../assets/images/commonImages/coupon.svg";
import ticket from "../../assets/images/commonImages/ticket.svg";
import Cards from "./dashboardComponents/Cards";
import {
  getCurrentFormattedDate,
  getFormattedTime,
} from "../../utilities/dateFunctions/dateFunctions";
import { useGetDashboardData, useGetDashboardOrderData } from "../../servicesQuery/dashboardQuery/dashboardQuery";
import { Context } from "../../utilities/context/Context";
import OrderChart from "./dashboardComponents/OrderChart";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import OrderPieChart from "./dashboardComponents/OrdePieChart";

const Dashboard = () => {
  // const [currentTime, setCurrentTime] = useState(getFormattedTime());
  const { is_unread, setUnRead } = useContext(Context);
  const [dateFilter, setDateFilter] = useState({
    startDate: "",
    endDate: ""
  });
  // const [dateFilterOrder, setDateFilterOrder] = useState({
  //   startDate: "",
  //   endDate: ""
  // });
  const { data, isLoading: stateLoading } = useGetDashboardData({
    dateFilter:dateFilter
  });
  // const { data:dataOrder, isLoading: stateLoadingOrder } = useGetDashboardOrderData({
  //   dateFilter:dateFilterOrder
  // });
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentTime(getFormattedTime());
  //   }, 1000); // Update every minute (60000 milliseconds)

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  useEffect(() => {
    if (data?.status === 200) {
      setUnRead(data?.data?.data?.unread_notifications);
    }
  }, [data]);

  return (
    <div className="w-full flex-1 ">
      <div>
        <div className="relative flex justify-between items-center">
          <DateRangePicker setDate={setDateFilter} name="card" />
          {/* <div className="text-right">
            <h1 className="text-sm font-bold text-darkBlue">
              {getCurrentFormattedDate()}
            </h1>
            <h3 className="text-sm text-darkBlue">{currentTime}</h3>
          </div> */}
        </div>
        <div className="my-8 flex justify-between gap-6 flex-col hxl:flex-row">
          <Cards
            heading="Users"
            bg="bg-tertiary"
            children={<img className="w-[44px]" src={users} />}
            label="Total User Registration"
            number={data?.data?.data?.users}
            loading={stateLoading}
          />
          {/* <Cards
            heading="Orders"
            bg="bg-lightYellow"
            children={<img src={order} />}
            label="Total Jewellery Orders"
            number={data?.data?.data?.orders?.total_orders}
            loading={stateLoading}
          /> */}
          <Cards
            heading="Revenue"
            bg="bg-tertiary "
            children={<img className="w-[44px]" src={card} />}
            label="Total revenue earning"
            number={data?.data?.data?.orders?.revenue}
            loading={stateLoading}
          />
          <Cards
            heading="Coupons"
            bg="bg-tertiary"
            children={<img className="w-[44px]" src={coupon} />}
            label="Ongoing"
            number={data?.data?.data?.coupon}
            loading={stateLoading}
          />
          <Cards
            heading="Tickets"
            bg="bg-tertiary"
            children={<img className="w-[44px]" src={ticket} />}
            label="Pending"
            number={data?.data?.data?.ticket}
            loading={stateLoading}
          />
        </div>
        <div className="flex gap-6 flex-col hxl:flex-row">
          <div className="bg-[#eeeeee] p-5 hxl:w-[66%] w-full rounded-[40px]">
            <div className="flex items-center justify-between">
              <span className="text-[#07415C] text-[24px] font-bold">Orders</span>
              {/* <DateRangePicker bg="white" setDate={setDateFilterOrder} name="order_graph" /> */}
            </div>
            {/* <div className="flex justify-end gap-5 items-center mt-5">
              <div className="flex items-center gap-2">
                <div className="p-2 bg-[#F19A0E] rounded-md"></div>
                <span className="text-[14px] text-[#9DA9AF] font-normal">Active Orders</span>
              </div>
              <div className="flex items-center gap-2">
                <div className="p-2 bg-[#07415C] rounded-md"></div>
                <span className="text-[14px] text-[#9DA9AF] font-normal">Completed Orders</span>
              </div>
            </div> */}
            <div className="flex justify-around mt-5">
              {stateLoading?
              <SkeletonTheme baseColor="#e2e0e0" highlightColor="#f3f2f2">
              <p>
                <Skeleton count={1} height={200} />
              </p>
            </SkeletonTheme>
              :
              <>
            <OrderPieChart active_order={data?.data?.data?.orders?.active} completed_order={data?.data?.data?.orders?.completed} cancelled_order={data?.data?.data?.orders?.cancelled} />
              <div className="flex flex-col gap-5 mt-5">
                <div className="flex items-center gap-2">
                  <div className="p-2 bg-[#F19A0E] rounded-md"></div>
                  <span className="text-[14px] text-[#9DA9AF] font-normal">Active Orders</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="p-2 bg-[#07415C] rounded-md"></div>
                  <span className="text-[14px] text-[#9DA9AF] font-normal">Completed Orders</span>
                </div>
                <div className="flex items-center gap-2">
                  <div className="p-2 bg-[#8e0b12] rounded-md"></div>
                  <span className="text-[14px] text-[#9DA9AF] font-normal">Cancelled Orders</span>
                </div>
              </div>
            </>
              }
            </div>
          </div>
          <div className="flex hxl:flex-col flex-row hxl:w-[34%] w-full gap-4">
            {/* <div className="flex flex-col gap-2 bg-[#eeeeee] p-5 rounded-[40px] w-full">
                <span className="text-[#07415C] text-[24px] font-bold">Coupons</span>
                <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M28.3333 9.97337V24.0267C28.3333 26.18 28.135 27.71 27.625 28.8009C27.625 28.815 27.6108 28.8434 27.5966 28.8575C27.285 29.2542 26.8742 29.4525 26.3925 29.4525C25.6417 29.4525 24.735 28.9567 23.7575 27.9084C22.5959 26.6617 20.8108 26.7609 19.7908 28.1209L18.36 30.0192C17.7934 30.7842 17.0425 31.1667 16.2917 31.1667C15.5408 31.1667 14.79 30.7842 14.2233 30.0192L12.7784 28.1067C11.7725 26.7609 10.0017 26.6617 8.83999 27.8942L8.82581 27.9084C7.22497 29.6225 5.80836 29.8775 4.98669 28.8575C4.97253 28.8434 4.95833 28.815 4.95833 28.8009C4.44833 27.71 4.25 26.18 4.25 24.0267V9.97337C4.25 7.82004 4.44833 6.29004 4.95833 5.19921C4.95833 5.18504 4.95836 5.17087 4.98669 5.15671C5.79419 4.12254 7.22497 4.37754 8.82581 6.09171L8.83999 6.10587C10.0017 7.33837 11.7725 7.23921 12.7784 5.89337L14.2233 3.98087C14.79 3.21587 15.5408 2.83337 16.2917 2.83337C17.0425 2.83337 17.7934 3.21587 18.36 3.98087L19.7908 5.87921C20.8108 7.23921 22.5959 7.33837 23.7575 6.09171C24.735 5.04337 25.6417 4.54754 26.3925 4.54754C26.8742 4.54754 27.285 4.76004 27.5966 5.15671C27.625 5.17087 27.625 5.18504 27.625 5.19921C28.135 6.29004 28.3333 7.82004 28.3333 9.97337Z" fill="#07415C"/>
                  <path d="M22.6668 15.5834H11.3335C10.7527 15.5834 10.271 15.1017 10.271 14.5209C10.271 13.94 10.7527 13.4584 11.3335 13.4584H22.6668C23.2477 13.4584 23.7293 13.94 23.7293 14.5209C23.7293 15.1017 23.2477 15.5834 22.6668 15.5834Z" fill="#07415C"/>
                  <path d="M19.8335 20.5416H11.3335C10.7527 20.5416 10.271 20.06 10.271 19.4791C10.271 18.8983 10.7527 18.4166 11.3335 18.4166H19.8335C20.4143 18.4166 20.896 18.8983 20.896 19.4791C20.896 20.06 20.4143 20.5416 19.8335 20.5416Z" fill="#07415C"/>
                </svg>
                <div>
                  <span className="text-[#07415C] text-[24px] font-bold mr-2">{data?.data?.data?.coupon}</span>
                  <span className="text-[#07415C] text-[18px] font-medium">Ongoing</span>
                </div>
            </div>
            <div className="flex flex-col gap-2 bg-[#eeeeee] p-5 rounded-[40px] w-full">
                <span className="text-[#07415C] text-[24px] font-bold">Tickets</span>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path opacity="0.4" d="M22.6665 24.5733H17.3332L11.3998 28.52C10.5198 29.1067 9.33317 28.48 9.33317 27.4133V24.5733C5.33317 24.5733 2.6665 21.9067 2.6665 17.9067V9.90666C2.6665 5.90666 5.33317 3.23999 9.33317 3.23999H22.6665C26.6665 3.23999 29.3332 5.90666 29.3332 9.90666V17.9067C29.3332 21.9067 26.6665 24.5733 22.6665 24.5733Z" fill="#07415C"/>
                  <path d="M15.9999 16.1466C15.4533 16.1466 14.9999 15.6933 14.9999 15.1466V14.8667C14.9999 13.32 16.1332 12.56 16.5599 12.2666C17.0532 11.9333 17.2132 11.7067 17.2132 11.36C17.2132 10.6933 16.6666 10.1466 15.9999 10.1466C15.3333 10.1466 14.7866 10.6933 14.7866 11.36C14.7866 11.9067 14.3333 12.36 13.7866 12.36C13.24 12.36 12.7866 11.9067 12.7866 11.36C12.7866 9.58665 14.2266 8.14661 15.9999 8.14661C17.7733 8.14661 19.2132 9.58665 19.2132 11.36C19.2132 12.88 18.0933 13.64 17.6799 13.92C17.1599 14.2666 16.9999 14.4933 16.9999 14.8667V15.1466C16.9999 15.7066 16.5466 16.1466 15.9999 16.1466Z" fill="#07415C"/>
                  <path d="M16 19.4667C15.44 19.4667 15 19.0133 15 18.4667C15 17.92 15.4533 17.4667 16 17.4667C16.5467 17.4667 17 17.92 17 18.4667C17 19.0133 16.56 19.4667 16 19.4667Z" fill="#07415C"/>
                </svg>
                <div>
                  <span className="text-[#07415C] text-[24px] font-bold mr-2">{data?.data?.data?.ticket}</span>
                  <span className="text-[#07415C] text-[18px] font-medium">Pending</span>
                </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
