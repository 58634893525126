import React from 'react'
import ModalLayout from '../../../components/customModal/CustomModal';
import Button from '../../../components/submitButton/Button';

function ConfirmModal({ open, setOpen,handleSubmit,nextTab,setTab,policyLoading }) {

    
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="bg-white  rounded-lg  w-[90%] hxl:max-w-[20rem] m-4 p-5 ">
        <div className='flex flex-col gap-3 items-center'>
            <span>do you want to discard changes!</span>
            <div className='flex justify-between gap-3'>
                <Button
                onClick={()=> {setOpen(false);setTab(nextTab)}}
                style="text-xs w-[6rem]  border border-2 border-error text-darkBlue  rounded-md py-2 px-6"
                content="Discard"
                />
                <Button
                    onClick={()=> handleSubmit()}
                    style="text-xs w-[6rem] border border-2 border-darkYellow text-darkBlue   rounded-md py-2 px-6"
                    content="Save"
                    disabled={policyLoading}
                />
            </div>
        </div>
      </div>
    </ModalLayout>
  )
}

export default React.memo(ConfirmModal)
