import { axiosInstance } from "../../services/apiInitialSetup";

const addCoupons = async (data) => {
  try {
    const response = axiosInstance.post(`coupon/coupon/`, data);
    return response;
  } catch (error) {
    throw error;
  }
};
const getCoupons = async (data) => {
  try {
    const response = axiosInstance.get(
      `coupon/coupon/?page=${data.page ? data.page : 1}&size=${data.pageSize ? data.pageSize : 10}&${data.type ? "coupon_type__in=" + data.type : ""}`
    );
    return response;
  } catch (error) {
    throw error;
  }
};
const getSingleCoupons = async (id) => {
  if(id){
    try {
      const response = axiosInstance.get(`/coupon/coupon/${id}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
};
const editCoupons = async (data) => {
  try {
    const response = axiosInstance.patch(
      `coupon/coupon/${data.id}/`,
      data.data
    );
    return response;
  } catch (error) {
    throw error;
  }
};

const addAutoCoupons = async (data) => {
  try {
    const response = axiosInstance.post(`autocoupon/auto-coupon/`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

const editAutoCoupons = async (data) => {
  try {
    const response = axiosInstance.patch(`autocoupon/auto-coupon/${data.id}/`, data.data);
    return response;
  } catch (error) {
    throw error;
  }
};
const getAutoCoupons = async () => {
  try {
    const response = axiosInstance.get(`autocoupon/auto-coupon/`);
    return response;
  } catch (error) {
    throw error;
  }
};

export {
  addCoupons,
  getCoupons,
  getSingleCoupons,
  editCoupons,
  addAutoCoupons,
  getAutoCoupons,
  editAutoCoupons
};
