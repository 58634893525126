import React, { useContext } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { routes } from "./routes";
import MasterLayout from "../layout/masterLayout/MasterLayout";
import AuthLayout from "../layout/authLayout/AuthLayout";
import PublicRoute from "./PublicRoute";
import PrivetRoute from "./PrivetRoute";
import uuid from "react-uuid";
import { useEffect } from "react";
import { Context } from "../utilities/context/Context";
import TermsAndConditions from "../layout/masterLayout/components/TermsAndConditions";

const Index = ({ userPermissions }) => {
  const pathname = useLocation()
  const {_,setValue}=useContext(Context)
  useEffect(() => {
    setValue("");
  }, [pathname.pathname]);
 
  return (
    <Routes >
      <Route element={<PrivetRoute  />}>
        <Route element={<MasterLayout />}>
          <Route path="*" element={null} />
          <Route path="/" element={<Navigate to={'/dashboard'} />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
           
          {routes?.map((item) => {
            if (userPermissions(item?.permission) && item?.privetRoute) {
              if (item?.isSubMenu) {
                return item?.subMenu.map((submenu) => {
                  return (
                    <Route
                      key={uuid()}
                      path={"/" + submenu.path}
                      element={<submenu.component />}
                    >
                      {submenu?.subRoute?.map((sub) => {
                        return (
                          <Route
                            key={uuid()}
                            path={"/" + sub?.path}
                            element={<sub.component />}
                          ></Route>
                        );
                      })}
                    </Route>
                  );
                });
              } else if (item?.isSubRoute) {
                return (
                  <Route
                    key={uuid()}
                    path={"/" + item.path}
                    element={<item.component />}
                  >
                    {item?.subRoute?.map((sub) => {
                      return (
                        <Route
                          key={uuid()}
                          path={"/" + sub?.path}
                          element={<sub.component />}
                        ></Route>
                      );
                    })}
                  </Route>
                );
              } else {
                return (
                  <Route
                    key={uuid()}
                    path={item?.path}
                    element={<item.component />}
                  ></Route>
                );
              }
            }
          })}
        </Route>
      </Route>
      <Route element={<PublicRoute />}>
        <Route element={<AuthLayout />}>
          {routes?.map((item) => {
            if (!item?.privetRoute) {
              return (
                <Route path={item?.path} key={uuid()} element={<item.component />}></Route>
              );
            }
          })}
        </Route>
      </Route>
    </Routes>
  );
};

export default Index;
