import { useFormikContext } from "formik";
import { useEffect } from "react";

export const ResetForm = ({ isOpen }) => {
  const { resetForm } = useFormikContext();
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);
  return null;
};

export const FormikContext = ({ setFormik }) => {
  const formik = useFormikContext();
  useEffect(() => {
    setFormik(formik);
  }, [formik.values]);
  return null;
};
