import { ErrorMessage } from "formik";
import React from "react";

const ErrorMessageComponent = ({ name, Render }) => {
  
  return (
    <div className="w-full flex justify-start mb-1 mt-1 h-[1rem]">
      <div className="text-xs text-error ">
        {Render  ? (
          <Render/>
        ) : (
          <ErrorMessage  name={name} />
        )}
      </div>
    </div>
  );
};

export default ErrorMessageComponent;
