import React, { useState } from "react";
import ReactQuill,{Quill} from "react-quill";
import "react-quill/dist/quill.snow.css";
import Button from "../../../components/submitButton/Button";
import { Skeleton, Tabs } from "antd";
import { toast } from "react-hot-toast";
import { useAddPolicy, useGetPolicyList } from "../../../servicesQuery/notificationQuery/notificationQuery";
import { useEffect } from "react";
import ConfirmModal from "./ConfirmModal";
import PhoneUI from "./PhoneUI";

function TermsAndConditions() {
  const [editorContent, setEditorContent] = useState("");
  const [tab, setTab] = useState("terms and conditions");
  const [nextTab, setNextTab] = useState("");
  const [open, setOpen] = useState(false);
  const { data, isLoading, isFetching, isError } = useGetPolicyList({tab});
  const { mutateAsync: addpolicy, isLoading: policyLoading } =
  useAddPolicy();

  useEffect(()=> {
    if(data?.status === 200 && data?.data?.content){
      if(data?.data?.content === "<h2><br></h2>"){
        setEditorContent("")
      }else {
        setEditorContent(data?.data?.content)
      }
    }
  },[data])

  function alignElements(htmlContent) {
    // Create a temporary div element to hold the HTML content
    var tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlContent;
    // Get all elements with the class "ql-align-right"
    var elements_right_align = tempDiv.getElementsByClassName('ql-align-right');
    for (var i = 0; i < elements_right_align.length; i++) {
        elements_right_align[i].style.textAlign = 'right';
    }
    var elements_center_align = tempDiv.getElementsByClassName('ql-align-center');
    for (var i = 0; i < elements_center_align.length; i++) {
        elements_center_align[i].style.textAlign = 'center';
    }
    var elements_left_align = tempDiv.getElementsByClassName('ql-align-left');
    for (var i = 0; i < elements_left_align.length; i++) {
        elements_left_align[i].style.textAlign = 'left';
    }
    var elements_justify_align = tempDiv.getElementsByClassName('ql-align-justify');
    for (var i = 0; i < elements_justify_align.length; i++) {
        elements_justify_align[i].style.textAlign = 'justify';
    }
    // Return the modified HTML content
    return tempDiv.innerHTML;
}

  const handleEditorChange = (content) => {
    let outputHTML = alignElements(content);
    setEditorContent(outputHTML);
  };
  const fontSizeArr = ['8px','9px','10px','12px','13px','14px','16px','20px','24px','32px','42px','54px','68px','84px','98px'];
  var Size = Quill.import('attributors/style/size');
  Size.whitelist = fontSizeArr;
  Quill.register(Size, true);
  const toolbarOptions = [
    // [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ 'size': ['13px', ...fontSizeArr] }],
    // [{ 'size': fontSizeArr }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ align: [] }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    // ['clean']
  ];

  const onChange = (key) => {
    if((data?.data?.content !== editorContent) && ((data?.data?.content === "<h2><br></h2>" && data?.data?.content === "<p><br></p>") && editorContent !== "")){
        setOpen(true)
        setNextTab(key)
    }else {
        setEditorContent("")
        setTab(key);
    }
  };

  const handleSubmit = () => {
    let data = {
        name: tab,
        content: editorContent
    }
    addpolicy(data)
        .then((res) => {
          toast.success("Policy added");
          setOpen(false)
        })
        .catch((err) => {
          console.log(err);
          toast.error("Something went wrong");
        });
  }

  const items = [
    {
      key: "terms and conditions",
      label: "Terms and Conditions",
    },
    {
      key: "return and exchange policy",
      label: "Return and Exchange Policy",
    },
    {
      key: "privacy policy",
      label: "Privacy Policy",
    },
    {
        key: "shipping policy",
        label: "Shipping Policy",
      },
  ];
  
  return (
    <div className="w-full">
        <div>
            <Tabs activeKey={tab} items={items} onChange={onChange} />
        </div>
        {isLoading || isFetching ?
        <Skeleton
            className="mt-5"
            title={false}
            loading={isLoading || isFetching}
            round
            active
            paragraph={{
            rows: 8,
            }}
        />
        :
        <div className="flex gap-2 md:flex-row flex-col">
            <div className="bg-white p-2 w-[65%]">
                <div style={{ flex: "1" }} className="">
                    <ReactQuill
                        value={editorContent}
                        onChange={handleEditorChange}
                        modules={{ toolbar: toolbarOptions }}
                        placeholder={"Write policy here..."}
                    />
                </div>
                <div className="flex items-center justify-end mt-3">
                    <button
                        className={` rounded-full text-xs w-full md:w-[10rem]  hover:shadow-lg active:shadow-sm  transition-all active:scale-95 py-2 px-6  px-10 py-1 text-sm text-white add-live-btn bg-btn-gradient rounded-md ${policyLoading?"opacity-50":'opacity-100'}`}
                        onClick={()=> handleSubmit()}
                        disabled={policyLoading}
                        >
                        Save
                    </button>
                </div>
            </div>
            <PhoneUI content={editorContent} />
        </div>
        }
        
      {open && <ConfirmModal open={open} setOpen={setOpen} handleSubmit={handleSubmit} nextTab={nextTab} setTab={setTab} policyLoading={policyLoading} />}
    </div>
  );
}

export default TermsAndConditions;
