import React, { useState } from "react";
import "./customSelect.scss";
import Select from "react-select";

const CustomSelect = (props) => {
  return (
    <div className="w-full">
      <Select
        className={` ${props.style ? props.style : "custom-select"} `}
        
        {...props}
      />
    </div>
  );
};

export default CustomSelect;
