import React from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import "./couponManagementStyle.scss";
import CustomSelect from "../../components/customSelect/CustomSelect";
import NormalCoupons from "./couponManagementSubPages/normalCoupons/NormalCoupons";
import CouponManagemantMainPage from "./couponManagementSubPages/CuponManagementMainPage";
const CouponManagemant = () => {
 
  
  
  return (
    <div className="flex-1 w-full h-auto">
       <CouponManagemantMainPage/>
    </div>
  );
};

export default CouponManagemant;
