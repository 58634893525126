import React, { useEffect, useRef, useState } from "react";
import Button from "../../../components/submitButton/Button";
import avtr from "../../../assets/images/avtr.png";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  useAcceptTicket,
  useGetChatList,
  useGetSingleTicket,
} from "../../../servicesQuery/tickerQuery/ticketQuery";
import ChatSkeleton from "./ChatSkeleton";
import { toast } from "react-hot-toast";
import ImagePreview from "../../AddEditProduct/AddEditProductComponents/ImagePreview";
import ChatImagePreview from "./ChatImagePreview";

function TicketChatPage() {
  const navigate = useNavigate();
  const messageContainerRef = useRef(null);
  const { id } = useParams();
  const location = useLocation();
  const { data: singleData, isLoading: singleLoading, isFetching: singleFetching } = useGetSingleTicket({
    id: id,
  });
  const paramData = singleData?.data;
  const closed = paramData?.closed;
  const user = paramData?.user?.id;
  const { access } = JSON.parse(localStorage.getItem("Tokens"));
  const { data, isLoading, isFetching } = useGetChatList({
    id: id,
  });

  const { mutateAsync: acceptTicket, isLoading: ticketLoading } =
    useAcceptTicket();

  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [isType, setIstype] = useState(false);

  const [socket, setSocket] = useState(null);

  useEffect(() => {
    let newObject = {
      content: paramData?.subject,
      id: paramData?.id,
      is_read: false,
      receiver: paramData?.admin,
      sender: paramData?.user?.id,
      ticket: paramData?.ticket,
      image: paramData?.image,
    };
    let newData = [newObject];
    if (data?.data) {
      newData = newData.concat(data?.data);
    }
    // const newData = [newObject, OldData];
    setMessages(newData);
  }, [data?.data]); 

  useEffect(() => {
    if (messageContainerRef.current) {
      if (!isLoading && !isFetching) {
        messageContainerRef.current.scrollTop =
          messageContainerRef.current.scrollHeight;
      }
    }
  }, [messages, isLoading, isFetching]);
  // Function to send a new message to the backend
  function isOnlySpaces(str) {
    return str.trim() === '';
  }
  const sendMessage = () => {
    if (socket.readyState === WebSocket.OPEN) {
      if(!isOnlySpaces(newMessage)){
        let messageData = {
          message: newMessage,
          message_type: "chat_message",
          is_admin: true,
        };
        socket.send(JSON.stringify(messageData));
        setNewMessage("");
      }
    } else {
      console.log("WebSocket connection is not open yet");
    }
  };

  // Function to receive messages from the server and update the message list
  const receiveMessage = (message) => {
    // console.log(JSON.parse(message.data));
    setMessages((prevMessages) => {
      const msg = JSON.parse(message.data);
      if (msg?.type === "chat_message") {
        return [
          ...prevMessages,
          {
            content: msg?.content,
            image: msg?.image,
            sender: msg?.sender,
            receiver: msg?.receiver,
          },
        ];
      } else if (msg?.type === "typing_status") {
        if (msg?.sender === Number(user)) {
          setIstype(true);
          setTimeout(() => {
            setIstype(false);
          }, 1000);
        }
      }
      return prevMessages;
    });
  };

  useEffect(() => {
    const newSocket = new WebSocket(
      `${process.env.REACT_APP_SOCKET_BASE_URL}ws/chat/${id}/?token=${access}`
    );
    newSocket.onopen = (e) => {
      console.log("socket added", e);
    };
    newSocket.onmessage = receiveMessage;
    setSocket(newSocket);
    return () => {
      newSocket.close();
    };
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    } else {
      if (socket.readyState === WebSocket.OPEN) {
        let messageData = {
          message_type: "typing_status",
          is_typing: true,
          is_admin: true,
        };
        socket.send(JSON.stringify(messageData));
      } else {
        console.log("WebSocket connection is not open yet");
      }
    }
  };

  const handleCloseTicket = () => {
    let data = {
      id: id,
      closed: true,
    };
    acceptTicket(data)
      .then((res) => {
        // console.log(res,"res");
        if (res.status === 200) {
          toast.success("Ticket Closed");
          navigate(`/tickets`);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <div className="relative w-full flex justify-between mb-8">
        <h1 className="text-xl font-bold text-[#F19A0E]">Ticket: MW-{id}</h1>
        <div className="w-[10rem]">
          {closed ? null : (
            <Button
              style={
                "transition-all text-xs w-[10rem]   py-2 px-6  px-10 py-1 text-sm text-white add-live-btn bg-btn-gradient rounded-md"
              }
              content="Close Ticket"
              onClick={() => handleCloseTicket()}
              disabled={ticketLoading}
            />
          )}
        </div>
      </div>
      <div className="md:flex md:justify-between w-full">
        <div className="md:w-[78%] w-full  bg-white rounded-xl shadow-lg p-8">
          <div
            className="w-full h-[500px] overflow-y-scroll"
            ref={messageContainerRef}
          >
            {isFetching || isLoading ? (
              <div>
                <ChatSkeleton />
                <ChatSkeleton />
                <ChatSkeleton />
                <ChatSkeleton />
              </div>
            ) : (
              messages &&
              messages?.map((i, index) => (
                <div
                  key={index}
                  className={`w-full flex ${
                    i.sender === Number(user) ? "justify-start" : "justify-end"
                  }`}
                >
                  <div
                    className={`max-w-[90%] flex flex-col mb-8 ${
                      i.sender === Number(user) ? "items-start" : "items-end"
                    }`}
                  >
                    <div
                      className={`w-full px-4 py-2 text-[#121212] text-[14px] font-normal ${
                        i.sender === Number(user)
                          ? "bg-[#DFEEF5] rounded-tl-none rounded-tr-xl rounded-br-xl rounded-bl-xl"
                          : "bg-[#F5F5F5] rounded-tl-2xl rounded-tr-0 rounded-br-2xl rounded-bl-2xl"
                      }`}
                    >
                      {i.image ? 
                      <div>
                        <ChatImagePreview img={i.image} />
                      </div>
                      : 
                      <span className="text-[14px] font-normal break-words">
                        {i.content}
                      </span>
                      }
                    </div>
                    {/* <p
                      className={`text-[12px] text-[#7E8299] ${
                        i.sender === Number(user) ? "ml-4" : "mr-4"
                      }`}
                    >
                      10mns
                    </p> */}
                  </div>
                </div>
              ))
            )}
          </div>
          <div className={`${isType ? "block" : "invisible"} h-[40px]`}>
            <svg
              version="1.1"
              id="L4"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 100 100"
              enableBackground="new 0 0 0 0"
              height="40px"
              className="ml-1"
            >
              <circle fill="#0f4c68" stroke="none" cx="6" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.1"
                />
              </circle>
              <circle fill="#0c4864" stroke="none" cx="26" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.2"
                />
              </circle>
              <circle fill="#07415C" stroke="none" cx="46" cy="50" r="6">
                <animate
                  attributeName="opacity"
                  dur="1s"
                  values="0;1;0"
                  repeatCount="indefinite"
                  begin="0.3"
                />
              </circle>
            </svg>
          </div>
          {closed ? null : (
            <div className="flex items-center justify-between mt-3">
              <div className="bg-[#F5F5F5] p-3 w-[85%] rounded-lg">
                <div className="bg-white w-full rounded-lg">
                  <textarea
                    rows="1"
                    onKeyDown={handleKeyDown}
                    onChange={(e) => setNewMessage(e.target.value)}
                    value={newMessage}
                    placeholder="Type your message here"
                    className="p-2 rounded-lg w-full break-words overflow-y-hidden"
                  />
                </div>
              </div>
              <div className="border border-[#F19A0E] py-2 px-5 rounded-lg cursor-pointer">
                <input
                  type="button"
                  onClick={sendMessage}
                  value="Send"
                  className="cursor-pointer text-[#07415C]"
                />
              </div>
            </div>
          )}
        </div>
        <div className="md:w-[20%] w-full rounded-xl mt-1 md:mt-0">
          <div className="rounded-3xl border-[.5px] border-[#d4cfac] w-full p-5 bg-[#f9f7f7]">
            <span className="text-[#F19A0E] text-[16px] font-bold">
              Requester Details
            </span>
            <div className="flex items-center gap-2 mt-4">
              <div className="w-[50px] h-[50px] rounded-[50px] bg-white">
                {paramData?.user?.profile_pic?
                <img className="w-full h-full rounded-[50px]" src={paramData?.user?.profile_pic} alt="avtar" />
                 :
                 <span className="w-full h-full rounded-[50px] flex items-center justify-center text-[#07415C] font-black text-[20px]">{paramData?.user?.full_name[0]}</span>
                }
              </div>
              <div className="flex flex-col ">
                {/* <span className="text-[12px] text-[#121212] font-medium">
                  {paramData?.user?.id}
                </span> */}
                <span className="text-[16px] text-[#07415C] font-bold">
                  {paramData?.user?.full_name}
                </span>
              </div>
            </div>
            <div className="flex items-center gap-2 mt-4">
              <svg
                width="15"
                height="15"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M7.86065 9.47333L5.68065 11.6533C5.44065 11.44 5.20732 11.22 4.98065 10.9933C4.29398 10.3 3.67398 9.57333 3.12065 8.81333C2.57398 8.05333 2.13398 7.29333 1.81398 6.54C1.49398 5.78 1.33398 5.05333 1.33398 4.35999C1.33398 3.90666 1.41398 3.47333 1.57398 3.07333C1.73398 2.66666 1.98732 2.29333 2.34065 1.95999C2.76732 1.53999 3.23398 1.33333 3.72732 1.33333C3.91398 1.33333 4.10065 1.37333 4.26732 1.45333C4.44065 1.53333 4.59398 1.65333 4.71398 1.82666L6.26065 4.00666C6.38065 4.17333 6.46732 4.32666 6.52732 4.47333C6.58732 4.61333 6.62065 4.75333 6.62065 4.88C6.62065 5.04 6.57398 5.19999 6.48065 5.35333C6.39398 5.50666 6.26732 5.66666 6.10732 5.82666L5.60065 6.35333C5.52732 6.42666 5.49398 6.51333 5.49398 6.62C5.49398 6.67333 5.50065 6.72 5.51398 6.77333C5.53398 6.82666 5.55398 6.86666 5.56732 6.90666C5.68732 7.12666 5.89398 7.41333 6.18732 7.76C6.48732 8.10666 6.80732 8.46 7.15398 8.81333C7.39398 9.04666 7.62732 9.27333 7.86065 9.47333Z"
                  fill="#07415C"
                />
                <path
                  d="M14.6471 12.22C14.6471 12.4067 14.6137 12.6 14.5471 12.7867C14.5271 12.84 14.5071 12.8933 14.4804 12.9467C14.3671 13.1867 14.2204 13.4133 14.0271 13.6267C13.7004 13.9867 13.3404 14.2467 12.9337 14.4133C12.9271 14.4133 12.9204 14.42 12.9137 14.42C12.5204 14.58 12.0937 14.6667 11.6337 14.6667C10.9537 14.6667 10.2271 14.5067 9.46039 14.18C8.69372 13.8533 7.92706 13.4133 7.16706 12.86C6.90706 12.6667 6.64706 12.4733 6.40039 12.2667L8.58039 10.0867C8.76706 10.2267 8.93372 10.3333 9.07372 10.4067C9.10706 10.42 9.14706 10.44 9.19372 10.46C9.24706 10.48 9.30039 10.4867 9.36039 10.4867C9.47372 10.4867 9.56039 10.4467 9.63372 10.3733L10.1404 9.87333C10.3071 9.70667 10.4671 9.58 10.6204 9.5C10.7737 9.40667 10.9271 9.36 11.0937 9.36C11.2204 9.36 11.3537 9.38667 11.5004 9.44667C11.6471 9.50667 11.8004 9.59333 11.9671 9.70667L14.1737 11.2733C14.3471 11.3933 14.4671 11.5333 14.5404 11.7C14.6071 11.8667 14.6471 12.0333 14.6471 12.22Z"
                  fill="#07415C"
                />
              </svg>
              <span className="text-[12px]">{paramData?.user?.phone_number}</span>
            </div>
            <div className="flex items-center gap-2 mt-4">
              <svg
                width="15"
                height="15"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M11.334 13.6667H4.66732C2.66732 13.6667 1.33398 12.6667 1.33398 10.3333V5.66666C1.33398 3.33333 2.66732 2.33333 4.66732 2.33333H11.334C13.334 2.33333 14.6673 3.33333 14.6673 5.66666V10.3333C14.6673 12.6667 13.334 13.6667 11.334 13.6667Z"
                  fill="#07415C"
                />
                <path
                  d="M7.9992 8.57999C7.4392 8.57999 6.87254 8.40666 6.4392 8.05333L4.35253 6.38666C4.1392 6.21333 4.0992 5.89999 4.27254 5.68666C4.44587 5.47333 4.75921 5.43333 4.97254 5.60666L7.0592 7.27333C7.56587 7.68 8.42586 7.68 8.93253 7.27333L11.0192 5.60666C11.2325 5.43333 11.5525 5.46666 11.7192 5.68666C11.8925 5.89999 11.8592 6.22 11.6392 6.38666L9.55254 8.05333C9.12587 8.40666 8.5592 8.57999 7.9992 8.57999Z"
                  fill="#07415C"
                />
              </svg>

              <span className="text-[12px] break-all">
                {paramData?.user?.email}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TicketChatPage;
