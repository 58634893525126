import { useMutation, useQuery, useQueryClient } from "react-query";
import { cancelOrder, cancelOrderSuccess, orderEdit, orderList, singleOrder, trackingDetials, userOrders } from "./orderFunctions";

const useGetOrderList = (data) => {
  let enabled = true
  if(data?.enabled == false){
    enabled = false
  }
  return useQuery(["orderList", data], () => orderList(data), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: enabled
  });
};
const useGetSigleOrder = (data) => {
  return useQuery(["orderList", data], () => singleOrder(data), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
const useGetUserOrder = (data) => {
  let enabled = true
  if(data?.enabled == false){
    enabled = false
  }
  return useQuery(["orderList", data], () => userOrders(data), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: enabled
  });
};
const useTrackingDetialsUpdate = (data) => {
  const queryClient = useQueryClient();

  return useMutation((data) => trackingDetials(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("orderList");
      queryClient.invalidateQueries("orderArtmotionList");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

const useEditOrder = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => orderEdit(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("orderList");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

export const useCancelOrder = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => cancelOrder(data), {
    onSuccess: (data) => {
      // queryClient.invalidateQueries("orderList");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

export const useCancelOrderSuccess = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => cancelOrderSuccess(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("orderList");
      queryClient.invalidateQueries("orderArtmotionList");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

export { useEditOrder,useGetOrderList, useGetSigleOrder, useTrackingDetialsUpdate,useGetUserOrder };
