import React, { useCallback, useEffect, useState } from "react";
import ModalLayout from "../customModal/CustomModal";
import { Field, Form, Formik } from "formik";
import BorderdInputField from "../customField/BorderdInputField";
import CustomSelect from "../customSelect/CustomSelect";
import SubmitButton from "../submitButton/SubmitButton";
import {
  useAddRoles,
  useEditRole,
} from "../../servicesQuery/roleQuery/roleQuery";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import ErrorMessageComponent from "../errorMessage/ErrorMessageComponent";
import {
  FormikContext,
  ResetForm,
} from "../../utilities/resetForm/FormikContext";
import { onlyAlphabets, onlyDecimal, onlyNumbers, onlyPhoneNo } from "../../utilities/onlyNumbers/onlyNumbers";
import axios from "axios";
import {
  useAddVendor,
  useEditVendor,
  useGetCityList,
  useGetStateList,
  useGetVendorSingle,
} from "../../servicesQuery/vendorQuery/vendorQuery";
import Skeleton from "react-loading-skeleton";
const Checkbox = ({ name, id, value, handleChange, isChecked, label }) => {
  const [checked, setChecked] = useState(isChecked);
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div className="flex gap-6 justify-between">
      <div className="flex gap-6 items-center">
        <Field
          type="checkbox"
          name={name}
          value={value}
          id={id}
          className="w-4 h-4 accent-secondary"
          onChange={(e) => {
            handleChange(e);
          }}
          onClick={() => setChecked(!checked)}
        />
        <label htmlFor={id} className="text-sm">
          {label}
        </label>
      </div>
    </div>
  );
};

const AddVendorModal = ({ open, setOpen, isEdit, vendor_id }) => {
  const [formikValue, setFormikValue] = useState("");
  const { data, isLoading: stateLoading } = useGetStateList();
  
  const state_list = data?.data?.map((item) => {
      return {
          label: item.name,
          value: item.id,
        };
    });
    const { data: vendorData, isLoading: vendorLoading,isFetching } = useGetVendorSingle(vendor_id);
    const vendor = vendorData?.data
    const { data: cityData, isLoading: cityLoading } = useGetCityList(formikValue.state?.label);
    const city_list = cityData?.data?.map((item) => {
            return {
            label: item,
            value: item,
            };
        });
  let validation = () => {
    return Yup.object().shape({
      vendor_id: Yup.string().required("Vendor ID is required"),
      first_name: Yup.string().required("Full Name is required"),
      last_name: Yup.string().required("Last Name is required"),
      leagal_name_of_entity: Yup.string().required(
        "Legal Name of Entity is required"
      ),
      vendor_type: Yup.mixed().required("Vendor Type is required"),
      email: Yup.string()
        .required("Email ID is required")
        .email("Invalid email format"),
      // password: Yup.string().required("Password is required"),
      address: Yup.string().required("Address is required"),
      state: Yup.mixed().required("State is required"),
      city: Yup.mixed().required("City is required"),
      // pin: Yup.string().required("Pin/Zip Code is required"),
      pan: Yup.string().required("PAN Number is required"),
      gst: Yup.string().required("GST Number is required"),
      my_earning_percent: Yup.number().required("Earning Percent is required"),
    });
  };

  if(isEdit){
    validation = () => {
      return Yup.object().shape({
        vendor_id: Yup.string().required("Vendor ID is required"),
        first_name: Yup.string().required("Full Name is required"),
        last_name: Yup.string().required("Last Name is required"),
        leagal_name_of_entity: Yup.string().required(
          "Legal Name of Entity is required"
        ),
        vendor_type: Yup.mixed().required("Vendor Type is required"),
        email: Yup.string()
          .required("Email ID is required")
          .email("Invalid email format"),
        // password: Yup.string().required("Password is required"),
        address: Yup.string().required("Address is required"),
        state: Yup.mixed().required("State is required"),
        city: Yup.mixed().required("City is required"),
        // pin: Yup.string().required("Pin/Zip Code is required"),
        pan: Yup.string().required("PAN Number is required"),
        gst: Yup.string().required("GST Number is required"),
        my_earning_percent: Yup.number().required("Earning Percent is required"),
      });
    };
  }
  

  const vendor_types = [
    {
      label: "Manufacture",
      value: "manufacture",
    },
    {
      label: "Distributor",
      value: "distributor",
    },
    {
      label: "Retailer",
      value: "retailer",
    },
  ];

  const { mutateAsync: addVendor, isLoading: addLoaing } = useAddVendor();
  const { mutateAsync: editVendor, isLoading: editLoaing } = useEditVendor();

  const getVendorObj = useCallback((list,value) => {
    if(list){
        let obj = list.filter((i)=> i.value === value)[0]
        if(!obj){
            obj = ""
        }
        return obj
    }else {
        return ""
    }
    
  },[])

  const initialValue = {
    vendor_id: vendor?.vendor_code || "",
    first_name: vendor?.first_name || "",
    last_name: vendor?.last_name || "",
    leagal_name_of_entity: vendor?.leagal_name_of_entity || "",
    vendor_type: getVendorObj(vendor_types,vendor?.vendor_type) || "",
    email: vendor?.email || "",
    // password: vendor?.password || "",
    address: vendor?.address || "",
    state: getVendorObj(state_list,vendor?.state) || "",
    city: getVendorObj(city_list,vendor?.city) || "",
    // city: {
    //   "label": vendor?.city,
    //   "value": vendor?.city
    // } || "",
    pin: vendor?.pin || "",
    phone: vendor?.phone || "+91",
    pan: vendor?.pan || "",
    gst: vendor?.gst || "",
    my_earning_percent: vendor?.my_earning_percent || "",
    id: vendor?.id || ""
  };

  function getDifferentFields(obj1, obj2) {
    const result = {};
    if(obj1.address !== obj2.address){
      result.address = obj1.address
    }
    if(obj1.city !== obj2.city?.value){
      result.city = obj1.city
    }
    if(obj1.first_name !== obj2.first_name){
      result.first_name = obj1.first_name
    }
    if(obj1.gst !== obj2.gst){
      result.gst = obj1.gst
    }
    if(obj1.last_name !== obj2.last_name){
      result.last_name = obj1.last_name
    }
    if(obj1.leagal_name_of_entity !== obj2.leagal_name_of_entity){
      result.leagal_name_of_entity = obj1.leagal_name_of_entity
    }
    if(obj1.my_earning_percent !== obj2.my_earning_percent){
      result.my_earning_percent = obj1.my_earning_percent
    }
    if(obj1.pan !== obj2.pan){
      result.pan = obj1.pan
    }
    if(obj1.state !== obj2.state?.value){
      result.state = obj1.state
    }
    if(obj1.vendor_code !== obj2.vendor_id){
      result.vendor_code = obj1.vendor_code
    }
    if(obj1.vendor_type !== obj2.vendor_type?.value){
      result.vendor_type = obj1.vendor_type
    }
    // for (const key in obj1) {
    //   if (obj1.hasOwnProperty(key) && obj2.hasOwnProperty(key)) {
    //     if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
    //       result[key] = obj1[key];
    //     }
    //   }
    // }
  
    return result;
  }


  const handleSubmmit = (values, { resetForm }) => {
    if (isEdit) {
      const data = {
        // id: values.id,
        // phone: values.phone,
        first_name: values.first_name,
        last_name: values.last_name,
        leagal_name_of_entity: values.leagal_name_of_entity,
        address: values.address,
        city: values.city.label,
        pan: values.pan,
        gst: values.gst,
        // email: values.email,
        vendor_type: values.vendor_type.value,
        vendor_code: values.vendor_id,
        my_earning_percent: values.my_earning_percent,
        state: values.state.value,
      };
      let result = getDifferentFields(data, initialValue);
      result.id = values.id
      // result.email = values.email
      editVendor(result)
          .then((res) => {
         
            if(res.status === 200){
              toast.success("Vendor edited");
              resetForm();
              setOpen(false);
            }
          })
          .catch((error) => {
            if (error?.response?.data?.name && error?.response?.data?.name[0]) {
                toast.error("Vendor name already exists");
            }else if (error?.response?.data.phone && error?.response?.data.phone[0]) {
                toast.error(error?.response?.data.phone[0]);
            }else if (error?.response?.data.email && error?.response?.data.email[0]) {
                toast.error(error?.response?.data.email[0]);
            }else if (error?.response?.data?.vendor_code && error?.response?.data?.vendor_code[0]) {
              toast.error("Vendor Code already exists");
          }
          });
    } else {
      const data = {
        phone: values.phone,
        first_name: values.first_name,
        last_name: values.last_name,
        leagal_name_of_entity: values.leagal_name_of_entity,
        address: values.address,
        city: values.city.label,
        pan: values.pan,
        gst: values.gst,
        email: values.email,
        vendor_type: values.vendor_type.value,
        vendor_code: values.vendor_id,
        my_earning_percent: values.my_earning_percent,
        state: values.state.value,
      };
        addVendor(data)
        .then((res) => {
            toast.success("Vendor added");
            resetForm();
            setOpen(false);
        })
        .catch((error) => {
            if (error?.response?.data?.name && error?.response?.data?.name[0]) {
                toast.error("Vendor name already exists");
            }else if (error?.response?.data.phone && error?.response?.data.phone[0]) {
                toast.error(error?.response?.data.phone[0]);
            }else if (error?.response?.data.email && error?.response?.data.email[0]) {
                toast.error(error?.response?.data.email[0]);
            }else if (error?.response?.data?.vendor_code && error?.response?.data?.vendor_code[0]) {
              toast.error("Vendor Code already exists");
          }
        });
    }
  };

  


  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="bg-white  rounded-xl  w-[90%] hxl:max-w-[50rem] m-4 ">
        <div className="w-full flex justify-between p-6 border-b-[.5px] border-darkBlue border-opacity-20 ">
          <h2 className="text-lg text-darkBlue font-bold">
            {isEdit ? "Edit Vendor" : "Add New Vendor"}
          </h2>

          <div
            className="cursor-pointer "
            onClick={() => {
              setOpen(false);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3554 8.00005L15.4665 2.88894C16.1109 2.24449 16.1109 1.17783 15.4665 0.533382C14.822 -0.111062 13.7554 -0.111062 13.1109 0.533382L7.9998 5.64449L2.88869 0.533382C2.24425 -0.111062 1.17758 -0.111062 0.533138 0.533382C-0.111306 1.17783 -0.111306 2.24449 0.533138 2.88894L5.64425 8.00005L0.533138 13.1112C-0.111306 13.7556 -0.111306 14.8223 0.533138 15.4667C0.866471 15.8 1.28869 15.9556 1.71092 15.9556C2.13314 15.9556 2.55536 15.8 2.88869 15.4667L7.9998 10.3556L13.1109 15.4667C13.4442 15.8 13.8665 15.9556 14.2887 15.9556C14.7109 15.9556 15.1331 15.8 15.4665 15.4667C16.1109 14.8223 16.1109 13.7556 15.4665 13.1112L10.3554 8.00005Z"
                fill="#07415C"
              />
            </svg>
          </div>
        </div>
        <div className="  p-6 ">
          <Formik
            initialValues={initialValue}
            onSubmit={handleSubmmit}
            validationSchema={validation}
            enableReinitialize
          >
            {({ setFieldValue, values, handleChange }) => (
              <Form>
                <ResetForm isOpen={open} />
                {vendorLoading || isFetching? <Skeleton height={50} count={10} />: 
                  <div className="flex flex-col gap-x-6 max-h-[600px] overflow-y-scroll">
                  <div className="flex items-center gap-6 w-full">
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        Vendor ID
                      </label>
                      <BorderdInputField
                        placeholder={"Enter Vendor ID"}
                        width={"w-full"}
                        name="vendor_id"
                        value={values.vendor_id}
                      />
                      <ErrorMessageComponent name={"vendor_id"} />
                    </div>
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        Legal Name of Entity
                      </label>
                      <BorderdInputField
                        placeholder={"Enter Legal Name of Entity"}
                        width={"w-full"}
                        name="leagal_name_of_entity"
                        value={values.leagal_name_of_entity}
                      />
                      <ErrorMessageComponent name={"leagal_name_of_entity"} />
                    </div>
                  </div>
                  <div className="flex items-center gap-6 w-full">
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        First Name
                      </label>
                      <BorderdInputField
                        placeholder={"Enter Full Name"}
                        width={"w-full"}
                        name="first_name"
                        value={values.first_name}
                        onInput={(e) => onlyAlphabets(e)}
                      />
                      <ErrorMessageComponent name={"first_name"} />
                    </div>
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        Last Name
                      </label>
                      <BorderdInputField
                        placeholder={"Enter Last Name"}
                        width={"w-full"}
                        name="last_name"
                        value={values.last_name}
                        onInput={(e) => onlyAlphabets(e)}
                      />
                      <ErrorMessageComponent name={"last_name"} />
                    </div>
                  </div>
                  <div className="flex items-center gap-6 w-full">
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        Vendor Type
                      </label>
                      <CustomSelect
                        placeholder={"Select Vendor Type"}
                        name="vendor_type"
                        value={values.vendor_type}
                        options={vendor_types}
                        onChange={(e) => setFieldValue("vendor_type", e)}
                      />
                      <ErrorMessageComponent name={"vendor_type"} />
                    </div>
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        Earning Percent
                      </label>
                      <BorderdInputField
                        placeholder={"Enter Earning Percent"}
                        width={"w-full"}
                        name="my_earning_percent"
                        value={values.my_earning_percent}
                        onInput={(e) => onlyDecimal(e)}
                      />
                      <ErrorMessageComponent name={"my_earning_percent"} />
                    </div>
                  </div>
                  <div className="flex items-center gap-6 w-full">
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        Email ID
                      </label>
                      <BorderdInputField
                        placeholder={"Enter Email ID"}
                        width={"w-full"}
                        name="email"
                        value={values.email}
                        disabled={isEdit}
                      />
                      <ErrorMessageComponent name={"email"} />
                    </div>
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        Contact Number
                      </label>
                      <BorderdInputField
                        placeholder={"Enter contact number"}
                        width={"w-full"}
                        name="phone"
                        value={values.phone}
                        maxLength={13}
                        onInput={(e) => onlyPhoneNo(e)}
                        disabled={isEdit}
                      />
                      <ErrorMessageComponent name={"phone"} />
                    </div>
                    {/* <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        Password
                      </label>
                      {isEdit?
                      <div className="rounded-md border-[1px] bg-opacity-0 border-[#bab1b1] overflow-hidden">
                        <Field
                          width={"w-full"}
                          disabled
                          value="************"
                          className="px-4 py-[8px] w-full text-sm placeholder:text-darkBlue placeholder:opacity-30 placeholder:text-sm"
                        />
                      </div>
                      :
                        <BorderdInputField
                          placeholder={"Enter Password"}
                          width={"w-full"}
                          name="password"
                          value={values.password}
                        />
                      }
                      <ErrorMessageComponent name={"password"} />
                      
                    </div> */}
                  </div>
                  <div className="w-full ">
                    <label
                      className="mb-3 block text-sm text-darkBlue"
                      htmlFor="type"
                    >
                      Address
                    </label>
                    <div className="rounded-md border-[1px] bg-opacity-0 focus-within:border-[#575252] transition-all border-[#bab1b1] overflow-hidden">
                      <Field
                        as="textarea"
                        placeholder={"Enter Address "}
                        width={"w-full"}
                        name="address"
                        value={values.address}
                        className="px-4 py-[8px] w-full h-[7rem]   placeholder:text-darkBlue placeholder:opacity-30 placeholder:text-sm"
                      />
                    </div>
                    <ErrorMessageComponent name={"address"} />
                  </div>
                  <div className="flex items-center gap-6 w-full">
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        State
                      </label>
                      <CustomSelect
                        placeholder={"Select State"}
                        name="state"
                        value={values.state}
                        options={state_list}
                        onChange={(e) =>{ 
                          setFieldValue("state", e)
                          setFieldValue('city','')
                        }}
                      />
                      <ErrorMessageComponent name={"state"} />
                    </div>
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        City
                      </label>
                      <CustomSelect
                        placeholder={"Select City"}
                        name="city"
                        value={values.city}
                        options={city_list}
                        onChange={(e) => setFieldValue("city", e)}
                      />
                      <ErrorMessageComponent name={"city"} />
                    </div>
                  </div>
                  
                  <div className="flex items-center gap-6 w-full">
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        PAN Number
                      </label>
                      <BorderdInputField
                        placeholder={"Enter PAN number"}
                        width={"w-full"}
                        name="pan"
                        value={values.pan}
                      />
                      <ErrorMessageComponent name={"pan"} />
                    </div>
                    <div className="w-full">
                      <label
                        className="mb-3 block text-sm text-darkBlue"
                        htmlFor="type"
                      >
                        GST Number
                      </label>
                      <BorderdInputField
                        placeholder={"Enter GST number"}
                        width={"w-full"}
                        name="gst"
                        value={values.gst}
                      />
                      <ErrorMessageComponent name={"gst"} />
                    </div>
                  </div>
                  
                  <SubmitButton
                    width="w-full"
                    type="submit"
                    content={isEdit ? "Edit Vendor" : "Add Vendor"}
                    isLoading={addLoaing || editLoaing}
                  />
                </div>
                }
                
                <FormikContext setFormik={(e) => setFormikValue(e.values)} />
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalLayout>
  );
};

export default AddVendorModal;
