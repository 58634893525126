import React, { useContext, useEffect, useState } from "react";
import CustomTable from "../../../components/customTable/CustomTable";
import Pagenation from "../../../components/pagination/Pagenation";
import { Link } from "react-router-dom";
import { useGetProductList } from "../../../servicesQuery/productQuery/productQuery";
import Button from "../../../components/submitButton/Button";
import ProductAddEditForm from "../../../components/productAddEditForm/ProductAddEditForm";

import UploadExcelModal from "./UploadExcelModal";
import { Context } from "../../../utilities/context/Context";
import { limitFraction } from "../../../utilities/limitFractionNumber/limitFraction";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import CustomSelectAsync from "../../../components/customSelect/CustomSelectAsync";
import {
  categoryLists,
  productTypeLists,
  subCategoryLists,
} from "../../../servicesQuery/categoryQuery/categoryFunctions";
import {
  useGetCategoryList,
  useGetCatSingle,
  useGetProductTypeList,
  useGetSubCategoryList,
} from "../../../servicesQuery/categoryQuery/categoryQuery";
import { getDynamicHead, getPageVal, shortenString } from "../../couponManagement/couponManagementSubPages/autoCoupons/autoCouponsComponents/utils";
import DynamicColumnFilter from "../../../components/customTable/DynamicColumnFilter";
import TableFilter from "../../../components/customTable/TableFilter";
import { Popover } from "antd";

const head1 = [
  {
    key: 0,
    label: "SlNo",
    index: "id",
    width: 6,
    is_checked: true,
  },
  {
    key: 1,
    label: "Product Name",
    index: "name",
    width: 12,
    is_checked: true,
  },
  {
    key: 2,
    label: "Product Number",
    index: "number",
    width: 7,
    is_checked: true,
  },
  {
    key: 3,
    label: "SKU Number",
    index: "skuNumber",
    width: 8,
    is_checked: true,
  },
  {
    key: 4,
    label: "Image",
    index: "image",
    width: 10,
    is_checked: false,
  },

  {
    key: 5,
    label: "Price",
    index: "price",
    width: 7,
    is_checked: true,
  },
  {
    key: 6,
    label: "Weight",
    index: "weight",
    width: 7,
    is_checked: true,
  },
  // {
  //   key: 7,
  //   label: "Purity of Gold",
  //   index: "purityOfGold",
  //   width: 8,
  // },
  // {
  //   key: 8,
  //   label: "Purity of Silver",
  //   index: "purityOfSilver",
  //   width: 8,
  // },
  // {
  //   key: 9,
  //   label: "Purity of Platinum",
  //   index: "purityOfPlatinum",
  //   width: 9,
  // },
  // {
  //   key: 10,
  //   label: "Price of Diamond (Per Carat)",
  //   index: "purityOfDiamond",
  //   width: 10,
  // },
  // {
  //   key: 11,
  //   label: "Diamond Carat Range",
  //   index: "diomandCarat",
  //   width: 10,
  // },
  // {
  //   key: 12,
  //   label: "Price of Gemstone (Per Carat)",
  //   index: "priceOfGemstone",
  //   width: 10,
  // },
  // {
  //   key: 13,
  //   label: "Gemstone Carat Range",
  //   index: "gemstoneCaratRange",
  //   width: 10,
  // },
  // {
  //   key: 14,
  //   label: "Wastage",
  //   index: "wastage",
  //   width: 7,
  // },
  {
    key: 15,
    label: "Description ",
    index: "description",
    width: 12,
    is_checked: false,
  },
  // {
  //   key: 16,
  //   label: "Title of Description",
  //   index: "titleOfDiscription",
  //   width: 10,
  // },
  {
    key: 17,
    label: "Discount",
    index: "discount",
    width: 7,
    is_checked: true,
  },
  {
    key: 17,
    label: "Sell Price",
    index: "sell_price",
    width: 7,
    is_checked: true,
  },
  {
    key: 18,
    label: "Action",
    index: "action",
    width: 7,
    is_checked: true,
  },
];

const ProductManagementMainPage = () => {
  const { pageVal,setPageVal } = useContext(Context);
  const dynamicHead = getDynamicHead("product_management")
  const [pageCount, setPageCount] = useState(getPageVal("product_management","pageCount",pageVal));
  const [pageSize, setPageSize] = useState(getPageVal("product_management","pageSize",pageVal));
  useEffect(()=> {
    setPageVal((prevVal)=> {
      return {
        ...prevVal,
        name: "product_management",
        pageSize,
        pageCount
      }
    })
  },[pageSize,pageCount])
  const [uploadOpen, setUploadOpen] = useState(false);
  const [isEnabled, setEnabled] = useState(true);
  const [filterCount, setFilterCount] = useState(0);
  const [filterData, setFilterData] = useState({
    category: "",
    subcategory: "",
    discount: "",
    price_from: "",
    price_to: "",
    productType: "",
    sortValue: "",
  });
  const { value, setValue } = useContext(Context);
  const [head, setHead] = useState(head1)
  const [dynamicData, setDynamicData] = useState(JSON.parse(localStorage.getItem('dynamicData')))
  const [showDescription, setShowDescription] = useState(false)
  
  useEffect(()=> {
    if(dynamicHead){
      setHead(dynamicHead)
    }
  },[])
  
  useEffect(() => {
    let hasNonEmptyValue = false;
    Object.values(filterData).forEach(value => {
        if (value !== "") {
            hasNonEmptyValue = true;
        }
    });
    if(hasNonEmptyValue){
      setPageCount(1);
    }
  }, [filterData]);

  const handleFilter = (type) => {
    if(type === "clear"){
      setFilterData({
        category: "",
        subcategory: "",
        discount: "",
        price_from: "",
        price_to: "",
        productType: "",
        sortValue: "",
      })
    }else if(type === "save"){
      setEnabled(true)
      let count = 0
      if(filterData?.category){
        count += 1
      }
      if(filterData?.subcategory){
        count += 1
      }
      if(filterData?.discount){
        count += 1
      }
      if(filterData?.price_from || filterData?.price_to){
        count += 1
      }
      if(filterData?.productType){
        count += 1
      }
      if(filterData?.sortValue){
        count += 1
      }
      setFilterCount(count)
    }
    
  }

  const { data, isLoading, isFetching, isError } = useGetProductList({
    page: pageCount,
    search: value,
    categoryName: filterData?.category?.label,
    productType: filterData?.productType?.label,
    subcategoryName: filterData?.subcategory?.value,
    discount: filterData?.discount?.value,
    price_from: filterData?.price_from,
    price_to: filterData?.price_to,
    sortValue: filterData?.sortValue.value,
    pageSize: pageSize?.value,
    enabled: isEnabled
  });

  const { data: categoryData } = useGetCategoryList({
    page: pageCount,
    search: value,
    invited: false,
  });
  const categoryList = [{ label: "All", value: "ab345" }].concat(
    categoryData?.data?.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    })
  );

  const {
    data: subcatData,
    subcatLoading,
    subCatFetching,
  } = useGetCatSingle({
    id: filterData?.category?.value,
  });
  let subCatList = [{ label: "All", value: "ab345" }];
  if (subcatData?.data?.child_categories?.length) {
    subCatList = subCatList.concat(
      subcatData?.data?.child_categories?.map((item) => {
        return {
          label: item.name,
          value: item.name,
        };
      })
    );
  }
  // subCatList =  [{ label: "All", value: "ab345" }].concat(
  //   subcatData?.data?.map((item) => {
  //     return {
  //         label: item.name,
  //         value: item.name,
  //       };
  //   })
  // )

  const {
    data: proTypeData,
    proTypeLoading,
    proTypeFetching,
  } = useGetProductTypeList({
    // page: pageCount,
    search: value,
    invited: false,
  });

  const proTypeList = [{ label: "All", value: "ab345" }].concat(
    proTypeData?.data?.results?.map((e) => {
      return {
        label: e.name,
        value: e.id,
      };
    })
  );

  // const categoryDataValue = async (inputValue = "", callback) => {
  //   try {
  //     const data = await categoryLists({
  //       page: 1,
  //       search: inputValue,
  //     });
  //     callback(
  //       [{ label: "All", value: "ab345" }].concat(
  //         data?.data?.map((e) => {
  //           return {
  //             label: e.name,
  //             value: e.id,
  //           };
  //         })
  //       )
  //     );
  //     return [{ label: "All", value: "ab345" }]
  //   } catch (error) {
  //     callback([]);
  //   }
  // };

  // const subcategoryDataValue = async (inputValue = "", callback) => {
  //   try {
  //     const data = await subCategoryLists({
  //       page: 1,
  //       search: inputValue,
  //     });
  //     callback(
  //       [{ label: "All", value: "ab345" }].concat(
  //         data?.data?.map((e) => {
  //           return {
  //             label: e.name,
  //             value: e.name,
  //           };
  //         })
  //       )
  //     );
  //     return [{ label: "All", value: "ab345" }]
  //   } catch (error) {
  //     callback([]);
  //   }
  // };

  // const productTypeDataValue = async (inputValue = "", callback) => {
  //   try {
  //     const data = await productTypeLists({
  //       page: 1,
  //       search: inputValue,
  //     });
  //     callback(
  //       [{ label: "All", value: "ab345" }].concat(
  //         data?.data?.results?.map((e) => {
  //           return {
  //             label: e.name,
  //             value: e.id,
  //           };
  //         })
  //       )
  //     );
  //     return [{ label: "All", value: "ab345" }]
  //   } catch (error) {
  //     callback([]);
  //   }
  // };

  const productBody = () => {
    return data?.data?.results?.map((item, index) => {
      return {
        id: {
          isRender: true,
          render: () => {
            return (
              <div>
                <span>{(pageCount - 1) * pageSize?.value + index + 1}</span>
              </div>
            );
          },
        },
        name: item.name || "No data",
        number: item.product_id || "No data",
        skuNumber: {
          isRender: true,
          render: () => {
            return (
              <div>
                <span className="font-semibold">{item.sku || "No data"}</span>
              </div>
            );
          },
        },
        // skuNumber: item.sku || "No data",
        // image: item.image || "No data",
        image: {
          isRender: true,
          render: () => {
            return (
              <div className="">
                {item.image?
                <div className="w-16 h-16 overflow-hidden rounded-lg">
                  <img src={item?.image} className="w-full h-full" />
                </div>
                : <span>No data</span>}
              </div>
            );
          },
        },
        price: limitFraction(item.price) || "No data",
        weight: limitFraction(item.weight) || "No data",
        // purityOfGold: item.gold_purity || "No data",
        // purityOfSilver: item.silver_purity || "No data",
        // purityOfPlatinum: item.platinum_purity || "No data",
        // purityOfDiamond: item.diamond_price_per_carat || "No data",
        // diomandCarat: item.diamond_carat_range || "No data",
        // priceOfGemstone: item.gemstone_price_per_carat || "No data",
        // gemstoneCaratRange: item.gemstone_carat_range || "No Data",
        // wastage: item.wastage || "No data",
        // description: item.description || "No data",
        description: {
          isRender: true,
          render: () => {
            return (
              <div className="">
                {item.description?
                <div onMouseEnter={()=> item?.description?.length > 50 ? setShowDescription(true) : setShowDescription(false)} onMouseLeave={()=> setShowDescription(false)}>
                  {showDescription?
                  <Popover placement="bottom" content={(
                    <div className="w-[500px] p-4">
                      <span className="break-all">{item.description}</span>
                    </div>
                  )}>
                  <span className="break-all">{shortenString(item.description)}</span>
                </Popover> 
                  :
                  <span className="break-all">{shortenString(item.description)}</span>
                  }
                  
                </div>
                : <span>No data</span>}
              </div>
            );
          },
        },
        // titleOfDiscription: item.description_title || "No data",
        discount: item?.product_discount?.discount_percentage || "No data",
        sell_price: limitFraction(item.sell_price) || "No data",
        action: {
          isRender: true,
          render: () => (
            <div className="flex  items-center w-full gap-4 h-full">
              <div className=" p-2 rounded-lg hover:bg-lightBlue transition-all">
                <Link to={`edit-product/${item?.id}`}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M17.5 18.3334H2.5C2.15833 18.3334 1.875 18.05 1.875 17.7084C1.875 17.3667 2.15833 17.0834 2.5 17.0834H17.5C17.8417 17.0834 18.125 17.3667 18.125 17.7084C18.125 18.05 17.8417 18.3334 17.5 18.3334Z"
                      fill="#F19A0E"
                    />
                    <path
                      opacity="0.4"
                      d="M15.8505 2.90005C14.2338 1.28338 12.6505 1.24172 10.9921 2.90005L9.9838 3.90838C9.90047 3.99172 9.86714 4.12505 9.90047 4.24172C10.5338 6.45005 12.3005 8.21672 14.5088 8.85005C14.5421 8.85838 14.5755 8.86672 14.6088 8.86672C14.7005 8.86672 14.7838 8.83338 14.8505 8.76672L15.8505 7.75838C16.6755 6.94172 17.0755 6.15005 17.0755 5.35005C17.0838 4.52505 16.6838 3.72505 15.8505 2.90005Z"
                      fill="#F19A0E"
                    />
                    <path
                      d="M13.0079 9.60829C12.7663 9.49163 12.5329 9.37496 12.3079 9.24163C12.1246 9.13329 11.9496 9.01663 11.7746 8.89163C11.6329 8.79996 11.4663 8.66663 11.3079 8.53329C11.2913 8.52496 11.2329 8.47496 11.1663 8.40829C10.8913 8.17496 10.5829 7.87496 10.3079 7.54163C10.2829 7.52496 10.2413 7.46663 10.1829 7.39163C10.0996 7.29163 9.95795 7.12496 9.83295 6.93329C9.73295 6.80829 9.61628 6.62496 9.50795 6.44163C9.37461 6.21663 9.25795 5.99163 9.14128 5.75829C9.02461 5.50829 8.93295 5.26663 8.84961 5.04163L3.61628 10.275C3.50795 10.3833 3.40795 10.5916 3.38295 10.7333L2.93295 13.925C2.84961 14.4916 3.00795 15.025 3.35795 15.3833C3.65795 15.675 4.07461 15.8333 4.52461 15.8333C4.62461 15.8333 4.72461 15.825 4.82461 15.8083L8.02461 15.3583C8.17461 15.3333 8.38295 15.2333 8.48295 15.125L13.7163 9.89163C13.4829 9.80829 13.2579 9.71663 13.0079 9.60829Z"
                      fill="#F19A0E"
                    />
                  </svg>
                </Link>
              </div>

              {/* <div className="p-2 rounded-lg hover:bg-lightBlue transition-all">
              <Link to={`product-detials/${item.id}`}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 9 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.999973 16.92L7.51997 10.4C8.28997 9.62996 8.28997 8.36996 7.51997 7.59996L0.999973 1.07996"
                    stroke="#07415C"
                    strokeMiterlimit="10"
                    strokelinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
              </div> */}
            </div>
          ),
        },
      };
    });
  };

  const discountList = [
    {
      label: "ALL",
      value: "ab345",
    },
    {
      label: "5% and above",
      value: "5",
    },
    {
      label: "10% and above",
      value: "10",
    },
    {
      label: "20% and above",
      value: "20",
    },
    {
      label: "40% and above",
      value: "40",
    },
    {
      label: "50% and above",
      value: "50",
    },
    {
      label: "60% and above",
      value: "60",
    },
    {
      label: "70% and above",
      value: "70",
    },
    {
      label: "80% and above",
      value: "80",
    },
    {
      label: "90% and above",
      value: "90",
    },
  ];

  const sort_list = [
    {
      label: "ALL",
      value: "ab345",
    },
    {
      label: "Newest First",
      value: "-created_at",
    },
    {
      label: "Oldest First",
      value: "created_at",
    },
    {
      label: "Price High to Low",
      value: "-price",
    },
    {
      label: "Price Low to High",
      value: "price",
    },
    {
      label: "Discount High to Low",
      value: "-product_discount__discount_percentage",
    },
    {
      label: "Discount Low to High",
      value: "product_discount__discount_percentage",
    },
  ];

  return (
    <div>
      <UploadExcelModal open={uploadOpen} setOpen={setUploadOpen} />

      <div className="w-full  bg-white rounded-xl shadow-lg p-6 relative">
        <Link to='upload-progress' className="z-[50] w-[5px] hover:w-[180px] transition-all bg-darkBlue p-2 h-[60px] fixed top-20 right-0 rounded-l-lg flex items-center" title="Product upload progresses">
          <p className="w-full truncate text-white text-[14px]">Product Upload Status</p>
        </Link>
        <div className="h-full">
          <div className="relative w-full flex justify-between mb-4 ">
            <div className="flex gap-3 flex-col hxl:flex-row w-full items-end">
              {/* <div className="flex flex-col justify-center w-full">
                <CustomSelect
                  placeholder={"Category"}
                  name="category"
                  value={filterData.category}
                  onChange={(e) => {
                    setFilterData({ ...filterData, category: e });
                  }}
                  options={categoryList}
                  width={"min-w-[8rem]"}
                />
              </div> */}
              {/* <div className="flex flex-col justify-center w-full">
                <CustomSelect
                  placeholder={"Sub Category"}
                  name="subcategory"
                  value={filterData.subcategory}
                  onChange={(e) => {
                    setFilterData({ ...filterData, subcategory: e });
                  }}
                  options={subCatList}
                  width={"min-w-[8rem]"}
                />
              </div> */}
              {/* <div className="flex flex-col justify-center w-full">
                <CustomSelect
                  placeholder={"Discount"}
                  options={discountList}
                  width={"min-w-[8rem]"}
                  value={filterData.discount}
                  onChange={(e) => {
                    setFilterData({ ...filterData, discount: e });
                  }}
                />
              </div> */}
              {/* <div className="flex flex-col justify-center w-full items-center">
                <span className="text-sm">Price</span>
                <div className="flex items-center gap-2 w-full">
                  <input
                    type="text"
                    placeholder="From"
                    onChange={(e) =>
                      setFilterData({
                        ...filterData,
                        price_from: e.target.value,
                      })
                    }
                    value={filterData?.price_from}
                    className="rounded-md border-[1px] bg-opacity-0 focus-within:border-[#575252]  transition-all    border-[#bab1b1] overflow-hidden px-4 py-[8px] w-full text-sm placeholder:text-darkBlue placeholder:opacity-30 placeholder:text-sm"
                  />
                  <input
                    type="text"
                    placeholder="To"
                    onChange={(e) =>
                      setFilterData({ ...filterData, price_to: e.target.value })
                    }
                    value={filterData?.price_to}
                    className="rounded-md border-[1px] bg-opacity-0 border-[#bab1b1] overflow-hidden focus-within:border-[#575252]  transition-all    px-4 py-[8px] w-full text-sm placeholder:text-darkBlue placeholder:opacity-30 placeholder:text-sm"
                  />
                </div>
              </div> */}

              {/* <div className="flex flex-col justify-center w-full">
                <CustomSelect
                  placeholder={"Product Type"}
                  name="subcategory"
                  value={filterData.productType}
                  onChange={(e) => {
                    setFilterData({ ...filterData, productType: e });
                  }}
                  options={proTypeList}
                  width={"min-w-[8rem]"}
                />
              </div> */}
            </div>
          </div>
          <div className="relative w-full flex justify-between mb-8 md:flex-row flex-col gap-8">
            {/* <div className="flex gap-3 flex-col w-full hxl:flex-row md:w-[22%]">
              <CustomSelect
                placeholder={"Sort"}
                options={sort_list}
                width={"min-w-[8rem]"}
                value={filterData.sortValue}
                onChange={(e) => {
                  setFilterData({ ...filterData, sortValue: e });
                }}
              />
            </div> */}
            <div className="flex items-center gap-6 w-full md:w-auto md:justify-end justify-between">
              <div className="w-full md:w-[8rem]">
                  <TableFilter type="product" filterData={filterData} setFilterData={setFilterData} categoryList={categoryList} subCatList={subCatList} discountList={discountList} proTypeList={proTypeList} sort_list={sort_list} handleFilter={handleFilter} setEnabled={setEnabled} filterCount={filterCount} />
                </div>
                <div className="w-full md:w-[10rem]">
                  <DynamicColumnFilter head={head} name="product_management" setHead={setHead} dynamicData={dynamicData} setDynamicData={setDynamicData} />
                </div>
            </div>
            <div className="flex items-center gap-6 w-full md:w-auto md:justify-end justify-between">
              <Link
                to={"add-product"}
                className="text-xs w-full md:w-[10rem] hover:shadow-md active:shadow-sm transition-all active:scale-95  border-2 border-darkYellow  text-darkBlue   rounded-md py-2 px-6"
              >
                Add new Product
              </Link>
              <Button
                style={
                  "text-xs w-full md:w-[10rem]  hover:shadow-lg active:shadow-sm  transition-all active:scale-95 py-2 px-6  px-10 py-1 text-sm text-white add-live-btn bg-btn-gradient rounded-md"
                }
                content=" Upload Products"
                onClick={() => setUploadOpen(true)}
              />
              
            </div>
          </div>
          <div className="w-full h-[95%]">
            <CustomTable
              head={head}
              body={productBody()}
              loading={isLoading || isFetching}
              dataLength={data?.data?.results?.length}
              height="max-h-[55vh]"
              error={isError}
              is_dynamic={true}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center my-6">
        <Pagenation
          pageCount={setPageCount}
          loading={isLoading || isFetching}
          data={data?.data?.results}
          next={data?.data?.next}
          currDataCount={data?.data?.data_count}
          totalLength={data?.data?.count}
          previous={data?.data?.previous}
          pageSize={pageSize}
          setPageSize={setPageSize}
          showPage={true}
          countVal={pageCount}
        />
      </div>
    </div>
  );
};

export default ProductManagementMainPage;
