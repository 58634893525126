import { axiosInstance } from "../../services/apiInitialSetup";


const reviewLists = async ({ page, pageSize = 10, status,search,productid}) => {
    let url = "/review/reviews/?"
    if(page){
      url = url + `page=${page}&`
    }
    if(status !== "ab345"){
        let is_publised = true
        if(status === "pending"){
            is_publised = false
        }
      url = url + `is_publised=${is_publised}&`
    }
    if(pageSize){
      url = url + `size=${pageSize}&`
    }
    if(search){
        url = url + `search=${search}&`
      }
      if(productid){
        url = url + `product__id__in=${[productid]}&`
      }
    try {
      const response = await axiosInstance.get(url);
      return response;
    } catch (error) {
      throw error;
    }
  };

  const reviewImgDelete = async (id) => {
    try {
        const response = await axiosInstance.delete(`review/reviews/${id}/delete_images/`, []);
        return response;
    } catch (error) {
        throw error;
    }
  };

  const reviewDelete = async (id) => {
    try {
        const response = await axiosInstance.delete(`review/reviews/${id}/`, []);
        return response;
    } catch (error) {
        throw error;
    }
  };

  const reviewPublish = async (data) => {
    try {
        const response = await axiosInstance.patch(`review/reviews/${data?.id}/`, data);
        return response;
    } catch (error) {
        throw error;
    }
  };


export {
    reviewLists,
    reviewImgDelete,
    reviewDelete,
    reviewPublish
}