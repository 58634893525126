import React, { useContext, useEffect, useState } from "react";
import SingleDatePicker from "../../../components/datePicker/SingleDatePicker";
import CustomTable from "../../../components/customTable/CustomTable";
import Pagenation from "../../../components/pagination/Pagenation";
import {
  useGetOrderList,
  useTrackingDetialsUpdate,
} from "../../../servicesQuery/orderQuery/OrderQuerys";
import Dropdown from "../../../components/dropDown/Dropdown";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import download from "../../../assets/images/commonImages/download.svg";
import { toast } from "react-hot-toast";
import eye from "../../../assets/images/commonImages/eyeBlue.png";
import OrderTrackingDetials from "../../../components/orderTrackingDetials/OrderTrackingDetials";
import OrderTrackingDetialsModal from "../../../components/orderTrackingDetialsModal/OrderTrackingDetialsModal";
import OrderItemsDetialsModal from "../../../components/orderItemDetialsModal/OrderItemsDetialsModal";
import { Context } from "../../../utilities/context/Context";
import { limitFraction } from "../../../utilities/limitFractionNumber/limitFraction";
import CustomSelect from "../../../components/customSelect/CustomSelect";
import DynamicColumnFilter from "../../../components/customTable/DynamicColumnFilter";
import { getDynamicHead, getPageVal, shortenString } from "../../couponManagement/couponManagementSubPages/autoCoupons/autoCouponsComponents/utils";
import TableFilterDropDown from "../../../components/customTable/TableFilterDropDown";
import CancelOrderModal from "../../../components/orderTrackingDetialsModal/CancelOrderModal";

const head1 = [
  {
    key: 0,
    label: "SlNo",
    index: "id",
    width: 6,
    is_checked: true,
  },
  {
    key: 1,
    label: "Order ID",
    index: "orderid",
    width: 12,
    is_checked: true,
  },
  {
    key: 2,
    label: "User Name",
    index: "name",
    width: 12,
    is_checked: true,
  },
  // {
  //   key: 3,
  //   label: "Address of User",
  //   index: "address",
  //   width: 13,
  //   is_checked: true,
  // },
  {
    key: 4,
    label: "Order Status",
    index: "orderstatus",
    width: 10,
    is_checked: true,
  },

  {
    key: 5,
    label: "Details",
    index: "productdetials",
    width: 4,
    is_checked: true,
  },
  {
    key: 6,
    label: "Timestamp",
    index: "datetime",
    width: 8,
    is_checked: true,
  },
  {
    key: 7,
    label: "Price",
    index: "price",
    width: 7,
    is_checked: true,
  },
  {
    key: 8,
    label: "Payment Status",
    index: "paymentstatus",
    width: 8,
    is_checked: true,
  },
  {
    key: 9,
    label: "Address",
    index: "dellveryaddress",
    width: 13,
    is_checked: true,
  },

  {
    key: 5,
    label: "Order Status Details",
    index: "trackingDetials",
    width: 7,
    is_checked: true,
  },

  {
    key: 11,
    label: "Invoice",
    index: "invoice",
    width: 8,
    is_checked: true,
  },
  {
    key: 12,
    label: "Action",
    index: "action",
    width: 9,
    is_checked: true,
  },
];

const OrderManagementMainPage = () => {
  const { pageVal,setPageVal } = useContext(Context);
  const dynamicHead = getDynamicHead("order_management")
  const [pageCount, setPageCount] = useState(getPageVal("order_management","pageCount",pageVal));
  const [pageSize, setPageSize] = useState(getPageVal("order_management","pageSize",pageVal));
  useEffect(()=> {
    setPageVal((prevVal)=> {
      return {
        ...prevVal,
        name: "order_management",
        pageSize,
        pageCount
      }
    })
  },[pageSize,pageCount])
  const [date, setDate] = useState("");
  const [to_date, setToDate] = useState("");
  const [orderItem, setOrderItems] = useState();
  const [open, setOpen] = useState(false);
  const [trackingOpen, setTrackingOpen] = useState(false);
  const [cancelOpen, setCancelOpen] = useState(false);
  const [itemData, setItemData] = useState({});
  const [orderOpen, setOrderOpen] = useState(false);
  const [trackingData, setTrackingData] = useState("");

  const [orderTrackingData, setOrderTrackingData] = useState("");
  const formData = new FormData();
  const { value } = useContext(Context);
  const [head, setHead] = useState(head1)
  const [dynamicData, setDynamicData] = useState(JSON.parse(localStorage.getItem('dynamicData')))
  const [filterData, setFilterData] = useState({
    order_status: "bc654",
    payment_status: "",
  });
  const [isEnabled, setEnabled] = useState(true);
  const [filterCount, setFilterCount] = useState(0);
  const { data, isLoading, isFetching, isError } = useGetOrderList({
    start_date: date,
    end_date: to_date,
    page: pageCount,
    search: value,
    order_status: filterData?.order_status?.value,
    payment_status: filterData?.payment_status?.value,
    pageSize: pageSize?.value,
    enabled: isEnabled
  });

  useEffect(() => {
    if (open || cancelOpen || orderOpen) {
        document.querySelector("body").style.overflow = "hidden";
    } else {
        document.querySelector("body").style.overflowY = "scroll";
    }
    }, [open,cancelOpen,orderOpen]);

  useEffect(()=> {
    if(dynamicHead){
      setHead(dynamicHead)
    }
  },[])

  useEffect(() => {
    if(date){
      setPageCount(1);
    }
  }, [date]);

  const handleFilter = (type)=> {
    if(type === "clear"){
      setFilterData({
        order_status: "bc654",
        payment_status: "",
      })
      setDate("")
      setToDate("")
      setEnabled(false)
    }else if(type === "save"){
      setEnabled(true)
      let count = 0
      if(filterData?.order_status !== "bc654" && filterData?.order_status !== ""){
        count += 1
      }
      if(filterData?.payment_status){
        count += 1
      }
      if(date){
        count += 1
      }
      if(to_date){
        count += 1
      }
      setFilterCount(count)
    }
  }

  
  const { mutateAsync: updateDetials } = useTrackingDetialsUpdate();
  const ordersBody = () => {
    return data?.data?.results?.map((item,index) => {
      return {
        id: {
          isRender:true,
          render:()=>{
            return (
              <div>
                <span>{(pageCount - 1) * pageSize?.value + index+1}</span>
              </div>
            )
          }
        },
        orderid: item.order_id || "No data",
        name: item?.user?.full_name || "No data",
        // address:
        //   `${item.address.build_flatname}, ${item.address.street} ${item.address.landmark},${item.address.state},${item.address.pincode}` ||
        //   "No data",
        orderstatus: {
          isRender: true,
          render: () => {
            if (item.status !== 6 && item.status !== 5 && item.status !== 8) {
              return (
                <div>
                  <Dropdown
                    style="border-[1px] border-lightBlue rounded-md"
                    heigth="max-h-[8rem]"
                    styles="text-darkBlue"
                    onchange={(e) => {
                      setTrackingData({
                        status: e.id,
                        id: item.id,
                      });
                      if (e.id !== 4 && e.id !== 2 && e.id !== 6) {
                        formData.append("status", e.id);
                        toast.promise(
                          updateDetials({
                            id: item.id,
                            data: formData,
                          }).then(() => {
                            setTrackingData("");
                          }),
                          {
                            loading: <b>Updating status</b>,
                            success: <b>Updated</b>,
                            error: <b>Could not update.</b>,
                          }
                        );
                      } else if (e.id === 4) {
                        setTrackingOpen(true);
                      } else if (e.id === 6) {
                        setItemData(item)
                        setCancelOpen(true);
                      }
                    }}
                    values={() => {
                      if (item.status === 2) {
                        return "Placed";
                      } else if (item.status === 3) {
                        return "Processing";
                      } else if (item.status === 4) {
                        return "Shipped";
                      } else if (item.status === 5) {
                        return "Delivered";
                      } else if (item.status === 6) {
                        return "Cancel";
                      }
                    }}
                    options={[
                      {
                        content: "Placed",
                        id: 2,
                        disabled: true,
                        // disabled: item.history["Placed"] ? true : false,
                      },
                      {
                        content: "Processing",
                        disabled: item.history["Placed"] && !item.history.Processing && !item.history.Shipped ? false : true,
                        // disabled:
                        //   item.history.Shipped || item.history.Processing
                        //     ? true
                        //     : false,
                        id: 3,
                      },
                      {
                        content: "Cancel",
                        disabled: (item.history.Processing || item.history.Placed) && !item.history.Shipped ? false : true,
                        id: 6,
                      },
                      {
                        content: "Shipped",
                        disabled: item.history.Processing && !item.history.Shipped ? false : true,
                        // disabled: item.history.Shipped ? true : false,
                        id: 4,
                      },
                      {
                        content: "Delivered",
                        id: 5,
                        disabled: item.history.Shipped ? false : true,
                        // disabled: item.Processing ? true : false,
                      },
                    ]}
                  />
                </div>
              );
            } else {
              return (
                <div className="w-full px-4">
                  <h1 className=" font-bold text-darkYellow text-xs">
                    {item.status === 5 && "Delivered"}
                    {item.status === 6 && "Cancelled"}
                    {item.status === 8 && "Refund Initiated"}
                  </h1>
                </div>
              );
            }
          },
        },
        productdetials: {
          isRender: true,
          render: () => {
            return (
              <div>
                <div className="p-2 rounded-lg border-[1px] border-lightBlue hover:bg-lightBlue transition-all">
                  <button
                    onClick={() => {
                      setOpen(true);
                      setOrderItems({
                        data: item?.order_items,
                        id: item.order_id,
                      });
                    }}
                    className="text-darkYellow "
                  >
                    View
                  </button>
                </div>
              </div>
            );
          },
        },
        datetime:
          dayjs(item.created_at).format("DD/MM/YYYY") +
            " " +
            dayjs(item.created_at).format("hh:mm A") || "No data",
        price: "Rs " + limitFraction(item.total_amount) || "No data",
        paymentstatus: {
          isRender: true,
          render: () => {
            return (
              <div>
                {item?.payment?.payment_refunds?.length && item?.payment?.payment_refunds[0]?.status === 2 ? (
                  <p className="text-xs font-semibold text-status">Refunded</p>
                ) :item.is_paid ? (
                  <p className="text-xs font-semibold text-status">Paid</p>
                ) : (
                  <p className="text-xs font-semibold text-darkBlue">Pending</p>
                )}
              </div>
            );
          },
        },
        dellveryaddress:
          shortenString(`${item.address.build_flatname}, ${item.address.street} ${item.address.landmark},${item.address.state},${item.address.pincode}`) ||
          "No data",

        trackingDetials: {
          isRender: true,
          render: () => {
            return (
              <div
                onClick={() => {
                  setOrderOpen(true);
                  setOrderTrackingData(item?.history);
                }}
                className="w-[20px] cursor-pointer"
              >
                <img className="w-full" src={eye} alt="eye" />
              </div>
            );
          },
        },
        invoice: {
          isRender: true,
          render: () => {
            return (
              <div>
                <Link
                  target="_blank"
                  to={item?.invoice_pdf}
                  className="text-sm text-darkYellow underline"
                >
                  <img src={download} alt="download" />
                </Link>
              </div>
            );
          },
        },

        action: {
          isRender: true,
          render: () => (
            <div className="flex   gap-4 ">
              <div className="p-2 rounded-lg hover:bg-lightBlue transition-all flex items-center">
                <Link to={`order-detials/${item.id}`}>
                  <svg
                    width="20"
                    height="20"
                    viewBox="0 0 9 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.999973 16.92L7.51997 10.4C8.28997 9.62996 8.28997 8.36996 7.51997 7.59996L0.999973 1.07996"
                      stroke="#07415C"
                      strokeMiterlimit="10"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          ),
        },
      };
    });
  };

  const order_status_list = [
    {
      label: "ALL",
      value: "bc654",
    },
    {
      label: "Placed",
      value: 2,
    },
    {
      label: "Processing",
      value: 3,
    },
    {
      label: "Shipped",
      value: 4,
    },
    {
      label: "Delivered",
      value: 5,
    },
  ];

  const payment_status_list = [
    {
      label: "ALL",
      value: "bc654",
    },
    {
      label: "Paid",
      value: 3,
    },
    {
      label: "Failed",
      value: 4,
    },
  ];

  return (
    <div>
      <OrderTrackingDetialsModal
        data={trackingData}
        open={trackingOpen}
        setOpen={setTrackingOpen}
      />
      {cancelOpen &&
      <CancelOrderModal
        data={itemData}
        open={cancelOpen}
        setOpen={setCancelOpen} />
      }
      
      <OrderItemsDetialsModal data={orderItem} open={open} setOpen={setOpen} />
      <OrderTrackingDetials
        opan={orderOpen}
        data={orderTrackingData}
        setOpen={setOrderOpen}
      />
      <div className="w-full  bg-white rounded-xl shadow-lg p-6 ">
        <div className="h-full">
          <div className="relative w-full flex gap-4 flex-col md:flex-row justify-between mb-8">
            <h1 className="text-base font-bold">Order Management</h1>
            <div className="flex items-center  flex-col  md:flex-row justify-between gap-3">
              {/* <div className="flex flex-col  md:min-w-[8rem] w-full  justify-center">
                <CustomSelect
                  placeholder={"Order Status"}
                  options={order_status_list}
             
                  value={filterData.order_status}
                  onChange={(e) => {
                    setFilterData({ ...filterData, order_status: e });
                  }}
                />
              </div>
              <div className="flex flex-col justify-center md:min-w-[8rem] w-full">
                <CustomSelect
                  placeholder={"Payment Status"}
                  options={payment_status_list}
                  value={filterData.payment_status}
                  onChange={(e) => {
                    setFilterData({ ...filterData, payment_status: e });
                  }}
                />
              </div>
              <div className="md:min-w-[8rem] w-full">
                <SingleDatePicker
                  onDateChange={(e) => {
                    if (e) {
                      setDate(e?.toLocaleDateString());
                    } else {
                      setDate("");
                    }
                  }}
                />
              </div> */}
              <div className="md:min-w-[8rem] w-full">
                <TableFilterDropDown name="order_management" setDate={setDate} setToDate={setToDate} setEnabled={setEnabled} filterCount={filterCount} handleFilter={handleFilter} date={date} to_date={to_date} filterData={filterData} setFilterData={setFilterData} payment_status_list={payment_status_list} order_status_list={order_status_list} />
              </div>
              <div className="md:min-w-[8rem] w-full">
                <DynamicColumnFilter head={head} name="order_management" setHead={setHead} dynamicData={dynamicData} setDynamicData={setDynamicData} />
              </div>
            </div>
          </div>
          <div className="w-full h-[95%]">
            <CustomTable
              head={head}
              body={ordersBody()}
              loading={isLoading || isFetching}
              dataLength={data?.data?.results?.length}
              error={isError}
              is_dynamic={true}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center my-6">
        <Pagenation
          data={data?.data?.results}
          loading={isLoading}
          next={data?.data?.next}
          previous={data?.data?.previous}
          pageCount={setPageCount}
          totalLength={data?.data?.count}
          currDataCount={data?.data?.data_count}
          pageSize={pageSize}
          setPageSize={setPageSize}
          showPage={true}
          countVal={pageCount}
        />
      </div>
    </div>
  );
};

export default OrderManagementMainPage;
