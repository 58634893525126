import { axiosInstance } from "../../services/apiInitialSetup"


const signin =async (data)=>{
    try {
        const response = await axiosInstance.post('adminpanel/login/',data)
       
        return response
    } catch (error) {
         if(!error.response){
            throw error
         }else{
            throw error.response.data
         }
    }

  
}
const resetPasswordEmail =async (data)=>{
    try {
        const response = await axiosInstance.post('adminpanel/forgot-password-send-email/',data)
   
        return response
    } catch (error) {
         if(!error.response){
            throw error
         }else{
            throw error.response.data
         }
    }

  
}
const resetPasswordConfirm =async (data)=>{
    try {
        const response = await axiosInstance.post('adminpanel/reset-password-confirm/',data)
   
        return response
    } catch (error) {
         if(!error.response){
            throw error
         }else{
            throw error.response.data
         }
    }
}

const resetVendorPassword =async (data)=>{
    try {
        const response = await axiosInstance.post('/vendor/vendor/activate/',data)
        return response
    } catch (error) {
         if(!error.response){
            throw error
         }else{
            throw error.response.data
         }
    }
}

export {
    signin,
    resetPasswordEmail,
    resetPasswordConfirm,
    resetVendorPassword
}