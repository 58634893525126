import { Formik, Form } from "formik";
import React, { useState } from "react";
import ProductDetials from "./tagTempleteComponents/ProductDetials";
import Background from "./tagTempleteComponents/Background";
import Detials from "./tagTempleteComponents/Detials";
import ToggleButton from "../../../../components/toggleButton/ToggleButton";
import TagStructure from "./tagTempleteComponents/TagStructure";
import * as Yup from "yup";
import SubmitButton from "../../../../components/submitButton/SubmitButton";
import {
  useAddtags,
  useEditags,
  useGetSingleTags,
} from "../../../../servicesQuery/tagQuery/tagQuery";
import { FormikContext } from "../../../../utilities/resetForm/FormikContext";
import Name from "./tagTempleteComponents/Name";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";

const tagValidation = Yup.object().shape({
  products: Yup.mixed().required("Products is required"),
  productType: Yup.mixed().required("Product type is required"),
  catogary: Yup.mixed().required("Catogary  is required"),
  // fromPrice: Yup.string().required("From price  is required"),
  // toPrice: Yup.string().required("To price is required"),
  // titleContent: Yup.string().required("Title  is required"),
  // detialsContent: Yup.string().required("Content  is required"),
  // minDiscount: Yup.string().required("Minimum discount  is required"),
  // maxDiscount: Yup.string().required("Maximum discount  is required"),
});
const TagTemplete = () => {
  const [image, setImage] = useState("");
  const { mutateAsync: addTag, isLoading } = useAddtags();
  const { mutateAsync: editTag, isLoading: editLoading } = useEditags();
  const [formik, setFormik] = useState();
  const id = useParams().id;
  const { data: tagData, isLoading: tagLoading } = useGetSingleTags(id);

  const formData = new FormData();
  const initialValues = {
    name: tagData?.data?.name || "",
    catogary:
      tagData?.data?.categories?.map((e) => {
        return {
          label: e.name,
          value: e.id,
        };
      }) || "",
    productType:
      tagData?.data?.product_types?.map((e) => {
        return {
          label: e.name,
          value: e.id,
        };
      }) || "",
    products:
      tagData?.data?.products?.map((e) => {
        return {
          label: e.name,
          value: e.id,
        };
      }) || "",
    fromPrice: tagData?.data?.from_price || "",
    toPrice: tagData?.data?.to_price || "",
    bgColor: tagData?.data?.card_color || "#e97b7b",
    bgImage: tagData?.data?.card_image || "",
    titleContent: tagData?.data?.title?.content || "Tag title",
    titleFontColor: tagData?.data?.title?.color || "#ffffff",
    titleFontBold: tagData?.data?.title?.is_bold || false,
    titleFontItalics: tagData?.data?.title?.is_italic || false,
    titleFontUnderline: tagData?.data?.title?.is_underline || false,
    detialsContent: tagData?.data?.content?.content || "Tag content",
    contentFontColor: tagData?.data?.content?.color || "#ffffff",
    contentFontBold: tagData?.data?.content?.is_bold || false,
    contentFontItalics: tagData?.data?.content?.is_italic || false,
    contentFontUnderline: tagData?.data?.content?.is_underline || false,
    minDiscount:tagData?.data?.min_discount||'',
    maxDiscount:tagData?.data?.max_discount||'',
    isActive: tagData?.data?.is_active === false ? false : true ,
  };

  const handleAddTag = (values) => {
    formData.append("name", values.name);
    formData.append("from_price", values.fromPrice);
    formData.append("to_price", values.toPrice);
    formData.append("min_discount", values.minDiscount);
    formData.append("max_discount", values.maxDiscount);
    if (values?.bgImage === "") {
      formData.append("card_color", values.bgColor);
    }
    if (values?.bgColor === "") {
      formData.append("card_image", values.bgImage);
    }
    formData.append(
      "background_type",
      values.bgColor !== "" ? "color" : "image"
    );
    formData.append(
      "title",
      JSON.stringify({
        content: values.titleContent,
        color: values.titleFontColor,
        is_bold: values.titleFontBold,
        is_italic: values.titleFontItalics,
        is_underline: values.titleFontUnderline,
      })
    );

    formData.append(
      "content",
      JSON.stringify({
        content: values.detialsContent,
        color: values.contentFontColor,
        is_bold: values.contentFontBold,
        is_italic: values.contentFontItalics,
        is_underline: values.contentFontUnderline,
      })
    );
    if (values?.products[0]?.label !== "All") {
      formData.append("is_active", values.isActive);
      values.products?.forEach((e) => {
        formData.append(`products`, e.value);
      });
    }
    if (values?.catogary[0]?.label !== "All") {
      values.catogary?.forEach((e) => {
        formData.append(`categories`, e.value);
      });
    }
    if (values?.productType[0]?.label !== "All") {
      values.productType?.forEach((e) => {
        formData.append(`product_types`, e.value);
      });
    }
    if (!id) {
      addTag(formData)
        .then((res) => {
          toast.success("Tag added");
        })
        .catch((err) => {
          toast.error("Some thing went wrong");
        });
    } else {
      editTag({ data: formData, id: id })
        .then((res) => {
          toast.success("Tag edited");
        })
        .catch((err) => {
          toast.error("Some thing went wrong");
        });
    }
  };

  return (
    <div
      className={`flex-1 w-full h-full ${
        (id && tagLoading) || isLoading || editLoading
          ? "opacity-50 pointer-events-none"
          : "opacity-100 pointer-events-auto"
      }`}
    >
      <div>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={tagValidation}
          onSubmit={handleAddTag}
        >
          {({ setFieldValue, values }) => (
            <Form>
              <div className="flex flex-col-reverse lg:flex-row gap-12">
                <div className="w-full lg:w-[60%]">
                  <ProductDetials
                    formik={formik}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                  <Name values={values} />
                  <Background
                    setFieldValue={setFieldValue}
                    setImage={setImage}
                    values={values}
                  />
                  <Detials
                    name={{
                      data: "titleContent",
                      color: "titleFontColor",
                      bold: "titleFontBold",
                      italic: "titleFontItalics",
                      underline: "titleFontUnderline",
                    }}
                    setFieldValue={setFieldValue}
                    title="Title"
                    values={values}
                  />
                  <Detials
                    title="Content"
                    name={{
                      data: "detialsContent",
                      color: "contentFontColor",
                      bold: "contentFontBold",
                      italic: "contentFontItalics",
                      underline: "contentFontUnderline",
                    }}
                    setFieldValue={setFieldValue}
                    values={values}
                  />
                </div>
                <div className="w-full lg:flex-1">
                  <div className="w-full flex justify-end mb-4 gap-4">
                    <p className="text-base text-darkBlue">Active</p>
                    <ToggleButton
                      isChecked={values.isActive}
                      change={(e) => setFieldValue("isActive", e)}
                      id={'tagActive'}
                    />
                  </div>

                  <div className="sticky top-10 flex flex-col gap-6 items-end">
                    <TagStructure values={values} image={image} />
                    <div className="w-[10rem] hidden lg:block">
                      <SubmitButton
                        content={"Save template"}
                        type="submit"
                        isLoading={isLoading || editLoading}
                      />
                    </div>
                  </div>

                </div>

              </div>
              <FormikContext setFormik={setFormik} />
              <div className="w-[10rem]  mx-auto block lg:hidden">
                      <SubmitButton
                        content={"Save template"}
                        type="submit"
                        isLoading={isLoading || editLoading}
                      />
                    </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default TagTemplete;
