import React from 'react'
import './authLayoutStyle.scss'
import { Outlet } from 'react-router-dom'
const AuthLayout = () => {
  return (
    <div className='min-h-screen w-full flex flex-col justify-between '>
        <div className='w-full h-[5rem] auth-bg'></div>
        <div className='w-full h-full p-8'>
            <Outlet/>
        </div>

        <div className='w-full flex justify-center px-5 py-8'>
             <h2 className='text-sm font-semibold text-darkBlue'>Copyright © 2023 Magicwomen. All rights reserved
</h2>
        </div>
    </div>
  )
}

export default AuthLayout