import { Formik, Form } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import Thumbnail from "./Thumbnail";
import ProductDetials from "./ProductDetials";
import General from "./General";
import PhotosAndVideos from "./PhotosAndVideos";
import Advanced from "./Advanced";
import Button from "../../../components/submitButton/Button";
import * as Yup from "yup";
import SubmitButton from "../../../components/submitButton/SubmitButton";
import {
  useAddProduct,
  useEditProduct,
  useGetCatogeryList,
  useGetProductTypeList,
  useGetSingleProduct,
  useGetVEndorList,
} from "../../../servicesQuery/productQuery/productQuery";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import ToggleButton from "../../../components/toggleButton/ToggleButton";
import { useGetCatSingle } from "../../../servicesQuery/categoryQuery/categoryQuery";
import { FormikContext } from "../../../utilities/resetForm/FormikContext";
import { useGetCategoryList } from "../../../servicesQuery/categoryQuery/categoryQuery";
import { categorySingle } from "../../../servicesQuery/categoryQuery/categoryFunctions";
import Specifications from "./Specifications";
import ProductReview from "../../review/ProductReview";
const productValidationStepOne = Yup.object().shape({
  productNumber: Yup.string().required("Product number is required"),
  name: Yup.string().required("Product Name is required"),
  skuNumber: Yup.string().required("SKU number is required"),
  price: Yup.number().required("Price is required"),
  title: Yup.string().required("Title of description is required"),
  discription: Yup.string().required("Description is required"),
  model: Yup.string().required("Model is required"),
  vendor: Yup.mixed().required("Vendor is required"),
  thumbnail: Yup.mixed().required("Thumbnail is required"),
  discountType: Yup.mixed().when(["discountActive"], {
    is: (value) => value !== 1,
    then: (schema) => schema.required("Discount type is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  discountPerc: Yup.number().when(["discountActive"], {
    is: (value) => value !== 1,
    then: (schema) => schema.required("Discount percentage is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  tax: Yup.mixed().required("Tax class is required"),
  CGST: Yup.number().when(["tax"], {
    is: (value) => value?.label === "CGST and SGST",
    then: (schema) =>
      schema.required("CGST is required").max(100, "Maximum value is 100"),
    otherwise: (schema) => schema.notRequired(),
  }),
  "CGST/SGST": Yup.number().when(["tax"], {
    is: (value) => value?.label === "CGST and SGST",
    then: (schema) =>
      schema.required("CGST and SGST").max(100, "Maximum value is 100"),
    otherwise: (schema) => schema.notRequired(),
  }),
  IGST: Yup.number().when(["tax"], {
    is: (value) => value?.label === "IGST",
    then: (schema) =>
      schema.required("IGST is required").max(100, "Maximum value is 100"),
    otherwise: (schema) => schema.notRequired(),
  }),
  productType: Yup.mixed().required("Product type is required"),
  catogery: Yup.mixed().required("Jewellery category is required"),
  subCatogery: Yup.mixed().required("Jewellery sub category is required"),
  purityOfGold: Yup.mixed().when(["productType"], {
    is: (value) => value?.label === "Gold",
    then: (schema) =>
      schema.required("Purity is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
const productValidationStepTwo = Yup.object().shape({
  weight: Yup.number().required("Weight is required"),
  productType: Yup.mixed().required("Product type is required"),
  catogery: Yup.mixed().required("Jewellery category is required"),
  subCatogery: Yup.mixed().required("Jewellery sub category is required"),
});
const AddEditForm = () => {
  const id = useParams().id;
  const { data: productData } = useGetSingleProduct(id);
  const [image, setImage] = useState("");
  const formData = new FormData();
  const [productId, setProductId] = useState(id ? id : "");
  const [formik, setFormik] = useState(null);
  // const [subCatList, setsubCatList] = useState([{ label: "All", value: "ab345" }]);
  const { data: categoryData, isFetched } = useGetCategoryList({
    page: 10,
    search: "",
    invited: false,
  });
  // let categoryList = [{ label: "All", value: "ab345" }];
  let categoryList = categoryData?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  // let subCatList = [{ label: "All", value: "ab345" }];
  // useEffect(() => {
  //   const fetchApi = async () => {
  //     try {
  //       let subcatData = await categorySingle({ id: formik?.catogery?.value });
  //       if (subcatData?.data?.child_categories?.length) {
  //         let subcat = subcatData?.data?.child_categories?.map((item) => {
  //           return {
  //             label: item.name,
  //             value: item.id,
  //           };
  //         });
  //         subCatList = subCatList.concat(subcat)
  //         // subCatList.concat(
  //         //   subcatData?.data?.child_categories?.map((item) => {
  //         //     return {
  //         //       label: item.name,
  //         //       value: item.id,
  //         //     };
  //         //   })
  //         // );
  //       }
  //     } catch (err) {
  //       console.log(err);
  //     }
  //   };
  //   fetchApi();
  // }, [formik?.catogery?.value]);
  // console.log(subCatList,"+++++++++++++");
  // const {
  //   data: subcatData,
  // } = useGetCatSingle({
  //   id: formik?.catogery?.value,
  // });

  const { data: productType } = useGetProductTypeList();
  const productTypeData = productType?.data?.results?.map((item) => {
    return {
      value: item.id,
      label: item.name,
    };
  });

  const { data: vendor } = useGetVEndorList();
  const vendorData = vendor?.data?.results?.map((item) => {
    return {
      label: item.first_name,
      value: item.id,
    };
  });
  const discountData = [{ label: "Percentage", value: "Percentage" }];

  const taxData = [
    { label: "CGST and SGST", value: "cgst_sgst" },
    { label: "No Tax", value: "no_tax" },
    { label: "IGST", value: "igst" },
  ];

  const purityDatas = [
    { label: "9k", value: "9" },
    { label: "10k", value: "10" },
    { label: "14k", value: "14" },
    { label: "18k", value: "18" },
    { label: "22k", value: "22" },
  ];

  const [index, setIndex] = useState("general");

  const initialValues = {
    tags: "",
    thumbnail: productData?.data?.image_file || "",
    productType:
      productTypeData?.find(
        (e) => e.value === productData?.data?.product_type
      ) || undefined,
    catogery:
      categoryList?.find((e) => e?.label === productData?.data?.parent_name) ||
      undefined,
    productNumber: productData?.data?.product_id || "",
    skuNumber: productData?.data?.sku || "",
    name: productData?.data?.name || "",
    title: productData?.data?.description_title || "",
    discription: productData?.data?.description || "",
    vendor:
      vendorData?.find((e) => e.value === productData?.data?.vendor) ||
      undefined,
    model: productData?.data?.model || "",
    price: productData?.data?.price || "",
    discountType: discountData?.find(
      (e) => e.value === productData?.data?.product_discount?.discount_type
    ) || { label: "Percentage", value: "Percentage" },
    discountPerc: productData?.data?.product_discount?.discount_percentage || 0,
    tax: taxData.find((e) => e.value === productData?.data?.tax_class) || {
      label: "CGST and SGST",
      value: "cgst_sgst",
    },
    CGST: productData?.data?.cgst || "",
    "CGST/SGST": productData?.data?.sgst || "",
    IGST: productData?.data?.igst || "",
    weight: productData?.data?.weight || "",
    // purityOfGold: productData?.data?.gold_purity || "",
    purityOfGold: purityDatas?.find((e) => Number(e.value) === Number(productData?.data?.gold_purity)) || "",
    purityOfSliver: productData?.data?.silver_purity || "",
    purityOfPlatinum: productData?.data?.platinum_purity || "",
    priceOfDiamond: productData?.data?.diamond_price_per_carat || "",
    diamondCaratRange: productData?.data?.diamond_carat_range || "",
    priceOfGemssotne: productData?.data?.gemstone_price_per_carat || "",
    gemsstoneCarat: productData?.data?.gemstone_carat_range || "",
    wastage: productData?.data?.wastage || "",
    isActive: productData?.data?.is_active === false ? false : true,
    discountActive: productData?.data?.product_discount?.validity || 2,
    all_tags: productData?.data?.all_tags || [],
    delivery_time: productData?.data?.delivery_time || [10,30],
  };

  const { mutateAsync: addProduct, isLoading } = useAddProduct();
  const { mutateAsync: editProduct, isLoading: editLoading } = useEditProduct();
  const handleAddProduct = (values, { resetForm }) => {
    if (!productId) {
      formData.append(
        "product_discount.discount_type",
        values?.discountType?.label
      );
      formData.append(
        "product_discount.discount_percentage",
        values?.discountPerc
      );
      formData.append("product_discount.validity", values?.discountActive);
      formData.append("product_id", values?.productNumber);
      formData.append("image_file", image);
      formData.append("price", values?.price);
      formData.append("weight", values?.weight);
      formData.append("gold_purity", values?.purityOfGold?.value? values?.purityOfGold?.value : "");
      formData.append("silver_purity", values.purityOfSliver);
      formData.append("platinum_purity", values.purityOfPlatinum);
      formData.append("diamond_price_per_carat", values.priceOfDiamond);
      formData.append("diamond_carat_range", values.diamondCaratRange);
      formData.append("gemstone_price_per_carat", values.priceOfGemssotne);
      formData.append("gemstone_carat_range", values.gemsstoneCarat);
      formData.append("wastage", values.wastage);
      formData.append("name", values.name);
      formData.append("description", values.discription);
      formData.append("description_title", values.title);
      formData.append("is_active", values.isActive);
      formData.append("category", values.subCatogery.value);
      formData.append("product_type", values.productType.value);
      formData.append("model", values.model);
      formData.append("sku", values.skuNumber);
      formData.append("cgst", values.CGST);
      formData.append("cgst", values.CGST);
      formData.append("sgst", values["CGST/SGST"]);
      formData.append("igst", values.IGST);
      formData.append("tax_class", values.tax.value);
      formData.append("vendor", values.vendor.value);
      formData.append("delivery_time", JSON.stringify(values.delivery_time));
      if(values?.all_tags && values?.all_tags.length){
        values.all_tags.map((t)=> 
          formData.append("tag_ids", t.value)
        )
      }

      addProduct(formData)
        .then((res) => {
          toast.success("Product added");
          // setIndex("photos");
          setIndex("specifications");
          setProductId(res?.data?.id);
          // resetForm();
        })
        .catch((err) => {
          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            toast.error("Something went wrong");
          }
        });
    } else {
      formData.append(
        "product_discount.discount_type",
        values.discountType.label
      );
      formData.append(
        "product_discount.discount_percentage",
        values.discountPerc
      );
      formData.append("product_discount.validity", values.discountActive);
      formData.append("product_id", values.productNumber);
      if (image) {
        formData.append("image_file", image);
      }
      formData.append("price", values.price);
      formData.append("weight", values.weight);
      formData.append("gold_purity", values?.purityOfGold?.value? values?.purityOfGold?.value : "");
      formData.append("silver_purity", values.purityOfSliver);
      formData.append("platinum_purity", values.purityOfPlatinum);
      formData.append("diamond_price_per_carat", values.priceOfDiamond);
      formData.append("diamond_carat_range", values.diamondCaratRange);
      formData.append("gemstone_price_per_carat", values.priceOfGemssotne);
      formData.append("is_active", values.isActive);
      formData.append("gemstone_carat_range", values.gemsstoneCarat);
      formData.append("wastage", values.wastage);
      formData.append("name", values.name);
      formData.append("description", values.discription);
      formData.append("description_title", values.title);
      formData.append("category", values.subCatogery.value);
      formData.append("product_type", values.productType.value);
      formData.append("model", values.model);
      formData.append("sku", values.skuNumber);
      formData.append("cgst", values.CGST);
      formData.append("sgst", values["CGST/SGST"]);
      formData.append("igst", values.IGST);
      formData.append("tax_class", values.tax.value);
      formData.append("vendor", values.vendor.value);
      formData.append("delivery_time", JSON.stringify(values.delivery_time));
      if(values?.all_tags && values?.all_tags.length){
        values.all_tags.map((t)=> 
          formData.append("tag_ids", t.value)
        )
      }

      editProduct({ data: formData, id: productId })
        .then((res) => {
          toast.success("Product edited");
          resetForm();
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
  };
  const handleTab = useCallback((tab) => {
    setIndex(tab);
  }, []);
  
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={
        index === "general"
          ? productValidationStepOne
          : productValidationStepTwo
      }
      onSubmit={handleAddProduct}
      validateOnMount
    >
      {({
        handleChange,
        validateForm,
        setTouched,
        setFieldValue,
        isValid,
        values,
      }) => (
        <Form>
          <div className="flex-col hxl:flex-row flex gap-6">
            <div className="hxl:w-[16rem] w-full flex flex-col gap-8">
              <Thumbnail
                setImage={setImage}
                change={handleChange}
                values={values}
                image={productData?.data?.image_file}
                id={id}
              />
              <ProductDetials
                setFieldValue={setFieldValue}
                change={handleChange}
                values={values}
                productData={productData}
                data={{
                  catData: categoryList,
                  prodTyoeData: productTypeData,
                  // subCat: subCatList,
                }}
              />
            </div>
            <div className="flex-1 w-[75%]">
              <div className="w-full mb-10 flex justify-between gap-6">
                <div className="flex items-center w-full gap-y-3 gap-x-2 justify-between md:justify-start md:gap-12 ">
                  <button
                    onClick={(e) => {
                      handleTab("general");
                    }}
                    className={`text-xs md:text-lg ${
                      index === "general" ? "tab-active" : ""
                    }  transition-all  hover:text-darkYellow text-[gray] font-bold`}
                    type="button"
                  >
                    General
                  </button>
                  <button
                    onClick={(e) => {
                      handleTab("specifications");
                    }}
                    className={`text-xs md:text-lg ${
                      index === "specifications" ? "tab-active" : ""
                    }  transition-all  ${
                      !isValid ? "hover:none" : "hover:text-darkYellow "
                    }   text-[gray] font-bold`}
                    type="button"
                    disabled={!isValid}
                  >
                    Product Specifications
                  </button>
                  {/* <button
                    onClick={(e) => {
                      handleTab("advanced");
                    }}
                    className={`text-xs md:text-lg ${
                      index === "advanced" ? "tab-active" : ""
                    }  transition-all  ${
                      !isValid ? "hover:none" : "hover:text-darkYellow "
                    }   text-[gray] font-bold`}
                    type="button"
                    disabled={!isValid}
                  >
                    Advanced
                  </button> */}
                  <button
                    onClick={(e) => {
                      handleTab("photos");
                    }}
                    className={`text-xs md:text-lg ${
                      index === "photos" ? "tab-active" : ""
                    }  transition-all ${
                      id ? "hover:text-darkYellow" : "hover:none"
                    }  text-[gray] font-bold`}
                    type="button"
                    disabled={id ? false : true}
                  >
                    Photos and Videos
                  </button>
                  {id && (
                    <button
                      onClick={(e) => {
                        handleTab("reviews");
                      }}
                      className={`text-xs md:text-lg ${
                        index === "reviews" ? "tab-active" : ""
                      }  transition-all ${
                        id ? "hover:text-darkYellow" : "hover:none"
                      }  text-[gray] font-bold`}
                      type="button"
                      disabled={id ? false : true}
                    >
                      Reviews
                    </button>
                  )}
                </div>
                {/* <div className="flex items-center gap-3 md:gap-6">
                  <p
                    className={`${
                      values.isActive ? "text-status" : "text-error"
                    } transition-all font-semibold text-xs md:text-base`}
                  >
                    {values.isActive ? "Active" : "Inactive"}
                  </p>
                  <ToggleButton
                    change={(e) => setFieldValue("isActive", e)}
                    isChecked={values.isActive ? true : false}
                    id={"productActive"}
                  />
                </div> */}
              </div>
              <div>
                {index === "general" && (
                  <General
                    setFieldValue={setFieldValue}
                    values={values}
                    data={{ vendorData, discountData, taxData,purityDatas }}
                  />
                )}
                {index === "specifications" && (
                  <Specifications
                    setFieldValue={setFieldValue}
                    values={values}
                    product={productId}
                  />
                )}
                {/* {index === "advanced" && (
                  <Advanced setFieldValue={setFieldValue} values={values} />
                )} */}
                {index === "photos" && (
                  <PhotosAndVideos
                    id={productId || id}
                    data={productData?.data}
                  />
                )}

                {id && index === "reviews" && <ProductReview id={id} />}
              </div>

              <div className="flex justify-end mt-6">
                {index === "general" ? (
                  <div className="flex justify-end">
                    <SubmitButton
                      type={"submit"}
                      isLoading={isLoading || editLoading}
                      content={id ? "Edit product" : "Add product"}
                    />
                  </div>
                ) : // <Button
                //   style={
                //     "transition-all  w-[10rem]  hover:opacity-70 py-2 px-6  px-10 py-1 text-base text-white add-live-btn bg-btn-gradient rounded-2xl"
                //   }
                //   content="Next"
                //   onClick={() => {
                //     validateForm().then((res) => {
                //       setTouched(res);
                //       if (isValid) {
                //         // setIndex("advanced");
                //         setIndex("specifications");
                //       }
                //     });
                //   }}
                //   type={"button "}
                // />
                index === "specifications" ? (
                  <div className="w-[10rem flex items-center gap-6">
                    <Button
                      style={
                        "transition-all  w-[10rem]  hover:opacity-70 py-2 px-6  px-10 py-1 text-base text-white add-live-btn bg-btn-gradient rounded-2xl"
                      }
                      content="Back"
                      onClick={() => setIndex("general")}
                      type={"button"}
                    />

                    <Button
                      style={
                        "transition-all  w-[10rem]  hover:opacity-70 py-2 px-6  px-10 py-1 text-base text-white add-live-btn bg-btn-gradient rounded-2xl"
                      }
                      content="Next"
                      onClick={() => {
                        setIndex("photos");
                      }}
                      type={"button "}
                    />
                  </div>
                ) : (
                  index === "photos" && (
                    <div className="w-[10rem flex items-center gap-6">
                      <Button
                        style={
                          "transition-all  w-[10rem]  hover:opacity-70 py-2 px-6  px-10 py-1 text-base text-white add-live-btn bg-btn-gradient rounded-2xl"
                        }
                        content="Back"
                        onClick={() => setIndex("general")}
                        type={"button"}
                      />
                      {/* <SubmitButton
                        type={"submit"}
                        isLoading={isLoading || editLoading}
                        content={id ? "Edit product" : "Add product"}
                      /> */}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <FormikContext setFormik={(e) => setFormik(e.values)} />
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(AddEditForm);
