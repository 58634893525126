import React from "react";
import ModalLayout from "../../../components/customModal/CustomModal";
import { Formik, Form } from "formik";
import ErrorMessageComponent from "../../../components/errorMessage/ErrorMessageComponent";
import SubmitButton from "../../../components/submitButton/SubmitButton";
import BorderdInputField from "../../../components/customField/BorderdInputField";
import * as Yup from "yup";
import {
  useAddUsers,
  useGetRoles,
} from "../../../servicesQuery/roleQuery/roleQuery";
import { toast } from "react-hot-toast";
import {ResetForm} from "../../../utilities/resetForm/FormikContext";
import { useEditUser } from "../../../servicesQuery/userQuery/userQuery";
const AddUserModal = ({ open, setOpen, roleId, isEdit, data }) => {
  const validation = () => {
    return Yup.object().shape({
      name: Yup.string().required("User name  is required"),
      email: Yup.string().required("Email address is required"),
      password: Yup.string().required("Password is required"),
    });
  };
  const initialValue = {
    name: data?.full_name || "",
    email: data?.email || "",
    password: "",
  };


  const { mutateAsync: addUsers, isLoading, isSuccess } = useAddUsers();
  const { mutateAsync: editUsers, isLoading: editLoading } = useEditUser();
  const formData = new FormData();
  const handleSubmit = (values, { resetForm }) => {
    formData.append("full_name", values.name);
    formData.append("group_id", roleId);
    formData.append("email", values.email);
    formData.append("password", values.password);

    if (isEdit) {
      editUsers({
        id: data?.id,
        data: formData,
      })
        .then((res) => {
          toast.success("User Edited");
          resetForm();
          setOpen(false);
        })
        .catch((error) => {
          if(error?.response?.data?.email){
            toast.error(error?.response?.data?.email[0]);
          }else{
            toast.error("Something went wrong, please  try again");
          }
        });
    } else {
      addUsers(formData) 
        .then((res) => {
          toast.success("User added");
          resetForm();
          setOpen(false);
        })
        .catch((error) => {
          if(error?.response?.data?.email){
            toast.error(error?.response?.data?.email[0]);
          }else{
            toast.error("Something went wrong, please  try again");
          }
        });
    }
  };
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="bg-primary  rounded-xl  w-[90%] hxl:w-auto ">
        <div className="w-full flex justify-between p-6 border-b-[.5px] border-darkBlue border-opacity-20 ">
          <h2 className="text-lg text-darkBlue font-bold">
            {isEdit ? "Edit user" : "Add New User"}
          </h2>

          <div
            className="cursor-pointer "
            onClick={() => {
              setOpen(false);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3554 8.00005L15.4665 2.88894C16.1109 2.24449 16.1109 1.17783 15.4665 0.533382C14.822 -0.111062 13.7554 -0.111062 13.1109 0.533382L7.9998 5.64449L2.88869 0.533382C2.24425 -0.111062 1.17758 -0.111062 0.533138 0.533382C-0.111306 1.17783 -0.111306 2.24449 0.533138 2.88894L5.64425 8.00005L0.533138 13.1112C-0.111306 13.7556 -0.111306 14.8223 0.533138 15.4667C0.866471 15.8 1.28869 15.9556 1.71092 15.9556C2.13314 15.9556 2.55536 15.8 2.88869 15.4667L7.9998 10.3556L13.1109 15.4667C13.4442 15.8 13.8665 15.9556 14.2887 15.9556C14.7109 15.9556 15.1331 15.8 15.4665 15.4667C16.1109 14.8223 16.1109 13.7556 15.4665 13.1112L10.3554 8.00005Z"
                fill="#07415C"
              />
            </svg>
          </div>
        </div>
        <div className="overflow-y-scroll  p-6 ">
          <Formik
            initialValues={initialValue}
            validationSchema={validation}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ values }) => (
              <Form>
                <ResetForm isOpen={open} />
                <div className="flex gap-6  flex-col sm:flex-row">
                  <div className="w-full ">
                    <label
                      className=" text-secondary text-base inline-block mb-2"
                      htmlFor="name"
                    >
                      Name of the User
                    </label>
                    <BorderdInputField
                      type={"text"}
                      id="name"
                      name={"name"}
                      placeholder="Enter name of the user"
                      value={values?.name}
                    />
                    <ErrorMessageComponent name={"name"} />
                  </div>
                  <div className="w-full ">
                    <label
                      className=" text-secondary text-base inline-block mb-2"
                      htmlFor="name"
                    >
                      Email id
                    </label>
                    <BorderdInputField
                      type={"text"}
                      id="email"
                      name={"email"}
                      placeholder="Enter email id"
                      value={values?.email}
                    />
                    <ErrorMessageComponent name={"email"} />
                  </div>
                </div>
                <div className="flex gap-6  flex-col sm:flex-row">
                  <div className="w-full ">
                    <label
                      className=" text-secondary text-base inline-block mb-2"
                      htmlFor="name"
                    >
                      Password
                    </label>
                    <BorderdInputField
                      type={"password"}
                      id="password"
                      name={"password"}
                      placeholder="Enter password"
                    />
                    <ErrorMessageComponent name={"password"} />
                  </div>
                </div>
                {/* <div className="flex gap-6  flex-col sm:flex-row">
                <div className=" w-full sm:w-[48%] ">
                  <label
                    className=" text-secondary text-base inline-block mb-2"
                    htmlFor="name"
                  >
                    Status
                  </label>
                  <CustomSelect
                    placeholder={"Select status"}
                    height="h-[2.5rem]"
                    items={[
                      {
                        content: "Active",
                        id: 1,
                      },
                      {
                        content: "Inactive",
                        id: 2,
                      },
                    ]}
                    onChange={(e)=>{
                      setFieldValue('status',e.id)
                 }}
                    isAnimaptedPlaceholder={false}
                  />
                  <ErrorMessageComponent name={'status'}/>
                </div>
              </div> */}
                <div className="w-full sm:w-[48%]">
                  <SubmitButton
                    width="w-full"
                    type="submit"
                    content={isEdit?'Edit user':"Add user"}
                    isLoading={isLoading||editLoading}
                  />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </ModalLayout>
  );
};

export default AddUserModal;
