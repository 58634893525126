import React, { useEffect, useState } from "react";

const ToggleButton = ({ isChecked = false, change, id }) => {
  const [checked, setChecked] = useState(isChecked);
  useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);
  return (
    <div>
      {" "}
      <div className="">
        <input
          onChange={() => {
            setChecked(!checked);
            change(!checked);
          }}
          hidden
          type="checkbox"
          id={id}
          checked={checked}
        />
        <label htmlFor={id} className="cursor-pointer transition-all">
          <div
            className={`md:w-[3rem] md:h-[1.5rem] w-[2rem]  h-[1.1rem] bg-white flex  rounded-xl border-[1px] p-[.1rem] relative`}
          >
            <div
              className={` md:w-[1.2rem]  md:h-[1.2rem] w-[.8rem] h-[.8rem] rounded-full ${
                checked ? "bg-darkYellow" : "bg-darkBlue"
              } absolute top-[.1rem]  ${
                checked ? "right-[.1rem]" : "left-[.1rem]"
              } transition-all`}
            ></div>
          </div>
        </label>
      </div>
    </div>
  );
};

export default React.memo(ToggleButton);
