const restrictAll = (options, selected) => {
    if (selected.find((e) => e.label === "All")) {
      return true;
    } else if (selected.find((e) => e.label !== "All")) {
      if (options.label === "All") {
        return true;
      }
    }
  };

  export {
    restrictAll
  }