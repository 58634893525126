import React from "react";

const SubmitButton = ({type,content,isLoading,disabled,width,height}) => {
  return (
    <button
      className={`submit-btn rounded-full bg-btn-gradient ${width ?width :''} ${height?height:''}  ${
        isLoading ? "opacity-60 hover:opacity:60" : "hover:opacity-70 active:scale-95"
      } flex justify-center`}
      disabled={isLoading||disabled}
      type={type}
    >
      {isLoading ? (
        <div className="flex gap-3 items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xlink="http://www.w3.org/1999/xlink"
            width="24px"
            height="24px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
          >
            <circle
              cx="50"
              cy="50"
              fill="none"
              stroke="white"
              strokeWidth="8"
              r="29"
              strokeDasharray="136.659280431156 47.553093477052"
            >
              <animateTransform
                attributeName="transform"
                type="rotate"
                repeatCount="indefinite"
                dur="2.6315789473684212s"
                values="0 50 50;360 50 50"
                keyTimes="0;1"
              ></animateTransform>
            </circle>
          </svg>
          <span className="text-sm"> please wait...</span>
        </div>
      ) : (
        content
      )}
    </button>
  );
};

export default SubmitButton;
