import {
    DownloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
    DeleteOutlined
  } from '@ant-design/icons';
  import React, { useEffect } from 'react';
  import { Image, Space } from 'antd';
  import toast from 'react-hot-toast';
import { useDeleteReviewImg } from '../../servicesQuery/reviewQuery/reviewQuery';
import { useState } from 'react';
import { EyeOutlined } from '@ant-design/icons';


  const ReviewImagePreview = ({img,id}) => {
    const [visible,setVisible] = useState(false)
    const { mutateAsync: deleteReviewImg, isLoading } = useDeleteReviewImg();
    // or you can download flipped and rotated image
    // https://codesandbox.io/s/zi-ding-yi-gong-ju-lan-antd-5-7-0-forked-c9jvmp
    useEffect(() => {
      if (visible) {
        document.querySelector("body").style.overflow = "hidden";
      }else{
        document.querySelector("body").style.overflowY = "scroll";
      }
    }, [visible]);

    const onDownload = () => {
      fetch(img)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.download = 'image.png';
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(url);
          link.remove();
        });
    };

    const onDelete = ()=> {
        console.log("check");
        toast.promise(deleteReviewImg(id)
                .then((res) => {
                    setVisible(false);
                })
                , {
                loading: "deleting review image",
                success: "review image Deleted",
                error: "Something went wrong",
          });
    }
    return (
      <Image
        width={50}
        className="rounded-lg"
        src={img}
        preview={{
          mask: <div className="custom-mask"><EyeOutlined /></div>,
          onVisibleChange: (visib,prevVisible)=> setVisible(visib),
          visible: visible,
          toolbarRender: (
            _,
            {
              transform: { scale },
              actions: { onFlipY, onFlipX, onRotateLeft, onRotateRight, onZoomOut, onZoomIn },
            },
          ) => (
            <Space size={12} className="toolbar-wrapper">
              <DeleteOutlined onClick={onDelete} />
              <DownloadOutlined onClick={onDownload} />
              <SwapOutlined rotate={90} onClick={onFlipY} />
              <SwapOutlined onClick={onFlipX} />
              <RotateLeftOutlined onClick={onRotateLeft} />
              <RotateRightOutlined onClick={onRotateRight} />
              <ZoomOutOutlined disabled={scale === 1} onClick={onZoomOut} />
              <ZoomInOutlined disabled={scale === 50} onClick={onZoomIn} />
            </Space>
          ),
        }}
      />
    );
  };
  export default ReviewImagePreview;

  
  