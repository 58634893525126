import { useMutation, useQuery, useQueryClient } from "react-query";
import {
  addRole,
  addUser,
  editUserRole,
  getRole,
  getRoleUsers,
  getSingleRoles,
  userDelete,
} from "./roleUrls";

const useAddRoles = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => addRole(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getRoles");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};
const useGetRoles = (data) => {
  return useQuery(["getRoles", data], () => getRole(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
const useGetRolesUsers = (data) => {
  return useQuery(["getRolesUsers", data], () => getRoleUsers(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
const useGetSingleRole = (data) => {
  return useQuery(["getSingleRoles", data], () => getSingleRoles(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
const useAddUsers = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => addUser(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getRolesUsers");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};
const useEditRole = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => editUserRole(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getRoles");
      queryClient.invalidateQueries("getSingleRoles");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

const useDeleteUser = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => userDelete(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getRolesUsers");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

export {
  useAddRoles,
  useGetRoles,
  useGetRolesUsers,
  useGetSingleRole,
  useAddUsers,
  useEditRole,
  useDeleteUser
};
