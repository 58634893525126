import { Formik, Form } from "formik";
import React, { useState } from "react";
import CouponImage from "../../subPagesComponents/CouponImage";
import DiscountSection from "../../subPagesComponents/DiscountSection";
import GeneralSection from "./GeneralSection";
import ProductDetials from "./ProductDetials";
import SubmitButton from "../../../../../components/submitButton/SubmitButton";
import * as Yup from "yup";
import {
  useGetCatogeryList,
  useGetProductList,
  useGetProductTypeList,
} from "../../../../../servicesQuery/productQuery/productQuery";
import { FormikContext } from "../../../../../utilities/resetForm/FormikContext";
import {
  useAddCoupons,
  useEditCoupons,
  useGetSingleCoupons,
} from "../../../../../servicesQuery/couponQuery/CouponQuery";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import ToggleButton from "../../../../../components/toggleButton/ToggleButton";
import { productLists } from "../../../../../servicesQuery/productQuery/productFunctions";

const couponValidationStepTwo = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  code: Yup.string()
    .matches(/^[A-Z0-9]+$/, "Only uppercase letters and numbers are allowed")
    .min(5, "Minium 5 values required")
    .max(20, "Only allowed 20 charactors")
    .required("Code is required"),
  expire: Yup.date().when(["noExpire"], {
    is: (value) => !value,
    then: (schema) =>
      schema
        .min(dayjs(), "Date should be in future")
        .required("Expiry is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  limit: Yup.string().when(["allLimit"], {
    is: (value) => !value,
    then: (schema) => schema.required("Limit is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  discription: Yup.string().required("Description is required"),
  catogary: Yup.array()
    .min(1, "Minimum one catogery is required")
    .required("Category is required"),
  productType: Yup.array()
    .min(1, "Minimum one product type is required")
    .required("Product type is required"),
  product: Yup.array()
    .min(1, "Minimum one product is required")
    .required("product is required"),
  discountType: Yup.string().required("Discount type is required"),
  discValue: Yup.string().when(["discountType"], {
    is: (value) => value === "percentage" || value === "fixed",
    then: (schema) =>
      schema
        .required("Discount value is required")
        .max(100, "Discount value should be percentage between 0-100"),
    otherwise: (schema) => schema.notRequired(),
  }),
  minOrderAmount: Yup.string().when(["discountType"], {
    is: (value) =>
      value === "percentage" || value === "free shipping" || value === "fixed",
    then: (schema) => schema.required("Minimum order amount is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  maxOrderAmount: Yup.string().when("discountType", {
    is: (value) => value === "percentage",
    then: (schema) => schema.required("Maximum order amount is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  totalOrderHistory: Yup.string().required("Total order history is required"),
});

const PremiumCouponAddEdit = () => {
  const id = useParams().id;
  const { data, isLoading } = useGetSingleCoupons(id);
  const formData = new FormData();
  const initialValues = {
    name: data?.data?.name || "",
    code: data?.data?.code || "",
    expire: data?.data?.expires_on || "",
    limit: data?.data?.usage_limit_per_user || "",
    discription: data?.data?.description || "",
    catogary:
      data?.data?.coupon_category?.length === 0
        ? [{ label: "All", value: "all" }]
        : data?.data?.coupon_category?.map((e) => ({
            label: e.name,
            value: e.id,
          })) || [],
    productType:
      data?.data?.coupon_product_type?.length === 0
        ? [{ label: "All", value: "all" }]
        : data?.data?.coupon_product_type?.map((e) => ({
            label: e.name,
            value: e.id,
          })) || [],
    product:
      data?.data?.coupon_products?.length === 0
        ? [{ label: "All", value: "all" }]
        : data?.data?.coupon_products?.map((e) => ({
            label: e.name,
            value: e.id,
          })) || [],
    discountType: data?.data?.discount_type || "fixed",
    discValue: data?.data?.current_discount_value || "",
    minOrderAmount: data?.data?.min_order_amount || "",
    maxOrderAmount: data?.data?.maximum_discount_value || "",
    is_active: data?.data?.is_active === false ? false : true,
    totalOrderHistory: data?.data?.order_history_amount || "",
    allLimit: data?.data?.usage_limit_per_user === 0 ? true : false,
    noExpire: data?.data?.expires_on === null ? true : false,
  };
  const navigate = useNavigate();
  const [formik, setFormik] = useState();
  const { data: typeData, isLoading: typeLoading } = useGetProductTypeList();
  const productType = [
    { label: "All", value: typeData?.data?.results?.length + 1 },
  ].concat(
    typeData?.data?.results?.map((e) => {
      return {
        value: e.id,
        label: e.name,
      };
    })
  );

  const { data: catData, isLoading: catLoading } = useGetCatogeryList();

  const catType = [{ label: "All", value: "bc654" }].concat(
    catData?.data?.map((e) => {
      return {
        value: e.id,
        label: e.parent + " - " + e.name,
      };
    })
  );

  // const productDataValue = async (inputValue = "", callback) => {
  //   try {
  //     const data = await productLists({
  //       page: 1,
  //       search: inputValue,
  //       category: formik?.values?.catogary?.map((e) => e.value),
  //       producyType: formik?.values?.productType?.map((e) => e.value),
  //     });
  //     callback(
  //       [{ label: "All", value: "ab345" }].concat(
  //         data?.data?.results?.map((e) => {
  //           return {
  //             label: e.name,
  //             value: e.id,
  //           };
  //         })
  //       )
  //     );
  //     return [{ label: "All", value: "ab345" }];
  //   } catch (error) {
  //     callback([]);
  //   }
  // };

  const { mutateAsync: addCoupons, isLoading: addLoding } = useAddCoupons();

  const { mutateAsync: editCoupons, isLoading: editLoding } = useEditCoupons();

  const handleAddCoupon = (values, { resetForm }) => {
    if (!id) {
      let coupon_category = []
      if(values.catogary[0].label !== "All"){
        coupon_category = values?.catogary?.map((e) => e.value)
      }
      let coupon_product_type = []
      if(values.productType[0].label !== "All"){
        coupon_product_type = values?.productType?.map((e) => e.value)
      }
      let coupon_products = []
      if(values.product[0].label !== "All"){
        coupon_products = values?.product?.map((e) => e.value)
      }
      const data = {
        code: values?.code,
        discount_type: values?.discountType,

        expires_on: values?.expire
          ? dayjs(values?.expire).format("YYYY-MM-DDThh:mm:ss")
          : "",

        ...(!values?.allLimit && { usage_limit_per_user: values.limit }),
        discount_value:
          values.discountType === "percentage"
            ? values?.discValue / 100
            : values?.discValue || 0,
        name: values?.name,
        description: values?.discription,
        min_order_amount: values?.minOrderAmount || 0,
        coupon_category: coupon_category,
        coupon_product_type: coupon_product_type,
        coupon_products:coupon_products,
        // ...(values.catogary[0].label !== "All" && {
        //   coupon_category: values?.catogary?.map((e) => e.value),
        // }),
        // ...(values.productType[0].label !== "All" && {
        //   coupon_product_type: values?.productType?.map((e) => e.value),
        // }),
        // ...(values.product[0].label !== "All" && {
        //   coupon_products: values?.product?.map((e) => e.value),
        // }),
        coupon_type: "premium",
        is_active: values.is_active,
        ...(values.discountType === "percentage" && {
          maximum_discount_value: parseInt(values?.maxOrderAmount),
        }),
        order_history_amount: values?.totalOrderHistory || 0,
      };

      addCoupons(data)
        .then((res) => {
          toast.success("Coupon Added");
          navigate("/coupon-management/premium-coupons");
          resetForm();
        })
        .catch((err) => {
          if (err.response?.data?.code) {
            toast.error(err.response?.data?.code[0]);
          } else {
            toast.error("Something went wrong");
          }
        });
    } else {
      let coupon_category = []
      if(values.catogary[0].label !== "All"){
        coupon_category = values?.catogary?.map((e) => e.value)
      }
      let coupon_product_type = []
      if(values.productType[0].label !== "All"){
        coupon_product_type = values?.productType?.map((e) => e.value)
      }
      let coupon_products = []
      if(values.product[0].label !== "All"){
        coupon_products = values?.product?.map((e) => e.value)
      }
      const valueData = {
        code: values?.code,
        expires_on: values?.expire
          ? dayjs(values?.expire).format("YYYY-MM-DDThh:mm:ss")
          : "",

        usage_limit_per_user: values.limit ? values.limit : 0,
        discount_value:
          values.discountType === "percentage"
            ? values?.discValue / 100
            : values?.discValue || 0,
        discount_type: values.discountType,

        name: values?.name,
        description: values?.discription,
        min_order_amount: parseInt(values?.minOrderAmount) || 0,
        coupon_category: coupon_category,
        coupon_product_type: coupon_product_type,
        coupon_products:coupon_products,
        // ...(values.catogary[0].label !== "All" && {
        //   coupon_category: values?.catogary?.map((e) => e.value),
        // }),
        // ...(values.productType[0].label !== "All" && {
        //   coupon_product_type: values?.productType?.map((e) => e.value),
        // }),
        // ...(values.product[0].label !== "All" && {
        //   coupon_products: values?.product?.map((e) => e.value),
        // }),
        coupon_type: "premium",
        is_active: values.is_active,
        totalOrderHistory: "",
        ...(values.discountType === "percentage" && {
          maximum_discount_value: parseInt(values?.maxOrderAmount),
        }),
        order_history_amount: values?.totalOrderHistory,
      };

      const data = {
        id: id,
        data: valueData,
      };
      editCoupons(data)
        .then((res) => {
          toast.success("Coupon Edited");
          navigate("/coupon-management/premium-coupons");
          resetForm();
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={couponValidationStepTwo}
      onSubmit={handleAddCoupon}
    >
      {({ setFieldValue, handleChange, values, initialValues }) => (
        <Form
          className={`${
            id && isLoading
              ? "opacity-50 pointer-events-none"
              : "opacity-100 pointer-events-auto"
          } transition`}
        >
          <div className="flex flex-col md:flex-row  gap-6">
            <div className="md:w-[16rem] flex flex-col gap-8">
              <DiscountSection
                setFieldValue={setFieldValue}
                change={handleChange}
                values={values}
              />
            </div>
            <div className="flex-1">
              <div className="w-full flex justify-end mb-4 gap-4">
                <p
                  className={` ${
                    values.is_active ? "text-darkBlue" : "text-error"
                  } text-base `}
                >
                  {values.is_active ? "Active" : "Inactive"}
                </p>

                <ToggleButton
                  isChecked={values.is_active}
                  change={(e) => setFieldValue("is_active", e)}
                  id={"premium"}
                />
              </div>
              <GeneralSection
                values={values}
                setFieldValue={setFieldValue}
                handleChange={handleChange}
                initialValues={initialValues}
              />
              <ProductDetials
                values={values}
                setFieldValue={setFieldValue}
                data={{
                  productType: { productType, typeLoading },
                  catType: { catType, catLoading },
                  // prodData: { productDataValue },
                }}
              />
            </div>
          </div>
          <div className="w-full flex justify-end">
            {" "}
            <div className="w-[20rem] mt-6">
              <SubmitButton
                isLoading={addLoding || editLoding}
                type={"submit"}
                content={id ? "Edit Coupons" : "Add Coupons"}
              />
            </div>
          </div>
          <FormikContext setFormik={setFormik} />
        </Form>
      )}
    </Formik>
  );
};

export default PremiumCouponAddEdit;
