import React, { useEffect, useState } from "react";
import "./dropdownStyle.scss";
const Items = ({
  content,
  setValue,
  setOpen,
  value,
  onclick,
  onchange,
  disabled,
}) => {
  return (
    <div
      title={content.content}
      className={`px-3 py-2 items ${
        value == content.content ? "bg-lightBlue" : ""
      } hover:bg-lightBlue cursor-pointer rounded-md transition-all ${
        disabled
          ? "cursor-normal pointer-events-none opacity-50"
          : "cursor-pointer pointer-events-auto opacity-100"
      } `}
      onClick={(e) => {
        setValue(e.target.title);
        setOpen(false);
        if (onclick) {
          onclick();
        }
        if (onchange) {
          onchange(content);
        }
      }}
      onBlur={() => setOpen(false)}
    >
      {content.content}
    </div>
  );
};
const Dropdown = ({
  options,
  style,
  heigth,
  styles,
  onchange,
  values,
  placeholder,
}) => {
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);

 

  return (
    <div className={`min-w-[8rem]`} tabIndex={2} onBlur={() => setOpen(false)}>
      <div className={`relative ${style} text-xs  `}>
        <div
          className="dropdown-btn py-2 px-4 cursor-pointer"
          onClick={() => setOpen(!open)}
        >
          <div className="flex items-center pointer-events-none justify-between dropdown-main gap-6 cursor-pointer font-semibold">
            <span className={styles}>
              {value !== "" ? value : values() || placeholder}
            </span>
            <svg
              width="14"
              height="7"
              viewBox="0 0 14 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.9401 0.749994L8.05006 5.64C7.47256 6.2175 6.52756 6.2175 5.95006 5.64L1.06006 0.749994"
                stroke="#29273A"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="absolute w-full top-0 left-0 dropdown-section">

        
          <div
            className={`absolute ${
              open ? "dropdown-active" : "dropdown"
            } dropdown-container  z-[99] ${heigth} overflow-y-scroll p-1 top-10 bg-white shadow-md min-w-[8rem] rounded-md flex flex-col gap-1 justify-between`}
          >
            {options &&
              options?.map((item, index) => {
                return (
                  <Items
                    content={item}
                    key={index}
                    value={value}
                    setOpen={setOpen}
                    setValue={setValue}
                    onclick={item?.onclick}
                    onchange={onchange}
                    disabled={item?.disabled}
                  />
                );
              })}
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
