import React from "react";
import eye from "../../../assets/images/commonImages/eye.png";
import { useDeleteImage } from "../../../servicesQuery/productQuery/productQuery";
import { toast } from "react-hot-toast";
const ImageCard = ({ item, setImgOpen, setImage ,id}) => {
  const {mutateAsync:deleteImage}=useDeleteImage()
  const handleDeleteImage=(id)=>{
    toast.promise(deleteImage(id), {
      loading: <b>Deleting image</b>,
      success: <b> Image deleted</b>,
      error: <b>Could not delete.</b>,
    });
  }
  return (
    <div>
      <div className="min-w-[10rem] h-[10rem] relative rounded-lg overflow-hidden img-card">
        <div className="absolute w-full h-full p-2 overlay">
          <div className="w-full h-full  relative z-[1]">
            {id&&<div className="h-[45%] w-full flex justify-end item-start">
              <button type="button" className="h-[15px]" onClick={()=>{
                handleDeleteImage(item.id)
              }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0,0,256,256"
                  width="25px"
                  height="25px"
                  fillRule="nonzero"
                >
                  <g
                    fill="#FFFFFF"
                    fillRule="nonzero"
                    stroke="none"
                    strokeWidth="1"
                    strokeLinecap="butt"
                    strokeLinejoin="miter"
                    strokeMiterlimit="10"
                    strokeDasharray=""
                    strokeDashoffset="0"
                    fontFamily="none"
                    fontWeight="none"
                    fontSize="none"
                    textAnchor="none"
                  >
                    <g transform="scale(8.53333,8.53333)">
                      <path d="M14.98438,2.48633c-0.55152,0.00862 -0.99193,0.46214 -0.98437,1.01367v0.5h-5.5c-0.26757,-0.00363 -0.52543,0.10012 -0.71593,0.28805c-0.1905,0.18793 -0.29774,0.44436 -0.29774,0.71195h-1.48633c-0.36064,-0.0051 -0.69608,0.18438 -0.87789,0.49587c-0.18181,0.3115 -0.18181,0.69676 0,1.00825c0.18181,0.3115 0.51725,0.50097 0.87789,0.49587h18c0.36064,0.0051 0.69608,-0.18438 0.87789,-0.49587c0.18181,-0.3115 0.18181,-0.69676 0,-1.00825c-0.18181,-0.3115 -0.51725,-0.50097 -0.87789,-0.49587h-1.48633c0,-0.26759 -0.10724,-0.52403 -0.29774,-0.71195c-0.1905,-0.18793 -0.44836,-0.29168 -0.71593,-0.28805h-5.5v-0.5c0.0037,-0.2703 -0.10218,-0.53059 -0.29351,-0.72155c-0.19133,-0.19097 -0.45182,-0.29634 -0.72212,-0.29212zM6,9l1.79297,15.23438c0.118,1.007 0.97037,1.76563 1.98438,1.76563h10.44531c1.014,0 1.86538,-0.75862 1.98438,-1.76562l1.79297,-15.23437z"></path>
                    </g>
                  </g>
                </svg>
              </button>
            </div>}
          { id && <div
              onClick={() => {
                setImgOpen(true);
                setImage(item.image);
              }}
              className="h-[55%] flex justify-center"
            >
              <button type="button" className="w-[30px] h-[30px]">
                <img className="w-full" src={eye} alt="" />
              </button>
            </div>}
          </div>
          <div className="absolute w-full h-full bg-[black] opacity-50 top-0 left-0 "></div>
        </div>
        <img
          className="object-fill w-[10rem] h-[10rem]	"
          src={item.image}
          alt=""
        />
      </div>
    </div>
  );
};

export default ImageCard;
