import React, { useState } from "react";

function CategoryAccordian({ mainHead, sub_categories }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="flex justify-center h-full flex-col">
      <div
        className={`flex justify-between items-center cursor-pointer   w-3/4`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <h2 className="text-sm font-semibold text-darkYellow">{mainHead}</h2>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className={`${isOpen? 'transform rotate-180' : ''}`}
        >
          <path
            d="M3.05945 6.6825L7.94945 11.5725C8.52695 12.15 9.47195 12.15 10.0495 11.5725L14.9395 6.6825"
            stroke="#F19A0E"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
      <div
        className={`ml-3 transition-all w-[71.5%] catogery-scroll duration-300 ${
          isOpen ? " max-h-36 overflow-scroll" : "max-h-0 overflow-hidden"
        }`}
      >
        {sub_categories.map((i, index) => (
          <p className="my-3 text-xs font-normal text-[#121212]" key={index}>
            {i.name}
          </p>
        ))}
      </div>
    </div>
  );
}

export default CategoryAccordian;
