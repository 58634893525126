import React from "react";
import CustomTable from "../../components/customTable/CustomTable";
import { Link, useParams } from "react-router-dom";
import placeholder from "../../assets/images/commonImages/imagePlaceholder.png";
import {
  useEditOrder,
  useGetSigleOrder,
} from "../../servicesQuery/orderQuery/OrderQuerys";
import Skeleton from "react-loading-skeleton";
import { limitFraction } from "../../utilities/limitFractionNumber/limitFraction";
import { useState } from "react";
import Button from "../../components/submitButton/Button";
import { toast } from "react-hot-toast";
import { useEffect } from "react";
import { getDynamicHead } from "../couponManagement/couponManagementSubPages/autoCoupons/autoCouponsComponents/utils";
import DynamicColumnFilter from "../../components/customTable/DynamicColumnFilter";

const head1 = [
  {
    key: 0,
    label: "SlNo",
    index: "id",
    width: 6,
    is_checked: true,
  },
  {
    key: 1,
    label: "Image",
    index: "image",
    width: 8,
    is_checked: true,
  },
  {
    key: 2,
    label: "Product Name",
    index: "name",
    width: 12,
    is_checked: true,
  },
  {
    key: 3,
    label: "Category",
    index: "catogery",
    width: 8,
    is_checked: true,
  },
  {
    key: 4,
    label: "Description",
    index: "discription",
    width: 12,
    is_checked: true,
  },

  {
    key: 5,
    label: "Weight",
    index: "weight",
    width: 4,
    is_checked: true,
  },
  {
    key: 6,
    label: "Quantity",
    index: "quantity",
    width: 4,
    is_checked: true,
  },
  {
    key: 7,
    label: "Price",
    index: "price",
    width: 9,
    is_checked: true,
  },
];

const DetialsCardWrap = ({ title, children }) => {
  return (
    <div className="w-full  bg-white rounded-md shadow-md p-6">
      <h1 className="mb-5 text-lg font-bold text-darkBlue">{title}</h1>
      <div className="w-full ">{children}</div>
    </div>
  );
};

const OrderDetials = () => {
  const dynamicHead = getDynamicHead("order_management_details")
  const [head, setHead] = useState(head1)
  const [dynamicData, setDynamicData] = useState(JSON.parse(localStorage.getItem('dynamicData')))
  const id = useParams().id;
  const { data, isLoading, isFetching } = useGetSigleOrder(id);
  const [isSubmit, setIsSubmit] = useState("");
  const [editTrackID, setEditTrackID] = useState(false);
  const [trackID, setTrackID] = useState("");
  const [trackLink, setTrackLink] = useState("");
  const [editTrackLink, setEditTrackLink] = useState(false);
  const { mutateAsync: orderEdit, isLoading: trackLoading } = useEditOrder();
  
  useEffect(()=> {
    if(dynamicHead){
      setHead(dynamicHead)
    }
  },[])

  useEffect(() => {
    if (data?.data) {
      setTrackID(data?.data?.tracking_id);
      let trackingLink = data?.data?.tracking_link
      if(!data?.data?.tracking_link?.includes("https://")){
        trackingLink = "https://" + trackingLink
      }
      setTrackLink(trackingLink);
    }
  }, [data]);

  const handleUpdate = (name) => {
    setIsSubmit(name)
    if ( 
      (name === "track_id" && trackID) ||
      (name === "track_link" && trackLink)
    ) {
      let data = {};
      let success = "";
      if (name === "track_id") {
        data.tracking_id = trackID;
        success = "Tracking ID edited";
      } else if (name === "track_link") {
        let trackingLink = trackLink
        if(!trackLink.includes("https://")){
          trackingLink = "https://" + trackingLink
        }
        data.tracking_link = trackingLink;
        success = "Tracking Link edited";  
      }
      orderEdit({ data: data, id: id })
        .then((res) => {
          toast.success(success);
          if (name === "track_id") {
            setEditTrackID(false);
          } else if (name === "track_link") {
            setEditTrackLink(false);
          }
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
  };

  const ordersItemsBody = () => {
    return data?.data?.order_items?.map((item, index) => {
      return {
        id: {
          isRender: true,
          render: () => {
            return (
              <div>
                <span>{index + 1}</span>
              </div>
            );
          },
        },
        image: {
          isRender: true,
          render: () => {
            return (
              <div className="w-[60px] h-[60px] rounded-md overflow-hidden">
                <img
                  className="w-full"
                  src={
                    item.product.image_file
                      ? item.product.image_file
                      : placeholder
                  }
                  alt=""
                />
              </div>
            );
          },
        },
        name: item.product.name || "No data",
        catogery: item.product.category_name || "No data",
        discription: item.product.description || "nodata",

        weight: item.item_total_weight + " g" || "No data",
        quantity: item.quantity || "No data",
        price: "INR " + limitFraction(item.price) || "nodata",
      };
    });
  };

  console.log(trackLink,"trackLink");
  return (
    <div className="w-full">
      <div className="w-full flex flex-col gap-8">
        <div className="flex w-full items-center justify-between gap-6">
          <div className="flex gap-6 items-center">
            <h1 className="text-sm text-darkBlue">Order ID : </h1>
            <p className="text-sm text-darkBlue font-bold">
              {data?.data?.order_id}
            </p>
          </div>
        </div>

        <div className="p-6 bg-white rounded-lg shadow-md">
          <div className="flex items-center justify-between w-full">
            <div className="text-sm font-bold mb-6">
              <h2>Items</h2>
            </div>
            <div className="">
              <DynamicColumnFilter head={head} name="order_management_details" setHead={setHead} dynamicData={dynamicData} setDynamicData={setDynamicData} />
            </div>
          </div>
          <div>
            <CustomTable
              head={head}
              loading={isFetching || isLoading}
              body={ordersItemsBody()}
              dataLength={data?.data?.order_items?.length}
              height="max-h-[15rem]"
              is_dynamic={true}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 hxl:grid-cols-2 gap-6">
          {isLoading ? (
            <Skeleton height={230} />
          ) : (
            <DetialsCardWrap title="Customer Details">
              <div className="flex gap-7 flex-col justify-between">
                <div className="flex w-full items-center justify-between gap-5 ">
                  <p className="text-sm ">Customer Name</p>
                  <p className="text-sm font-bold">
                    {data?.data?.user?.full_name}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between gap-5 ">
                  <p className="text-sm ">Mobile Number</p>
                  <p className="text-sm font-bold">
                    {data?.data?.user?.phone_number}{" "}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between gap-5 ">
                  <p className="text-sm ">Email ID</p>
                  <p className="text-sm font-bold">{data?.data?.user?.email}</p>
                </div>
              </div>
            </DetialsCardWrap>
          )}
          {isLoading ? (
            <Skeleton height={230} />
          ) : (
            <DetialsCardWrap title="Delivery Address">
              <div className="flex w-full items-center justify-between gap-5 mb-5">
                <p className="text-base ">
                  {data?.data?.address?.name || "no data"} |{" "}
                  {data?.data?.address?.mobile || "no data"}
                </p>
                <p className="text-xs font-bold font-semibold px-5 py-1 bg-lightYellow text-darkYellow">
                  {data?.data?.address?.address_type}
                </p>
              </div>
              <div className=" w-full   ">
                <p className="text-sm  mb-3">
                  {data?.data?.address?.build_flatname},
                  {data?.data?.address?.street}{" "}
                </p>
                <p className="text-sm mb-3">
                  {data?.data?.address?.landmark},{" "}
                </p>
                <p className="text-sm mb-3">
                  {data?.data?.address?.state}, {data?.data?.address?.pincode}
                </p>
              </div>
              <div className="flex w-full items-center justify-between gap-5 mb-4">
                <p className="text-sm w-[22%]">Tracking ID</p>
                <div className="flex items-start gap-2">
                  <>
                    {editTrackID ? (
                      <div className="flex justify-center flex-col">
                        <input
                          type="text"
                          placeholder="enter tracking id"
                          onChange={(e) => setTrackID(e.target.value)}
                          value={trackID}
                          className="rounded-md border-[1px] bg-opacity-0 focus-within:border-[#575252]  transition-all    border-[#bab1b1] overflow-hidden px-4 py-[8px] w-full text-sm placeholder:text-darkBlue placeholder:opacity-30 placeholder:text-sm"
                        />
                        {isSubmit=== "track_id" && !trackID?
                        <span className="text-[12px] text-[red]">Tracking ID required</span>
                        : null}
                        
                      </div>
                    ) : (
                      <p className="text-sm font-bold">
                        {trackID ? trackID : "No id"}
                      </p>
                    )}
                  </>

                  <>
                    {editTrackID ? (
                      <Button
                        style="text-xs w-full md:w-[6rem]  hover:shadow-lg active:shadow-sm  transition-all active:scale-95 py-2 px-2 text-sm text-white add-live-btn bg-btn-gradient rounded-md"
                        content="Update"
                        type="butten"
                        onClick={() => handleUpdate("track_id")}
                        disabled={trackLoading}
                      />
                    ) : (
                      <div
                        className="cursor-pointer"
                        onClick={() => setEditTrackID(true)}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M17.5 18.3334H2.5C2.15833 18.3334 1.875 18.05 1.875 17.7084C1.875 17.3667 2.15833 17.0834 2.5 17.0834H17.5C17.8417 17.0834 18.125 17.3667 18.125 17.7084C18.125 18.05 17.8417 18.3334 17.5 18.3334Z"
                            fill="#F19A0E"
                          />
                          <path
                            opacity="0.4"
                            d="M15.8505 2.90005C14.2338 1.28338 12.6505 1.24172 10.9921 2.90005L9.9838 3.90838C9.90047 3.99172 9.86714 4.12505 9.90047 4.24172C10.5338 6.45005 12.3005 8.21672 14.5088 8.85005C14.5421 8.85838 14.5755 8.86672 14.6088 8.86672C14.7005 8.86672 14.7838 8.83338 14.8505 8.76672L15.8505 7.75838C16.6755 6.94172 17.0755 6.15005 17.0755 5.35005C17.0838 4.52505 16.6838 3.72505 15.8505 2.90005Z"
                            fill="#F19A0E"
                          />
                          <path
                            d="M13.0079 9.60829C12.7663 9.49163 12.5329 9.37496 12.3079 9.24163C12.1246 9.13329 11.9496 9.01663 11.7746 8.89163C11.6329 8.79996 11.4663 8.66663 11.3079 8.53329C11.2913 8.52496 11.2329 8.47496 11.1663 8.40829C10.8913 8.17496 10.5829 7.87496 10.3079 7.54163C10.2829 7.52496 10.2413 7.46663 10.1829 7.39163C10.0996 7.29163 9.95795 7.12496 9.83295 6.93329C9.73295 6.80829 9.61628 6.62496 9.50795 6.44163C9.37461 6.21663 9.25795 5.99163 9.14128 5.75829C9.02461 5.50829 8.93295 5.26663 8.84961 5.04163L3.61628 10.275C3.50795 10.3833 3.40795 10.5916 3.38295 10.7333L2.93295 13.925C2.84961 14.4916 3.00795 15.025 3.35795 15.3833C3.65795 15.675 4.07461 15.8333 4.52461 15.8333C4.62461 15.8333 4.72461 15.825 4.82461 15.8083L8.02461 15.3583C8.17461 15.3333 8.38295 15.2333 8.48295 15.125L13.7163 9.89163C13.4829 9.80829 13.2579 9.71663 13.0079 9.60829Z"
                            fill="#F19A0E"
                          />
                        </svg>
                      </div>
                    )}
                  </>
                </div>
              </div>
              <div className="flex w-full items-center justify-between gap-5">
                <p className="text-sm ">Tracking link</p>
                <div className="flex items-start gap-2">
                  <>
                    {editTrackLink ? (
                      <div className="flex justify-center flex-col">
                        <input
                          type="text"
                          placeholder="enter tracking link"
                          onChange={(e) => setTrackLink(e.target.value)}
                          value={trackLink}
                          className="rounded-md border-[1px] bg-opacity-0 focus-within:border-[#575252]  transition-all    border-[#bab1b1] overflow-hidden px-4 py-[8px] w-full text-sm placeholder:text-darkBlue placeholder:opacity-30 placeholder:text-sm"
                        />
                        {isSubmit=== "track_link" && !trackLink?
                        <span className="text-[12px] text-[red]">Tracking Link required</span>
                        : null}
                      </div>
                      
                    ) : trackLink ? (
                      <Link
                        // to={trackLink ? "//" + trackLink : ""}
                        to={trackLink ? trackLink : ""}
                        className="text-sm font-bold text-darkYellow hover:underline"
                        target="_blank"
                      >
                        Track
                      </Link>
                    ) : (
                      <p className="text-sm font-bold">No tracking link</p>
                    )}
                  </>

                  <>
                    {editTrackLink ? (
                      <Button
                        style="text-xs w-full md:w-[6rem]  hover:shadow-lg active:shadow-sm  transition-all active:scale-95 py-2 px-2 text-sm text-white add-live-btn bg-btn-gradient rounded-md"
                        content="Update"
                        type="butten"
                        onClick={() => handleUpdate("track_link")}
                        disabled={trackLoading}
                      />
                    ) : (
                      <div
                        className="cursor-pointer"
                        onClick={() => setEditTrackLink(true)}
                      >
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            opacity="0.4"
                            d="M17.5 18.3334H2.5C2.15833 18.3334 1.875 18.05 1.875 17.7084C1.875 17.3667 2.15833 17.0834 2.5 17.0834H17.5C17.8417 17.0834 18.125 17.3667 18.125 17.7084C18.125 18.05 17.8417 18.3334 17.5 18.3334Z"
                            fill="#F19A0E"
                          />
                          <path
                            opacity="0.4"
                            d="M15.8505 2.90005C14.2338 1.28338 12.6505 1.24172 10.9921 2.90005L9.9838 3.90838C9.90047 3.99172 9.86714 4.12505 9.90047 4.24172C10.5338 6.45005 12.3005 8.21672 14.5088 8.85005C14.5421 8.85838 14.5755 8.86672 14.6088 8.86672C14.7005 8.86672 14.7838 8.83338 14.8505 8.76672L15.8505 7.75838C16.6755 6.94172 17.0755 6.15005 17.0755 5.35005C17.0838 4.52505 16.6838 3.72505 15.8505 2.90005Z"
                            fill="#F19A0E"
                          />
                          <path
                            d="M13.0079 9.60829C12.7663 9.49163 12.5329 9.37496 12.3079 9.24163C12.1246 9.13329 11.9496 9.01663 11.7746 8.89163C11.6329 8.79996 11.4663 8.66663 11.3079 8.53329C11.2913 8.52496 11.2329 8.47496 11.1663 8.40829C10.8913 8.17496 10.5829 7.87496 10.3079 7.54163C10.2829 7.52496 10.2413 7.46663 10.1829 7.39163C10.0996 7.29163 9.95795 7.12496 9.83295 6.93329C9.73295 6.80829 9.61628 6.62496 9.50795 6.44163C9.37461 6.21663 9.25795 5.99163 9.14128 5.75829C9.02461 5.50829 8.93295 5.26663 8.84961 5.04163L3.61628 10.275C3.50795 10.3833 3.40795 10.5916 3.38295 10.7333L2.93295 13.925C2.84961 14.4916 3.00795 15.025 3.35795 15.3833C3.65795 15.675 4.07461 15.8333 4.52461 15.8333C4.62461 15.8333 4.72461 15.825 4.82461 15.8083L8.02461 15.3583C8.17461 15.3333 8.38295 15.2333 8.48295 15.125L13.7163 9.89163C13.4829 9.80829 13.2579 9.71663 13.0079 9.60829Z"
                            fill="#F19A0E"
                          />
                        </svg>
                      </div>
                    )}
                  </>
                </div>
              </div>
              {/* <div className="flex w-full items-center justify-between gap-5 ">
                <p className="text-sm ">Tracking link</p>
                <div className="flex items-center gap-2">
                  {data?.data?.tracking_link ? (
                    <Link
                      to={"//" + data?.data?.tracking_link}
                      className="text-sm font-bold text-darkYellow hover:underline"
                    >
                      Track
                    </Link>
                  ) : (
                    <p className="text-sm font-bold">No tracking link</p>
                  )}
                  <div className="cursor-pointer">
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.4"
                        d="M17.5 18.3334H2.5C2.15833 18.3334 1.875 18.05 1.875 17.7084C1.875 17.3667 2.15833 17.0834 2.5 17.0834H17.5C17.8417 17.0834 18.125 17.3667 18.125 17.7084C18.125 18.05 17.8417 18.3334 17.5 18.3334Z"
                        fill="#F19A0E"
                      />
                      <path
                        opacity="0.4"
                        d="M15.8505 2.90005C14.2338 1.28338 12.6505 1.24172 10.9921 2.90005L9.9838 3.90838C9.90047 3.99172 9.86714 4.12505 9.90047 4.24172C10.5338 6.45005 12.3005 8.21672 14.5088 8.85005C14.5421 8.85838 14.5755 8.86672 14.6088 8.86672C14.7005 8.86672 14.7838 8.83338 14.8505 8.76672L15.8505 7.75838C16.6755 6.94172 17.0755 6.15005 17.0755 5.35005C17.0838 4.52505 16.6838 3.72505 15.8505 2.90005Z"
                        fill="#F19A0E"
                      />
                      <path
                        d="M13.0079 9.60829C12.7663 9.49163 12.5329 9.37496 12.3079 9.24163C12.1246 9.13329 11.9496 9.01663 11.7746 8.89163C11.6329 8.79996 11.4663 8.66663 11.3079 8.53329C11.2913 8.52496 11.2329 8.47496 11.1663 8.40829C10.8913 8.17496 10.5829 7.87496 10.3079 7.54163C10.2829 7.52496 10.2413 7.46663 10.1829 7.39163C10.0996 7.29163 9.95795 7.12496 9.83295 6.93329C9.73295 6.80829 9.61628 6.62496 9.50795 6.44163C9.37461 6.21663 9.25795 5.99163 9.14128 5.75829C9.02461 5.50829 8.93295 5.26663 8.84961 5.04163L3.61628 10.275C3.50795 10.3833 3.40795 10.5916 3.38295 10.7333L2.93295 13.925C2.84961 14.4916 3.00795 15.025 3.35795 15.3833C3.65795 15.675 4.07461 15.8333 4.52461 15.8333C4.62461 15.8333 4.72461 15.825 4.82461 15.8083L8.02461 15.3583C8.17461 15.3333 8.38295 15.2333 8.48295 15.125L13.7163 9.89163C13.4829 9.80829 13.2579 9.71663 13.0079 9.60829Z"
                        fill="#F19A0E"
                      />
                    </svg>
                  </div>
                </div>
              </div> */}
            </DetialsCardWrap>
          )}
          {isLoading ? (
            <Skeleton height={230} />
          ) : (
            <DetialsCardWrap title="Price Details ">
              <div className="flex gap-3 flex-col justify-between">
                <div className="flex w-full items-center justify-between gap-5 ">
                  <p className="text-sm ">Amount</p>
                  <p className="text-sm font-bold">
                    {" "}
                    ₹ {Math.round(data?.data?.amount * 100) / 100}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between gap-5 ">
                  <p className="text-sm ">Order Tax</p>
                  <p className="text-sm font-bold">
                    {" "}
                    ₹ {Math.round(data?.data?.total_gst * 100) / 100}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between gap-5 ">
                  <p className="text-sm ">Shipping Cost</p>
                  <p className="text-sm font-bold">
                    {" "}
                    ₹ {Math.round(data?.data?.shipping_cost * 100) / 100}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between gap-5 ">
                  <p className="text-sm ">Discount Applied</p>
                  <p className="text-sm font-bold">
                    {" "}
                    ₹ {Math.round(data?.data?.total_discount * 100) / 100}{" "}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between gap-5 ">
                  <p className="text-sm ">Total Order Price</p>
                  <p className="text-sm font-bold">
                    {" "}
                    ₹ {Math.round(data?.data?.total_amount * 100) / 100}
                  </p>
                </div>
              </div>
            </DetialsCardWrap>
          )}
          {isLoading ? (
            <Skeleton height={230} />
          ) : (
            <DetialsCardWrap title="Payment Details">
              <div className="flex gap-3 flex-col ">
                <div className="flex w-full items-center justify-between gap-5 ">
                  <p className="text-sm ">Payment ID</p>
                  <p className="text-sm font-bold text-darkBlue">
                    { data?.data?.payment?.payment_id}
                  </p>
                </div>
                <div className="flex w-full items-center justify-between gap-5 ">
                  <p className="text-sm ">Order ID</p>
                  <p className="text-sm font-bold text-darkBlue">
                    {data?.data?.payment?.razorpay_order_id}
                  </p>
                </div>
               
                 <div className="flex w-full items-center justify-between gap-5 ">
                  <p className="text-sm ">Payment Status</p>
                  <p className="text-sm font-bold text-status">
                    {data?.data?.payment?.payment_refunds?.length && data?.data?.payment?.payment_refunds[0]?.status === 2? 
                    "Refunded":data?.data?.is_paid ? "Paid" : "Not paid"}
                  </p>
                </div>
                {data?.data?.payment?.payment_refunds?.length && data?.data?.payment?.payment_refunds[0]?.status === 2?
                  <>
                  <div className="flex w-full items-center justify-between gap-5 ">
                    <p className="text-sm ">Refund ID</p>
                    <p className="text-sm font-bold">{data?.data?.payment?.payment_refunds[0]?.refund_id}</p>
                  </div>
                  <div className="flex w-full items-center justify-between gap-5 ">
                    <p className="text-sm ">Refund Amount</p>
                    <p className="text-sm font-bold"> ₹ {Math.round(data?.data?.payment?.total_refund * 100) / 100}</p>
                  </div>
                  </>
                  
                :null}
                {/* // <div className="flex w-full items-center justify-between gap-5 ">
                //   <p className="text-sm ">Payment Status</p>
                //   <p className="text-sm font-bold text-status">
                //     {data?.data?.is_paid ? "Paid" : "Not paid"}
                //   </p>
                // </div> */}
                <div className="flex w-full items-center justify-between gap-5 ">
                  <p className="text-sm ">Payment Mode</p>
                  <p className="text-sm font-bold">{data?.data?.method}</p>
                </div>
              </div>
            </DetialsCardWrap>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderDetials;
