import React from "react";

const FileUploadButton = ({ name, accept, onChange, id, onclick, content, style,disable }) => {
  return (
    <div  onClick={onclick}>
      <input
        type="file"
        id={id}
        name={name}
        hidden
        accept={accept}
        onChange={onChange}
        disabled={disable}
        
      />
      <label
        htmlFor={id}
        className={`w-[150px] ${
          style ? style : ""
        } h-[30px]  ${disable ? 'hover:opacity-50 active:opacity-50 opacity-50 cursor-normal':'hover:opacity-90 active:opacity-70 opacity-100 cursor-pointer'}  transition-all px-10 py-1 text-sm text-white add-live-btn bg-btn-gradient rounded-md`}
      >
        {content}
      </label>
    </div>
  );
};

export default FileUploadButton;
