import { Formik, Form } from "formik";
import React, { useCallback, useEffect, useState } from "react";
import * as Yup from "yup";
import SubmitButton from "../../../components/submitButton/SubmitButton";
import {
  useAddProduct,
  useEditProduct,
  useGetCatogeryList,
  useGetProductTypeList,
  useGetSingleProduct,
  useGetVEndorList,
} from "../../../servicesQuery/productQuery/productQuery";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { FormikContext } from "../../../utilities/resetForm/FormikContext";
import { useGetCategoryList } from "../../../servicesQuery/categoryQuery/categoryQuery";
import ProductReview from "../../review/ProductReview";
import GeneralForm from "./GeneralForm";
import Button from "../../../components/submitButton/Button";
import PhotosAndVideos from "./PhotosAndVideos";
import { useArtmotionAddProduct, useArtmotionEditProduct, useGetArtmotionDesignList, useGetArtmotionMetalList, useGetArtmotionSingleProduct } from "../../../servicesQuery/artmotionQuery/artMotionQuery";

const productValidationStepOne = Yup.object().shape({
  model: Yup.string().required("Model is required"),
  skuNumber: Yup.string().required("SKU number is required"),
  name: Yup.string().required("Product Name is required"),
  discription: Yup.string().required("Description is required"),
  metal_type: Yup.mixed().required("Matal Type is required"),
  design_option: Yup.mixed().required("Design Option is required"),
  weight: Yup.number().required("Weight is required"),
  purity: Yup.number().required("purity is required"),
  // delivery_time: Yup.number().required("Delivery Time is required"),
  price: Yup.number().required("Price is required"),
  // priceExtax: Yup.number().required("Price Ex Tax is required"),
  discountType: Yup.mixed().when(["discountActive"], {
    is: (value) => value !== 1,
    then: (schema) => schema.required("Discount type is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  discountPerc: Yup.number().when(["discountActive"], {
    is: (value) => value !== 1,
    then: (schema) => schema.required("Discount percentage is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  tax: Yup.mixed().required("Tax class is required"),
  CGST: Yup.number().when(["tax"], {
    is: (value) => value?.label === "CGST and SGST",
    then: (schema) =>
      schema.required("CGST is required").max(100, "Maximum value is 100"),
    otherwise: (schema) => schema.notRequired(),
  }),
  "CGST/SGST": Yup.number().when(["tax"], {
    is: (value) => value?.label === "CGST and SGST",
    then: (schema) =>
      schema.required("CGST and SGST").max(100, "Maximum value is 100"),
    otherwise: (schema) => schema.notRequired(),
  }),
  IGST: Yup.number().when(["tax"], {
    is: (value) => value?.label === "IGST",
    then: (schema) =>
      schema.required("IGST is required").max(100, "Maximum value is 100"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const AddEditForm = () => {
  const id = useParams().id;
  const { data: productData } = useGetArtmotionSingleProduct(id);
  const [image, setImage] = useState("");
  const formData = new FormData();
  const [productId, setProductId] = useState(id ? id : "");
  const [formik, setFormik] = useState(null);
  const [index, setIndex] = useState("general");

  const handleTab = useCallback((tab) => {
    setIndex(tab);
  }, []);


  const { data: metalData } = useGetArtmotionMetalList();
  const metalDataList = metalData?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const { data: designData } = useGetArtmotionDesignList();
  const designDataList = designData?.data?.map((item) => {
    return {
      label: item.name,
      value: item.id,
    };
  });

  const discountData = [{ label: "Percentage", value: "Percentage" }];

  const taxData = [
    { label: "CGST and SGST", value: "cgst_sgst" },
    { label: "No Tax", value: "no_tax" },
    { label: "IGST", value: "igst" },
  ];


  const initialValues = {
    model: productData?.data?.model || "",
    name: productData?.data?.name || "",
    skuNumber: productData?.data?.sku || "",
    discription: productData?.data?.description || "",
    metal_type:
      metalDataList?.find((e) => e.label === productData?.data?.metal_type_name) ||
      undefined,
    design_option:
      designDataList?.find((e) => e.label === productData?.data?.design_option_name) ||
      undefined,
    price: productData?.data?.price || "",
    // priceExtax: productData?.data?.priceExtax || "",
    discountType: discountData?.find(
      (e) => e.value === productData?.data?.product_discount?.discount_type
    ) || { label: "Percentage", value: "Percentage" },
    discountPerc: productData?.data?.product_discount?.discount_percentage || 0,
    tax: taxData.find((e) => e.value === productData?.data?.tax_class) || {
      label: "CGST and SGST",
      value: "cgst_sgst",
    },
    CGST: productData?.data?.cgst || "",
    "CGST/SGST": productData?.data?.sgst || "",
    IGST: productData?.data?.igst || "",
    weight: productData?.data?.weight || "",
    purity: productData?.data?.purity || "",
    delivery_time: productData?.data?.delivery_time || [10,30],
    isActive: productData?.data?.is_active === false ? false : true,
    discountActive: productData?.data?.product_discount?.validity || 2,
  };

  const { mutateAsync: addProduct, isLoading } = useArtmotionAddProduct();
  const { mutateAsync: editProduct, isLoading: editLoading } = useArtmotionEditProduct();
  const handleAddProduct = (values, { resetForm }) => {
    console.log(values,"valuesvalues");
    if (!productId) {
      formData.append(
        "product_discount.discount_type",
        values?.discountType?.label
      );
      formData.append(
        "product_discount.discount_percentage",
        values?.discountPerc
        );
        formData.append("product_discount.validity", values?.discountActive);
        // formData.append("product_id", values?.productNumber);
        // formData.append("image_file", image);
        formData.append("price", values?.price);
        formData.append("weight", values?.weight);
        formData.append("purity", values?.purity);
      formData.append("name", values.name);
      formData.append("description", values.discription);
      formData.append("is_active", values.isActive);
      formData.append("model", values.model);
      formData.append("sku", values.skuNumber);
      formData.append("cgst", values.CGST);
      formData.append("sgst", values["CGST/SGST"]);
      formData.append("igst", values.IGST);
      formData.append("tax_class", values.tax.value);
      formData.append("metal_type", values.metal_type.value);
      formData.append("design_option", values.design_option.value);
      formData.append("delivery_time", JSON.stringify(values.delivery_time));

      addProduct(formData)
        .then((res) => {
          toast.success("Product added");
          // setIndex("photos");
          setIndex("photos");
          setProductId(res?.data?.id);
          // resetForm();
        })
        .catch((err) => {
          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          } else {
            toast.error("Something went wrong");
          }
        });
    } else {
      formData.append(
        "product_discount.discount_type",
        values.discountType.label
      );
      formData.append(
        "product_discount.discount_percentage",
        values.discountPerc
      );
      formData.append("product_discount.validity", values.discountActive);
      formData.append("price", values.price);
      formData.append("weight", values.weight);
      formData.append("purity", values.purity);
      formData.append("is_active", values.isActive);
      formData.append("name", values.name);
      formData.append("description", values.discription);
      formData.append("model", values.model);
      formData.append("sku", values.skuNumber);
      formData.append("cgst", values.CGST);
      formData.append("sgst", values["CGST/SGST"]);
      formData.append("igst", values.IGST);
      formData.append("tax_class", values.tax.value);
      formData.append("metal_type", values.metal_type.value);
      formData.append("design_option", values.design_option.value);
      formData.append("delivery_time", JSON.stringify(values.delivery_time));

      editProduct({ data: formData, id: productId })
        .then((res) => {
          toast.success("Product edited");
          resetForm();
        })
        .catch((err) => {
          toast.error("Something went wrong");
        });
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={productValidationStepOne}
      onSubmit={handleAddProduct}
      validateOnMount
    >
      {({
        handleChange,
        validateForm,
        setTouched,
        setFieldValue,
        isValid,
        values,
      }) => (
        <Form>
          <div className="w-full mb-10 flex justify-between gap-6">
          <div className="flex items-center w-full gap-y-3 gap-x-2 justify-between md:justify-start md:gap-12 ">
            <button
              onClick={(e) => {
                handleTab("general");
              }}
              className={`text-xs md:text-lg ${
                index === "general" ? "tab-active" : ""
              }  transition-all  hover:text-darkYellow text-[gray] font-bold`}
              type="button"
            >
              General
            </button>
            <button
              onClick={(e) => {
                handleTab("photos");
              }}
              className={`text-xs md:text-lg ${
                index === "photos" ? "tab-active" : ""
              }  transition-all ${
                id ? "hover:text-darkYellow" : "hover:none"
              }  text-[gray] font-bold`}
              type="button"
              disabled={id ? false : true}
            >
              Photos and Videos
            </button>
          </div>
        </div>
          <div className="w-full">
          {
                index === "general" ?
                <>
                <GeneralForm
                  setFieldValue={setFieldValue}
                  values={values}
                  data={{ metalDataList, discountData, taxData,designDataList }}
                />
                <div className="flex justify-end mt-6">
                  <div className="flex justify-end">
                    <SubmitButton
                      type={"submit"}
                      isLoading={isLoading || editLoading}
                      content={id ? "Edit product" : "Add product"}
                    />
                  </div>
                </div>
                </> :
                index === "photos" ?
                <>
                <PhotosAndVideos
                    id={productId || id}
                    data={productData?.data}
                  />
                <div className="w-[10rem flex items-center justify-end gap-6">
                  <Button
                        style={
                          "transition-all  w-[10rem]  hover:opacity-70 py-2 px-6  px-10 py-1 text-base text-white add-live-btn bg-btn-gradient rounded-2xl"
                        }
                        content="Back"
                        onClick={() => setIndex("general")}
                        type={"button"}
                      />
                </div>
                </> :null

          }
            
            {/* <div className="flex justify-end mt-6">
              {
                index === "general" ?
                <div className="flex justify-end">
                  <SubmitButton
                    type={"submit"}
                    isLoading={isLoading || editLoading}
                    content={id ? "Edit product" : "Add product"}
                  />
                </div> :
                index === "photos" ?
                <div className="w-[10rem flex items-center gap-6">
                  <Button
                        style={
                          "transition-all  w-[10rem]  hover:opacity-70 py-2 px-6  px-10 py-1 text-base text-white add-live-btn bg-btn-gradient rounded-2xl"
                        }
                        content="Back"
                        onClick={() => setIndex("general")}
                        type={"button"}
                      />
                </div> :null
              }
              
            </div> */}

            {id && index === "reviews" && <ProductReview id={id} />}
          </div>
          <FormikContext setFormik={(e) => setFormik(e.values)} />
        </Form>
      )}
    </Formik>
  );
};

export default React.memo(AddEditForm);
