import { useMutation, useQuery, useQueryClient } from "react-query";
import { notificationList, policyAdd, policyList, readAllNotification, readNotification } from "./notificationFunctions";

const useGetNotificationList = (data) => {
  return useQuery(["getNotificationList", data], () => notificationList(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const useReadNotification = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => readNotification(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getNotificationList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useReadAllNotification = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => readAllNotification(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getNotificationList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useGetPolicyList = (data) => {
    return useQuery(["getPolicyList", data], () => policyList(data), {
      // staleTime: 30000,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    });
  };

  const useAddPolicy = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => policyAdd(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getPolicyList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };


export {
    useGetNotificationList,
    useReadNotification,
    useReadAllNotification,
    useGetPolicyList,
    useAddPolicy
}