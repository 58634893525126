export const isCoupons = (data, type) => {
  return data?.filter((e) => {
    return e.coupon_for === type;
  })[0]
};

export const getDynamicHead = (name) => {
  const dynamicData = JSON.parse(localStorage.getItem('dynamicData'));
  let dynamicHead = null
  if(dynamicData&& dynamicData?.filter((f)=> f.name === name)?.length){
    dynamicHead = dynamicData?.filter((f)=> f.name === name)[0]?.head
  }
  return dynamicHead
}

export function shortenString(inputString, maxLength = 50) {
  if (inputString.length > maxLength) {
    return inputString.substring(0, maxLength) + '...';
  } else {
    return inputString;
  }
}

export function getPageVal(name, type,pageVal) {
  if(pageVal?.name === name){
    if(type==="pageSize"){
      return pageVal?.pageSize
    }else if(type === "pageCount"){
      return pageVal?.pageCount
    }
  }
  else {
    if(type==="pageSize"){
      return {
        label: "10",
        value: 10,
      }
    }else if(type === "pageCount"){
      return 1
    }
  }
}