import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const Cards = ({
  heading,
  number,
  label,
  children,
  bg,
  showChart = false,
  loading,
}) => {
  return (
    <>
      {loading ? (
        <div
          className={`flex-1 gap-5 ${bg} text-darkBlue rounded-[40px] p-6 flex flex-col justify-between md:w-[50%] w-full  `}
        >
          <SkeletonTheme baseColor="#e2e0e0" highlightColor="#f3f2f2">
          <p>
            <Skeleton count={1} height={100} />
          </p>
        </SkeletonTheme>
        </div>
      ) : (
        <div
          className={`flex-1 gap-5 ${bg} text-darkBlue rounded-[40px] p-6 flex flex-col justify-between md:w-[50%] w-full  `}
        >
          <h1 className="text-[24px] font-bold ">{heading}</h1>
          {children}
          {heading === "Revenue" ? (
            <div className="w-full">
              <h2 className="text-4xl font-bold">
                {number ? Number(number).toFixed(2) : 0}
              </h2>
              <p className="font-semibold text-sm">{label}</p>
            </div>
          ) :heading === "Tickets" ? (
            <div className="w-full flex items-center justify-around">
              <div className="flex flex-col items-center">
                <h2 className="text-4xl font-bold">{number?.pending ? number?.pending : 0}</h2>
                <p className="font-semibold text-sm">Pending</p>
              </div>
              <div className="flex flex-col items-center">
                <h2 className="text-4xl font-bold">{number?.ongoing ? number?.ongoing : 0}</h2>
                <p className="font-semibold text-sm">Ongoing</p>
              </div>
              <div className="flex flex-col items-center">
                <h2 className="text-4xl font-bold">{number?.closed ? number?.closed : 0}</h2>
                <p className="font-semibold text-sm">Closed</p>
              </div>
            </div>
          ) : (
            <div className="w-full flex items-center gap-6">
              <h2 className="text-4xl font-bold">{number ? number : 0}</h2>
              <p className="font-semibold text-sm">{label}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Cards;
