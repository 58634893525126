import React from "react";
import { TableOutlined } from "@ant-design/icons";
import { Dropdown, Space, Switch } from "antd";
import { useState } from "react";
import { useEffect } from "react";

function DynamicColumnFilter({head,name,setHead,dynamicData,setDynamicData}) {
    // const dynamicData = JSON.parse(localStorage.getItem('dynamicData'));
    const [items, setItems] = useState([])

    function updateIsCheckedByIndex(arr, index, newIsCheckedValue) {
      return arr.map(item => {
        if (item.index === index) {
          return { ...item, is_checked: newIsCheckedValue };
        }
        return item;
      });
    }
    const onChange = (e,index) => {
        if (dynamicData) {
          let dynamicArr = dynamicData
          if(typeof dynamicData === "string"){
            dynamicArr = JSON.parse(dynamicData)
          }
            const filteredData = dynamicArr?.filter((f) => f.name === name);
            if (filteredData.length > 0) {
              const dynamicHead = filteredData[0].head;
              const updatedData = dynamicHead.map(item => {
                if (item.index === index) {
                  return { ...item, is_checked: e };
                }
                return item;
              });
          
              // Create a new copy of dynamicData with the updated head
              const updatedDynamicData = dynamicArr.map(item => {
                if (item.name === name) {
                  return { ...item, head: updatedData };
                }
                return item;
              });
          
              localStorage.setItem("dynamicData", JSON.stringify(updatedDynamicData));
              setHead(updatedData);
              setDynamicData(JSON.stringify(updatedDynamicData))
            }else {
              const updatedhead = updateIsCheckedByIndex(head, index, e);
              let datas = {
                  name: name,
                  head: updatedhead
              }
              dynamicData.push(datas);
              localStorage.setItem("dynamicData", JSON.stringify(dynamicData));
              setHead(updatedhead)
              setDynamicData(JSON.stringify(dynamicData))
            }
          }
          else {
            const updatedhead = updateIsCheckedByIndex(head, index, e);
            let datas = {
                name: name,
                head: updatedhead
            }
            localStorage.setItem("dynamicData", JSON.stringify([datas]));
            setHead(updatedhead)
            setDynamicData(JSON.stringify([datas]))
        }
      };

    useEffect(()=> {
        let item = []
        if(head?.length){
            head?.map((h,index)=> {
                item.push(
                    {
                        key: index + 1,
                        label: (
                          <div className="flex items-center justify-between" onClick={(e)=> e.stopPropagation()}>
                            <span>{h?.label}</span>
                            <Switch checked={h?.is_checked} onChange={(e)=> onChange(e,h?.index)} className={`${h?.is_checked ? "" : "dynamic-table"}`} />
                          </div>
                        ),
                    }
                )
            })
        }
        setItems(item)
    },[head])

  return (
    <div className="w-full">
      <Dropdown
        trigger="click"
        menu={{
          items,
        }}
        overlayStyle={{ width: 200 }}
        placement="bottomRight"
      >
        <a onClick={(e) => e.preventDefault()}>
          <div className="hover:text-[#65656a] text-[#07415C] border rounded border-[#EEF0F7] py-1 px-3 flex items-center justify-between cursor-pointer gap-4">
            <TableOutlined />
            <span className="text-[14px]">Columns</span>
            <span className="text-[14px] w-[22px] h-[22px] bg-[#EEF0F7] flex items-center justify-center rounded-[22px]">{head?.filter((f)=> f.is_checked == true)?.length}</span>
          </div>
        </a>
      </Dropdown>
    </div>
  );
}

export default React.memo(DynamicColumnFilter);
