import React, { useEffect, useState } from 'react';
import { Button, Drawer } from 'antd';
import { FilterFilled } from "@ant-design/icons";
import SubmitButton from '../submitButton/SubmitButton';
import CustomSelect from '../customSelect/CustomSelect';

function TableFilter(props) {
    const [open, setOpen] = useState(false);
    useEffect(() => {
    if (open) {
        document.querySelector("body").style.overflow = "hidden";
    } else {
        document.querySelector("body").style.overflowY = "scroll";
    }
    }, [open]);
    const showDrawer = () => {
        setOpen(true);
        props?.setEnabled(false)
      };
      const onClose = () => {
        setOpen(false);
      };


  return (
    <div>
        <div onClick={showDrawer} className="hover:text-[#65656a] text-[#07415C] border rounded border-[#EEF0F7] py-1 px-3 flex items-center gap-3 cursor-pointer">
            <FilterFilled />
            <span className="text-[14px]">Filters</span>
            <span className="text-[14px] w-[22px] h-[22px] bg-[#EEF0F7] flex items-center justify-center rounded-[22px]">{props?.filterCount}</span>
        </div>
      <Drawer 
        title="Filters"
        placement="right"
        onClose={onClose}
        open={open}
        className='filter-drawer'
        footer={
            <div className='flex items-center justify-around'>
                <Button onClick={()=> props?.handleFilter("clear")}>Clear all filters</Button>
                <Button onClick={()=> {props?.handleFilter("save");setOpen(false)}} className='text-xs w-full md:w-[8rem]  hover:shadow-lg active:shadow-sm  transition-all active:scale-95 py-2 px-6  px-10 py-1 text-sm text-white add-live-btn bg-btn-gradient rounded-md'>Apply</Button>
            </div>
        }
        contentWrapperStyle={{ width: '350px' }}
        >
        <div className='h-full'>
            {props.type === "product"?
            <div className=''>
                <div className='flex flex-col gap-2 mb-3'>
                    <span className='font-semibold'>Category</span>
                    <CustomSelect
                        placeholder={"Category"}
                        name="category"
                        value={props?.filterData?.category}
                        onChange={(e) => {
                        props?.setFilterData({ ...props?.filterData, category: e });
                        }}
                        options={props?.categoryList}
                        width={"min-w-[8rem]"}
                    />
                </div>
                <div className='flex flex-col gap-2 mb-3'>
                    <span className='font-semibold'>Sub Category</span>
                    <CustomSelect
                        placeholder={"Sub Category"}
                        name="subcategory"
                        value={props?.filterData?.subcategory}
                        onChange={(e) => {
                            props?.setFilterData({ ...props?.filterData, subcategory: e });
                        }}
                        options={props?.subCatList}
                        width={"min-w-[8rem]"}
                    />
                </div>
                <div className='flex flex-col gap-2 mb-3'>
                    <span className='font-semibold'>Discount</span>
                    <CustomSelect
                        placeholder={"Discount"}
                        options={props?.discountList}
                        width={"min-w-[8rem]"}
                        value={props?.filterData?.discount}
                        onChange={(e) => {
                            props?.setFilterData({ ...props?.filterData, discount: e });
                        }}
                        />
                </div>

                <div className='flex flex-col gap-2 mb-3'>
                    <span className='font-semibold'>Price</span>
                    <div className="flex items-center gap-2 w-full">
                    <input
                        type="text"
                        placeholder="From"
                        onChange={(e) =>
                        props?.setFilterData({
                            ...props?.filterData,
                            price_from: e.target.value,
                        })
                        }
                        value={props?.filterData?.price_from}
                        className="rounded-md border-[1px] bg-opacity-0 focus-within:border-[#575252]  transition-all    border-[#bab1b1] overflow-hidden px-4 py-[8px] w-full text-sm placeholder:text-darkBlue placeholder:opacity-30 placeholder:text-sm"
                    />
                    <input
                        type="text"
                        placeholder="To"
                        onChange={(e) =>
                        props?.setFilterData({ ...props?.filterData, price_to: e.target.value })
                        }
                        value={props?.filterData?.price_to}
                        className="rounded-md border-[1px] bg-opacity-0 border-[#bab1b1] overflow-hidden focus-within:border-[#575252]  transition-all    px-4 py-[8px] w-full text-sm placeholder:text-darkBlue placeholder:opacity-30 placeholder:text-sm"
                    />
                    </div>
                </div>

                <div className='flex flex-col gap-2 mb-6'>
                    <span className='font-semibold'>Product Type</span>
                    <CustomSelect
                        placeholder={"Product Type"}
                        name="subcategory"
                        value={props?.filterData?.productType}
                        onChange={(e) => {
                            props?.setFilterData({ ...props?.filterData, productType: e });
                        }}
                        options={props?.proTypeList}
                        width={"min-w-[8rem]"}
                    />
                </div>
                <div className='flex flex-col gap-2'>
                    <span className='font-semibold'>Sort</span>
                    <CustomSelect
                        placeholder={"Sort"}
                        options={props?.sort_list}
                        width={"min-w-[8rem]"}
                        value={props?.filterData?.sortValue}
                        onChange={(e) => {
                        props?.setFilterData({ ...props?.filterData, sortValue: e });
                        }}
                    />
                </div>
                </div>
            :
            null
            }
        </div>
      </Drawer>
    </div>
  )
}

export default React.memo(TableFilter)
