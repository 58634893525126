import React, { useContext, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { Link, useNavigate } from "react-router-dom";
import SingleDatePicker from "../../../components/datePicker/SingleDatePicker";
import Pagenation from "../../../components/pagination/Pagenation";
import {
  useAcceptTicket,
  useGetTicketList,
} from "../../../servicesQuery/tickerQuery/ticketQuery";
import {
  convertDateFormat,
  formatDate,
  getTimeDifference,
} from "../../../utilities/dateFunctions/dateFunctions";
import { toast } from "react-hot-toast";
import { Context } from "../../../utilities/context/Context";
import CustomSelect from "../../../components/customSelect/CustomSelect";

function TicketMainPage() {
  const navigate = useNavigate();
  const { value, setValue } = useContext(Context);
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState({
    label: "10/page",
    value: 10,
  });
  const [date, setDate] = useState("");
  const [status, setStatus] = useState({label: "ALL",value: "ALL"});
  const { data, isLoading, isFetching } = useGetTicketList({
    page: pageCount,
    search: value,
    date: convertDateFormat(date),
    status: status
  });

  const { mutateAsync: acceptTicket, isLoading: ticketLoading } =
    useAcceptTicket();

  const handleChat = (i) => {
    if (i.accepted) {
      navigate(`/tickets/chat/${i.id}`);
    } else {
      let data = {
        id: i.id,
        accepted: true,
      };
      acceptTicket(data)
        .then((res) => {
          // console.log(res,"res");
          if (res.status === 200) {
            toast.success("Ticket Accepted");
            navigate(`/tickets/chat/${res?.data?.id}`);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const statusList = [
    {
      label: "ALL",
      value: "ALL",
    },
    {
      label: "Open",
      value: "open",
    },
    {
      label: "Accepted",
      value: "accepted",
    },
    {
      label: "Closed",
      value: "closed",
    },
  ];
  return (
    <div>
      <div className="relative w-full flex justify-between mb-8">
        <h1 className="text-xl font-bold text-[#F19A0E]">Tickets</h1>
        <div className="flex items-center gap-2">
          <div className="w-[10rem]">
            <CustomSelect
              placeholder={"Status"}
              options={statusList}
              width={"min-w-[8rem]"}
              value={status}
              onChange={(e) => {
                setStatus(e);
              }}
            />
          </div>
          <div className="w-[10rem]">
            <SingleDatePicker
              onDateChange={(date) => {
                if (date) {
                  setDate(date?.toLocaleDateString());
                } else {
                  setDate("");
                }
              }}
              value={date}
            />
          </div>
        </div>
      </div>
      {isLoading ? (
        <SkeletonTheme baseColor="#e2e0e0" highlightColor="#f3f2f2">
          <p>
            <Skeleton count={3} height={100} />
          </p>
        </SkeletonTheme>
      ) : (
        data?.data?.results.map((i, index) => (
          <div
            className="w-full  bg-white rounded-xl shadow-lg p-6 mb-4"
            key={index}
          >
            <div className="flex justify-between">
              <span className="text-[#07415C] text-base font-semibold">
                {"Ticket No:" + i.ticket_id}
              </span>
              <span className="text-[#07415C] text-base font-semibold">
                {formatDate(i.created_at)}
              </span>
            </div>
            <p className="text-sm my-4 font-normal text-[#121212]">
              {i.subject}
            </p>
            <div className="flex items-center gap-6">
              <span className="text-[#07415C] text-xs rounded-lg border-[.5px] border-[#121212] py-1 px-3">
                {i.accepted === false && i.closed === false
                  ? "Open"
                  : i.closed === true
                  ? "Closed"
                  : i.accepted === true
                  ? "Accepted"
                  : ""}
              </span>
              <div className="flex items-center gap-2">
                <div onClick={() => handleChat(i)}>
                  <svg
                    className="cursor-pointer"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M17.0571 24.157L17.1829 24.0733H17.334H22.6673C24.5537 24.0733 26.0868 23.4467 27.1471 22.3864C28.2074 21.3262 28.834 19.793 28.834 17.9067V9.90666C28.834 8.02028 28.2074 6.48715 27.1471 5.42688C26.0868 4.3666 24.5537 3.73999 22.6673 3.73999H9.33399C7.44761 3.73999 5.91448 4.3666 4.85421 5.42688C3.79393 6.48715 3.16732 8.02028 3.16732 9.90666V17.9067C3.16732 19.793 3.79393 21.3262 4.85421 22.3864C5.91448 23.4467 7.44761 24.0733 9.33399 24.0733H9.83399V24.5733V27.4133C9.83399 28.078 10.5734 28.4706 11.1233 28.104L11.1237 28.1037L17.0571 24.157Z"
                      stroke="#F19A0E"
                    />
                  </svg>
                </div>
                <span className="text-[#07415C] text-xs">
                  {i.admin_unread_count ? i.admin_unread_count : ""}
                </span>
              </div>
            </div>
          </div>
        ))
      )}

      <div className="w-full flex justify-center my-6">
        <Pagenation
          pageCount={setPageCount}
          loading={isLoading || isFetching}
          data={data?.data?.results}
          next={data?.data?.next}
          currDataCount={data?.data?.data_count}
          totalLength={data?.data?.count}
          previous={data?.data?.previous}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </div>
    </div>
  );
}

export default TicketMainPage;
