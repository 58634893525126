import { useMutation, useQuery, useQueryClient } from "react-query";
import { addTag, deleteTag, editTag, getLatestTag, getSingleTag, getTag } from "./tagUrl";

const useAddtags = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => addTag(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getTags");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};
const useGetTags = (data) => {
  return useQuery(["getTags", data], () => getTag(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
const useGetSingleTags = (data) => {
  return useQuery(["getSingleTags", data], () => getSingleTag(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const useGetLatestTags = (data) => {
  return useQuery(["getLatestTags", data], () => getLatestTag(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const useDeleteTags = () => {
  const queryClient = useQueryClient();
  return useMutation((id) => deleteTag(id), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getTags");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};
const useEditags = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => editTag(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getTags");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };
export { useAddtags, useGetTags, useDeleteTags,useGetSingleTags,useEditags,useGetLatestTags };
