import React, { useEffect } from 'react'
import ModalLayout from '../../../components/customModal/CustomModal'

const VideoPreview = ({open,setOpen,video}) => {
  useEffect(()=>{
   const video = document.querySelector('.video')
   if(!open){
    video.pause()
    video.currentTime=0
   }
  },[open])
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen} >
         <div >

         <video
          controls={true}
           className='video'
          src={video}
        ></video>
              
         </div>
    </ModalLayout>
  )
}

export default VideoPreview