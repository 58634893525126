import { Link, BrowserRouter as Router } from "react-router-dom";
import "./App.scss";
import Index from "./routes/Index";
import CustomToast from "./components/customToast/CustomToast";
import { createContext, useContext, useEffect, useState } from "react";
import { Context } from "./utilities/context/Context";
import { axiosInstance } from "./services/apiInitialSetup";
import firebase from "./firebase";
import { toast } from "react-hot-toast";
import sound from "./assets/excel/sound/sound.mp3";
import {
  initialNavigation,
  userPermissions,
} from "./utilities/userPermissons/userPermissions";
function App() {
  const [socket, setSocket] = useState(null);
  const { progress,setProgres } = useContext(Context);
  const { is_unread,setUnRead } = useContext(Context);
  useEffect(() => {
    const requestFirebaseNotificationPermission = async () => {
      const messaging = firebase.messaging();
      try {
        const token = await messaging.getToken();
        axiosInstance.post("notifications/fcm/", {
          registration_id: token,
        });
        return token;
      } catch (error) {
        console.error("Error obtaining FCM token:", error);
      }
    };
    requestFirebaseNotificationPermission();
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          const messaging = firebase.messaging();
          messaging.onMessage((payload) => {
            {setUnRead(true)}
            toast(
              <div className="flex items-center  gap-3 min-w-[10rem]">
                <a
                  className="flex items-center  gap-3 min-w-[10rem]"
                  href="/order-management"
                >
                  <div className="w-[3rem]">
                    <img
                      src={
                        process.env.REACT_APP_API_BASE_URL + "static/mwlogo.jpg"
                      }
                      alt="notification"
                    />
                  </div>
                  <div>
                    <p className="text-sm font-bold">{payload.data.title}</p>
                    <p className="text-sm">{payload.data.body}</p>
                  </div>
                </a>
              </div>,
              {
                position: "top-right",
              }
            );
            const audio = new Audio(sound);
            audio.play();
          });
        })
        .catch((error) => {
          console.error("Service Worker registration failed:", error);
        });
    }
  }, []);

  const receiveMessage = (message) => {
    const progressDatas = JSON.parse(message?.data);
    const task_id = progressDatas?.task_id;
    setProgres((prevProgress) => {
      const existingTaskIndex = prevProgress.findIndex((item) => item?.task_id === task_id);
      if (existingTaskIndex !== -1) {
        const updatedProgress = [...prevProgress];
        updatedProgress[existingTaskIndex] = progressDatas;
        return updatedProgress;
      } else {
        return [...prevProgress, progressDatas];
      }
    });
  };

  useEffect(() => {
    // const { access } = JSON.parse(localStorage.getItem("Tokens"));
    const tokensData = JSON.parse(localStorage.getItem("Tokens"));
    if (tokensData && tokensData.access) {
      const { access } = tokensData;
      const newSocket = new WebSocket(
        `${process.env.REACT_APP_SOCKET_BASE_URL}ws/admin-socket-connection/?token=${access}`
      );
      newSocket.onopen = (e) => {
        console.log("socket added", e);
      };
      newSocket.onmessage = receiveMessage;
      setSocket(newSocket);
      return () => {
        newSocket.close();
      };
    }
    
  }, []);
  return (
    <div className="App">
      <CustomToast />
        <Router>
          <Index userPermissions={userPermissions} />
        </Router>
    </div>
  );
}

export default App;
