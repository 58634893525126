import React, { useEffect } from "react";
import ErrorMessageComponent from "../../../components/errorMessage/ErrorMessageComponent";
import {useFormik } from "formik";
import SubmitButton from "../../../components/submitButton/SubmitButton";
import * as Yup from "yup";
import ModalLayout from "../../../components/customModal/CustomModal";
import { useResetPasswordQuery } from "../../../servicesQuery/authQuery/AuthQuerys";
import { capitalize } from "../../../utilities/capitaize/capitalize";
import { toast } from "react-hot-toast";

const ForgotPasswordModal = ({ modalOpen, setModalOpen }) => {
    const formData = new FormData()
  const emailValidation = () => {
    const emailRegex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return Yup.object().shape({
      resetEmail: Yup.string()
        .email("Please enter correct format")
        .matches(emailRegex, "Please enter correct format")
        .required("Email is required"),
    });
  };


  const {
    mutateAsync: resetPassword,
    isError,
    isLoading,
    isSuccess,
    error,

    
  } = useResetPasswordQuery();
 
  const initialValue = {
    resetEmail: "",
  };


  const onSubmit = (values)=>{
    
    formData.append('email',values.resetEmail)
    resetPassword(formData).then(res=>{
        toast.success(capitalize(res.data.message))
        setModalOpen(false)
        
    }).catch(err=>{
        toast.error(capitalize(err.errors.email))
    })
  } 

  const {
    setErrors,
    resetForm,
    handleChange,
    values,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: initialValue,
    validationSchema: emailValidation,
    onSubmit:onSubmit
  });

  useEffect(() => {
    resetForm();
    if (!modalOpen) {
      setErrors({});
      resetForm();
    }
    return () => {
      setErrors({});
    };
  }, [modalOpen]);

  
  return (
    <ModalLayout modalOpen={modalOpen} setModalOpen={setModalOpen}>
      <div className="max-w-[720px]  h-[300px] bg-lightYellow py-6 px-16 rounded-lg m-5">
        <div className="flex max-w-[25rem] flex-col items-center justify-center h-full ">
          <h1 className="  text-darkBlue text-center text-sm font-semibold mb-8">
            Enter your registered email address. We will send you a link to
            reset your password !
          </h1>

          <form onSubmit={handleSubmit} className="flex flex-col  w-full mb-6">
            <input
              type="text"
              className="w-full border-solid bg-[transparent]	 border-2 border-[#F19A0E4D] rounded-md px-4 py-2"
              placeholder="Email ID"
              name="resetEmail"
              onChange={handleChange}
              value={values.resetEmail}
            />
            <ErrorMessageComponent
              Render={() =>
                errors.resetEmail && touched.resetEmail && errors.resetEmail
              }
              name="resetEmail"
            />

            <SubmitButton content="Send" type="submit" isLoading={isLoading} />
          </form>
       
        </div>
     
      </div>
    </ModalLayout>
  );
};

export default ForgotPasswordModal;
