import { routes } from "../../routes/routes";

export const userPermissions = (user) => {
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  return permissions?.filter((e) => {
    if (e === user) {
      return true;
    }
  })?.length > 0
    ? true
    : false;
};

export const initialNavigation = () => {
  const permission = JSON.parse(localStorage.getItem("permissions"));
  if (permission) {
    return routes
      ?.map((e) => {
        return permission?.map((p) => {
          if (e.permission === p) {
            return e.path;
          }
        });
      })
      .flat()
      .filter((e) => {
        return e;
      })[0];
   
  }
};
