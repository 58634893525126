import React, { useState } from "react";
import logoAuth from "../../assets/images/logoHalf.svg";
import { Field, Form, Formik } from "formik";
import "./signinStyle.scss";
import { useLoginQuery } from "../../servicesQuery/authQuery/AuthQuerys";
import * as Yup from "yup";
import ErrorMessageComponent from "../../components/errorMessage/ErrorMessageComponent";
import { capitalize } from "../../utilities/capitaize/capitalize";
import SubmitButton from "../../components/submitButton/SubmitButton";
import ForgotPasswordModal from "./signinComponents/ForgotPasswordModal";
import { useNavigate } from "react-router-dom";

const Signin = () => {
  const [open,setOpen]= useState(false)
  const signinValidation = () => {
    const emailRegex = new RegExp(
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

    return Yup.object().shape({
      email: Yup.string()
        .email("Please enter correct format")
        .matches(emailRegex, "Please enter correct format")
        .required("Email is required"),

      password: Yup.string().required("Password is required"),
    });
  };

  const formData = new FormData();
  const initialValue = {
    email: "",
    password: "",
  };

  const { mutateAsync: signin, isError, isLoading, error } = useLoginQuery();

  const onSubmit = (values) => {
    formData.append("email", values.email);
    formData.append("password", values.password);
  
    signin(formData)
  };
  return (
    <div className="w-full h-full flex flex-col gap-4 items-center justify-center">
      <div className="mb-8 w-[8rem]">
        <img className="w-full" src={logoAuth} alt="logo" />
      </div>

      <div className="flex w-full md:w-[25rem] flex-col items-center gap-0">
        <h1 className="text-xl font-bold text-darkYellow ">Login</h1>
        <div className="h-12 w-full p-2 text-error text-sm font-normal text-center transition-all  ">
          {isError && (
            <div className="border-error border h-full w-full flex justify-center transition-all items-center bg-error/[.1] rounded-md ">
              {capitalize(error?.message)}
            </div>
          )}
        </div>
        <div className="w-full">
          <Formik
            initialValues={initialValue}
            onSubmit={onSubmit}
            validationSchema={signinValidation}
          >
            <Form className="flex flex-col items-center   p-2">
              <Field
                type="text"
                className="w-full border-solid	 border-2 border-[#F19A0E4D] rounded-md px-4 py-2"
                placeholder="Email"
                name="email"
              />
              <ErrorMessageComponent name="email" />
              <Field
                type="password"
                className="w-full border-solid	 border-2 border-[#F19A0E4D] rounded-md px-4 py-2"
                placeholder="Password"
                name="password"
              />
              <ErrorMessageComponent name="password" />
              <div className="flex w-full items-center justify-between mb-8">
                <div className="flex itemscenter gap-3">
                  <input className="checkbox-r" type="checkbox" id="checkbox" />
                  <label className="text-sm text-darkBlue" htmlFor="checkbox">
                    Remember me
                  </label>
                </div>
                <div>
                  <button
                    onClick={() => setOpen(true)}
                    className="font-semibold text-sm underline decoration-darkBlue decoration-1 underline-offset-4"
                    type="button"
                  >
                    Forgot password
                  </button>
                </div>
              </div>
              <SubmitButton
                value="signin"
                type="submit"
                content="Login"
                isLoading={isLoading}
              />
            </Form>
          </Formik>
          <ForgotPasswordModal  modalOpen={open} setModalOpen={setOpen}/>
        </div>
      </div>
    </div>
  );
};

export default Signin;
