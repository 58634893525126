import React from "react";
import "./userOverViewStyle.scss";
import "react-loading-skeleton/dist/skeleton.css";
import Skeleton from "react-loading-skeleton";
import avatar from '../../../assets/images/commonImages/avatar.png'
const DetialsSection = ({ setTabIndex, tabIndex, data, loading }) => {
  return (
    <div className="w-full   bg-white rounded-xl shadow-lg px-6 pt-6 pb-3 ">
      <div>
        <div className="flex gap-6 h-[9rem]">
          <div className="w-[150px]  rounded-xl overflow-hidden">
            {loading ? (
              <Skeleton height={150} />
            ) : (
              <img className="w-full" src={data?.profile_pic || avatar} alt="avtar" />
            )}
          </div>
          <div className="flex flex-col   justify-between">
            {/* {loading ? (
              <Skeleton count={1} />
            ) : (
              <h2 className="text-sm hxl:text-base font-semibold ">
                {data?.id}
              </h2>
            )} */}
            {loading ? (
              <Skeleton count={1} />
            ) : (
              <h1 className="text-lg hxl:xl-basefont-bold text-darkBlue">
                {data?.full_name}
              </h1>
            )}
            <div className="flex  gap-3 hxl:gap-8 flex-col hxl:flex-row">
              {loading ? (
                <Skeleton width={200} />
              ) : (
                <div className="flex  gap-3 items-center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M11.79 14.21L8.52 17.48C8.16 17.16 7.81 16.83 7.47 16.49C6.44 15.45 5.51 14.36 4.68 13.22C3.86 12.08 3.2 10.94 2.72 9.81C2.24 8.67 2 7.58 2 6.54C2 5.86 2.12 5.21 2.36 4.61C2.6 4 2.98 3.44 3.51 2.94C4.15 2.31 4.85 2 5.59 2C5.87 2 6.15 2.06 6.4 2.18C6.66 2.3 6.89 2.48 7.07 2.74L9.39 6.01C9.57 6.26 9.7 6.49 9.79 6.71C9.88 6.92 9.93 7.13 9.93 7.32C9.93 7.56 9.86 7.8 9.72 8.03C9.59 8.26 9.4 8.5 9.16 8.74L8.4 9.53C8.29 9.64 8.24 9.77 8.24 9.93C8.24 10.01 8.25 10.08 8.27 10.16C8.3 10.24 8.33 10.3 8.35 10.36C8.53 10.69 8.84 11.12 9.28 11.64C9.73 12.16 10.21 12.69 10.73 13.22C11.09 13.57 11.44 13.91 11.79 14.21Z"
                      fill="#07415C"
                    />
                    <path
                      d="M21.97 18.33C21.97 18.61 21.92 18.9 21.82 19.18C21.79 19.26 21.76 19.34 21.72 19.42C21.55 19.78 21.33 20.12 21.04 20.44C20.55 20.98 20.01 21.37 19.4 21.62C19.39 21.62 19.38 21.63 19.37 21.63C18.78 21.87 18.14 22 17.45 22C16.43 22 15.34 21.76 14.19 21.27C13.04 20.78 11.89 20.12 10.75 19.29C10.36 19 9.97001 18.71 9.60001 18.4L12.87 15.13C13.15 15.34 13.4 15.5 13.61 15.61C13.66 15.63 13.72 15.66 13.79 15.69C13.87 15.72 13.95 15.73 14.04 15.73C14.21 15.73 14.34 15.67 14.45 15.56L15.21 14.81C15.46 14.56 15.7 14.37 15.93 14.25C16.16 14.11 16.39 14.04 16.64 14.04C16.83 14.04 17.03 14.08 17.25 14.17C17.47 14.26 17.7 14.39 17.95 14.56L21.26 16.91C21.52 17.09 21.7 17.3 21.81 17.55C21.91 17.8 21.97 18.05 21.97 18.33Z"
                      fill="#07415C"
                    />
                  </svg>
                  <p className="text-xs hxl:text-sm">{data?.phone_number}</p>
                </div>
              )}
              {loading ? (
                <Skeleton width={200} />
              ) : (
                <div className="flex gap-3 items-center">
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M17 20.5H7C4 20.5 2 19 2 15.5V8.5C2 5 4 3.5 7 3.5H17C20 3.5 22 5 22 8.5V15.5C22 19 20 20.5 17 20.5Z"
                      fill="#07415C"
                    />
                    <path
                      d="M12 12.87C11.16 12.87 10.31 12.61 9.66001 12.08L6.53001 9.57997C6.21001 9.31997 6.15001 8.84997 6.41001 8.52997C6.67001 8.20997 7.14001 8.14997 7.46001 8.40997L10.59 10.91C11.35 11.52 12.64 11.52 13.4 10.91L16.53 8.40997C16.85 8.14997 17.33 8.19997 17.58 8.52997C17.84 8.84997 17.79 9.32997 17.46 9.57997L14.33 12.08C13.69 12.61 12.84 12.87 12 12.87Z"
                      fill="#07415C"
                    />
                  </svg>
                  <p className="text-xs hxl:text-sm">{data?.email}</p>
                </div>
              )}
            </div>
            {loading ? (
              <Skeleton />
            ) : (
              <div className="p-1 rounded-md bg-status w-[95px] h-[25px] flex items-center justify-center text-xs hxl:text-sm text-white">
                <p>{data?.is_active ? "Active" : "Inactive"}</p>
              </div>
            )}
          </div>
        </div>

        <div>
          <div className="flex gap-12 items-start mt-10 justify-between  hxl:justify-start">
            <button
              className={`text-base font-bold relative ${
                tabIndex === "address" ? "tab-active" : ""
              }   ${
                tabIndex === "address" ? "text-darkYellow" : "text-lightBlue"
              } transition-all`}
              onClick={() => setTabIndex("address")}
            >
              Addresses
            </button>
            <button
              className={`text-base font-bold relative ${
                tabIndex === "kyc" ? "tab-active" : ""
              }   ${
                tabIndex === "kyc" ? "text-darkYellow" : "text-lightBlue"
              } transition-all`}
              onClick={() => setTabIndex("kyc")}
            >
              KYC Details
            </button>
            <button
              className={`text-base font-bold relative ${
                tabIndex === "order" ? "tab-active" : ""
              }   ${
                tabIndex === "order" ? "text-darkYellow" : "text-lightBlue"
              } transition-all`}
              onClick={() => setTabIndex("order")}
            >
              Order Details
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetialsSection;
