import React from 'react'
import toast from 'react-hot-toast';
import ModalLayout from '../../../components/customModal/CustomModal';
import Button from '../../../components/submitButton/Button';
import { useDeleteProductType } from '../../../servicesQuery/categoryQuery/categoryQuery';
import { useDeleteUser } from '../../../servicesQuery/roleQuery/roleQuery';

function DeleteModal({ open, setOpen,data,groupID }) {

    const { mutateAsync: deleteUser, isLoading: addLoaing } = useDeleteUser();

    const handleDelete = ()=> {
        data.groupID = groupID
        toast.promise(deleteUser(data)
                .then((res) => {
                    setOpen(false);
                })
                , {
                loading: "deleting User",
                success: "User Deleted",
                error: "Something went wrong",
          });
        
    }
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="bg-white  rounded-lg  w-[90%] hxl:max-w-[20rem] m-4 p-5 ">
        <div className='flex flex-col gap-3 items-center'>
            <span>are you sure?</span>
            <div className='flex justify-between gap-3'>
                <Button
                onClick={()=> setOpen(false)}
                style="text-xs w-[6rem]  border border-2 border-darkYellow  text-darkBlue  rounded-md py-2 px-6"
                content="Cancel"
                />
                <Button
                    onClick={()=> handleDelete()}
                    style="text-xs w-[6rem] border border-2 border-error text-darkBlue   rounded-md py-2 px-6"
                    content="Delete"
                />
            </div>
        </div>
      </div>
    </ModalLayout>
  )
}

export default React.memo(DeleteModal)
