import { Form, Formik } from "formik";
import React from "react";
import SubmitButton from "../../../../components/submitButton/SubmitButton";


import { toast } from "react-hot-toast";
import * as Yup from "yup";
import TextEditComponent from "../../../../components/TextEdit/TextEditComponent";

const salesTemplateValidation = Yup.object().shape({
  cuponCode: Yup.string()
    .matches(/^[A-Z0-9]+$/, "Only uppercase letters and numbers are allowed")
    .min(5, "Minium 5 values required")
    .required("Coupon code  is required"),
  discValue: Yup.mixed().required("Discount value  is required"),
  minDiscValue: Yup.mixed().required("Minimum Order Amount  is required"),
  maxDiscAmount: Yup.string().when(["discType"], {
    is: (value) => value === "percentage",
    then: (schema) => schema.required("Maximum discount value is required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  titleContent: Yup.string().required("Title content is required"),
  subContent: Yup.string().required("Sub content is required"),
  wishContent: Yup.string().required("Wish content is required"),
  wishDetialsContent: Yup.string().required("Wish detials is required"),
});
const SalesTemplateAddEdit = () => {
 
  const initialValue = {
  
  };


  const onSubmit = (values) => {
   
  };

  return (
    <div className="h-full">
      <Formik
        initialValues={initialValue}
        enableReinitialize
        validationSchema={salesTemplateValidation}
        onSubmit={onSubmit}
      >
        {({ setFieldValue, values, handleChange, errors }) => (
          <Form>
            <div className="flex flex-col-reverse lg:flex-row gap-12">
              <div className="w-full lg:w-[50%]">
             

                <TextEditComponent
                  name={{
                    data: "titleContent",
                    color: "titleFontColor",
                    bold: "titleFontBold",
                    italic: "titleFontItalics",
                    underline: "titleFontUnderline",
                  }}
                  setFieldValue={setFieldValue}
                  title="Head"
                  values={values}
                  isError={errors.titleContent}
                />

                <TextEditComponent
                  name={{
                    data: "subContent",
                    color: "subFontColor",
                    bold: "subFontBold",
                    italic: "subFontItalics",
                    underline: "subFontUnderline",
                  }}
                  setFieldValue={setFieldValue}
                  title="Sub Head"
                  values={values}
                  isError={errors.subContent}
                />

                <TextEditComponent
                  name={{
                    data: "wishContent",
                    color: "wishFontColor",
                    bold: "wishFontBold",
                    italic: "wishFontItalics",
                    underline: "wishFontUnderline",
                  }}
                  setFieldValue={setFieldValue}
                  title="Wish"
                  values={values}
                  isError={errors.wishContent}
                />
                <TextEditComponent
                  name={{
                    data: "wishDetialsContent",
                    color: "wishDetialsFontColor",
                    bold: "wishDetialsFontBold",
                    italic: "wishDetialsFontItalics",
                    underline: "wishDetialsFontUnderline",
                  }}
                  setFieldValue={setFieldValue}
                  title="Wish Content"
                  values={values}
                  isError={errors.wishDetialsContent}
                />
             
              </div>
              <div className="w-full lg:w-[50%]">
                <div className=" flex flex-col gap-6 mb-5 items-end">
                  <div className="w-[10rem] hidden lg:block">
                    <SubmitButton
                      content={"Save template"}
                      type="submit"
                    //   isLoading={isLoading || editLoading}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="w-[10rem]  mx-auto block lg:hidden">
              <SubmitButton
                content={"Save template"}
                type="submit"
                // isLoading={isLoading || editLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SalesTemplateAddEdit;
