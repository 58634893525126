import React, { useEffect, useRef, useState } from "react";
import { ChromePicker } from "react-color";
import "./colorPickerStyle.scss";
import uuid from "react-uuid";

const ColorPicker = ({ SelectButton, onChange, defaultColor }) => {
  const [color, setColor] = useState({
    hex: defaultColor,
  });
  const ref = useRef(null);
  const [active, setActive] = useState(false);
  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      if (active && ref.current && !ref.current.contains(e.target)) {
        setActive(false);
      }
    });
  }, [active]);
  return (
    <div className="max-w-[10rem] relative">
      <div onClick={() => setActive(true)} className="color-picker-btn">
        {SelectButton({ color })}
      </div>
      <div
        className={`absolute left-[100%] bottom-0-[150%] transition-all color-picker-modal z-10 translate-y-[-50%] bg-white shadow-lg rounded-lg ${
          active ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        ref={ref}
      >
        <div className="flex items-center gap-4 justify-between p-4 border-b-[.5px] mb-4 border-opacity-50">
          <p className="text-lg text-darkBlue font-semibold  ">Choose Color</p>
          <div className="cursor-pointer" onClick={() => setActive(false)}>
            <svg
              width="14"
              height="14"
              viewBox="0 0 17 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.4638 7.9998L15.5749 2.88869C16.2193 2.24425 16.2193 1.17758 15.5749 0.533138C14.9304 -0.111306 13.8638 -0.111306 13.2193 0.533138L8.1082 5.64425L2.99709 0.533138C2.35265 -0.111306 1.28598 -0.111306 0.641536 0.533138C-0.00290796 1.17758 -0.00290796 2.24425 0.641536 2.88869L5.75265 7.9998L0.641536 13.1109C-0.00290796 13.7554 -0.00290796 14.822 0.641536 15.4665C0.97487 15.7998 1.39709 15.9554 1.81931 15.9554C2.24154 15.9554 2.66376 15.7998 2.99709 15.4665L8.1082 10.3554L13.2193 15.4665C13.5526 15.7998 13.9749 15.9554 14.3971 15.9554C14.8193 15.9554 15.2415 15.7998 15.5749 15.4665C16.2193 14.822 16.2193 13.7554 15.5749 13.1109L10.4638 7.9998Z"
                fill="#07415C"
              />
            </svg>
          </div>
        </div>
        <div className="p-4">
          <ChromePicker
            color={color}
            onChange={(e) => {
              onChange(e);
              setColor(e);
            }}
            className="color-picker shadow-none"
          />
        </div>
      </div>
    </div>
  );
};

export default ColorPicker;
