import {
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import {
  CatogeryList,
  deleteImage,
  deleteVideo,
  getVendor,
  productAdd,
  productEdit,
  productExcelUpload,
  productImageUpload,
  productLists,
  productTypeList,
  productVideoUpload,
  removeProductTag,
  singleProduct,
  uploadTaskLists,
} from "./productFunctions";

const useGetProductList = (data) => {
  let enabled = true
  if(data?.enabled == false){
    enabled = false
  }
  return useQuery(["getProductList", data], () => productLists(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    enabled: enabled
  });
};

const useGetSingleProduct = (id) => {
  return useQuery(["getSingleProduct", id], () => singleProduct(id), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
const useGetCatogeryList = () => {
  return useQuery(["getCatogeryList"], () => CatogeryList(), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const useGetProductTypeList = () => {
  return useQuery(["getProductType"], () => productTypeList(), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
const useGetVEndorList = () => {
  return useQuery(["getVendor"], () => getVendor(), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};
const useProductImageUpload = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => productImageUpload(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getSingleProduct");
      return data 
    },
    onError: (data) => {
      return data;
    },
  });
};
const useProductVideoUpload = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => productVideoUpload(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getSingleProduct");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};
const useAddProduct = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => productAdd(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getProductList");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};


const useEditProduct = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => productEdit(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getProductList");
      queryClient.invalidateQueries("getSingleProduct");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};
const useAddProductExcel = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => productExcelUpload(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getProductList");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};
const useDeleteImage = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => deleteImage(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getSingleProduct");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};
const useDeleteVideo = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => deleteVideo(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getSingleProduct");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

const useGetUploadTaskList = (data) => {
  return useQuery(["getUploadTaskList", data], () => uploadTaskLists(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};


const useRemoveProductTag = () => {
  const queryClient = useQueryClient();
  return useMutation((data) => removeProductTag(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries("getProductList");
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

export {
  useGetProductList,
  useGetSingleProduct,
  useProductImageUpload,
  useProductVideoUpload,
  useAddProduct,
  useAddProductExcel,
  useEditProduct,
  useGetCatogeryList,
  useGetProductTypeList,
  useDeleteImage,
  useDeleteVideo,
  useGetVEndorList,
  useGetUploadTaskList,
  useRemoveProductTag
};
