import React, { useContext, useEffect, useState } from "react";
import CustomTable from "../../../components/customTable/CustomTable";
import Pagenation from "../../../components/pagination/Pagenation";
import { Link } from "react-router-dom";
import { Context } from "../../../utilities/context/Context";
import Button from "../../../components/submitButton/Button";
import { useGetCategoryList, useGetSubCategoryList } from "../../../servicesQuery/categoryQuery/categoryQuery";
import CategoryAccordian from "./CategoryAccordian";
import AddCategoryModal from "./AddCategoryModal";
import CategorySelect from "./CategorySelect";

const MainCategory = () => {
  const [pageCount, setPageCount] = useState(1);
  const [pageSize, setPageSize] = useState({
    label: "10",
    value: 10,
  });
  const [open, setOpen] = useState(false);
  const [category,setCategory] =useState({})
  const {value,setValue}=useContext(Context)
  const { data, isLoading, isFetching,isError } = useGetCategoryList({
    page: pageCount,
    search:value,
    invited: false,
    pageSize: pageSize?.value
  });

  useEffect(() => {
    document.querySelector("body").style.overflowY = "scroll";
  }, []);

  const { data: subCatData, isLoading: subCatLoading } = useGetSubCategoryList();
  const subCat_list = subCatData?.data?.map((item) => {
    return {
        label: item.name,
        value: item.name,
        name: item.name,
      };
  });


  const getSubCatList = (subats) => {
    let nameArr1 = subCat_list?.map(item => item.name);
    let nameArr2 = subats?.map(item => item.name);
    let subcats = nameArr1?.filter(item => !nameArr2?.includes(item));
    let result = []
    subcats?.map((i)=> {
        result.push({
            label: i,
            value: i,
            name: i,
        })
    })
    return result
};
  
  const head = [
    {
      key: 0,
      label: "SlNo",
      index: "id",
      width: 6,
    },
    {
      key: 1,
      label: "Category Name",
      index: "name",
      width: 10,
    },
    // {
    //   key: 2,
    //   label: "Description",
    //   index: "description",
    //   width: 12,
    // },
    // {
    //   key: 3,
    //   label: "Status",
    //   index: "status",
    //   width: 8,
    // },
    {
      key: 18,
      label: "Action",
      index: "action",
      width: 7,
    },
  ];
  const categorytBody = () => {
    return data?.data?.map((item,index) => {
      return {
        id: {
          isRender:true,
          render:()=>{
            return (
              <div className="h-[100%] flex items-start">
                <span>{(pageCount - 1) * pageSize?.value + index+1}</span>
              </div>
            )
          }
        },
        name: {
            isRender: true,
            render: ()=> (<CategoryAccordian mainHead={item.name} sub_categories={item.child_categories} key={index} />)
        },
        // description: item.description || "No data",
        // status: {
        //     isRender: true,
        //     render: ()=> (<span className={`${item.is_active? "text-[#79B364] font-bold" : "" }`}>{item.is_active? "Active": "Inactive"}</span>)
        // },
        action: {
          isRender: true,
          render: () => (
            <div className="flex  items-center w-full gap-4 h-full">
              <CategorySelect options={getSubCatList(item.child_categories)} item={item} />
              {/* <div className="p-2 rounded-lg hover:bg-lightBlue transition-all">
              <Link to={`product-detials/${item.id}`}>
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 9 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.999973 16.92L7.51997 10.4C8.28997 9.62996 8.28997 8.36996 7.51997 7.59996L0.999973 1.07996"
                    stroke="#07415C"
                    strokeMiterlimit="10"
                    strokelinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
              </div> */}
            </div>
          ),
        },
      };
    });
  };
  const statuses = [
    {
        label: "Active",
        value: "active",
    },
    {
        label: "Inactive",
        value: "inactive",
    }
  ]
  return (
    <div >
      <div className="w-full  bg-white rounded-xl shadow-lg p-6 ">
        <div className="h-full">
        <div className="mb-8">
          <div className="flex gap-6 justify-between">
            {/* <div className="flex gap-6 w-[6rem]">
                <CustomSelect
                    placeholder={"Status"}
                    name="status"
                    value="active"
                    options={statuses}
                />
            </div> */}

            {/* <Button
              onClick={() => setOpen(true)}
              style="text-xs w-[10rem]  border border-2 border-darkYellow  text-darkBlue   rounded-md py-2 px-6"
              content="Add New Category"
            /> */}
          </div>
        </div>
          <div className="w-full h-[95%]">
            <CustomTable
              head={head}
              body={categorytBody()}
              loading={isLoading || isFetching}
              dataLength={data?.data.length}
              height="max-h-[55vh]"
              error={isError}
            />
          </div>
        </div>
      </div>
      {/* <div className="w-full flex justify-center my-6">
        <Pagenation
          pageCount={setPageCount}
          loading={isLoading || isFetching}
          data={data?.data?.results}
          next={data?.data?.next}
          currDataCount={data?.data?.data_count}
          totalLength={data?.data?.count}
          previous={data?.data?.previous}
          pageSize={pageSize}
          setPageSize={setPageSize}
        />
      </div> */}
      {/* <AddCategoryModal open={open} setOpen={setOpen} /> */}
    </div>
  );
};

export default MainCategory;
