import React from 'react'
import { useState } from 'react';
import toast from 'react-hot-toast';
import ModalLayout from '../../../components/customModal/CustomModal';
import CustomSelect from '../../../components/customSelect/CustomSelect';
import Button from '../../../components/submitButton/Button';
import SubmitButton from '../../../components/submitButton/SubmitButton';
import { useDeleteProductType, useEditSpecificationProduct } from '../../../servicesQuery/categoryQuery/categoryQuery';

function SpecificationEditModal({ open, setOpen,data,specificationData,product }) {
    const { mutateAsync: editSpecification, isLoading } = useEditSpecificationProduct();
    const [item,setItem] = useState({
        id: data?.id,
        value: data?.value,
        product: product,
        specification_id: data?.specification_id,
        unit: data?.specification?.unit,
        specification: {
                    value: data?.specification.id,
                    label: data?.specification.name,
                    unit: data?.specification?.unit?.symbol},
    })

    const handleSelect = (value,name) => {
        if(name === "specification"){
            setItem((prevItem)=> {
                return {
                    ...prevItem,
                    specification_id: value?.value,
                    specification: value,
                    unit: value?.unit
                } 
            })
        }else {
            setItem((prevItem)=> {
                return {
                    ...prevItem,
                    [name]: value
                } 
            })
        }
        
    }

    const handleSubmit = ()=> {
        editSpecification(item)
        .then((res) => {
            if(res?.status === 200){
                toast.success("Specification edited");
                setOpen(false);
            }
        })
        .catch((err) => {
            console.log(err);
          if (err?.response?.data?.error) {
            toast.error(err?.response?.data?.error);
          }else if (err?.response?.data && err?.response?.data[0]?.non_field_errors) {
            toast.error("Specification Already exist");
          } else {
            toast.error("Something went wrong");
          }
        });
    }

    
  return (
    <ModalLayout modalOpen={open} setModalOpen={setOpen}>
      <div className="bg-white  rounded-lg  w-[90%] hxl:max-w-[20rem] m-4 p-5 ">
      <div className="w-full flex justify-between border-b-[.5px] border-darkBlue border-opacity-20 mb-4">
          <h2 className="text-lg text-darkBlue font-bold">Edit Specification</h2>
          <div
            className="cursor-pointer "
            onClick={() => {
              setOpen(false);
            }}
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3554 8.00005L15.4665 2.88894C16.1109 2.24449 16.1109 1.17783 15.4665 0.533382C14.822 -0.111062 13.7554 -0.111062 13.1109 0.533382L7.9998 5.64449L2.88869 0.533382C2.24425 -0.111062 1.17758 -0.111062 0.533138 0.533382C-0.111306 1.17783 -0.111306 2.24449 0.533138 2.88894L5.64425 8.00005L0.533138 13.1112C-0.111306 13.7556 -0.111306 14.8223 0.533138 15.4667C0.866471 15.8 1.28869 15.9556 1.71092 15.9556C2.13314 15.9556 2.55536 15.8 2.88869 15.4667L7.9998 10.3556L13.1109 15.4667C13.4442 15.8 13.8665 15.9556 14.2887 15.9556C14.7109 15.9556 15.1331 15.8 15.4665 15.4667C16.1109 14.8223 16.1109 13.7556 15.4665 13.1112L10.3554 8.00005Z"
                fill="#07415C"
              />
            </svg>
          </div>
        </div>
      <div className="flex flex-col gap-2 justify-center items-center">
              <CustomSelect
                placeholder={"Select specification"}
                name="subcategory"
                value={item?.specification}
                onChange={(e) => {
                  handleSelect(e,"specification");
                }}
                options={specificationData}
                width={"w-1/2"}
              />
              <div className='flex items-center gap-2 w-full'>
              <input
                type="text"
                placeholder="Enter value"
                onChange={(e) => {
                    handleSelect(e.target.value, "value");
                }}
                value={item?.value}
                className="rounded-md border-[1px] bg-opacity-0 focus-within:border-[#575252]  transition-all    border-[#bab1b1] overflow-hidden px-4 py-[8px] w-full text-sm placeholder:text-darkBlue placeholder:opacity-30 placeholder:text-sm"
              />
              <span>{item?.unit?.symbol}</span>
              </div>
              <Button
                style="text-xs w-[10rem] hover:shadow-md active:shadow-sm transition-all active:scale-95  border border-2 border-darkYellow  text-darkBlue   rounded-md py-2 px-6"
                content="Edit"
                type="butten"
                onClick={()=> handleSubmit()}
                disabled={isLoading}
            />
            </div>
      </div>
    </ModalLayout>
  )
}

export default React.memo(SpecificationEditModal)
