import { useMutation, useQuery, useQueryClient } from "react-query";
import { reviewDelete, reviewImgDelete, reviewLists, reviewPublish } from "./reviewFunctions";

const useGetReviewList = (data) => {
  return useQuery(["getReviewList", data], () => reviewLists(data), {
    // staleTime: 30000,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
};

const useDeleteReviewImg = () => {
    const queryClient = useQueryClient();
    return useMutation((id) => reviewImgDelete(id), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getReviewList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const useDeleteReview = () => {
    const queryClient = useQueryClient();
    return useMutation((id) => reviewDelete(id), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getReviewList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

  const usePublishReview = () => {
    const queryClient = useQueryClient();
    return useMutation((data) => reviewPublish(data), {
      onSuccess: (data) => {
        queryClient.invalidateQueries("getReviewList");
        return data;
      },
      onError: (data) => {
        return data;
      },
    });
  };

export {
    useGetReviewList,
    useDeleteReviewImg,
    useDeleteReview,
    usePublishReview
}