import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const PrivetRoute = () => {
  const token = JSON.parse(localStorage.getItem("Tokens"));

  return <> {token?.access ? <Outlet /> : <Navigate to="/signin" />} </>;
};

export default PrivetRoute;
