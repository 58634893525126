import { useMutation } from "react-query";
import {
  resetPasswordConfirm,
  resetPasswordEmail,
  resetVendorPassword,
  signin,
} from "./authFunctions";
import { useNavigate } from "react-router-dom";

const useLoginQuery = () => {

  return useMutation((userData) => signin(userData), {
    onSuccess: (data) => {
      if (data.data.access_token) {
        localStorage.setItem(
          "Tokens",
          JSON.stringify({
            access: data.data.access_token,
            refresh: data.data.refresh,
          })
        );
        
        localStorage.setItem(
          "permissions",
          JSON.stringify(data.data.permissions)
        );
       
        window.location.reload()
      }
      if (data.data) {
        localStorage.setItem("USERID", JSON.stringify(data?.data?.full_name));
        localStorage.setItem("user_type", JSON.stringify(data?.data?.user_type));
      }
    },
    onError: (data) => {
      return data;
    },
  });
};
const useResetPasswordQuery = () => {
  return useMutation((email) => resetPasswordEmail(email), {
    onSuccess: (data) => {
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};
const useResetPasswordConfirmQuery = () => {
  return useMutation((detials) => resetPasswordConfirm(detials), {
    onSuccess: (data) => {
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

const useVendorResetPassword = () => {
  return useMutation((detials) => resetVendorPassword(detials), {
    onSuccess: (data) => {
      return data;
    },
    onError: (data) => {
      return data;
    },
  });
};

export { useLoginQuery, useResetPasswordQuery, useResetPasswordConfirmQuery,useVendorResetPassword };
