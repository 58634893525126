import React from "react";

import { Field } from "formik";
import ErrorMessageComponent from "../../../../components/errorMessage/ErrorMessageComponent";

import BorderdInputField from "../../../../components/customField/BorderdInputField";
import { onlyNumbers } from "../../../../utilities/onlyNumbers/onlyNumbers";
import { useLocation } from "react-router-dom";

const Flat = ({ values }) => {
  return (
    <div>
      <div className="w-full">
        <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
          Discount Value
        </label>
        <BorderdInputField
          placeholder={"Discount Value"}
          width={"w-full"}
          name="discValue"
          value={values.discValue}
          onInput={(e) => onlyNumbers(e)}
        />
        <ErrorMessageComponent name={"discValue"} />
      </div>
      <div className="w-full">
        <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
          Minimum Order Amount
        </label>
        <BorderdInputField
          placeholder={"Minimum Order Amount"}
          width={"w-full"}
          name="minOrderAmount"
          value={values.minOrderAmount}
          onInput={(e) => onlyNumbers(e)}
        />
        <ErrorMessageComponent name={"minOrderAmount"} />
      </div>
    </div>
  );
};
const Percentage = ({ values }) => {
  return (
    <div>
      <div className="w-full">
        <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
          Discount Value %
        </label>
        <BorderdInputField
          placeholder={"Discount Value"}
          width={"w-full"}
          name="discValue"
          onInput={(e) => onlyNumbers(e)}
          value={values.discValue}
        />
        <ErrorMessageComponent name={"discValue"} />
      </div>
      <div className="w-full">
        <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
          Minimum Order Amount
        </label>
        <BorderdInputField
          placeholder={"Minimum Order Amount"}
          width={"w-full"}
          name="minOrderAmount"
          onInput={(e) => onlyNumbers(e)}
          value={values.minOrderAmount}
        />
        <ErrorMessageComponent name={"minOrderAmount"} />
      </div>
      <div className="w-full">
        <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
          Maximum Discount Amount
        </label>
        <BorderdInputField
          placeholder={"Maximum Discount Amount"}
          width={"w-full"}
          name="maxOrderAmount"
          onInput={(e) => onlyNumbers(e)}
          value={values.maxOrderAmount}
        />
        <ErrorMessageComponent name={"maxOrderAmount"} />
      </div>
    </div>
  );
};
const FreeShipping = ({ values }) => {
  return (
    <div>
      <div className="w-full">
        <label className="mb-3 block text-sm text-darkBlue" htmlFor="type">
          Minimum Order Amount
        </label>
        <BorderdInputField
          placeholder={"Minimum Order Amount"}
          width={"w-full"}
          name="minOrderAmount"
          value={values.minOrderAmount}
          onInput={(e) => onlyNumbers(e)}
        />
        <ErrorMessageComponent name={"minOrderAmount"} />
      </div>
    </div>
  );
};

const DiscountSection = ({ change, setFieldValue, values }) => {
  const pathname = useLocation().pathname.split("/");

  return (
    <div className="p-6 rounded-lg bg-white ">
      <h1 className="text-lg font-semibold text-darkBlue mb-4">Discount</h1>
      <div className="flex flex-col gap-x-4">
        <div className="mb-6">
          <label className="block mb-3 text-sm  font-bold text-darkBlue">
            Discount Type
          </label>
          <div className="flex gap-4 flex-wrap">
            <div className="flex items-center gap-3">
              <p className="text-sm text-darkBlue">Flat</p>

              <Field
                type="radio"
                className="accent-secondary w-4 h-4"
                name="discountType"
                value="fixed"
                onChange={(e) => {
                  setFieldValue("discValue", "");
                  setFieldValue("minOrderAmount", "");
                  setFieldValue("maxOrderAmount", "");
                  change(e);
                }}
              />
            </div>
            <div className="flex items-center gap-3">
              <p className="text-sm text-darkBlue">Percentage</p>

              <Field
                type="radio"
                className="w-4 h-4 accent-secondary "
                name="discountType"
                value="percentage"
                onChange={(e) => {
                  setFieldValue("discValue", "");
                  setFieldValue("minOrderAmount", "");
                  setFieldValue("maxOrderAmount", "");
                  change(e);
                }}
              />
            </div>
            {pathname.includes("normal-coupons") && (
              <div className="flex items-center gap-3">
                <p className="text-sm text-darkBlue">Free Shipping</p>

                <Field
                  type="radio"
                  className="w-4 h-4 accent-secondary "
                  name="discountType"
                  value="free shipping"
                  onChange={(e) => {
                    setFieldValue("discValue", "");
                    setFieldValue("minOrderAmount", "");
                    setFieldValue("maxOrderAmount", "");
                    change(e);
                  }}
                />
              </div>
            )}
          </div>
        </div>

        {values?.discountType === "fixed" && <Flat values={values} />}
        {values?.discountType === "percentage" && (
          <Percentage values={values} />
        )}
        {values?.discountType === "free shipping" && (
          <FreeShipping values={values} />
        )}
      </div>
    </div>
  );
};

export default DiscountSection;
